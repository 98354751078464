import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Modal, Row, Space, message } from 'antd';
import FileSaver from 'file-saver';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import bankApi from '../../../apis/BankApi';
import seizureBankAmountRequestApi from '../../../apis/SeizureBankAmountRequestApi';
import { CouncilTaxpayerSeizureBankAmountRequest, SeizureBankAmountRequestCreation, SeizureBankAmountRequestDraft } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './SeizureBankAmountRequestModal.module.scss';

const SeizureBankAmountRequestModal = (props: Props) => {
    const { councilId } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [seizureBankAmountRequestDraft, setSeizureBankAmountRequestDraft] = useState<SeizureBankAmountRequestDraft>();
    const [loading, setLoading] = useState<'loading' | 'saving'>();
    const [seizureBankAmountRequestCreation, setSeizureBankAmountRequestCreation] = useState<SeizureBankAmountRequestCreation>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                // list banks (council and parent online)
                const banksPage = await bankApi.list(0, 1000, 'name', true, 'ENABLED', undefined, undefined, undefined, undefined, councilId);

                const seizureBankAmountRequestCreation: SeizureBankAmountRequestCreation = {
                    councilId: councilId,
                    bankIds: banksPage.content.map((b) => b.id!)
                };
                setSeizureBankAmountRequestCreation(seizureBankAmountRequestCreation);

                const seizureBankAmountRequestDraft = await seizureBankAmountRequestApi.createDraft(seizureBankAmountRequestCreation);
                setSeizureBankAmountRequestDraft(seizureBankAmountRequestDraft);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, intl]);

    /*** METHODS ***/

    const save = async () => {
        try {
            setLoading('saving');
            await seizureBankAmountRequestApi.create(seizureBankAmountRequestCreation!);
            await props.onSave();
            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // cancel
    const cancel = async () => {
        props.onCancel();
    };

    /*** VISUAL ***/

    const ctSeizureBankAmountRequests = seizureBankAmountRequestDraft ? seizureBankAmountRequestDraft.ctSeizureBankAmountRequests : [];

    return (
        <Modal
            title={<FormattedMessage id="seizureBankAmountRequestModal.title" />}
            open={true}
            onCancel={cancel}
            onOk={save}
            okText={<FormattedMessage id="seizureBankAmountRequestModal.send" tagName="span" />}
            okButtonProps={{ loading: loading === 'saving', size: 'large' }}
            cancelButtonProps={{ disabled: loading === 'saving', size: 'large' }}
            width={1000}
        >
            <Row gutter={[0, 0]} className={styles.container}>
                <Col span={24} className={styles.file}>
                    <Col>
                        <Row gutter={[0, 0]} className={styles.container}>
                            <Col span={24}>
                                <FormattedMessage id="seizureBankAmountRequestModal.banks" />
                                <CouncilTaxpayerSeizureAmountBanks
                                    ctSeizureBankAmountRequests={ctSeizureBankAmountRequests}
                                    seizureBankAmountRequestCreation={seizureBankAmountRequestCreation}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Col>
            </Row>
        </Modal>
    );
};
export default SeizureBankAmountRequestModal;

interface Props {
    councilId: number;
    onSave: () => Promise<void>;
    onCancel: () => void;
}

const CouncilTaxpayerSeizureAmountBanks: React.FC<CouncilTaxpayerSeizureAmountBanksProps> = (props) => {
    const { ctSeizureBankAmountRequests, seizureBankAmountRequestCreation } = props;

    // group council taxpayer seizure banks by bank id
    const ctSeizuresByBanks = _.groupBy(ctSeizureBankAmountRequests, (ctscb) => ctscb.councilTaxpayerSeizureBank.bank.id);

    if (seizureBankAmountRequestCreation && Object.keys(ctSeizuresByBanks).length > 0) {
        return (
            <ul>
                {Object.keys(ctSeizuresByBanks).map((bankId) => {
                    const ctSeizureBankAmountRequestsByBank = ctSeizuresByBanks[bankId];

                    return (
                        <CouncilTaxpayerSeizureBankAmountComponent
                            ctSeizureBankAmountRequests={ctSeizureBankAmountRequestsByBank}
                            seizureBankAmountRequestCreation={seizureBankAmountRequestCreation}
                        />
                    );
                })}
            </ul>
        );
    } else {
        return (
            <p>
                <ul>
                    <li>
                        <strong>
                            <FormattedMessage id="seizureBankInfoRequestModal.councilTaxpayerSeizureBanks.empty" />
                        </strong>
                    </li>
                </ul>
            </p>
        );
    }
};
interface CouncilTaxpayerSeizureAmountBanksProps {
    ctSeizureBankAmountRequests: CouncilTaxpayerSeizureBankAmountRequest[];
    seizureBankAmountRequestCreation: SeizureBankAmountRequestCreation | undefined;
}

const CouncilTaxpayerSeizureBankAmountComponent: React.FC<CouncilTaxpayerSeizureBankAmountProps> = (props) => {
    const { ctSeizureBankAmountRequests, seizureBankAmountRequestCreation } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [checked, setChecked] = useState<boolean>();
    const [loading, setLoading] = useState<'downloading'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                const id = ctSeizureBankAmountRequests[0].councilTaxpayerSeizureBank.bank.id!;
                let checked = seizureBankAmountRequestCreation.bankIds.some((bankId) => bankId === id);
                setChecked(checked);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [ctSeizureBankAmountRequests, intl, seizureBankAmountRequestCreation]);

    /*** METHODS ***/

    const select = (checked: boolean) => {
        const id = ctSeizureBankAmountRequests[0].councilTaxpayerSeizureBank.bank.id!;
        if (checked) {
            seizureBankAmountRequestCreation.bankIds = [...seizureBankAmountRequestCreation.bankIds, id];
        } else {
            seizureBankAmountRequestCreation.bankIds = seizureBankAmountRequestCreation.bankIds.filter((bankId) => bankId === id);
        }
        setChecked(checked);
    };

    const download = async () => {
        try {
            setLoading('downloading');
            const document = await seizureBankAmountRequestApi.downloadDraft(
                ctSeizureBankAmountRequests[0].councilTaxpayerSeizureBank.councilTaxpayer.councilId,
                ctSeizureBankAmountRequests[0].councilTaxpayerSeizureBank.bank.id!
            );
            FileSaver.saveAs(document, 'c63.dat');
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const name = ctSeizureBankAmountRequests[0].councilTaxpayerSeizureBank.bank.name!;
    const councilTaxpayers = ctSeizureBankAmountRequests.map((ct) => ct.councilTaxpayerSeizureBank.councilTaxpayer);

    return (
        <div className={styles.bank}>
            <h4>
                <Space>
                    <Checkbox checked={checked} onChange={(e) => select(e.target.checked)}>
                        {name}
                    </Checkbox>
                    <Button icon={<CloudDownloadOutlined />} size="small" onClick={download} loading={loading === `downloading`}></Button>
                </Space>
            </h4>
            <p>{councilTaxpayers.map((councilTaxpayer) => councilTaxpayer.taxpayer.fullName).join(', ')}</p>
        </div>
    );
};

interface CouncilTaxpayerSeizureBankAmountProps {
    ctSeizureBankAmountRequests: CouncilTaxpayerSeizureBankAmountRequest[];
    seizureBankAmountRequestCreation: SeizureBankAmountRequestCreation;
}
