import { Tabs, TabsProps } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import councilTaxpayerApi from '../../../apis/CouncilTaxpayerApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import CustomCouncilContext from '../../../contexts/CustomCouncilContext';
import CustomCouncilTaxpayerContext from '../../../contexts/CustomCouncilTaxpayerContext';
import { BreadcrumbItem } from '../../../models/Elements';
import { Council, CouncilTaxpayer } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import CouncilTaxpayerArchivesComponent from './CouncilTaxpayerArchivesComponent/CouncilTaxpayerArchivesComponent';
import CouncilTaxpayerIrpfsComponent from './CouncilTaxpayerIrpfsComponent/CouncilTaxpayerIrpfsComponent';
import styles from './CouncilTaxpayerPage.module.scss';
import CouncilTaxpayerPaymentOrdersComponent from './CouncilTaxpayerPaymentOrdersComponent/CouncilTaxpayerPaymentOrdersComponent';
import CouncilTaxpayerPropertiesComponent from './CouncilTaxpayerPropertiesComponent/CouncilTaxpayerPropertiesComponent';
import CouncilTaxpayerReceivablesComponent from './CouncilTaxpayerReceivablesComponent/CouncilTaxpayerReceivablesComponent';
import CouncilTaxpayerSeizureIncomesComponent from './CouncilTaxpayerSeizureIncomesComponent/CouncilTaxpayerSeizureIncomesComponent';
import CouncilTaxpayerSeizureBanksComponent from './CouncilTaxpayerSeizureBanksComponent/CouncilTaxpayerSeizureBanksComponent';
import CouncilTaxpayerSummaryComponent from './CouncilTaxpayerSummaryComponent/CouncilTaxpayerSummaryComponent';
import CouncilTaxpayerVehiclesComponent from './CouncilTaxpayerVehiclesComponent/CouncilTaxpayerVehiclesComponent';

/**
 * Returns the council taxpayer receivables page.
 * @returns the council taxpayer receivables page.
 */
const CouncilTaxpayerPage: React.FC = () => {
    /*** HOOKS ***/

    const { council } = useContext(CustomCouncilContext);
    const { councilTaxpayer } = useContext(CustomCouncilTaxpayerContext);
    const [currentCouncilTaxpayer, setCurrentCouncilTaxpayer] = useState<CouncilTaxpayer>();
    const [, setLoading] = useState<'loading'>();
    const intl = useIntl();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                setCurrentCouncilTaxpayer(councilTaxpayer);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [council, councilTaxpayer, intl]);

    /*** METHODS ***/

    const refresh = async () => {
        if (councilTaxpayer) {
            const currentCouncilTaxpayer = await councilTaxpayerApi.get(councilTaxpayer.id);
            setCurrentCouncilTaxpayer(currentCouncilTaxpayer);
        }
    };

    /*** VISUAL ***/

    const tabs: TabsProps['items'] = [
        {
            key: 'receivables',
            label: <FormattedMessage id="councilTaxpayer.receivables" />,
            children: <CouncilTaxpayerReceivablesComponent onRefresh={refresh} />
        },
        {
            key: 'paymentOrders',
            label: <FormattedMessage id="councilTaxpayer.paymentOrders" />,
            children: <CouncilTaxpayerPaymentOrdersComponent />
        },
        {
            key: 'banks',
            label: <FormattedMessage id="councilTaxpayer.banks" />,
            children: <CouncilTaxpayerSeizureBanksComponent />
        },
        {
            key: 'salaries',
            label: <FormattedMessage id="councilTaxpayer.salaries" />,
            children: <CouncilTaxpayerSeizureIncomesComponent />
        },
        {
            key: 'irpfs',
            label: <FormattedMessage id="councilTaxpayer.irpfs" />,
            children: <CouncilTaxpayerIrpfsComponent />,
            disabled: true
        },

        {
            key: 'properties',
            label: <FormattedMessage id="councilTaxpayer.properties" />,
            children: <CouncilTaxpayerPropertiesComponent />,
            disabled: true
        },
        {
            key: 'vehicles',
            label: <FormattedMessage id="councilTaxpayer.vehicles" />,
            children: <CouncilTaxpayerVehiclesComponent />,
            disabled: true
        },
        {
            key: 'archives',
            label: <FormattedMessage id="councilTaxpayer.archives" />,
            children: <CouncilTaxpayerArchivesComponent />,
            disabled: true
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="receivables.title" />}
            menu={'taxpayers'}
            path={PathComponent(council, councilTaxpayer)}
            council={council}
        >
            <div className={styles.summaryComponent}>
                <CouncilTaxpayerSummaryComponent councilTaxpayer={currentCouncilTaxpayer} />
            </div>
            <Tabs defaultActiveKey="receivables" type="card" className={styles.tabs} size="large" items={tabs} />
        </LayoutComponent>
    );
};
export default CouncilTaxpayerPage;

/**
 * Returns the path of the receivables page.
 * @param council the council
 * @param taxpayer the taxpayer
 * @returns  the path
 */
const PathComponent = (council: Council, councilTaxpayer: CouncilTaxpayer | undefined): BreadcrumbItem[] => {
    return [
        { path: '/councils', name: <FormattedMessage id="councils.title" /> },
        { path: `/councils/${council.id}`, name: council.name },
        { path: `/councils/${council.id}/taxpayers`, name: <FormattedMessage id="taxpayers.title" /> },
        {
            path: `/councils/${council.id}/taxpayers/${councilTaxpayer?.id}`,
            name: `${councilTaxpayer?.taxpayer?.fullName}`
        }
    ];
};
