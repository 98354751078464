import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Popconfirm, Row, Select, message } from 'antd';
import { Space } from 'antd/lib';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import bankApi from '../../../apis/BankApi';
import councilCollectionAccountApi from '../../../apis/CouncilCollectionAccountApi';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomContext from '../../../contexts/CustomContext';
import CustomCouncilContext from '../../../contexts/CustomCouncilContext';
import { Bank, CouncilCollectionAccount } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import rolesService from '../../../services/RolesService';
import stringService from '../../../services/StringService';

/**
 * Returns the council collection account page.
 * @returns the council collection account page.
 */
const CouncilCollectionAccountPage: React.FC = () => {
    /***HOOKS***/
    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { council } = useContext(CustomCouncilContext);
    const { auth } = useContext(CustomContext);
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [councilCollectionAccount, setCouncilCollectionAccount] = useState<CouncilCollectionAccount>();
    const [banks, setBanks] = useState<Bank[]>([]);
    const params = useParams<ParamsType>();

    /*** EFFECTS ***/
    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('initializing');

                    const banks = await bankApi.list(0, 1000, 'name', true, 'ENABLED', undefined, undefined, true);
                    setBanks(banks.content);

                    let councilCollectionAccount: CouncilCollectionAccount;
                    if (params.id === 'new') {
                        councilCollectionAccount = { councilId: council.id, status: 'ENABLED' };
                    } else {
                        councilCollectionAccount = await councilCollectionAccountApi.get(+params.id!);
                    }
                    setCouncilCollectionAccount(councilCollectionAccount);

                    form.setFieldsValue(councilCollectionAccount);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [council, form, intl, params.id]);

    /*** METHODS ***/
    const save = async (values: any) => {
        try {
            setLoading('loading');

            const bank = banks.find((b) => b.id === values.bank.name);
            let updatedCouncilCollectionAccount: CouncilCollectionAccount = Object.assign({}, councilCollectionAccount, values, { bank });
            if (updatedCouncilCollectionAccount.id) {
                updatedCouncilCollectionAccount = await councilCollectionAccountApi.update(updatedCouncilCollectionAccount);
            } else {
                updatedCouncilCollectionAccount = await councilCollectionAccountApi.create(updatedCouncilCollectionAccount);
            }
            setCouncilCollectionAccount(updatedCouncilCollectionAccount);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async () => {
        try {
            if (councilCollectionAccount && councilCollectionAccount.id) {
                setLoading('deleting');
                await councilCollectionAccountApi.delete(councilCollectionAccount.id);
                message.success(intl.formatMessage({ id: 'status.account.disabled' }));
                navigate(`/councils/${council?.id}/collection-accounts`);
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);

    const bankOptions = banks.map((bank) => (
        <Select.Option key={bank.id} value={bank.id}>
            {bank.name}
        </Select.Option>
    ));

    return (
        <LayoutComponent
            title={<FormattedMessage id="councilCollectionAccounts.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/collection-accounts`, name: <FormattedMessage id="councilCollectionAccounts.title" /> },
                {
                    path: `/councils/${council?.id}/collection-accounts/${councilCollectionAccount?.id}`,
                    name: councilCollectionAccount && councilCollectionAccount.id && `${councilCollectionAccount?.iban}`
                }
            ]}
            council={council}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col xs={24} lg={12}>
                        <Form.Item
                            label={<FormattedMessage id="councilCollectionAccount.bank" />}
                            name={['bank', 'name']}
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Select size="large" allowClear showSearch disabled={!!councilCollectionAccount?.id} filterOption={stringService.filterOptions}>
                                {bankOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="councilCollectionAccount.iban" />}
                            name="iban"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} disabled={!!councilCollectionAccount?.id} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className="buttons">
                    <Col span={16}>
                        <Space>
                            {isAdmin && (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    size="large"
                                    loading={loading === 'loading'}
                                    icon={<SaveOutlined />}
                                    disabled={!!councilCollectionAccount?.id}
                                >
                                    <FormattedMessage id="button.save" tagName="span" />
                                </Button>
                            )}
                            {council?.id !== undefined && isAdmin && councilCollectionAccount?.status! === 'ENABLED' && (
                                <Popconfirm
                                    title={intl.formatMessage({ id: 'councilCollectionAccount.deleteModal.title' })}
                                    description={intl.formatMessage({ id: 'councilCollectionAccount.deleteModal.text' })}
                                    onConfirm={remove}
                                    okText={intl.formatMessage({ id: 'councilCollectionAccount.deleteModal.yes' })}
                                    cancelText={intl.formatMessage({ id: 'councilCollectionAccount.deleteModal.no' })}
                                >
                                    <Button type="primary" danger size="large" icon={<DeleteOutlined />}>
                                        {desktop && <FormattedMessage id="button.disable" tagName="span" />}
                                    </Button>
                                </Popconfirm>
                            )}
                            <Link to={`/councils/${council?.id}/collection-accounts`}>
                                <Button size="large" icon={<ArrowLeftOutlined />}>
                                    {desktop && <FormattedMessage id="button.back" tagName="span" />}
                                </Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </LayoutComponent>
    );
};

export default CouncilCollectionAccountPage;
type ParamsType = { id: string };
