import { Tag } from 'antd';
import { ReceptionStateType } from '../../models/Types';
import ConstantLabel from '../ConstantLabel/ConstantLabel';
import styles from './ReceptionStateTag.module.scss';

/**
 * Returns a reception state tag.
 * @returns the reception state tag.
 */
const ReceptionStateTag: React.FC<Props> = (props) => {
    const { value } = props;

    if (value === 'RECEIVED') {
        return (
            <Tag color="green" className={styles.text}>
                <ConstantLabel value={value} prefix="paymentOrder.receptionState." />
            </Tag>
        );
    } else if (value === 'ABSENT' || value === 'WRONG_ADDRESS' || value === 'UNKNOWN') {
        return (
            <Tag color="red" className={styles.text}>
                <ConstantLabel value={value} prefix="paymentOrder.receptionState." />
            </Tag>
        );
    } else {
        return (
            <Tag color="orange" className={styles.text}>
                <ConstantLabel value={'SENT'} prefix="paymentOrder.receptionState." />
            </Tag>
        );
    }
};
export default ReceptionStateTag;

interface Props {
    value: ReceptionStateType | undefined;
}
