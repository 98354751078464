import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, DescriptionsProps, Row } from 'antd';
import FileSaver from 'file-saver';
import { useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import receivableCancellationApi from '../../../../apis/ReceivableCancellationApi';
import { ReceivableCancellation } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivableCancellationComponent.module.scss';

const ReceivableCancellationComponent = (props: Props) => {
    const { cancellation } = props;

    /*** HOOKS ***/
    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading'>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            const document = await receivableCancellationApi.download(cancellation.id!);
            FileSaver.saveAs(document, cancellation.file?.name);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const items: DescriptionsProps['items'] = [
        {
            key: 'file',
            label: <FormattedMessage id="receivable.cancellation.file.name" />,
            children: cancellation.file.name,
            span: 1
        },
        {
            key: 'remarks',
            label: <FormattedMessage id="receivable.cancellation.modal.remarks" />,
            children: cancellation.remarks,
            span: 2
        },
        {
            key: 'canceled',
            label: <FormattedMessage id="receivable.cancellation.canceled" />,
            children: cancellation.canceled && (
                <FormattedDate value={cancellation.canceled as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            ),
            span: 2
        }
    ];

    return (
        <Row gutter={[28, 0]}>
            <Col span={24}>
                <Descriptions layout="horizontal" items={items} size="default" column={4} />
            </Col>
            <Button className={styles.button} icon={<CloudDownloadOutlined />} onClick={download} loading={loading === 'downloading'}>
                <FormattedMessage id="button.download" tagName="span" />
            </Button>
        </Row>
    );
};

export default ReceivableCancellationComponent;

interface Props {
    cancellation: ReceivableCancellation;
}
