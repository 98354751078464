import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, PaperClipOutlined, SaveOutlined } from '@ant-design/icons';
import { App, Button, Col, Descriptions, DescriptionsProps, Form, Input, List, Row, Space, Tag, Upload, message } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { UploadFile } from 'antd/lib';
import dayjs from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import councilCertificateApi from '../../apis/CouncilCertificateApi';
import FileSizeComponent from '../../components/FileSizeComponent/FileSizeComponent';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { Page } from '../../models/Elements';
import { CouncilCertificate, PublicCouncilCertificate } from '../../models/Entities';
import alertService from '../../services/AlertService';
import styles from './CouncilCertificatePage.module.scss';

/**
 * Returns the council certificate page.
 * @returns the council certificate page.
 */
const CouncilCertificatePage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const { council } = useContext(CustomCouncilContext);
    const [, setLoading] = useState<'loading'>();
    const [publicCouncilCertificate, setPublicCouncilCertificate] = useState<PublicCouncilCertificate>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                let publicCouncilCertificate: PublicCouncilCertificate;
                let publicCouncilCertificatesPage: Page<PublicCouncilCertificate> = await councilCertificateApi.list(council.id!, 0, 1, 'id', true);
                if (!publicCouncilCertificatesPage.empty) {
                    publicCouncilCertificate = publicCouncilCertificatesPage.content[0];
                    setPublicCouncilCertificate(publicCouncilCertificate);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [council.id, form, intl]);

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="council.certificates.title" />}
            menu="councils"
            path={[{ path: `/councils/${council?.id}/certificates`, name: <FormattedMessage id="council.certificates.title" /> }]}
            council={council}
        >
            {publicCouncilCertificate && (
                <PublicCouncilCertificateComponent
                    publicCouncilCertificate={publicCouncilCertificate}
                    onDelete={() => setPublicCouncilCertificate(undefined)}
                />
            )}
            {!publicCouncilCertificate && <CouncilCertificateForm onSave={setPublicCouncilCertificate} />}
        </LayoutComponent>
    );
};
export default CouncilCertificatePage;

/**
 * Returns the council certificate form.
 * @returns the council certificate form.
 */
const CouncilCertificateForm: React.FC<CouncilCertificateFormProps> = (props) => {
    const { onSave } = props;
    const maxFileSize = 5 * 1024 * 1024;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [desktop] = useResponsiveLayout();
    const { council } = useContext(CustomCouncilContext);
    const [loading, setLoading] = useState<'loading' | 'saving'>();
    const [files, setFiles] = useState<UploadFile[]>([]);

    /*** EFFECTS ***/

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('saving');

            let councilCertificate: CouncilCertificate = Object.assign({}, values);
            councilCertificate.councilId = council.id;

            const publicCouncilCertificate = await councilCertificateApi.create(councilCertificate, files[0]);
            message.success(intl.formatMessage({ id: 'status.saved' }));
            onSave(publicCouncilCertificate);
        } catch (error) {
            alertService.displayError(error, intl, [
                { status: 400, message: 'council.certificate.invalid' },
                { status: 409, message: 'council.certificate.duplicate' }
            ]);
        } finally {
            setLoading(undefined);
        }
    };

    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            file: files
        });
        setFiles(files);
    };

    const validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    /*** VISUAL ***/

    return (
        <Form form={form} onFinish={save} colon={false} layout="vertical">
            <Row gutter={[28, 0]}>
                <Col lg={12}>
                    <Form.Item
                        name="file"
                        valuePropName="files"
                        label={
                            <span>
                                <FormattedMessage id="council.certificate.import.upload" />
                            </span>
                        }
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                validator: validateFile
                            }
                        ]}
                        extra={
                            <>
                                <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                            </>
                        }
                    >
                        <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                            <CloudUploadOutlined /> <FormattedMessage id="council.certificate.import.upload.button" />
                        </Upload.Dragger>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <List
                        className={styles.files}
                        itemLayout="horizontal"
                        dataSource={files}
                        locale={{ emptyText: <></> }}
                        renderItem={(file) => (
                            <List.Item actions={[<Button icon={<DeleteOutlined />} danger size="large" onClick={removeFile} />]}>
                                <List.Item.Meta
                                    avatar={<PaperClipOutlined className={styles.icon} />}
                                    title={file.name}
                                    description={<FileSizeComponent value={file.size} />}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
            <Row gutter={[28, 0]}>
                <Col span={12}>
                    <Form.Item
                        label={intl.formatMessage({ id: 'council.certificate.password' })}
                        name="secret"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input.Password type="password" size="large" maxLength={30} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[28, 0]} className="buttons">
                <Col span={24}>
                    <Space>
                        <Button type="primary" htmlType="submit" size="large" loading={loading === 'saving'} icon={<SaveOutlined />}>
                            <FormattedMessage id="button.save" tagName="span" />
                        </Button>
                        <Link to={`/councils/${council?.id}`}>
                            <Button size="large" icon={<ArrowLeftOutlined />}>
                                {desktop && <FormattedMessage id="button.back" tagName="span" />}
                            </Button>
                        </Link>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};
interface CouncilCertificateFormProps {
    onSave: (publicCouncilCertificate: PublicCouncilCertificate) => void;
}

/**
 * Returns a public council certificate component.
 * @param props the props
 * @returns the public council certificate component
 */
const PublicCouncilCertificateComponent: React.FC<PublicCouncilCertificateComponentProps> = (props) => {
    const { publicCouncilCertificate, onDelete } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [loading, setLoading] = useState<'deleting'>();
    const { modal } = App.useApp();

    /*** METHODS ***/

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'council.certificate.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting' },
            onOk: async () => {
                try {
                    setLoading('deleting');
                    await councilCertificateApi.delete(publicCouncilCertificate!.councilId!, publicCouncilCertificate!.id!);
                    message.success(intl.formatMessage({ id: 'status.deleted' }));
                    onDelete();
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    /*** VISUAL ***/

    const items: DescriptionsProps['items'] = [
        {
            key: 'commonName',
            label: <FormattedMessage id="council.certificate.commonName" />,
            span: 2,
            children: publicCouncilCertificate.commonName
        },
        {
            key: 'validFrom',
            label: <FormattedMessage id="council.certificate.validFrom" />,
            children: (
                <FormattedDate value={publicCouncilCertificate.validFrom as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            )
        },
        {
            key: 'validTo',
            label: <FormattedMessage id="council.certificate.validTo" />,
            children: (
                <FormattedDate value={publicCouncilCertificate.validTo as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            )
        },
        {
            key: 'status',
            label: <FormattedMessage id="council.certificate.status" />,
            children: (
                <>
                    {dayjs().isBefore(dayjs(publicCouncilCertificate.validTo)) ? (
                        <Tag color="green">
                            <FormattedMessage id="council.certificate.status.valid" />
                        </Tag>
                    ) : (
                        <Tag color="red">
                            <FormattedMessage id="council.certificate.status.expired" />
                        </Tag>
                    )}
                </>
            )
        }
    ];

    return (
        <>
            <Descriptions
                title={<FormattedMessage id="council.certificate.title" />}
                layout="horizontal"
                items={items}
                column={2}
                bordered
                className={styles.summary}
            />
            <Row gutter={[28, 0]} className="buttons">
                <Col span={24}>
                    <Space>
                        <Button type="primary" danger size="large" onClick={remove} loading={loading === 'deleting'} icon={<DeleteOutlined />}>
                            {desktop && <FormattedMessage id="button.delete" tagName="span" />}
                        </Button>
                        <Link to={`/councils/${publicCouncilCertificate.councilId}`}>
                            <Button size="large" icon={<ArrowLeftOutlined />}>
                                {desktop && <FormattedMessage id="button.back" tagName="span" />}
                            </Button>
                        </Link>
                    </Space>
                </Col>
            </Row>
        </>
    );
};
interface PublicCouncilCertificateComponentProps {
    publicCouncilCertificate: PublicCouncilCertificate;
    onDelete: () => void;
}
