import { Image } from 'antd';
import React from 'react';
import taxfenIllustration from '../../../resources/images/taxfen-illustration.svg';
import styles from './InfoComponent.module.scss';

/**
 * Returns the info component.
 * @returns the info component.
 */
const InfoComponent: React.FC = () => {
    return (
        <div className={styles.container}>
            <Image src={taxfenIllustration} preview={false} />
        </div>
    );
};
export default InfoComponent;
