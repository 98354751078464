import { PlusOutlined, SolutionOutlined, HomeOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import councilApi from '../../apis/CouncilApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import ProvinceComponent from '../../components/ProvinceComponent/ProvinceComponent';
import { Page } from '../../models/Elements';
import { Council } from '../../models/Entities';
import alertService from '../../services/AlertService';
import tableService from '../../services/TableService';
import styles from '../CouncilsPage/CouncilsPage.module.scss';

/**
 * Returns the councils page.
 * @returns the councils page.
 */
const CouncilsPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [councils, setCouncils] = useState<Page<Council>>();
    const [loading, setLoading] = useState<'loading'>();
    const [form] = Form.useForm();
    const [filter, setFilter] = useState<Filter>({});
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('name');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [desktop] = useResponsiveLayout();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                //get councils
                const councils = await councilApi.list(page, 100, sortField, sortOrder, 'ENABLED', filter.searchText);
                setCouncils(councils);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter]);

    /*** VISUAL ***/
    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterUsers = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    /*** VISUAL ***/

    const items = councils ? councils.content : [];
    const columns: ColumnsType<Council> = [
        {
            title: <FormattedMessage id="council.name" />,
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: <FormattedMessage id="council.identifier" />,
            dataIndex: 'identifier',
            key: 'identifier',
            width: 180
        },
        {
            title: <FormattedMessage id="address.province" />,
            dataIndex: ['address', 'province'],
            key: 'province',
            width: 250,
            render: (value: string | undefined, council: Council) => <ProvinceComponent provinceId={value} />
        },
        {
            title: <FormattedMessage id="address.email" />,
            dataIndex: ['address', 'email'],
            key: 'email',
            width: 200
        },
        {
            title: <FormattedMessage id="address.phone" />,
            dataIndex: ['address', 'phone'],
            key: 'phone',
            width: 200
        },
        {
            key: 'actions',
            width: 150,
            align: 'center',
            render: (record, council: Council) => (
                <Space size="large">
                    <Link to={`/councils/${council.id}`}>
                        <Tooltip title={<FormattedMessage id="councils.tooltip" />}>
                            <HomeOutlined className={styles.icons} />
                        </Tooltip>
                    </Link>
                    <Link to={`/councils/${council.id}/taxpayers`}>
                        <Tooltip title={<FormattedMessage id="council.taxpayers.tooltip" />}>
                            <UserOutlined className={styles.icons} />
                        </Tooltip>
                    </Link>
                    <Link to={`/councils/${council.id}/receivables`}>
                        <Tooltip title={<FormattedMessage id="receivables.tooltip" />}>
                            <SolutionOutlined className={styles.icons} />
                        </Tooltip>
                    </Link>
                </Space>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="councils.title" />}
            menu="councils"
            path={[{ path: '/councils', name: <FormattedMessage id="councils.title" /> }]}
        >
            <Form form={form} onFinish={filterUsers} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={20}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'councils.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} className={styles.buttons}>
                        <Link to="/councils/new">
                            <Tooltip title={<FormattedMessage id="councils.new.tooltip" />}>
                                <Button type="primary" size="large" icon={<PlusOutlined />}>
                                    {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                </Button>
                            </Tooltip>
                        </Link>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(councils)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default CouncilsPage;

interface Filter {
    searchText?: string | undefined;
}
