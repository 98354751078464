import { Page } from '../models/Elements';
import { TaxpayerVersion, TaxpayerWithVersion } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class TaxpayerVersionApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        taxpayerId: number,
        searchText?: string
    ): Promise<Page<TaxpayerVersion>> => {
        const response = await adminApiAxios.get<Page<TaxpayerVersion>>(`/taxpayer-versions`, {
            params: { page, size, sortField, sortOrder, taxpayerId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((taxpayerVersion) => this.loadDates(taxpayerVersion));

        return response.data;
    };

    get = async (id: number): Promise<TaxpayerVersion> => {
        const response = await adminApiAxios.get<TaxpayerVersion>(`/taxpayer-versions/${id}`);
        this.loadDates(response.data);

        return response.data;
    };
    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/taxpayer-versions/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Loads the dates of a taxpayer versions.
     * @param taxpayerVersion - the taxpayer version
     */
    private loadDates(taxpayerVersion: TaxpayerVersion) {
        taxpayerVersion.audit!.created = dateService.transformDate(taxpayerVersion.audit!.created)!;
        taxpayerVersion.audit!.updated = dateService.transformDate(taxpayerVersion.audit!.updated)!;
    }

    /**
     * Builds a form data.
     * @param taxpayerWithVersion the taxpayer with version
     * @param attachment the attachment
     * @returns the form data
     */
    private buildFormData = (taxpayerWithVersion: TaxpayerWithVersion, attachment?: File) => {
        const formData: FormData = new FormData();
        taxpayerWithVersion.taxpayer.id && formData.append('taxpayer.id', taxpayerWithVersion.taxpayer.id.toString());
        taxpayerWithVersion.taxpayer.identifier && formData.append('taxpayer.identifier', taxpayerWithVersion.taxpayer.identifier);
        taxpayerWithVersion.taxpayer.firstName && formData.append('taxpayer.firstName', taxpayerWithVersion.taxpayer.firstName);
        taxpayerWithVersion.taxpayer.lastName && formData.append('taxpayer.lastName', taxpayerWithVersion.taxpayer.lastName);
        taxpayerWithVersion.taxpayer.secondLastName && formData.append('taxpayer.secondLastName', taxpayerWithVersion.taxpayer.secondLastName);
        taxpayerWithVersion.taxpayer.legalEntity && formData.append('taxpayer.legalEntity', taxpayerWithVersion.taxpayer.legalEntity);
        taxpayerWithVersion.taxpayer.status && formData.append('taxpayer.status', taxpayerWithVersion.taxpayer.status);
        taxpayerWithVersion.taxpayer.address?.street && formData.append('taxpayer.address.street', taxpayerWithVersion.taxpayer.address.street);
        taxpayerWithVersion.taxpayer.address?.city && formData.append('taxpayer.address.city', taxpayerWithVersion.taxpayer.address.city);
        taxpayerWithVersion.taxpayer.address?.province && formData.append('taxpayer.address.province', taxpayerWithVersion.taxpayer.address.province);
        taxpayerWithVersion.taxpayer.address?.state && formData.append('taxpayer.address.state', taxpayerWithVersion.taxpayer.address.state);
        taxpayerWithVersion.taxpayer.address?.postcode &&
            formData.append('taxpayer.address.postcode', taxpayerWithVersion.taxpayer.address.postcode.toString());
        taxpayerWithVersion.taxpayer.address?.phone && formData.append('taxpayer.address.phone', taxpayerWithVersion.taxpayer.address.phone);
        taxpayerWithVersion.taxpayer.address?.email && formData.append('taxpayer.address.email', taxpayerWithVersion.taxpayer.address.email);

        taxpayerWithVersion.version.remarks && formData.append('version.remarks', taxpayerWithVersion.version.remarks);
        attachment && formData.append('version.attachment.name', attachment.name);
        attachment && formData.append('version.attachment.file', attachment);

        return formData;
    };

    /**
     * Builds a form data from a taxpayer version.
     * @param taxpayerVersion the taxpayer version
     * @param attachment the attachment
     * @returns the form data
     */
    private buildTaxpayerVersionFormData = (taxpayerVersion: TaxpayerVersion, attachment?: File) => {
        const formData: FormData = new FormData();
        taxpayerVersion.remarks && formData.append('remarks', taxpayerVersion.remarks);
        attachment && formData.append('attachment.name', attachment.name);
        attachment && formData.append('attachment.file', attachment);

        return formData;
    };
}

const taxpayerVersionApi: TaxpayerVersionApi = new TaxpayerVersionApi();
export default taxpayerVersionApi;
