import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router';
import councilApi from '../../apis/CouncilApi';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { Council } from '../../models/Entities';

/**
 * This component represents a council wrapper.
 * @param props - the props
 */
const CouncilWrapper: React.FC = () => {
    const params = useParams<ParamsType>();
    const [council, setCouncil] = useState<Council>({});

    // initialize the app
    useEffect(() => {
        const init = async () => {
            if (params.councilId && params.councilId !== 'new') {
                const council = await councilApi.get(+params.councilId);
                setCouncil(council);
            }
        };
        init();
    }, [params.councilId]);

    return (
        <CustomCouncilContext.Provider value={{ council, setCouncil }}>
            <Outlet />
        </CustomCouncilContext.Provider>
    );
};
export default CouncilWrapper;
type ParamsType = { councilId: string };
