import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, List, Modal, Row, Space, message } from 'antd';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PaymentOrderSign, PaymentOrderSignCreation, PaymentOrderSignDraft } from '../../models/Entities';
import alertService from '../../services/AlertService';
import ConstantLabel from '../../components/ConstantLabel/ConstantLabel';
import styles from './PaymentOrderSignModal.module.scss';
import paymentOrderSignApi from '../../apis/PaymentOrderSignApi';

const PaymentOrderSignModal: React.FC<Props> = (props) => {
    const { councilId } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading' | 'sending' | 'downloading'>();
    const [paymentOrderSignDraft, setPaymentOrderSignDraft] = useState<PaymentOrderSignDraft>();
    const [paymentOrderSignCreation, setPaymentOrderSignCreation] = useState<PaymentOrderSignCreation>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const paymentOrderSignCreation: PaymentOrderSignCreation = {
                    councilId: councilId
                };
                setPaymentOrderSignCreation(paymentOrderSignCreation);

                const paymentOrderSignDraft = await paymentOrderSignApi.createDraft(paymentOrderSignCreation);
                setPaymentOrderSignDraft(paymentOrderSignDraft);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, intl]);

    /*** METHODS ***/

    const send = async () => {
        try {
            setLoading('sending');
            if (paymentOrderSignCreation) {
                await paymentOrderSignApi.create(paymentOrderSignCreation);
                await props.onSave();
                message.success(intl.formatMessage({ id: 'status.sent' }));
            }
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = async () => {
        props.onCancel();
    };

    const download = async (paymentOrderSign: PaymentOrderSign) => {
        try {
            setLoading('downloading');
            const document = await paymentOrderSignApi.downloadDraft(paymentOrderSign.council.id!, paymentOrderSign.tax, paymentOrderSign.fiscalYear);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'paymentOrderSign.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const paymentOrderSigns = paymentOrderSignDraft ? paymentOrderSignDraft.paymentOrderSigns : [];

    return (
        <Modal
            title={<FormattedMessage id="paymentOrderSignModal.title" />}
            open={true}
            onCancel={cancel}
            onOk={send}
            okText={<FormattedMessage id="button.send" tagName="span" />}
            okButtonProps={{
                loading: loading === 'sending',
                size: 'large',
                disabled: !paymentOrderSignDraft || paymentOrderSignDraft.paymentOrderSigns.length === 0
            }}
            cancelButtonProps={{ size: 'large' }}
            width={800}
        >
            <Row gutter={[0, 0]} className={styles.container}>
                <Col span={24}>
                    <FormattedMessage id="paymentOrderSignModal.description" />
                </Col>
                <Col span={24}>
                    <List
                        className={styles.list}
                        itemLayout="horizontal"
                        dataSource={paymentOrderSigns}
                        loading={loading === 'loading'}
                        renderItem={(paymentOrderSign) => (
                            <List.Item
                                actions={[
                                    <Button
                                        icon={<CloudDownloadOutlined />}
                                        danger
                                        size="large"
                                        onClick={() => download(paymentOrderSign)}
                                        loading={loading === `downloading`}
                                    />
                                ]}
                            >
                                <List.Item.Meta
                                    title={<ConstantLabel value={paymentOrderSign.tax} prefix="receivable.tax." />}
                                    description={
                                        <Space split="|">
                                            <FormattedMessage id="paymentOrderSignModal.fiscalYear" values={{ fiscalYear: paymentOrderSign.fiscalYear }} />
                                            <FormattedMessage
                                                id="paymentOrderSignModal.receivables"
                                                values={{
                                                    receivables: paymentOrderSignDraft?.receivableNotificationSigns.filter(
                                                        (rns) => rns.paymentOrderSignId === paymentOrderSign.id
                                                    ).length
                                                }}
                                            />
                                        </Space>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    );
};
export default PaymentOrderSignModal;

interface Props {
    councilId: number;
    onSave: () => Promise<void>;
    onCancel: () => void;
}
