import React, { useContext, useState } from 'react';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import PublicLayoutComponent from '../../components/PublicLayoutComponent/PublicLayoutComponent';
import { Button, Col, Form, Image, Input, Row, message } from 'antd';
import InfoComponent from '../AuthsPage/InfoComponent/InfoComponent';
import styles from './ForgotPasswordPage.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import authService from '../../services/AuthService';
import taxfenIcon from '../../resources/images/taxfen-icon.svg';
import Icon, { EyeInvisibleOutlined, EyeTwoTone, KeyOutlined } from '@ant-design/icons';
import CustomContext from '../../contexts/CustomContext';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as emailIcon } from '../../resources/images/email-icon.svg';
import { ReactComponent as lockIcon } from '../../resources/images/lock-icon.svg';

/**
 * Returns the forgot password page.
 * @returns the forgot password page.
 */

const ForgotPasswordPage: React.FC = () => {
    /**HOOKS***/
    const [codeSent, setCodeSent] = useState<boolean>(false);
    const [desktop] = useResponsiveLayout();

    /**VISUAL***/

    return (
        <PublicLayoutComponent>
            <Col xs={0} lg={12}>
                <InfoComponent />
            </Col>
            <Col md={24} lg={12} className={`${styles.form} ${desktop ? styles.desktop : styles.mobile}`}>
                {codeSent && <ResetPasswordForm />}
                {!codeSent && <ForgotPasswordForm setCodeSent={setCodeSent} />}
            </Col>
        </PublicLayoutComponent>
    );
};
export default ForgotPasswordPage;

/**
 * Returns the forgot password form.
 * @returns the forgot password form.
 */

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (props) => {
    const { setCodeSent } = props;

    /**HOOKS***/
    const [form] = Form.useForm();
    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();

    /*** METHODS ***/

    const forgotPassword = async (values: any) => {
        try {
            setLoading('loading');
            await authService.forgotPassword(values.username);
            setCodeSent(true);
        } catch (error: any) {
            if (error.message && error.message === 'Username/client id combination not found.') {
                message.error(intl.formatMessage({ id: 'forgotPassword.status.incorrect' }), 6);
            } else if (error.message) {
                message.error(error.message, 6);
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
            }
        } finally {
            setLoading(undefined);
        }
    };
    /*** VISUAL ***/

    return (
        <Form form={form} onFinish={forgotPassword} colon={false} layout="vertical">
            <Row>
                <Col span={24} className={styles.panel}>
                    <Image src={taxfenIcon} preview={false} />
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panel}>
                    <div className={styles.title}>
                        <FormattedMessage id="forgotPassword.title" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panel}>
                    <div className={styles.createAcc}>
                        <FormattedMessage id="forgotPassword.desc" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panelSpace}>
                    <Form.Item name="username" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]} required={false}>
                        <Input
                            maxLength={100}
                            size="large"
                            placeholder={intl.formatMessage({ id: 'forgotPassword.email' })}
                            prefix={<Icon component={emailIcon} className={styles.icon} />}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panel}>
                    <span className={styles.haveAccountText}>
                        <FormattedMessage id="forgotPassword.returnTo" />
                    </span>
                    <a href="/signin" className={styles.login}>
                        <FormattedMessage id="forgotPassword.signin" />
                    </a>
                </Col>
            </Row>
            <Row>
                <Col span={14} className={styles.button}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} block className={styles.signupButton}>
                            <FormattedMessage id="forgotPassword.submit" tagName="span" />
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

interface ForgotPasswordFormProps {
    setCodeSent: (codeSent: boolean) => void;
}

/**
 * Returns the reset password form.
 * @returns the reset password form.
 */

const ResetPasswordForm: React.FC = () => {
    /*** HOOKS ***/
    const context = useContext(CustomContext);
    const [form] = Form.useForm();
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<'loading'>();

    /*** METHODS ***/

    const resetPassword = async (values: any) => {
        try {
            setLoading('loading');
            const auth = await authService.resetPassword(values.username, values.code, values.password);
            context.setAuth(auth);
            navigate('/');
        } catch (error: any) {
            if (error.message && error.message === 'Invalid verification code provided, please try again.') {
                message.error(intl.formatMessage({ id: 'resetPassword.status.incorrect' }), 6);
            } else if (error.message && error.message.toString().includes('Member must satisfy regular expression pattern')) {
                message.error(intl.formatMessage({ id: 'resetPassword.status.incorrect' }), 6);
            } else if (error.message && error.message === 'Username/client id combination not found.') {
                message.error(intl.formatMessage({ id: 'resetPassword.status.incorrectUsername' }), 6);
            } else if (error.message) {
                message.error(error.message, 6);
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
            }
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <Form form={form} onFinish={resetPassword} colon={false} layout="vertical">
            <Row>
                <Col span={24} className={styles.panel}>
                    <Image src={taxfenIcon} preview={false} />
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panel}>
                    <div className={styles.title}>
                        <FormattedMessage id="forgotPassword.title" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panel}>
                    <div className={styles.createAcc}>
                        <FormattedMessage id="forgotPassword.desc.reset" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="username" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]} required={false}>
                        <Input
                            maxLength={100}
                            size="large"
                            placeholder={intl.formatMessage({ id: 'forgotPassword.email' })}
                            prefix={<Icon component={emailIcon} className={styles.icon} />}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="code" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]} required={false}>
                        <Input
                            maxLength={50}
                            size="large"
                            placeholder={intl.formatMessage({ id: 'forgotPassword.code' })}
                            prefix={<KeyOutlined className={styles.defaultIcon} />}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panelSpace}>
                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_.,;]).{8,}$/,
                                message: <FormattedMessage id="status.password" />
                            }
                        ]}
                        required={false}
                    >
                        <Input.Password
                            type="password"
                            size="large"
                            maxLength={25}
                            placeholder={intl.formatMessage({ id: 'forgotPassword.password' })}
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            prefix={<Icon component={lockIcon} className={styles.icon} />}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panel}>
                    <span className={styles.haveAccountText}>
                        <FormattedMessage id="forgotPassword.returnTo" />
                    </span>
                    <a href="/signin" className={styles.login}>
                        <FormattedMessage id="forgotPassword.signin" />
                    </a>
                </Col>
            </Row>
            <Row>
                <Col span={8} className={styles.button}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} block className={styles.signupButton}>
                            <FormattedMessage id="forgotPassword.submit" tagName="span" />
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
