import { FormattedMessage } from 'react-intl';

/**
 * Returns a constant label.
 * @returns the constant label.
 */
const ConstantLabel: React.FC<Props> = (props) => {
    const { value, prefix, defaultValue } = props;

    if (value && prefix) {
        return <FormattedMessage id={`${prefix}${value}`} />;
    } else if (value) {
        return <FormattedMessage id={value} />;
    } else if (defaultValue) {
        return <>{defaultValue}</>;
    } else {
        return <></>;
    }
};
export default ConstantLabel;

interface Props {
    value: string | undefined;
    prefix?: string;
    defaultValue?: React.ReactNode;
}
