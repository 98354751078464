import { ArrowLeftOutlined, CloudDownloadOutlined, FormOutlined, SendOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import FileSaver from 'file-saver';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import seizureIncomeOrderApi from '../../apis/SeizureIncomeOrderApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import ReceptionStateTag from '../../components/ReceptionStateTag/ReceptionStateTag';
import SeizureIncomeOrderEventModal from '../../components/SeizureIncomeOrderEventModal/SeizureIncomeOrderEventModal';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { Page } from '../../models/Elements';
import { SeizureIncomeOrder } from '../../models/Entities';
import { ReceptionStateType } from '../../models/Types';
import alertService from '../../services/AlertService';
import tableService from '../../services/TableService';
import styles from './SeizureIncomeOrdersPage.module.scss';
import SeizureIncomeOrderResendModal from '../../components/SeizureIncomeOrderResendModal/SeizureIncomeOrderResendModal';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import SeizureIncomeOrderModal from '../../components/SeizureIncomeOrderModal/SeizureIncomeOrderModal';

/**
 * Returns the seizure income orders page.
 * @returns the seizure income orders page.
 */

const SeizureIncomeOrdersPage: React.FC = () => {
    /***HOOKS */

    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const { council } = useContext(CustomCouncilContext);
    const [filter, setFilter] = useState<Filter>({});
    const [seizureIncomeOrdersPage, setSeizureIncomeOrdersPage] = useState<Page<SeizureIncomeOrder>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('auditCreated');
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [seizureIncomeOrder, setSeizureIncomeOrder] = useState<SeizureIncomeOrder>();
    const [seizureIncomeOrderModalVisible, setSeizureIncomeOrderModalVisible] = useState<boolean>(false);
    const [seizureIncomeOrderEventModalVisible, setSeizureIncomeOrderEventModalVisible] = useState<boolean>(false);
    const [seizureIncomeOrderResendModalVisible, setSeizureIncomeOrderResendModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<'loading' | 'downloading'>();

    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const seizureIncomeOrdersPage = await seizureIncomeOrderApi.list(page, 50, sortField, sortOrder, council.id, undefined, filter.searchText);
                    setSeizureIncomeOrdersPage(seizureIncomeOrdersPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterSeizureIncomeOrders = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    const download = async (seizureIncomeOrder: SeizureIncomeOrder) => {
        try {
            setLoading('downloading');
            const document = await seizureIncomeOrderApi.download(seizureIncomeOrder.id!);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'seizureIncomeOrder.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const showSeizureIncomeOrderEventModal = async (seizureIncomeOrder: SeizureIncomeOrder) => {
        setSeizureIncomeOrder(seizureIncomeOrder);
        setSeizureIncomeOrderEventModalVisible(true);
    };

    const showSeizureIncomeOrderResendModal = async (seizureIncomeOrder: SeizureIncomeOrder) => {
        setSeizureIncomeOrder(seizureIncomeOrder);
        setSeizureIncomeOrderResendModalVisible(true);
    };

    const refresh = async () => {
        try {
            setLoading('loading');
            setSeizureIncomeOrderModalVisible(false);
            setSeizureIncomeOrderEventModalVisible(false);
            setSeizureIncomeOrderResendModalVisible(false);
            const seizureIncomeOrdersPage = await seizureIncomeOrderApi.list(page, 100, sortField, sortOrder, council.id, undefined, filter.searchText);
            setSeizureIncomeOrdersPage(seizureIncomeOrdersPage);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const items = seizureIncomeOrdersPage ? seizureIncomeOrdersPage.content : [];
    const columns: ColumnsType<SeizureIncomeOrder> = [
        {
            title: <FormattedMessage id="seizureIncomeOrder.id" />,
            dataIndex: 'id',
            key: 'id',
            align: 'right',
            width: 60,
            sorter: true,
            defaultSortOrder: 'descend'
        },
        {
            title: <FormattedMessage id="seizureIncomeOrder.taxpayer.name" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'fullName'],
            key: 'fullName'
        },
        {
            title: <FormattedMessage id="seizureIncomeOrder.taxpayer.street" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'address', 'street'],
            key: 'street',
            width: 220
        },
        {
            title: <FormattedMessage id="seizureIncomeOrder.taxpayer.city" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'address', 'city'],
            key: 'city',
            width: 160
        },
        {
            title: <FormattedMessage id="seizureIncomeOrder.taxpayer.identifier" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'identifier'],
            key: 'identifier',
            align: 'center',
            width: 80
        },

        {
            title: <FormattedMessage id="seizureIncomeOrder.created" />,
            dataIndex: ['audit', 'created'],
            key: 'auditCreated',
            align: 'center',
            width: 140,
            render: (value: any, seizureIncomeOrder: SeizureIncomeOrder) => <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" />
        },
        {
            title: <FormattedMessage id="seizureIncomeOrder.receptionState" />,
            dataIndex: 'receptionState',
            key: 'receptionState',
            align: 'center',
            width: 120,
            render: (value: ReceptionStateType | undefined, seizureIncomeOrder: SeizureIncomeOrder) => <ReceptionStateTag value={value} />
        },
        {
            key: 'actions',
            width: 140,
            align: 'center',
            render: (seizureIncomeOrder: SeizureIncomeOrder) => (
                <Space size="small">
                    <Tooltip title={<FormattedMessage id="button.download" />}>
                        <Button icon={<CloudDownloadOutlined />} className={styles.icon} onClick={() => download(seizureIncomeOrder)} type="link" />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="button.update" />}>
                        <Button
                            icon={<FormOutlined />}
                            className={styles.icon}
                            onClick={() => showSeizureIncomeOrderEventModal(seizureIncomeOrder)}
                            disabled={!!seizureIncomeOrder.receptionState}
                            type="link"
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="seizureIncomeOrder.resend" />}>
                        <Button
                            icon={<SendOutlined />}
                            className={styles.icon}
                            onClick={() => showSeizureIncomeOrderResendModal(seizureIncomeOrder)}
                            disabled={!['WRONG_ADDRESS', 'UNKNOWN'].includes(seizureIncomeOrder.receptionState!)}
                            type="link"
                        />
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="users.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/payment-orders`, name: <FormattedMessage id="seizureIncomeOrders.title" /> }
            ]}
            council={council}
        >
            <Form form={form} onFinish={filterSeizureIncomeOrders} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'seizureIncomeOrders.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6} className={styles.buttons}>
                        <Space>
                            <Link to={`/councils/${council?.id}`}>
                                <Button size="large" icon={<ArrowLeftOutlined />} />
                            </Link>
                            <Tooltip title={<FormattedMessage id="seizureIncomeOrders.new.tooltip" />}>
                                <Button type="primary" size="large" icon={<PlusOutlined />} onClick={() => setSeizureIncomeOrderModalVisible(true)}>
                                    {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                </Button>
                            </Tooltip>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(seizureIncomeOrdersPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />{' '}
            {seizureIncomeOrderModalVisible && council.id && (
                <SeizureIncomeOrderModal councilId={council.id} onCancel={() => setSeizureIncomeOrderModalVisible(false)} clone={false} onSave={refresh} />
            )}
            {seizureIncomeOrderEventModalVisible && seizureIncomeOrder && (
                <SeizureIncomeOrderEventModal
                    seizureIncomeOrderId={seizureIncomeOrder.id!}
                    onCancel={() => setSeizureIncomeOrderEventModalVisible(false)}
                    onSave={refresh}
                />
            )}
            {seizureIncomeOrderResendModalVisible && seizureIncomeOrder && (
                <SeizureIncomeOrderResendModal
                    councilId={council!.id!}
                    councilTaxpayerIds={[seizureIncomeOrder.councilTaxpayer!.id]}
                    clone={true}
                    onCancel={() => setSeizureIncomeOrderResendModalVisible(false)}
                    onSave={refresh}
                />
            )}
        </LayoutComponent>
    );
};
export default SeizureIncomeOrdersPage;

interface Filter {
    searchText?: string | undefined;
}
