import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { App, Button, Checkbox, Col, Form, Input, Row, Space, message } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { default as BankApi, default as bankApi } from '../../../apis/BankApi';
import AddressComponent from '../../../components/AddressComponent/AddressComponent';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import { Bank } from '../../../models/Entities';
import alertService from '../../../services/AlertService';

/**
 * Returns the bank page.
 * @returns the bank page.
 */
const BankPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [desktop] = useResponsiveLayout();
    const params = useParams<ParamsType>();
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [bank, setBank] = useState<Bank>();
    const { modal } = App.useApp();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');
                const bankId = params.id;
                let bank: Bank;
                if (bankId === 'new') {
                    bank = { online: false, status: 'ENABLED' };
                } else {
                    bank = await bankApi.get(+bankId!);
                }
                form.setFieldsValue(bank);
                setBank(bank);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl, params.id]);

    /*** METHODS ***/

    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedBank: Bank = Object.assign({}, bank, values);
            if (updatedBank.id) {
                updatedBank = await bankApi.update(updatedBank);
            } else {
                updatedBank = await bankApi.create(updatedBank);
            }
            setBank(updatedBank);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error) {
            alertService.displayError(error, intl, [{ status: 409, message: 'bank.duplicate' }]);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'bank.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting' },
            onOk: async () => {
                try {
                    setLoading('deleting');

                    await BankApi.delete(bank?.id!);
                    message.success(intl.formatMessage({ id: 'status.deleted' }));

                    navigate('/banks');
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="companies.title" />}
            menu="banks"
            path={[
                { path: '/banks', name: <FormattedMessage id="banks.title" /> },
                { path: `/banks/${params.id}`, name: bank?.id }
            ]}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={20}>
                        <Form.Item
                            label={<FormattedMessage id="bank.name" />}
                            name="name"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item
                            label={<FormattedMessage id="bank.online" />}
                            name="online"
                            valuePropName="checked"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Checkbox />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="bank.parentEntity" />} name="parentEntity">
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item label={<FormattedMessage id="bank.euCode" />} name="euCode">
                            <Input size="large" maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={5}>
                        <Form.Item label={<FormattedMessage id="bank.lei" />} name="lei">
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={3}>
                        <Form.Item
                            label={<FormattedMessage id="bank.mode" />}
                            name="mode"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={10} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item label={<FormattedMessage id="bank.supervisorCode" />} name="supervisorCode">
                            <Input size="large" maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
                <AddressComponent field={'address'} hideHeader />
                <Row gutter={[28, 0]} className="buttons">
                    <Col span={24}>
                        <Space>
                            <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                            {bank && bank.id && (
                                <Button type="primary" danger size="large" onClick={remove} icon={<DeleteOutlined />}>
                                    {desktop && <FormattedMessage id="button.delete" tagName="span" />}
                                </Button>
                            )}
                            <Link to="/banks">
                                <Button size="large" icon={<ArrowLeftOutlined />}>
                                    {desktop && <FormattedMessage id="button.back" tagName="span" />}
                                </Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </LayoutComponent>
    );
};
export default BankPage;
type ParamsType = { id: string };
