import { Button, List, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import billingApi from '../../../apis/BillingApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import { BillingReceivablePayment, BillingReceivablePaymentsByDay } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './BillingReceivablePaymentsByDayComponent.module.scss';
import { FileExcelOutlined } from '@ant-design/icons';
import FileSaver from 'file-saver';

/**
 *
 * @param props the props
 * @returns the billing receivable payments component
 */
const BillingReceivablePaymentsByDayComponent: React.FC<Props> = (props) => {
    const { councilId, startDate, endDate } = props;

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'loading'>();
    const [receivablePaymentsByDay, setReceivablePaymentsByDay] = useState<BillingReceivablePaymentsByDay>();
    const [, setCouncilId] = useState<number>(councilId);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                setCouncilId(councilId);
                const receivablePaymentsByDay = await billingApi.getReceivablePaymentDays(councilId, startDate, endDate);
                setReceivablePaymentsByDay(receivablePaymentsByDay);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, endDate, intl, startDate]);

    /*** HOOKS ***/
    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');

            const document = await billingApi.downloadReceivablePaymentDays(councilId, startDate, endDate);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'billingReceivablePayment.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const columns: ColumnsType<BillingReceivablePayment> = [
        {
            title: <FormattedMessage id="billingReceivablePayment.councilTaxpayerId" />,
            dataIndex: 'councilTaxpayerId',
            key: 'councilTaxpayerId',
            align: 'center'
        },
        {
            title: <FormattedMessage id="billingReceivablePayment.taxpayerFullName" />,
            dataIndex: 'taxpayerFullname',
            key: 'taxpayerFullname',
            align: 'center'
        },
        {
            title: <FormattedMessage id="billingReceivablePayment.receivableFiscalYear" />,
            dataIndex: 'receivableFiscalYear',
            key: 'receivableFiscalYear',
            align: 'center'
        },
        {
            title: <FormattedMessage id="billingReceivablePayment.receivableCreated" />,
            dataIndex: 'receivableCreated',
            key: 'receivableCreated',
            align: 'center',
            render: (value: string, billingReceivablePayment: BillingReceivablePayment) => <FormattedDate value={value as any} year="numeric" />
        },
        {
            title: <FormattedMessage id="billingReceivablePayment.receivableId" />,
            dataIndex: 'receivableId',
            key: 'receivableId',
            align: 'center'
        },
        {
            title: <FormattedMessage id="billingReceivablePayment.receivableReference" />,
            dataIndex: 'receivableReference',
            key: 'receivableReference',
            align: 'center'
        },

        {
            title: <FormattedMessage id="billingReceivablePayment.receivableProcedure" />,
            dataIndex: 'receivableProcedure',
            key: 'receivableProcedure',
            align: 'center',
            render: (value: string, billingReceivablePayment: BillingReceivablePayment) =>
                value && value === 'CERTIFICATION' ? <span>C</span> : <span>R</span>
        },
        {
            title: <FormattedMessage id="billingReceivablePayment.receivableTax" />,
            dataIndex: 'receivableTax',
            key: 'receivableTax',
            align: 'center',
            render: (value: string, billingReceivablePayment: BillingReceivablePayment) => <ConstantLabel value={value} prefix="receivable.tax.short." />
        },

        {
            title: <FormattedMessage id="billingReceivablePayment.receivableTaxObject" />,
            dataIndex: 'receivableTaxObject',
            key: 'receivableTaxObject',
            align: 'center'
        },

        {
            title: <FormattedMessage id="billingReceivablePayment.councilCollectionAccountBankName" />,
            dataIndex: 'councilCollectionAccountBankName',
            key: 'councilCollectionAccountBankName',
            align: 'center'
        },
        {
            title: <FormattedMessage id="billingReceivablePayment.councilCollectionAccountIban" />,
            dataIndex: 'councilCollectionAccountIban',
            key: 'councilCollectionAccountIban',
            align: 'center',
            render: (value: string, billingReceivablePayment: BillingReceivablePayment) => (value === 'Compensación' ? '-' : <span>{value}</span>)
        },

        {
            title: <FormattedMessage id="billingReceivablePayment.amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'amountPrincipal',
            align: 'center',

            render: (value: number, billingReceivablePayment: BillingReceivablePayment) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingReceivablePayment.amount.surcharge" />,
            dataIndex: ['amount', 'surcharge'],
            key: 'amountSurcharge',
            align: 'center',

            render: (value: number, billingReceivablePayment: BillingReceivablePayment) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingReceivablePayment.amount.interest" />,
            dataIndex: ['amount', 'interest'],
            key: 'amountInterest',
            align: 'center',

            render: (value: number, billingReceivablePayment: BillingReceivablePayment) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },

        {
            title: <FormattedMessage id="billingReceivablePayment.costs" />,
            dataIndex: 'costs',
            key: 'costs',
            align: 'center',

            render: (value: number, billingReceivablePayment: BillingReceivablePayment) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingReceivablePayment.total" />,
            dataIndex: 'total',
            key: 'total',
            align: 'center',

            render: (value: number, billingReceivablePayment: BillingReceivablePayment) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        }
    ];

    const billingReceivablePaymentsDays = receivablePaymentsByDay?.billingReceivablePaymentsDays ? receivablePaymentsByDay?.billingReceivablePaymentsDays : [];

    return (
        <div className={styles.container}>
            <Tooltip title={<FormattedMessage id="button.download" />}>
                <Button size="large" icon={<FileExcelOutlined />} onClick={download} loading={loading === 'downloading'}>
                    <FormattedMessage id="button.download" tagName="span" />
                </Button>
            </Tooltip>
            <List
                className={styles.list}
                size="small"
                locale={{ emptyText: <></> }}
                itemLayout="horizontal"
                dataSource={billingReceivablePaymentsDays}
                loading={loading === 'loading'}
                renderItem={(billingReceivablePaymentsDay) => (
                    <>
                        <div className={styles.date}>
                            <FormattedDate value={billingReceivablePaymentsDay.date! as any} day="2-digit" month="2-digit" year="numeric" />
                        </div>
                        <Table
                            className={styles.table}
                            dataSource={billingReceivablePaymentsDay.billingReceivablePayments}
                            columns={columns}
                            pagination={false}
                            rowKey="id"
                            showSorterTooltip={false}
                            loading={loading === 'loading'}
                            summary={(pageData) => {
                                return (
                                    <>
                                        <Table.Summary.Row className={styles.summary}>
                                            <Table.Summary.Cell index={0} align="center">
                                                <FormattedMessage id="billingReceivablePayment.total" />
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={1} />
                                            <Table.Summary.Cell index={2} />
                                            <Table.Summary.Cell index={3} />
                                            <Table.Summary.Cell index={4} />
                                            <Table.Summary.Cell index={5} />
                                            <Table.Summary.Cell index={6} />
                                            <Table.Summary.Cell index={7} />
                                            <Table.Summary.Cell index={8} />
                                            <Table.Summary.Cell index={9} />
                                            <Table.Summary.Cell index={10} />
                                            <Table.Summary.Cell index={11} align="right">
                                                <FormattedNumber
                                                    value={billingReceivablePaymentsDay.amount.principal}
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                    style={'currency' as any}
                                                    currency="EUR"
                                                />
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={12} align="right">
                                                <FormattedNumber
                                                    value={billingReceivablePaymentsDay.amount.surcharge}
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                    style={'currency' as any}
                                                    currency="EUR"
                                                />
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={13} align="right">
                                                <FormattedNumber
                                                    value={billingReceivablePaymentsDay.amount.interest}
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                    style={'currency' as any}
                                                    currency="EUR"
                                                />
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={14} align="right">
                                                <FormattedNumber
                                                    value={billingReceivablePaymentsDay.costs}
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                    style={'currency' as any}
                                                    currency="EUR"
                                                />
                                            </Table.Summary.Cell>
                                            <Table.Summary.Cell index={15} align="right">
                                                <FormattedNumber
                                                    value={billingReceivablePaymentsDay.total}
                                                    minimumFractionDigits={2}
                                                    maximumFractionDigits={2}
                                                    style={'currency' as any}
                                                    currency="EUR"
                                                />
                                            </Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </>
                )}
            />
        </div>
    );
};
export default BillingReceivablePaymentsByDayComponent;

interface Props {
    councilId: number;
    startDate: Dayjs;
    endDate: Dayjs;
}
