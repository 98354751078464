import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router';
import councilTaxpayerApi from '../../apis/CouncilTaxpayerApi';
import CustomCouncilTaxpayerContext from '../../contexts/CustomCouncilTaxpayerContext';
import { CouncilTaxpayer } from '../../models/Entities';

/**
 * This component represents a council taxpayer wrapper.
 * @param props - the props
 */
const CouncilTaxpayerWrapper: React.FC = () => {
    const params = useParams<ParamsType>();
    const [councilTaxpayer, setCouncilTaxpayer] = useState<CouncilTaxpayer>();

    // initialize the app
    useEffect(() => {
        const init = async () => {
            if (params.councilTaxpayerId && params.councilTaxpayerId !== 'new') {
                const councilTaxpayer = await councilTaxpayerApi.get(+params.councilTaxpayerId);
                setCouncilTaxpayer(councilTaxpayer);
            }
        };
        init();
    }, [params.councilTaxpayerId]);

    return (
        <CustomCouncilTaxpayerContext.Provider value={{ councilTaxpayer, setCouncilTaxpayer }}>
            <Outlet />
        </CustomCouncilTaxpayerContext.Provider>
    );
};
export default CouncilTaxpayerWrapper;
type ParamsType = { councilTaxpayerId: string };
