import { Page } from '../models/Elements';
import { SeizureBankCancelRequest, SeizureBankCancelRequestDraft } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class SeizureBankCancelRequestApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId: number,
        searchText?: string
    ): Promise<Page<SeizureBankCancelRequest>> => {
        const response = await adminApiAxios.get<Page<SeizureBankCancelRequest>>('/seizure-bank-cancel-requests', {
            params: { page, size, sortField, sortOrder, councilId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (seizureBankCancelRequestId: number): Promise<SeizureBankCancelRequest> => {
        const response = await adminApiAxios.get<SeizureBankCancelRequest>(`/seizure-bank-cancel-requests/${seizureBankCancelRequestId}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (councilTaxpayerId: number, councilTaxpayerSeizureBankId?: number): Promise<SeizureBankCancelRequest[]> => {
        const response = await adminApiAxios.post<SeizureBankCancelRequest[]>(
            `/seizure-bank-cancel-requests`,
            {},
            { params: { councilTaxpayerId, councilTaxpayerSeizureBankId } }
        );
        response.data.forEach((seizureBankCancelRequest) => this.loadDates(seizureBankCancelRequest));

        return response.data;
    };

    createDraft = async (councilTaxpayerId?: number, councilTaxpayerSeizureBankId?: number): Promise<SeizureBankCancelRequestDraft> => {
        const response = await adminApiAxios.post<SeizureBankCancelRequestDraft>(
            `/seizure-bank-cancel-requests/draft`,
            {},
            {
                params: { councilTaxpayerId, councilTaxpayerSeizureBankId }
            }
        );
        this.loadDraftDates(response.data);

        return response.data;
    };

    downloadDraft = async (councilTaxpayerSeizureBankId: number): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(
            `/seizure-bank-cancel-requests/draft/file`,
            {},
            { responseType: 'blob', params: { councilTaxpayerSeizureBankId } }
        );

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/seizure-bank-cancel-requests/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Loads the dates of a seizure bank cancel request.
     * @param seizureBankCancelRequest - the seizure bank cancel request
     */
    private loadDates(seizureBankCancelRequest: SeizureBankCancelRequest) {
        seizureBankCancelRequest.sent = dateService.transformDate(seizureBankCancelRequest.sent);
        seizureBankCancelRequest.audit!.created = dateService.transformDate(seizureBankCancelRequest.audit!.created)!;
        seizureBankCancelRequest.audit!.updated = dateService.transformDate(seizureBankCancelRequest.audit!.updated)!;
    }

    /**
     * Loads the dates of the seizure bank cancel request draft.
     * @param seizureBankCancelRequestDraft - the seizure bank cancel request draft
     */
    private loadDraftDates(seizureBankCancelRequestDraft: SeizureBankCancelRequestDraft) {
        const { seizureBankCancelRequests, ctSeizureBankCancelRequests } = seizureBankCancelRequestDraft;
        seizureBankCancelRequests.forEach((seizureBankCancelRequest) => {
            this.loadDates(seizureBankCancelRequest);
        });

        ctSeizureBankCancelRequests.forEach((ctSeizureBankCancelRequest) => {
            ctSeizureBankCancelRequest.audit!.created = dateService.transformDate(ctSeizureBankCancelRequest.audit!.created)!;
            ctSeizureBankCancelRequest.audit!.updated = dateService.transformDate(ctSeizureBankCancelRequest.audit!.updated)!;
        });
    }
}

const seizureBankCancelRequestApi: SeizureBankCancelRequestApi = new SeizureBankCancelRequestApi();
export default seizureBankCancelRequestApi;
