import { Page } from '../models/Elements';
import { CouncilTaxpayerSeizureIncome, CouncilTaxpayerSeizureIncomeFull } from '../models/Entities';
import { CouncilTaxpayerSeizureIncomePhaseType } from '../models/Types';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class CouncilTaxpayerSeizureIncomeApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilTaxpayerId: number,
        includeCancelled?: boolean,
        phase?: CouncilTaxpayerSeizureIncomePhaseType
    ): Promise<Page<CouncilTaxpayerSeizureIncome>> => {
        const response = await adminApiAxios.get<Page<CouncilTaxpayerSeizureIncome>>('/council-taxpayer-seizure-incomes', {
            params: { page, size, sortField, sortOrder, councilTaxpayerId, includeCancelled, phase }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    getFull = async (id: number): Promise<CouncilTaxpayerSeizureIncomeFull> => {
        const response = await adminApiAxios.get<CouncilTaxpayerSeizureIncomeFull>(`/council-taxpayer-seizure-incomes/${id}/full`);
        this.loadFullDates(response.data);

        return response.data;
    };

    /**
     * Loads the dates of the council taxpayer seizure income info request.
     * @param councilTaxpayerSeizureIncome
     */
    private loadDates(councilTaxpayerSeizureIncome: CouncilTaxpayerSeizureIncome) {
        councilTaxpayerSeizureIncome.audit!.created = dateService.transformDate(councilTaxpayerSeizureIncome.audit!.created)!;
        councilTaxpayerSeizureIncome.audit!.updated = dateService.transformDate(councilTaxpayerSeizureIncome.audit!.updated)!;
    }

    /**
     * Loads the dates of the council taxpayer seizure income full info request.
     * @param councilTaxpayerSeizureIncomeFull
     */
    private loadFullDates(councilTaxpayerSeizureIncomeFull: CouncilTaxpayerSeizureIncomeFull) {
        const { councilTaxpayerSeizureIncome, events } = councilTaxpayerSeizureIncomeFull;

        councilTaxpayerSeizureIncome.audit!.created = dateService.transformDate(councilTaxpayerSeizureIncome.audit!.created)!;
        councilTaxpayerSeizureIncome.audit!.updated = dateService.transformDate(councilTaxpayerSeizureIncome.audit!.updated)!;
        events.forEach((event) => {
            event.audit.created = dateService.transformDate(event.audit.created)!;
            event.audit.updated = dateService.transformDate(event.audit.updated)!;
            this.loadDates(event.councilTaxpayerSeizureIncome);
        });
    }
}

const councilTaxpayerSeizureIncomeApi: CouncilTaxpayerSeizureIncomeApi = new CouncilTaxpayerSeizureIncomeApi();
export default councilTaxpayerSeizureIncomeApi;
