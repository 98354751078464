import { Page } from '../models/Elements';
import { SeizureIncomeOrder, SeizureIncomeOrderCreation, SeizureIncomeOrderDraft } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class SeizureIncomeOrderApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId?: number,
        councilTaxpayerId?: number,
        searchText?: string
    ): Promise<Page<SeizureIncomeOrder>> => {
        const response = await adminApiAxios.get<Page<SeizureIncomeOrder>>('/seizure-income-orders', {
            params: { page, size, sortField, sortOrder, councilId, councilTaxpayerId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<SeizureIncomeOrder> => {
        const response = await adminApiAxios.get<SeizureIncomeOrder>(`/seizure-income-orders/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (seizureIncomeOrderCreation: SeizureIncomeOrderCreation): Promise<SeizureIncomeOrder[]> => {
        const response = await adminApiAxios.post<SeizureIncomeOrder[]>('/seizure-income-orders', seizureIncomeOrderCreation);
        response.data.forEach((seizureIncomeOrder) => this.loadDates(seizureIncomeOrder));

        return response.data;
    };

    createDraft = async (seizureIncomeOrderCreation: SeizureIncomeOrderCreation): Promise<SeizureIncomeOrderDraft> => {
        const response = await adminApiAxios.post<SeizureIncomeOrderDraft>('/seizure-income-orders/draft', seizureIncomeOrderCreation);
        this.loadDraftDates(response.data);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/seizure-income-orders/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    downloadCompany = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/seizure-income-orders/${id}/file/company`, {
            responseType: 'blob'
        });

        return response.data;
    };

    downloadDraft = async (councilTaxpayerId: number, clone?: boolean): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(
            `/seizure-income-orders/draft/file`,
            {},
            { responseType: 'blob', params: { councilTaxpayerId, clone } }
        );

        return response.data;
    };

    downloadDraftCompany = async (councilTaxpayerId: number, clone?: boolean): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(
            `/seizure-income-orders/draft/file/company`,
            {},
            { responseType: 'blob', params: { councilTaxpayerId, clone } }
        );

        return response.data;
    };

    /**
     * Loads the dates of a seizureIncomeOrder.
     * @param seizureIncomeOrder - the seizure income order
     */
    private loadDates(seizureIncomeOrder: SeizureIncomeOrder) {
        seizureIncomeOrder.audit!.created = dateService.transformDate(seizureIncomeOrder.audit!.created)!;
        seizureIncomeOrder.audit!.updated = dateService.transformDate(seizureIncomeOrder.audit!.updated)!;
    }

    /**
     * Loads the dates of the seizure income order  draft.
     * @param seizureIncomeOrderDraft - the seizure income order  draft
     */
    private loadDraftDates(seizureIncomeOrderDraft: SeizureIncomeOrderDraft) {
        const { seizureIncomeOrders } = seizureIncomeOrderDraft;
        seizureIncomeOrders &&
            seizureIncomeOrders.forEach((seizureIncomeOrder) => {
                this.loadDates(seizureIncomeOrder);
            });
    }
}

const seizureIncomeOrderApi: SeizureIncomeOrderApi = new SeizureIncomeOrderApi();
export default seizureIncomeOrderApi;
