import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import { Page } from '../../models/Elements';
import { NationalParameter } from '../../models/Entities';
import alertService from '../../services/AlertService';
import tableService from '../../services/TableService';
import styles from './NationalParametersPage.module.scss';
import nationalParameterApi from '../../apis/NationalParameterApi';

/**
 * Returns the national parameters page.
 * @returns the national parameters page.
 */
const NationalParametersPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [nationalParameters, setnationalParameters] = useState<Page<NationalParameter>>();
    const [loading, setLoading] = useState<'loading'>();
    const [form] = Form.useForm();
    const [filter, setFilter] = useState<Filter>({});
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('fiscalYear');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [desktop] = useResponsiveLayout();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                //get nationalParameters
                const nationalParameters = await nationalParameterApi.list(page, 100, sortField, sortOrder, filter.searchText);
                setnationalParameters(nationalParameters);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter]);

    /*** VISUAL ***/
    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterUsers = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    /*** VISUAL ***/

    const items = nationalParameters ? nationalParameters.content : [];
    const columns: ColumnsType<NationalParameter> = [
        {
            title: <FormattedMessage id="nationalParameter.fiscalYear" />,
            dataIndex: 'fiscalYear',
            key: 'fiscalYear',
            defaultSortOrder: 'descend',
            sorter: true,
            render: (value: string | undefined, nationalParameter: NationalParameter) => (
                <Link to={`/national-parameters/${nationalParameter.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="nationalParameter.interestRate" />,
            dataIndex: 'interestRate',
            key: 'interestRate',
            align: 'right',
            width: 300,
            render: (value: number, nationalParameter: NationalParameter) => (
                <Link to={`/national-parameters/${nationalParameter.id}`}>
                    <FormattedNumber value={value / 100.0} minimumFractionDigits={2} maximumFractionDigits={2} style={'percent' as any} />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="nationalParameter.mailCosts" />,
            dataIndex: 'mailCosts',
            key: 'mailCosts',
            align: 'right',
            width: 300,
            render: (value: number, nationalParameter: NationalParameter) => (
                <Link to={`/national-parameters/${nationalParameter.id}`}>
                    <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                </Link>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="nationalParameters.title" />}
            menu="nationalParameters"
            path={[{ path: '/national-parameters', name: <FormattedMessage id="nationalParameters.title" /> }]}
        >
            <Form form={form} onFinish={filterUsers} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={20}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'nationalParameters.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} className={styles.buttons}>
                        <Link to="/national-parameters/new">
                            <Tooltip title={<FormattedMessage id="nationalParameters.new.tooltip" />}>
                                <Button type="primary" size="large" icon={<PlusOutlined />}>
                                    {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                </Button>
                            </Tooltip>
                        </Link>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(nationalParameters)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default NationalParametersPage;

interface Filter {
    searchText?: string | undefined;
}
