import { Tag } from 'antd';
import { StatusType } from '../../models/Types';
import ConstantLabel from '../ConstantLabel/ConstantLabel';
import styles from './StatusTag.module.scss';

/**
 * Returns a state tag.
 * @returns the state tag.
 */
const StatusTag: React.FC<Props> = (props) => {
    const { value } = props;

    if (value === 'ENABLED') {
        return (
            <Tag color="green" className={styles.text}>
                <ConstantLabel value={value} prefix="status.type." />
            </Tag>
        );
    } else if (value === 'DISABLED') {
        return (
            <Tag color="red" className={styles.text}>
                <ConstantLabel value={value} prefix="status.type." />
            </Tag>
        );
    } else {
        return <></>;
    }
};
export default StatusTag;

interface Props {
    value: StatusType | undefined;
}
