import { Page } from '../models/Elements';
import { CouncilCertificate, PublicCouncilCertificate } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class CouncilCertificateApi {
    list = async (
        councilId: number,
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        searchText?: string
    ): Promise<Page<PublicCouncilCertificate>> => {
        const response = await adminApiAxios.get<Page<PublicCouncilCertificate>>(`/councils/${councilId}/certificates`, {
            params: { page, size, sortField, sortOrder, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((user) => this.loadDates(user));

        return response.data;
    };

    get = async (councilId: number, id: number): Promise<PublicCouncilCertificate> => {
        const response = await adminApiAxios.get<PublicCouncilCertificate>(`/councils/${councilId}/certificates/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (councilCertificate: CouncilCertificate, file: any): Promise<PublicCouncilCertificate> => {
        const formData: FormData = this.buildFormData(councilCertificate, file);
        const response = await adminApiAxios.post<PublicCouncilCertificate>(`/councils/${councilCertificate.councilId}/certificates`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (councilId: number, id: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/councils/${councilId}/certificates/${id}`);

        return response.data;
    };

    /**
     * Builds a form data from a council certificate document.
     * @param councilCertificate the council certificate document
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (councilCertificate: CouncilCertificate, file?: File) => {
        const formData: FormData = new FormData();
        councilCertificate.id && formData.append('id', councilCertificate.id.toString());
        councilCertificate.councilId && formData.append('councilId', councilCertificate.councilId.toString());
        councilCertificate.secret && formData.append('secret', councilCertificate.secret);
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a council certificate.
     * @param councilCertificate - the council certificate
     */
    private loadDates(councilCertificate: CouncilCertificate) {
        councilCertificate.audit!.created = dateService.transformDate(councilCertificate.audit!.created)!;
        councilCertificate.audit!.updated = dateService.transformDate(councilCertificate.audit!.updated)!;
    }
}

const councilCertificateApi: CouncilCertificateApi = new CouncilCertificateApi();
export default councilCertificateApi;
