import { Descriptions, DescriptionsProps, Space } from 'antd';
import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { CouncilTaxpayer } from '../../../../models/Entities';
import styles from './CouncilTaxpayerSummaryComponent.module.scss';

/**
 * The council taxpayer summary component with the receivable information.
 * @param props the props
 * @returns the council taxpayer summary component
 */
const CouncilTaxpayerSummaryComponent: React.FC<CouncilTaxpayerSummaryProps> = (props) => {
    const { councilTaxpayer } = props;

    /*** HOOKS ***/

    /*** METHODS ***/

    /*** VISUAL ***/

    const items: DescriptionsProps['items'] = [
        {
            key: 'id',
            label: <FormattedMessage id="councilTaxpayer.id" />,
            children: <strong>{councilTaxpayer?.id}</strong>,
            span: 1
        },
        {
            key: 'firstName',
            label: <FormattedMessage id="taxpayer.firstName" />,
            children: <strong>{councilTaxpayer?.taxpayer?.fullName}</strong>,
            span: 2
        },
        {
            key: 'identifier',
            label: <FormattedMessage id="taxpayer.identifier" />,
            children: <strong>{councilTaxpayer?.taxpayer?.identifier}</strong>,
            span: 2
        },
        {
            key: 'created',
            label: <FormattedMessage id="audit.created" />,
            children: councilTaxpayer?.taxpayer?.audit && (
                <strong>
                    <FormattedDate
                        value={councilTaxpayer.taxpayer.audit.created as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                    />
                </strong>
            ),
            span: 2
        },
        {
            key: 'address',
            label: <FormattedMessage id="address.city" />,
            children: (
                <strong>
                    <Space>
                        {councilTaxpayer?.taxpayer?.address?.street}
                        {councilTaxpayer?.taxpayer?.address?.postcode}
                        {councilTaxpayer?.taxpayer?.address?.city}
                    </Space>
                </strong>
            ),
            span: 3
        },
        {
            key: 'phone',
            label: <FormattedMessage id="address.phone" />,
            children: <strong>{councilTaxpayer?.taxpayer?.address?.phone}</strong>,
            span: 2
        },
        {
            key: 'email',
            label: <FormattedMessage id="address.email" />,
            children: <strong>{councilTaxpayer?.taxpayer?.address?.email}</strong>,
            span: 2
        },

        {
            key: 'amountPrincipal',
            label: <FormattedMessage id="amount.principal" />,
            children: (
                <strong>
                    {councilTaxpayer && (
                        <FormattedNumber
                            value={councilTaxpayer.amount.principal}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            style={'currency' as any}
                            currency="EUR"
                        />
                    )}
                </strong>
            ),
            span: 1
        },
        {
            key: 'amountSurcharge',
            label: <FormattedMessage id="amount.surcharge" />,
            children: (
                <strong>
                    {councilTaxpayer && (
                        <FormattedNumber
                            value={councilTaxpayer.amount.surcharge}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            style={'currency' as any}
                            currency="EUR"
                        />
                    )}
                </strong>
            )
        },
        {
            key: 'amountInterest',
            label: <FormattedMessage id="amount.interest" />,
            children: (
                <strong>
                    {councilTaxpayer && (
                        <FormattedNumber
                            value={councilTaxpayer.amount.interest}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            style={'currency' as any}
                            currency="EUR"
                        />
                    )}
                </strong>
            )
        },
        {
            key: 'amountTotal',
            label: <FormattedMessage id="amount.total" />,
            children: (
                <strong>
                    {councilTaxpayer && (
                        <FormattedNumber
                            value={councilTaxpayer.amount.total}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            style={'currency' as any}
                            currency="EUR"
                        />
                    )}
                </strong>
            )
        },
        {
            key: 'amountCosts',
            label: <FormattedMessage id="councilTaxpayer.costs" />,
            children: (
                <strong>
                    {councilTaxpayer && (
                        <FormattedNumber
                            value={councilTaxpayer.costs}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            style={'currency' as any}
                            currency="EUR"
                        />
                    )}
                </strong>
            )
        },

        {
            key: 'amountSeized',
            label: <FormattedMessage id="amount.seized" />,
            children: (
                <strong>
                    {councilTaxpayer && councilTaxpayer.seizedAmount ? (
                        <FormattedNumber
                            value={councilTaxpayer.seizedAmount}
                            minimumFractionDigits={2}
                            maximumFractionDigits={2}
                            style={'currency' as any}
                            currency="EUR"
                        />
                    ) : (
                        <FormattedNumber value={0} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                    )}
                </strong>
            )
        }
    ];

    return <Descriptions layout="horizontal" items={items} size="middle" column={6} className={styles.summary} bordered />;
};
export default CouncilTaxpayerSummaryComponent;
interface CouncilTaxpayerSummaryProps {
    councilTaxpayer: CouncilTaxpayer | undefined;
}
