/**
 * The council taxpayer component with the irpf/iva information.
 * @param props the props
 * @returns the council taxpayer irpf/iva component
 */
const CouncilTaxpayerIrpfsComponent: React.FC = () => {
    /*** HOOKS ***/

    /*** VISUAL ***/

    return <p>IRPF/IVA</p>;
};
export default CouncilTaxpayerIrpfsComponent;
