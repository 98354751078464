import { Page } from '../models/Elements';
import { SeizureIncomeSign, SeizureIncomeSignCreation, SeizureIncomeSignDraft } from '../models/Entities';
import { SeizureIncomeSignSignatureType } from '../models/Types';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class SeizureIncomeSignApi {
    //list seizure income signs
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId?: number,
        searchText?: string
    ): Promise<Page<SeizureIncomeSign>> => {
        const response = await adminApiAxios.get<Page<SeizureIncomeSign>>('/seizure-income-signs', {
            params: { page, size, sortField, sortOrder, councilId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    // get seizure income sign
    get = async (id: number): Promise<SeizureIncomeSign> => {
        const response = await adminApiAxios.get<SeizureIncomeSign>(`/seizure-income-signs/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    // download seizure income sign file
    download = async (id: number, signature: SeizureIncomeSignSignatureType): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/seizure-income-signs/${id}/file`, {
            responseType: 'blob',
            params: { signature }
        });

        return response.data;
    };

    // create seizure income sign
    create = async (seizureIncomeSignCreation: SeizureIncomeSignCreation): Promise<SeizureIncomeSign> => {
        const response = await adminApiAxios.post<SeizureIncomeSign>('/seizure-income-signs', seizureIncomeSignCreation);

        return response.data;
    };

    // create seizure income sign draft
    createDraft = async (seizureIncomeSignCreation: SeizureIncomeSignCreation): Promise<SeizureIncomeSignDraft> => {
        const response = await adminApiAxios.post<SeizureIncomeSignDraft>('/seizure-income-signs/draft', seizureIncomeSignCreation);
        this.loadDraftDates(response.data);

        return response.data;
    };

    downloadDraft = async (seizureIncomeSignCreation: SeizureIncomeSignCreation): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(`/seizure-income-signs/draft/file`, seizureIncomeSignCreation, { responseType: 'blob' });

        return response.data;
    };

    // upload draft seizure income sign
    uploadDraftResponse = async (seizureIncomeSign: SeizureIncomeSign): Promise<SeizureIncomeSign> => {
        const response = await adminApiAxios.post<SeizureIncomeSign>('/seizure-income-signs/draft/file', seizureIncomeSign);
        this.loadDates(response.data);

        return response.data;
    };

    //update
    update = async (id: number, signature: SeizureIncomeSignSignatureType, seizureIncomeSign: SeizureIncomeSign, file: File): Promise<SeizureIncomeSign> => {
        const formData: FormData = this.buildFormData(seizureIncomeSign, file);
        const response = await adminApiAxios.put<SeizureIncomeSign>(`/seizure-income-signs/${seizureIncomeSign.id}/responses`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    sign = async (seizureIncomeSign: SeizureIncomeSign, signature: SeizureIncomeSignSignatureType, signedFile: any): Promise<SeizureIncomeSign> => {
        const formData: FormData = this.buildFormData(seizureIncomeSign, signedFile);
        const response = await adminApiAxios.put<SeizureIncomeSign>(`/seizure-income-signs/${seizureIncomeSign.id}/signs`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params: {
                signature
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    /**
     * Builds a form data from a seizure income sign.
     * @param seizureIncome the seizure income sign
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (seizureIncomeSign: SeizureIncomeSign, file?: File) => {
        const formData: FormData = new FormData();
        seizureIncomeSign.id && formData.append('id', seizureIncomeSign.id.toString());
        seizureIncomeSign.councilId && formData.append('councilId', seizureIncomeSign.councilId.toString());
        seizureIncomeSign.income && formData.append('income', seizureIncomeSign.income.toString());
        seizureIncomeSign.treasurer.id && formData.append('treasurer.id', seizureIncomeSign.treasurer.id.toString());
        seizureIncomeSign.treasurer.fullName && formData.append('treasurer.fullName', seizureIncomeSign.treasurer.fullName.toString());
        seizureIncomeSign.treasurerSigned && formData.append('treasurerSigned', seizureIncomeSign.treasurerSigned.format('YYYY-MM-DDThh:mm:ss'));
        seizureIncomeSign.sent && formData.append('sent', seizureIncomeSign.sent.format('YYYY-MM-DDThh:mm:ss'));
        seizureIncomeSign.state && formData.append('state', seizureIncomeSign.state.toString());
        file && formData.append('signedFile.name', file.name);
        file && formData.append('signedFile.file', file);

        return formData;
    };

    /**
     * Loads the dates of a seizureIncomeSign.
     * @param seizureIncomeSign - the seizure income sign
     */
    private loadDates(seizureIncomeSign: SeizureIncomeSign) {
        seizureIncomeSign.sent && (seizureIncomeSign.sent = dateService.transformDate(seizureIncomeSign.sent));
        seizureIncomeSign.treasurerSigned && (seizureIncomeSign.treasurerSigned = dateService.transformDate(seizureIncomeSign.treasurerSigned));
        seizureIncomeSign.audit!.created && (seizureIncomeSign.audit!.created = dateService.transformDate(seizureIncomeSign.audit!.created)!);
        seizureIncomeSign.audit!.updated && (seizureIncomeSign.audit!.updated = dateService.transformDate(seizureIncomeSign.audit!.updated)!);
    }

    /**
     * Loads the dates of a seizureIncomeSignDraft.
     * @param seizureIncomeSignDraft - the seizure income sign draft
     */
    private loadDraftDates(seizureIncomeSignDraft: SeizureIncomeSignDraft) {
        const { seizureIncome, ctSeizureIncomeSigns } = seizureIncomeSignDraft;
        seizureIncome.sent && (seizureIncome.sent = dateService.transformDate(seizureIncome.sent));
        seizureIncome.audit!.created && (seizureIncome.audit!.created = dateService.transformDate(seizureIncome.audit!.created)!);
        seizureIncome.audit!.updated && (seizureIncome.audit!.updated = dateService.transformDate(seizureIncome.audit!.updated)!);

        ctSeizureIncomeSigns &&
            ctSeizureIncomeSigns.forEach((csis) => {
                csis.councilTaxpayerSeizureIncome.audit!.created &&
                    (csis.councilTaxpayerSeizureIncome.audit!.created = dateService.transformDate(csis.councilTaxpayerSeizureIncome.audit.created)!);
                csis.councilTaxpayerSeizureIncome.audit!.updated &&
                    (csis.councilTaxpayerSeizureIncome.audit!.updated = dateService.transformDate(csis.councilTaxpayerSeizureIncome.audit.updated)!);
                //csis.councilTaxpayerSeizureIncome.councilTaxpayer.audit.created &&
                csis.councilTaxpayerSeizureIncome.councilTaxpayer.audit.created = dateService.transformDate(
                    csis.councilTaxpayerSeizureIncome.councilTaxpayer.audit.created
                )!;
                //csis.councilTaxpayerSeizureIncome.councilTaxpayer.audit.updated &&
                csis.councilTaxpayerSeizureIncome.councilTaxpayer.audit.updated = dateService.transformDate(
                    csis.councilTaxpayerSeizureIncome.councilTaxpayer.audit.updated
                )!;
                //csis.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit!.created &&
                csis.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit!.created = dateService.transformDate(
                    csis.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit?.created
                )!;
                //csis.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit!.updated &&
                csis.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit!.updated = dateService.transformDate(
                    csis.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit?.updated
                )!;
                // csis.audit!.created && (csis.audit.created = dateService.transformDate(csis.audit!.created)!);
                // csis.audit!.updated && (csis.audit!.updated = dateService.transformDate(csis.audit!.updated)!);
            });
    }
}

const seizureIncomeSignApi: SeizureIncomeSignApi = new SeizureIncomeSignApi();
export default seizureIncomeSignApi;
