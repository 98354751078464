import { Button, Col, List, Modal, Row, Space, message } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import announcementApi from '../../../apis/AnnouncementApi';
import {
    AnnouncementCreation,
    AnnouncementDraft,
    CouncilTaxpayerSeizureIncomeOrderAnnouncementSign,
    Receivable,
    ReceivablePaymentOrderAnnouncementSign
} from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './AnnouncementModal.module.scss';
import FileSaver from 'file-saver';

const AnnouncementModal: React.FC<Props> = (props) => {
    const { councilId } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading' | 'sending' | 'downloading' | 'downloadingXml'>();
    const [announcementDraft, setAnnouncementDraft] = useState<AnnouncementDraft>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const announcementCreation: AnnouncementCreation = {
                    councilId
                };
                const announcementDraft = await announcementApi.createDraft(announcementCreation);
                setAnnouncementDraft(announcementDraft);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, intl]);

    /*** METHODS ***/

    const send = async () => {
        try {
            setLoading('sending');
            const updatedAnnouncementCreation: AnnouncementCreation = {
                councilId
            };
            await announcementApi.create(updatedAnnouncementCreation);
            await props.onSave();
            message.success(intl.formatMessage({ id: 'status.sent' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const downloadDraft = async () => {
        try {
            setLoading('downloading');
            const document = await announcementApi.downloadDraft(councilId);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'announcement.draft.file.name' }));
            message.success(intl.formatMessage({ id: 'status.downloaded' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const downloadDraftXml = async () => {
        try {
            setLoading('downloadingXml');
            const document = await announcementApi.downloadDraftXml(councilId);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'announcement.draft.xml.file.name' }));
            message.success(intl.formatMessage({ id: 'status.downloaded' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = async () => {
        props.onCancel();
    };

    /*** VISUAL ***/

    const receivablePaymentOrderAnnouncementSigns = announcementDraft ? announcementDraft.receivablePaymentOrderAnnouncementSigns : [];
    const ctSeizureIncomeOrderAnnouncementSigns = announcementDraft ? announcementDraft.ctSeizureIncomeOrderAnnouncementSigns : [];
    const receivables = announcementDraft ? announcementDraft?.receivables : [];

    return (
        <Modal
            title={<FormattedMessage id="announcementModal.title" />}
            open={true}
            onCancel={cancel}
            width={800}
            footer={[
                <Button key="cancel" size="large" onClick={cancel}>
                    <FormattedMessage id="button.cancel" tagName="span" />
                </Button>,
                <Button
                    key="downloadDraft"
                    size="large"
                    onClick={downloadDraft}
                    loading={loading === 'downloading'}
                    disabled={
                        !announcementDraft ||
                        (announcementDraft.receivablePaymentOrderAnnouncementSigns.length === 0 &&
                            announcementDraft.ctSeizureIncomeOrderAnnouncementSigns.length === 0)
                    }
                >
                    <FormattedMessage id="button.preview" tagName="span" />
                </Button>,
                <Button
                    key="downloadDraftXml"
                    size="large"
                    onClick={downloadDraftXml}
                    loading={loading === 'downloadingXml'}
                    disabled={
                        !announcementDraft ||
                        (announcementDraft.receivablePaymentOrderAnnouncementSigns.length === 0 &&
                            announcementDraft.ctSeizureIncomeOrderAnnouncementSigns.length === 0)
                    }
                >
                    <FormattedMessage id="announcement.button.preview.xml" tagName="span" />
                </Button>,
                <Button
                    key="ok"
                    size="large"
                    type="primary"
                    onClick={send}
                    loading={loading === 'sending'}
                    disabled={
                        !announcementDraft ||
                        (announcementDraft.receivablePaymentOrderAnnouncementSigns.length === 0 &&
                            announcementDraft.ctSeizureIncomeOrderAnnouncementSigns.length === 0)
                    }
                >
                    <FormattedMessage id="button.save" tagName="span" />
                </Button>
            ]}
        >
            <Row gutter={[0, 0]} className={styles.container}>
                <Col span={24}>
                    <FormattedMessage id="announcementModal.description" />
                </Col>
                <Col span={24}>
                    <List
                        className={styles.list}
                        size="small"
                        locale={{ emptyText: <></> }}
                        itemLayout="horizontal"
                        dataSource={receivablePaymentOrderAnnouncementSigns}
                        loading={loading === 'loading'}
                        renderItem={(receivablePaymentOrderAnnouncementSign) => (
                            <AnnouncementReceivableComponent item={receivablePaymentOrderAnnouncementSign} receivables={receivables} />
                        )}
                    />
                </Col>
                <Col span={24}>
                    <List
                        className={styles.list}
                        size="small"
                        itemLayout="horizontal"
                        dataSource={ctSeizureIncomeOrderAnnouncementSigns}
                        loading={loading === 'loading'}
                        locale={{ emptyText: <></> }}
                        renderItem={(ctSeizureIncomeOrderAnnouncementSign) => (
                            <AnnouncementReceivableSeizureIncomeComponent item={ctSeizureIncomeOrderAnnouncementSign} />
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    );
};
export default AnnouncementModal;

interface Props {
    councilId: number;
    onSave: () => Promise<void>;
    onCancel: () => void;
}

const AnnouncementReceivableComponent: React.FC<AnnouncementReceivableComponentProps> = (props) => {
    const { item, receivables } = props;

    const selectedReceivable = receivables.find((receivable) => receivable.id === item.receivableId);

    /*** HOOKS ***/

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <List.Item>
            <List.Item.Meta
                title={
                    <FormattedMessage
                        id="announcementModal.taxpayer.identifier"
                        values={{ identifier: selectedReceivable?.councilTaxpayer?.taxpayer.identifier }}
                    />
                }
                description={
                    <Space split="|">
                        <FormattedMessage id="announcementModal.receivable.id" values={{ receivableId: selectedReceivable?.id }} />
                        <FormattedMessage id="announcementModal.type" />
                    </Space>
                }
            />
        </List.Item>
    );
};

interface AnnouncementReceivableComponentProps {
    item: ReceivablePaymentOrderAnnouncementSign;
    receivables: Receivable[];
}

const AnnouncementReceivableSeizureIncomeComponent: React.FC<AnnouncementReceivableSeizureIncomeComponentProps> = (props) => {
    const { item } = props;

    /*** HOOKS ***/

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <List.Item>
            <List.Item.Meta
                title={
                    <FormattedMessage
                        id="announcementModal.taxpayer.identifier"
                        values={{ identifier: item.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.identifier }}
                    />
                }
                description={<Space split="|">{item.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.fullName}</Space>}
            />
        </List.Item>
    );
};

interface AnnouncementReceivableSeizureIncomeComponentProps {
    item: CouncilTaxpayerSeizureIncomeOrderAnnouncementSign;
}
