import { CloudUploadOutlined, DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, List, Modal, Row, Upload, UploadFile, message } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import receivableCancellationApi from '../../../../apis/ReceivableCancellationApi';
import FileSizeComponent from '../../../../components/FileSizeComponent/FileSizeComponent';
import { Receivable, ReceivableCancellation } from '../../../../models/Entities';

import styles from './ReceivableCancellationModal.module.scss';
import alertService from '../../../../services/AlertService';

const ReceivableCancellationModal = (props: Props) => {
    const { receivable } = props;
    const maxFileSize = 5 * 1024 * 1024;
    const { TextArea } = Input;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'loading' | 'saving'>();
    const [files, setFiles] = useState<UploadFile[]>([]);

    /*** METHODS ***/

    const save = async () => {
        try {
            setLoading('saving');

            const values = await form.validateFields();
            let cancellation: ReceivableCancellation = Object.assign({}, { receivableId: receivable!.id }, values);

            const attachment: UploadFile | undefined = files.length > 0 ? files[0] : undefined;
            await receivableCancellationApi.create(cancellation, attachment);
            props.onSave();

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // cancel
    const cancel = async () => {
        props.onCancel();
    };

    // validate file
    const validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    // upload file
    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    //remove file
    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            file: files
        });
        setFiles(files);
    };

    /*** VISUAL ***/

    return (
        <Modal
            title={<FormattedMessage id="receivable.cancellation.modal.title" />}
            open={true}
            onCancel={cancel}
            onOk={save}
            okText={<FormattedMessage id="button.save" tagName="span" />}
            okButtonProps={{ loading: loading === 'saving', size: 'large' }}
            cancelButtonProps={{ disabled: loading === 'saving', size: 'large' }}
            width={1000}
        >
            <Form form={form} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={7}>
                        <Form.Item
                            label={<FormattedMessage id="receivable.cancellation.date" />}
                            name="canceled"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <DatePicker />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={<FormattedMessage id="receivable.cancellation.modal.remarks" />}
                            name="remarks"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <TextArea rows={6} maxLength={800} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <Form.Item
                            name="file"
                            valuePropName="files"
                            label={
                                <>
                                    <span>
                                        <FormattedMessage id="receivable.cancellation.modal.upload" />
                                    </span>
                                </>
                            }
                            rules={[
                                {
                                    validator: validateFile
                                }
                            ]}
                            extra={
                                <>
                                    <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                                </>
                            }
                        >
                            <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                                <CloudUploadOutlined /> <FormattedMessage id="button.upload" />
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <List
                            itemLayout="horizontal"
                            dataSource={files}
                            locale={{ emptyText: <></> }}
                            renderItem={(file) => (
                                <List.Item actions={[<Button icon={<DeleteOutlined />} danger size="large" onClick={removeFile} />]}>
                                    <List.Item.Meta
                                        avatar={<PaperClipOutlined className={styles.icon} />}
                                        title={file.name}
                                        description={<FileSizeComponent value={file.size} />}
                                    />
                                </List.Item>
                            )}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
export default ReceivableCancellationModal;

interface Props {
    receivable: Receivable;
    onSave: () => void;
    onCancel: () => void;
}
