import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, DescriptionsProps, Row, Space } from 'antd';
import FileSaver from 'file-saver';
import { useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import paymentOrderUnofficialApi from '../../../../apis/PaymentOrderUnofficialApi';
import { Receivable, ReceivableNotificationUnofficial } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivableNotificationUnofficialComponent.module.scss';

const ReceivableNotificationUnofficialComponent = (props: Props) => {
    const { receivable, notificationUnofficial } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading'>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            const document = await paymentOrderUnofficialApi.download(notificationUnofficial.paymentOrderUnofficial.id);
            FileSaver.saveAs(document, `${receivable.councilTaxpayer?.taxpayer.id}.pdf`);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const items: DescriptionsProps['items'] = [
        {
            key: 'amount',
            label: <FormattedMessage id="receivable.amount.principal" />,
            children: notificationUnofficial?.amount !== undefined && (
                <FormattedNumber
                    value={notificationUnofficial?.amount.principal!}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },
        {
            key: 'surcharge',
            label: (
                <>
                    <FormattedMessage id="amount.surcharge" /> ({notificationUnofficial.penalty * 100}%)
                </>
            ),
            children: notificationUnofficial?.amount?.surcharge !== undefined && (
                <FormattedNumber
                    value={notificationUnofficial.amount.surcharge}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },
        {
            key: 'totalAmount',
            label: <FormattedMessage id="receivable.amount.total" />,
            children: notificationUnofficial?.amount?.total !== undefined && (
                <strong>
                    <FormattedNumber
                        value={notificationUnofficial?.amount?.total}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        style={'currency' as any}
                        currency="EUR"
                    />
                </strong>
            )
        }
    ];

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Descriptions layout="horizontal" items={items} size="default" column={4} />
                </Col>
            </Row>

            <Row gutter={[28, 0]} className={styles.buttons}>
                <Col span={24}>
                    <Space>
                        <Button icon={<CloudDownloadOutlined />} onClick={download} loading={loading === 'downloading'}>
                            <FormattedMessage id="button.download" tagName="span" />
                        </Button>
                    </Space>
                </Col>
            </Row>
        </>
    );
};

export default ReceivableNotificationUnofficialComponent;

interface Props {
    receivable: Receivable;
    notificationUnofficial: ReceivableNotificationUnofficial;
}
