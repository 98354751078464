import { PlusOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import bankApi from '../../apis/BankApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import { Page } from '../../models/Elements';
import { Bank } from '../../models/Entities';
import alertService from '../../services/AlertService';
import tableService from '../../services/TableService';
import styles from '../BanksPage/BanksPage.module.scss';

/**
 * Returns the banks page.
 * @returns the banks page.
 */
const BanksPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [banks, setBanks] = useState<Page<Bank>>();
    const [loading, setLoading] = useState<'loading'>();
    const [form] = Form.useForm();
    const [filter, setFilter] = useState<Filter>({});
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('name');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [desktop] = useResponsiveLayout();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                //get banks
                const banks = await bankApi.list(page, 100, sortField, sortOrder, 'ENABLED', filter.searchText, undefined, true);
                setBanks(banks);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter]);

    /*** VISUAL ***/
    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterUsers = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    /*** VISUAL ***/

    const items = banks ? banks.content : [];
    const columns: ColumnsType<Bank> = [
        {
            title: <FormattedMessage id="bank.name" />,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string | undefined, bank: Bank) => <Link to={`/banks/${bank.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="bank.euCode" />,
            dataIndex: 'euCode',
            key: 'euCode',
            width: 250,
            align: 'center',
            render: (value: string | undefined, bank: Bank) => <Link to={`/banks/${bank.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="bank.lei" />,
            dataIndex: 'lei',
            key: 'lei',
            width: 250,
            render: (value: string | undefined, bank: Bank) => <Link to={`/banks/${bank.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="address.city" />,
            dataIndex: ['address', 'city'],
            key: 'city',
            width: 180,
            align: 'left',
            render: (value: string | undefined, bank: Bank) => <Link to={`/banks/${bank.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="bank.online" />,
            dataIndex: 'online',
            key: 'online',
            width: 100,
            align: 'center',
            sorter: true,
            render: (value: boolean, bank: Bank) => <Link to={`/banks/${bank.id}`}>{bank.online && <CheckCircleOutlined />}</Link>
        }
    ];

    return (
        <LayoutComponent title={<FormattedMessage id="banks.title" />} menu="banks" path={[{ path: '/banks', name: <FormattedMessage id="banks.title" /> }]}>
            <Form form={form} onFinish={filterUsers} colon={false} layout="vertical" requiredMark={false} className={styles.form}>
                <Row>
                    <Col span={20}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'banks.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} className={styles.buttons}>
                        <Link to="/banks/new">
                            <Tooltip title={<FormattedMessage id="banks.new.tooltip" />}>
                                <Button type="primary" size="large" icon={<PlusOutlined />}>
                                    {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                </Button>
                            </Tooltip>
                        </Link>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(banks)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className={`table ${styles.table}`}
            />
        </LayoutComponent>
    );
};
export default BanksPage;

interface Filter {
    searchText?: string | undefined;
}
