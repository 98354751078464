import { Page } from '../models/Elements';
import { CouncilCollectionAccount } from '../models/Entities';
import { StatusType } from '../models/Types';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class CouncilCollectionAccountApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId: number,
        status?: StatusType,
        searchText?: string
    ): Promise<Page<CouncilCollectionAccount>> => {
        const response = await adminApiAxios.get<Page<CouncilCollectionAccount>>(`/council-collection-accounts`, {
            params: { page, size, sortField, sortOrder, councilId, status, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((cca) => this.loadDates(cca));

        return response.data;
    };

    get = async (id: number): Promise<CouncilCollectionAccount> => {
        const response = await adminApiAxios.get<CouncilCollectionAccount>(`/council-collection-accounts/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (councilCollectionAccount: CouncilCollectionAccount): Promise<CouncilCollectionAccount> => {
        const response = await adminApiAxios.post<CouncilCollectionAccount>(`/council-collection-accounts`, councilCollectionAccount);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (councilCollectionAccount: CouncilCollectionAccount): Promise<CouncilCollectionAccount> => {
        const response = await adminApiAxios.put<CouncilCollectionAccount>(`/council-collection-accounts`, councilCollectionAccount);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (id: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/council-collection-accounts/${id}`);

        return response.data;
    };

    /**
     * Loads the dates of a council collection account.
     * @param councilCollectionAccount - the council collection account
     */
    private loadDates(councilCollectionAccount: CouncilCollectionAccount) {
        councilCollectionAccount.audit!.created = dateService.transformDate(councilCollectionAccount.audit!.created)!;
        councilCollectionAccount.audit!.updated = dateService.transformDate(councilCollectionAccount.audit!.updated)!;
    }
}

const councilCollectionAccountApi: CouncilCollectionAccountApi = new CouncilCollectionAccountApi();
export default councilCollectionAccountApi;
