import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, DescriptionsProps, Row, Space } from 'antd';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import paymentOrderSignApi from '../../../../apis/PaymentOrderSignApi';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import { PaymentOrderSign, ReceivableNotificationSign } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivableNotificationSignComponent.module.scss';

const ReceivableNotificationSignComponent = (props: Props) => {
    const { notificationSign } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading'>();
    const [paymentOrderSign, setPaymentOrderSign] = useState<PaymentOrderSign>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                const paymentOrderSign = await paymentOrderSignApi.get(notificationSign.paymentOrderSignId);
                setPaymentOrderSign(paymentOrderSign);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, notificationSign]);

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            const document = await paymentOrderSignApi.download(notificationSign.paymentOrderSignId, 'LATEST');
            FileSaver.saveAs(document, intl.formatMessage({ id: 'paymentOrderSign.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const items: DescriptionsProps['items'] = [
        {
            key: 'controller',
            label: <FormattedMessage id="receivable.notificationSign.paymentOrderSign.controller" />,
            children: paymentOrderSign?.controller.fullName
        },
        {
            key: 'controllerSigned',
            label: <FormattedMessage id="receivable.notificationSign.paymentOrderSign.signed" />,
            children:
                paymentOrderSign && paymentOrderSign.controllerSigned ? (
                    <FormattedDate
                        value={paymentOrderSign.controllerSigned as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                    />
                ) : (
                    '-'
                )
        },
        {
            key: 'sentState',
            label: <FormattedMessage id="receivable.notificationSign.paymentOrderSign.sentState" />,
            children: <ConstantLabel value={paymentOrderSign?.sentState} prefix="sentState." defaultValue={<FormattedMessage id="sentState.PENDING" />} />
        },
        {
            key: 'treasurer',
            label: <FormattedMessage id="receivable.notificationSign.paymentOrderSign.treasurer" />,
            children: paymentOrderSign?.treasurer.fullName
        },
        {
            key: 'treasurerSigned',
            label: <FormattedMessage id="receivable.notificationSign.paymentOrderSign.signed" />,
            children:
                paymentOrderSign && paymentOrderSign.treasurerSigned ? (
                    <FormattedDate
                        value={paymentOrderSign.treasurerSigned as any}
                        day="2-digit"
                        month="2-digit"
                        year="numeric"
                        hour="2-digit"
                        minute="2-digit"
                    />
                ) : (
                    '-'
                )
        }
    ];

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Descriptions layout="horizontal" items={items} size="default" column={3} />
                </Col>
            </Row>

            <Row gutter={[28, 0]} className={styles.buttons}>
                <Col span={24}>
                    <Space>
                        <Button icon={<CloudDownloadOutlined />} onClick={download} loading={loading === 'downloading'}>
                            <FormattedMessage id="button.download" tagName="span" />
                        </Button>
                    </Space>
                </Col>
            </Row>
        </>
    );
};

export default ReceivableNotificationSignComponent;

interface Props {
    notificationSign: ReceivableNotificationSign;
}
