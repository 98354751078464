import { Col, Divider, Row } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    Bank,
    CouncilTaxpayerSeizureBankAmountRequest,
    CouncilTaxpayerSeizureBankAmountResponse,
    CouncilTaxpayerSeizureBankCancelRequest,
    CouncilTaxpayerSeizureBankInfoRequest,
    CouncilTaxpayerSeizureBankInfoResponse,
    CouncilTaxpayerSeizureBankPaymentResponse
} from '../../../../models/Entities';
import councilTaxpayerSeizureBankApi from '../../../../apis/CouncilTaxpayerSeizureBankApi';
import { CouncilTaxpayerSeizureBank, CouncilTaxpayerSeizureBankEvent, CouncilTaxpayerSeizureBankFull } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import AmountRequestComponent from '../AmountRequestComponent/AmountRequestComponent';
import AmountResponseComponent from '../AmountResponseComponent/AmountResponseComponent';
import InfoRequestComponent from '../InfoRequestComponent/InfoRequestComponent';
import InfoResponseComponent from '../InfoResponseComponent/InfoResponseComponent';
import CancelRequestComponent from '../CancelRequestComponent/CancelRequestComponent';
import PaymentResponseComponent from '../PaymentResponseComponent/PaymentResponseComponent';

const CouncilTaxpayerSeizureBankComponent = (props: Props) => {
    const { councilTaxpayerSeizureBank, onUpdate } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [councilTaxpayerSeizureBankFull, setCouncilTaxpayerSeizureBankFull] = useState<CouncilTaxpayerSeizureBankFull>();
    const [, setLoading] = useState<'loading'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const councilTaxpayerSeizureBankFull = await councilTaxpayerSeizureBankApi.getFull(councilTaxpayerSeizureBank.id);
                setCouncilTaxpayerSeizureBankFull(councilTaxpayerSeizureBankFull);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, councilTaxpayerSeizureBank]);

    // get bank
    const bank: Bank | undefined = useMemo(() => {
        let bank: Bank | undefined;
        if (councilTaxpayerSeizureBankFull) {
            bank = councilTaxpayerSeizureBankFull.councilTaxpayerSeizureBank.bank;
        }

        return bank;
    }, [councilTaxpayerSeizureBankFull]);

    /*** METHODS ***/

    const refresh = async (update?: boolean) => {
        try {
            setLoading('loading');
            const councilTaxpayerSeizureBankFull = await councilTaxpayerSeizureBankApi.getFull(councilTaxpayerSeizureBank.id);
            setCouncilTaxpayerSeizureBankFull(councilTaxpayerSeizureBankFull);
            update && onUpdate();
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <>
            <Divider orientation="left" plain>
                {bank?.name}
            </Divider>
            <Row gutter={[16, 16]}>
                {councilTaxpayerSeizureBankFull &&
                    councilTaxpayerSeizureBankFull.events.map((event: CouncilTaxpayerSeizureBankEvent, index: number) => {
                        const eventReadonly = index !== councilTaxpayerSeizureBankFull.events.length - 1;

                        switch (event.phase) {
                            case 'PHASE_1':
                                return (
                                    <Col key={`${event.phase}-${event.councilTaxpayerSeizureBank.id}`} xs={24} md={4}>
                                        <InfoRequestComponent infoRequest={event as CouncilTaxpayerSeizureBankInfoRequest} />
                                    </Col>
                                );
                            case 'PHASE_2':
                                return (
                                    <Col key={`${event.phase}-${event.councilTaxpayerSeizureBank.id}`} xs={24} md={5}>
                                        <InfoResponseComponent infoResponse={event as CouncilTaxpayerSeizureBankInfoResponse} />
                                    </Col>
                                );
                            case 'PHASE_3':
                                return (
                                    <Col key={`${event.phase}-${event.councilTaxpayerSeizureBank.id}`} xs={24} md={5}>
                                        <AmountRequestComponent amountRequest={event as CouncilTaxpayerSeizureBankAmountRequest} />
                                    </Col>
                                );
                            case 'PHASE_4':
                                return (
                                    <Col key={`${event.phase}-${event.councilTaxpayerSeizureBank.id}`} xs={24} md={5}>
                                        <AmountResponseComponent
                                            readonly={eventReadonly}
                                            amountResponse={event as CouncilTaxpayerSeizureBankAmountResponse}
                                            onCancel={refresh}
                                            onPay={() => refresh(true)}
                                        />
                                    </Col>
                                );
                            case 'PHASE_5':
                                return (
                                    <Col key={`${event.phase}-${event.councilTaxpayerSeizureBank.id}`} xs={24} md={5}>
                                        <CancelRequestComponent cancelRequest={event as CouncilTaxpayerSeizureBankCancelRequest} />
                                    </Col>
                                );
                            case 'PHASE_6':
                                return (
                                    <Col key={`${event.phase}-${event.councilTaxpayerSeizureBank.id}`} xs={24} md={4}>
                                        <PaymentResponseComponent paymentResponse={event as CouncilTaxpayerSeizureBankPaymentResponse} />
                                    </Col>
                                );
                            default:
                                return <></>;
                        }
                    })}
            </Row>
        </>
    );
};
export default CouncilTaxpayerSeizureBankComponent;

interface Props {
    councilTaxpayerSeizureBank: CouncilTaxpayerSeizureBank;
    onUpdate: () => Promise<void>;
}
