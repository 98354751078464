import { Checkbox, Col, Empty, Form, List, Row, Select, Space } from 'antd';
import Search from 'antd/es/input/Search';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import councilTaxpayerSeizureBankApi from '../../../../apis/CouncilTaxpayerSeizureBankApi';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import CustomCouncilTaxpayerContext from '../../../../contexts/CustomCouncilTaxpayerContext';
import { CouncilTaxpayerSeizureBank } from '../../../../models/Entities';
import { councilTaxpayerSeizureBankPhaseTypes } from '../../../../models/Types';
import alertService from '../../../../services/AlertService';
import CouncilTaxpayerSeizureBankComponent from '../CouncilTaxpayerSeizureBankComponent/CouncilTaxpayerSeizureBankComponent';
import styles from './CouncilTaxpayerSeizureBanksComponent.module.scss';

/**
 * The council taxpayer component with banks information.
 * @param props the props
 * @returns the council taxpayer banks component
 */
const CouncilTaxpayerSeizureBanksComponent: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const { councilTaxpayer } = useContext(CustomCouncilTaxpayerContext);
    const [councilTaxpayerSeizureBanks, setCouncilTaxpayerSeizureBanks] = useState<CouncilTaxpayerSeizureBank[]>([]);
    const [loading, setLoading] = useState<'loading' | 'refreshing'>();

    /*** EFFECTS ***/

    // list council banks
    useEffect(() => {
        const init = async () => {
            if (councilTaxpayer) {
                try {
                    setLoading('loading');
                    const councilTaxpayerSeizureBankPage = await councilTaxpayerSeizureBankApi.list(0, 1000, 'id', true, councilTaxpayer.id!);
                    setCouncilTaxpayerSeizureBanks(councilTaxpayerSeizureBankPage.content);
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        };
        init();
    }, [councilTaxpayer, intl]);

    /*** METHODS ***/

    const refresh = async () => {
        if (councilTaxpayer) {
            try {
                setLoading('loading');
                const includeArchived = form.getFieldValue('includeArchived');
                const phase = form.getFieldValue('phase');
                const searchText = form.getFieldValue('searchText');
                const councilTaxpayerSeizureBankPage = await councilTaxpayerSeizureBankApi.list(
                    0,
                    1000,
                    'id',
                    true,
                    councilTaxpayer.id!,
                    includeArchived,
                    phase,
                    searchText
                );
                setCouncilTaxpayerSeizureBanks(councilTaxpayerSeizureBankPage.content);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        }
    };

    const search = async (values: any) => {
        const searchResult = await councilTaxpayerSeizureBankApi.list(
            0,
            1000,
            'id',
            true,
            councilTaxpayer!.id!,
            values.includeArchived,
            values.phase,
            values.searchText
        );
        setCouncilTaxpayerSeizureBanks(searchResult.content);
    };

    /*** VISUAL ***/

    const phaseOptions = councilTaxpayerSeizureBankPhaseTypes.map((phase) => (
        <Select.Option key={phase} value={phase}>
            <ConstantLabel value={phase} prefix="seizureBankAccount." />
        </Select.Option>
    ));

    return (
        <>
            <Form form={form} onFinish={search} colon={false} layout="vertical" requiredMark={false} className={styles.form}>
                <Row gutter={[0, 0]}>
                    <Col span={24}>
                        <Space className={styles.filter}>
                            <Form.Item name="searchText">
                                <Search
                                    placeholder={intl.formatMessage({
                                        id: 'banks.search'
                                    })}
                                    size="large"
                                    allowClear
                                    className={styles.search}
                                    onSearch={form.submit}
                                />
                            </Form.Item>
                            <Form.Item name="phase">
                                <Select
                                    placeholder={intl.formatMessage({
                                        id: 'seizureBankAccount.filter'
                                    })}
                                    size="large"
                                    className={styles.phase}
                                    allowClear
                                    maxTagCount={'responsive'}
                                    onChange={form.submit}
                                >
                                    {phaseOptions}
                                </Select>
                            </Form.Item>
                            <Form.Item name="includeArchived" valuePropName="checked">
                                <Checkbox onChange={form.submit} className={styles.checkbox}>
                                    <FormattedMessage id="seizureBankAccount.include.ARCHIVE" />
                                </Checkbox>
                            </Form.Item>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Col span={24}>
                <List
                    itemLayout="vertical"
                    loading={loading === 'loading'}
                    dataSource={councilTaxpayerSeizureBanks}
                    locale={{ emptyText: <Empty /> }}
                    renderItem={(councilTaxpayerSeizureBank) => (
                        <List.Item>
                            <CouncilTaxpayerSeizureBankComponent
                                key={councilTaxpayerSeizureBank.id}
                                councilTaxpayerSeizureBank={councilTaxpayerSeizureBank}
                                onUpdate={refresh}
                            />
                        </List.Item>
                    )}
                />
            </Col>
        </>
    );
};

export default CouncilTaxpayerSeizureBanksComponent;
