import { Col, Descriptions, DescriptionsProps, Row, Tag } from 'antd';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import ProvinceComponent from '../../../../components/ProvinceComponent/ProvinceComponent';
import { ReceivableRegistration, ReceivableRegistrationDocument } from '../../../../models/Entities';
import { useEffect, useState } from 'react';
import alertService from '../../../../services/AlertService';
import receivableRegistrationDocumentApi from '../../../../apis/ReceivableRegistrationDocumentApi';

const ReceivableRegistrationComponent = (props: Props) => {
    const { registration } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [, setLoading] = useState<'loading'>();
    const [receivableRegistrationDocument, setReceivableRegistrationDocument] = useState<ReceivableRegistrationDocument>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const receivableRegistrationDocument = await receivableRegistrationDocumentApi.get(registration.documentId!);
                setReceivableRegistrationDocument(receivableRegistrationDocument);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, registration]);

    /*** METHODS ***/

    /*** VISUAL ***/

    const items: DescriptionsProps['items'] = [
        {
            key: 'fullName',
            label: <FormattedMessage id="taxpayer.fullName" />,
            children: registration.fullName
        },
        {
            key: 'identifier',
            label: <FormattedMessage id="taxpayer.identifier" />,
            children: registration.identifier
        },
        {
            key: 'phone',
            label: <FormattedMessage id="address.phone" />,
            children: registration.address?.phone
        },
        {
            key: 'email',
            label: <FormattedMessage id="address.email" />,
            children: registration.address?.email
        },
        {
            key: 'street',
            label: <FormattedMessage id="address.street" />,
            children: registration.address?.street
        },
        {
            key: 'city',
            label: <FormattedMessage id="address.city" />,
            children: registration.address?.city
        },
        {
            key: 'province',
            label: <FormattedMessage id="address.province" />,
            children: <ProvinceComponent provinceId={registration.address?.province} />
        },
        {
            key: 'postcode',
            label: <FormattedMessage id="address.postcode" />,
            children: registration.address?.postcode
        },
        {
            key: 'subject',
            label: <FormattedMessage id="receivable.taxObject" />,
            children: registration.taxObject
        },
        {
            key: 'fiscalYear',
            label: <FormattedMessage id="receivable.fiscalYear" />,
            children: registration.fiscalYear
        },
        {
            key: 'fiscalPeriod',
            label: <FormattedMessage id="receivable.fiscalPeriod" />,
            children: <ConstantLabel value={registration?.fiscalPeriod} prefix="receivable.fiscalPeriod." />
        },
        {
            key: 'created',
            label: <FormattedMessage id="audit.created" />,
            children: registration.audit && (
                <FormattedDate value={registration.audit.created as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            )
        },
        {
            key: 'principal',
            label: <FormattedMessage id="amount.principal" />,
            children: registration.amount?.principal !== undefined && (
                <FormattedNumber
                    value={registration.amount.principal}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },
        {
            key: 'surcharge',
            label: <FormattedMessage id="amount.surcharge" />,
            children: registration.amount?.surcharge !== undefined && (
                <FormattedNumber
                    value={registration.amount.surcharge}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },
        {
            key: 'expired',
            label: <FormattedMessage id="receivable.expired" />,
            children: registration.audit && (
                <FormattedDate value={registration.audit.created as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            )
        },
        {
            key: 'reference',
            label: <FormattedMessage id="receivable.reference" />,
            children: registration.reference
        },
        {
            key: 'documentNumber',
            label: <FormattedMessage id="receivable.receivableRegistrationDocument.identifier" />,
            children: receivableRegistrationDocument?.identifier,
            span: 4
        },
        {
            key: 'tax',
            children: (
                <Tag className="tag">
                    <ConstantLabel value={registration?.tax} prefix="receivable.tax." />
                </Tag>
            ),
            span: 2
        },
        {
            key: 'subtax',
            children: (
                <Tag className="tag">
                    <ConstantLabel value={registration?.subtax} prefix="receivable.subtax." />
                </Tag>
            ),
            span: 2
        }
    ];

    return (
        <Row gutter={[28, 0]}>
            <Col span={24}>
                <Descriptions layout="horizontal" items={items} size="default" column={4} />
            </Col>
        </Row>
    );
};

export default ReceivableRegistrationComponent;

interface Props {
    registration: ReceivableRegistration;
}
