import { Col, Descriptions, DescriptionsProps, Row } from 'antd';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { ReceivableInitialization } from '../../../../models/Entities';

const ReceivableInitializationComponent = (props: Props) => {
    const { initialization } = props;

    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** VISUAL ***/

    const items: DescriptionsProps['items'] = [
        {
            key: 'principal',
            label: <FormattedMessage id="amount.principal" />,
            children: initialization.amount?.principal !== undefined && (
                <FormattedNumber
                    value={initialization.amount.principal}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },
        {
            key: 'surcharge',
            label: (
                <>
                    <FormattedMessage id="amount.surcharge" /> ({initialization.penalty * 100}%)
                </>
            ),
            children: initialization.amount?.surcharge !== undefined && (
                <FormattedNumber
                    value={initialization.amount.surcharge}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },

        {
            key: 'total',
            label: <FormattedMessage id="amount.total" />,
            children: initialization.amount?.total !== undefined && (
                <strong>
                    <FormattedNumber
                        value={initialization.amount.total}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        style={'currency' as any}
                        currency="EUR"
                    />
                </strong>
            )
        }
    ];

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Descriptions layout="horizontal" items={items} size="default" column={4} />
                </Col>
            </Row>
        </>
    );
};

export default ReceivableInitializationComponent;

interface Props {
    initialization: ReceivableInitialization;
}
