/**
 * The council taxpayer component with the archives information.
 * @param props the props
 * @returns the council taxpayer archives component
 */
const CouncilTaxpayerArchivesComponent: React.FC = () => {
    /*** HOOKS ***/

    /*** VISUAL ***/

    return <p>Archivo</p>;
};
export default CouncilTaxpayerArchivesComponent;
