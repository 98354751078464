import { Page } from '../models/Elements';
import { SeizureBankPaymentResponse, SeizureBankPaymentResponseDraft } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class SeizureBankPaymentResponseApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId: number,
        searchText?: string
    ): Promise<Page<SeizureBankPaymentResponse>> => {
        const response = await adminApiAxios.get<Page<SeizureBankPaymentResponse>>('/seizure-bank-payment-responses', {
            params: { page, size, sortField, sortOrder, councilId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<SeizureBankPaymentResponse> => {
        const response = await adminApiAxios.get<SeizureBankPaymentResponse>(`/seizure-bank-payment-responses/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (seizureBankPaymentResponse: SeizureBankPaymentResponse, file: any): Promise<SeizureBankPaymentResponse> => {
        const formData: FormData = this.buildFormData(seizureBankPaymentResponse, file);
        const response = await adminApiAxios.post<SeizureBankPaymentResponse>(`/seizure-bank-payment-responses`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    createDraft = async (seizureBankPaymentResponse: SeizureBankPaymentResponse, file: any): Promise<SeizureBankPaymentResponseDraft> => {
        const formData: FormData = this.buildFormData(seizureBankPaymentResponse, file);
        const response = await adminApiAxios.post<SeizureBankPaymentResponseDraft>(`/seizure-bank-payment-responses/draft`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDraftDates(response.data);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/seizure-bank-payment-responses/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Builds a form data from a seizure bank payment response.
     * @param seizureBankPaymentResponse the seizure bank payment response
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (seizureBankPaymentResponse: SeizureBankPaymentResponse, file?: File) => {
        const formData: FormData = new FormData();
        seizureBankPaymentResponse.id && formData.append('id', seizureBankPaymentResponse.id.toString());
        seizureBankPaymentResponse.councilId && formData.append('councilId', seizureBankPaymentResponse.councilId.toString());
        seizureBankPaymentResponse.bankId && formData.append('bankId', seizureBankPaymentResponse.bankId.toString());
        seizureBankPaymentResponse.ctSeizureBankId && formData.append('ctSeizureBankId', seizureBankPaymentResponse.ctSeizureBankId.toString());
        seizureBankPaymentResponse.paidAmount && formData.append('paidAmount', seizureBankPaymentResponse.paidAmount.toString());
        seizureBankPaymentResponse.paid && formData.append('paid', seizureBankPaymentResponse.paid.format('YYYY-MM-DDThh:mm:ss'));
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a seizure bank payment response.
     * @param seizureBankPaymentResponse - the seizure bank payment response
     */
    private loadDates(seizureBankPaymentResponse: SeizureBankPaymentResponse) {
        seizureBankPaymentResponse.audit!.created = dateService.transformDate(seizureBankPaymentResponse.audit!.created)!;
        seizureBankPaymentResponse.audit!.updated = dateService.transformDate(seizureBankPaymentResponse.audit!.updated)!;
    }

    /**
     * Loads the dates of the seizure bank payment response draft.
     * @param seizureBankPaymentResponseDraft - the seizure bank payment response draft
     */
    private loadDraftDates(seizureBankPaymentResponseDraft: SeizureBankPaymentResponseDraft) {
        const { seizureBankPaymentResponse, ctSeizureBankPaymentResponse, receivables, receivablePayments } = seizureBankPaymentResponseDraft;
        this.loadDates(seizureBankPaymentResponse);
        ctSeizureBankPaymentResponse.audit.created = dateService.transformDate(ctSeizureBankPaymentResponse.audit!.created)!;
        ctSeizureBankPaymentResponse.audit.updated = dateService.transformDate(ctSeizureBankPaymentResponse.audit!.updated)!;
        receivables.forEach((receivable) => {
            receivable.audit!.created = dateService.transformDate(receivable.audit!.created)!;
            receivable.audit!.updated = dateService.transformDate(receivable.audit!.updated)!;
        });
        receivablePayments.forEach((rp) => {
            rp.audit!.created = dateService.transformDate(rp.audit!.created)!;
            rp.audit!.updated = dateService.transformDate(rp.audit!.updated)!;
        });
    }
}

const seizureBankPaymentResponseApi: SeizureBankPaymentResponseApi = new SeizureBankPaymentResponseApi();
export default seizureBankPaymentResponseApi;
