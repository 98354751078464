import { ArrowLeftOutlined, ExportOutlined, ReloadOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, CollapseProps, Descriptions, DescriptionsProps, Row, Space, Spin, Tag, Tooltip } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { Link, useParams } from 'react-router-dom';
import receivableApi from '../../../apis/ReceivableApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import StateTag from '../../../components/StateTag/StateTag';
import CustomCouncilContext from '../../../contexts/CustomCouncilContext';
import CustomCouncilTaxpayerContext from '../../../contexts/CustomCouncilTaxpayerContext';
import { BreadcrumbItem } from '../../../models/Elements';
import {
    Council,
    CouncilTaxpayer,
    Receivable,
    ReceivableCancellation,
    ReceivableFull,
    ReceivableInitialization,
    ReceivableNotificationAbsent,
    ReceivableNotificationBuild,
    ReceivableNotificationReceived,
    ReceivableNotificationSent,
    ReceivableNotificationSign,
    ReceivableNotificationUnknown,
    ReceivableNotificationUnofficial,
    ReceivableNotificationWrongAddress,
    ReceivablePayment,
    ReceivablePaymentOrderAnnouncementPublished,
    ReceivablePaymentOrderAnnouncementSign,
    ReceivablePaymentOrderAnnouncementSigned,
    ReceivableRegistration,
    ReceivableSeizure,
    ReceivableSeizureBank
} from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import receivableService from '../../../services/ReceivableService';
import ReceivableCancellationComponent from './ReceivableCancellationComponent/ReceivableCancellationComponent';
import ReceivableCancellationModal from './ReceivableCancellationModal/ReceivableCancellationModal';
import ReceivableInitializationComponent from './ReceivableInitializationComponent/ReceivableInitializationComponent';
import ReceivableNotificationAbsentComponent from './ReceivableNotificationAbsentComponent/ReceivableNotificationAbsentComponent';
import ReceivableNotificationBuildComponent from './ReceivableNotificationBuildComponent/ReceivableNotificationBuildComponent';
import ReceivableNotificationReceivedComponent from './ReceivableNotificationReceivedComponent/ReceivableNotificationReceivedComponent';
import ReceivableNotificationSentComponent from './ReceivableNotificationSentComponent/ReceivableNotificationSentComponent';
import ReceivableNotificationSignComponent from './ReceivableNotificationSignComponent/ReceivableNotificationSignComponent';
import ReceivableNotificationUnknownComponent from './ReceivableNotificationUnknownComponent/ReceivableNotificationUnknownComponent';
import ReceivableNotificationUnofficialComponent from './ReceivableNotificationUnofficialComponent/ReceivableNotificationUnofficialComponent';
import ReceivableNotificationWrongAddressComponent from './ReceivableNotificationWrongAddressComponent/ReceivableNotificationWrongAddressComponent';
import styles from './ReceivablePage.module.scss';
import ReceivablePaymentComponent from './ReceivablePaymentComponent/ReceivablePaymentComponent';
import ReceivablePaymentOrderAnnouncementPublishedComponent from './ReceivablePaymentOrderAnnouncementPublishedComponent/ReceivablePaymentOrderAnnouncementPublishedComponent';
import ReceivablePaymentOrderAnnouncementSignComponent from './ReceivablePaymentOrderAnnouncementSignComponent/ReceivablePaymentOrderAnnouncementSignComponent';
import ReceivablePaymentOrderAnnouncementSignedComponent from './ReceivablePaymentOrderAnnouncementSignedComponent/ReceivablePaymentOrderAnnouncementSignedComponent';
import ReceivableRegistrationComponent from './ReceivableRegistrationComponent/ReceivableRegistrationComponent';
import ReceivableSeizureBankComponent from './ReceivableSeizureBankComponent/ReceivableSeizureBankComponent';
import ReceivableSeizureComponent from './ReceivableSeizureComponent/ReceivableSeizureComponent';
import ReceivableSeizureIncomeComponent from './ReceivableSeizureIncomeComponent/ReceivableSeizureIncomeComponent';

/**
 * Returns the receivable page.
 * @returns the receivable page.
 */
const ReceivablePage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const params = useParams<ParamsType>();
    const { council } = useContext(CustomCouncilContext);
    const { councilTaxpayer } = useContext(CustomCouncilTaxpayerContext);
    const [loading, setLoading] = useState<'initializing' | 'refreshing'>();
    const [receivableFull, setReceivableFull] = useState<ReceivableFull>();
    const [selectedEventKey, setSelectedEventKey] = useState<string | string[]>();
    const [uuid, setUuid] = useState<string>(); // force recreation of events (subcomponents) on reload

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');
                const receivableFull: ReceivableFull = await receivableApi.getFull(+params.id!);
                receivableFull.events = receivableFull.events.reverse();
                setReceivableFull(receivableFull);

                const uuid = crypto.randomUUID();
                const selectedEventKey = receivableService.getEventKey(receivableFull.events.at(0), uuid);
                setUuid(uuid);
                setSelectedEventKey(selectedEventKey);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, params.id]);

    /*** METHODS ***/

    const refresh = async () => {
        try {
            setLoading('refreshing');
            const receivableFull: ReceivableFull = await receivableApi.getFull(+params.id!);
            receivableFull.events = receivableFull.events.reverse();
            setReceivableFull(receivableFull);

            const uuid = crypto.randomUUID();
            const selectedEventKey = receivableService.getEventKey(receivableFull.events.at(0), uuid);
            setUuid(uuid);
            setSelectedEventKey(selectedEventKey);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const items: CollapseProps['items'] = receivableFull?.events
        .map((event, index) => {
            const readonly: boolean = index !== 0;
            const key = receivableService.getEventKey(event, uuid);
            const eventDate = (
                <span className={styles.date}>
                    <FormattedDate value={event.audit?.created as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
                </span>
            );

            if (event.state === 'REGISTERED') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.registration" />,
                    children: <ReceivableRegistrationComponent registration={event as ReceivableRegistration} />,
                    extra: eventDate
                };
            } else if (event.state === 'INITIALIZED') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.initialization" />,
                    children: <ReceivableInitializationComponent initialization={event as ReceivableInitialization} />,
                    extra: eventDate
                };
            } else if (event.state === 'NOTIFICATION_SIGN') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.notificationSign" />,
                    children: <ReceivableNotificationSignComponent notificationSign={event as ReceivableNotificationSign} />,
                    extra: eventDate
                };
            } else if (event.state === 'NOTIFICATION_BUILD') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.notificationBuild" />,
                    children: (
                        <ReceivableNotificationBuildComponent receivable={receivableFull.receivable} notificationBuild={event as ReceivableNotificationBuild} />
                    ),
                    extra: eventDate
                };
            } else if (event.state === 'NOTIFICATION_UNOFFICIAL') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.notificationUnofficial" />,
                    children: (
                        <ReceivableNotificationUnofficialComponent
                            receivable={receivableFull.receivable}
                            notificationUnofficial={event as ReceivableNotificationUnofficial}
                        />
                    ),
                    extra: eventDate
                };
            } else if (event.state === 'NOTIFICATION_SENT') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.notificationSent" />,
                    children: <ReceivableNotificationSentComponent notificationSent={event as ReceivableNotificationSent} />,
                    extra: eventDate
                };
            } else if (event.state === 'NOTIFICATION_RECEIVED') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.notificationReceived" />,
                    children: <ReceivableNotificationReceivedComponent notificationReceived={event as ReceivableNotificationReceived} />,
                    extra: eventDate
                };
            } else if (event.state === 'NOTIFICATION_ABSENT') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.notificationAbsent" />,
                    children: <ReceivableNotificationAbsentComponent notificationAbsent={event as ReceivableNotificationAbsent} />,
                    extra: eventDate
                };
            } else if (event.state === 'NOTIFICATION_UNKNOWN') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.notificationUnknown" />,
                    children: (
                        <ReceivableNotificationUnknownComponent
                            receivable={receivableFull.receivable}
                            notificationUnknown={event as ReceivableNotificationUnknown}
                        />
                    ),
                    extra: eventDate
                };
            } else if (event.state === 'NOTIFICATION_WRONG_ADDRESS') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.notificationWrongAddress" />,
                    children: (
                        <ReceivableNotificationWrongAddressComponent
                            receivable={receivableFull.receivable}
                            notificationWrongAddress={event as ReceivableNotificationWrongAddress}
                        />
                    ),
                    extra: eventDate
                };
            } else if (event.state === 'PAYMENT_ORDER_ANNOUNCEMENT_SIGN') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.paymentOrderAnnouncementSign" />,
                    children: (
                        <ReceivablePaymentOrderAnnouncementSignComponent paymentOrderAnnouncementSign={event as ReceivablePaymentOrderAnnouncementSign} />
                    ),
                    extra: eventDate
                };
            } else if (event.state === 'PAYMENT_ORDER_ANNOUNCEMENT_SIGNED') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.paymentOrderAnnouncementSigned" />,
                    children: (
                        <ReceivablePaymentOrderAnnouncementSignedComponent paymentOrderAnnouncementSigned={event as ReceivablePaymentOrderAnnouncementSigned} />
                    ),
                    extra: eventDate
                };
            } else if (event.state === 'PAYMENT_ORDER_ANNOUNCEMENT_PUBLISHED') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.paymentOrderAnnouncementPublished" />,
                    children: (
                        <ReceivablePaymentOrderAnnouncementPublishedComponent
                            paymentOrderAnnouncementPublished={event as ReceivablePaymentOrderAnnouncementPublished}
                        />
                    ),
                    extra: eventDate
                };
            } else if (event.state === 'SEIZURE') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.seizure" />,
                    children: (
                        <ReceivableSeizureComponent
                            receivable={receivableFull.receivable}
                            seizure={event as ReceivableSeizure}
                            onUpdate={refresh}
                            readonly={readonly}
                        />
                    ),
                    extra: eventDate
                };
            } else if (event.state === 'SEIZURE_INCOME') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.seizure.income" />,
                    children: <ReceivableSeizureIncomeComponent receivable={receivableFull.receivable} onUpdate={refresh} readonly={readonly} />,
                    extra: eventDate
                };
            } else if (event.state === 'SEIZURE_BANK') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.seizure.bank" />,
                    children: (
                        <ReceivableSeizureBankComponent
                            receivable={receivableFull.receivable}
                            seizureBank={event as ReceivableSeizureBank}
                            onUpdate={refresh}
                            readonly={readonly}
                        />
                    ),
                    extra: eventDate
                };
            } else if (event.state === 'CANCELLED') {
                return {
                    key,
                    label: <FormattedMessage id="receivable.cancellation" />,
                    children: <ReceivableCancellationComponent cancellation={event as ReceivableCancellation} />,
                    extra: eventDate
                };
            } else if (event.state === 'PAID') {
                const receivablePayment: ReceivablePayment = event as ReceivablePayment;
                return {
                    key,
                    label: (
                        <Space>
                            <FormattedMessage id="receivable.paid.title" />
                            {receivablePayment.partial && (
                                <Tag>
                                    <ConstantLabel prefix="receivable.payment.partial." value={receivablePayment.partial.toString()} />
                                </Tag>
                            )}
                            {!receivablePayment.partial && (
                                <Tag color="green">
                                    <ConstantLabel prefix="receivable.payment.partial." value={receivablePayment.partial.toString()} />
                                </Tag>
                            )}
                        </Space>
                    ),
                    children: <ReceivablePaymentComponent receivable={receivableFull.receivable} payment={receivablePayment} />,
                    extra: eventDate
                };
            } else {
                return undefined;
            }
        })
        .filter((p) => p !== undefined)
        .map((p) => p as any);

    return (
        <LayoutComponent
            title={<FormattedMessage id="receivable.title" />}
            menu={council.id ? 'councils' : 'taxpayers'}
            path={PathComponent(council, councilTaxpayer, receivableFull?.receivable)}
            council={council}
        >
            <SummaryComponent receivable={receivableFull?.receivable} loading={loading} onRefresh={refresh} />
            {items?.length === 0 && loading === 'initializing' && <LoaderComponent />}
            {items?.length && items?.length > 0 && (
                <>
                    <h3>
                        <FormattedMessage id="receivable.events" />
                    </h3>
                    <Collapse items={items} activeKey={selectedEventKey} onChange={setSelectedEventKey} size="middle" />
                </>
            )}
        </LayoutComponent>
    );
};
export default ReceivablePage;
type ParamsType = { id: string };

/**
 * The loader component.
 * @returns the loader component
 */
const LoaderComponent: React.FC = () => {
    return (
        <div className={styles.loader}>
            <Spin size="large" />
        </div>
    );
};

/**
 * The summary component with the receivable information.
 * @param props the props
 * @returns the summary component
 */
const SummaryComponent: React.FC<SummaryProps> = (props) => {
    const { receivable, loading, onRefresh } = props;

    /*** HOOKS ***/

    const [desktop] = useResponsiveLayout();
    const [cancellationModalVisible, setCancellationModalVisible] = useState<boolean>(false);

    /*** METHODS ***/

    const refresh = () => {
        setCancellationModalVisible(false);
        props.onRefresh();
    };

    /*** VISUAL ***/

    const items: DescriptionsProps['items'] = [
        {
            key: 'firstName',
            label: <FormattedMessage id="taxpayer.firstName" />,
            children: receivable && (
                <>
                    <strong>{receivable?.councilTaxpayer?.taxpayer.fullName}</strong>
                    <Link to={`/councils/${receivable.councilId}/taxpayers/${receivable.councilTaxpayer?.id}`}>
                        <Tooltip title={<FormattedMessage id="council.taxpayers.tooltip" />} className={styles.icon}>
                            <ExportOutlined />
                        </Tooltip>
                    </Link>
                </>
            ),
            span: 2
        },
        {
            key: 'id',
            label: <FormattedMessage id="councilTaxpayer.id" />,
            children: <strong>{receivable?.councilTaxpayer?.id}</strong>
        },
        {
            key: 'identifier',
            label: <FormattedMessage id="taxpayer.identifier" />,
            children: <strong>{receivable?.councilTaxpayer?.taxpayer.identifier}</strong>
        },
        {
            key: 'created',
            label: <FormattedMessage id="audit.created" />,
            children: receivable && receivable.audit && (
                <strong>
                    <FormattedDate value={receivable.audit.created as any} day="2-digit" month="2-digit" year="numeric" />
                </strong>
            ),
            span: 1
        },
        {
            key: 'principal',
            label: <FormattedMessage id="amount.principal" />,
            children: receivable && receivable.amount?.principal !== undefined && (
                <strong>
                    <FormattedNumber
                        value={receivable.amount.principal}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        style={'currency' as any}
                        currency="EUR"
                    />
                </strong>
            )
        },
        {
            key: 'surcharge',
            label: <FormattedMessage id="amount.surcharge" />,
            children: receivable && receivable.amount?.surcharge !== undefined && (
                <strong>
                    <FormattedNumber
                        value={receivable.amount?.surcharge}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        style={'currency' as any}
                        currency="EUR"
                    />
                </strong>
            )
        },
        {
            key: 'interest',
            label: <FormattedMessage id="amount.interest" />,
            children: receivable && receivable.amount?.interest !== undefined && (
                <strong>
                    <FormattedNumber
                        value={receivable.amount?.interest}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        style={'currency' as any}
                        currency="EUR"
                    />
                </strong>
            )
        },
        {
            key: 'total',
            label: <FormattedMessage id="amount.total" />,
            children: receivable && receivable.amount?.total !== undefined && (
                <strong>
                    <FormattedNumber
                        value={receivable.amount?.total}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        style={'currency' as any}
                        currency="EUR"
                    />
                </strong>
            )
        },
        {
            key: 'reference',
            label: <FormattedMessage id="receivable.reference" />,
            children: receivable && <strong>{receivable.reference}</strong>
        },

        {
            key: 'tax',
            label: <FormattedMessage id="receivable.tax" />,
            span: 2,
            children: (
                <Tag className="tag">
                    <ConstantLabel value={receivable?.tax} prefix="receivable.tax." />
                </Tag>
            )
        },
        {
            key: 'phase',
            label: <FormattedMessage id="receivable.phase" />,
            children: (
                <Tag color="blue" className="tag">
                    <ConstantLabel value={receivable?.phase} prefix="receivable.phase." />
                </Tag>
            )
        },
        {
            key: 'state',
            label: <FormattedMessage id="receivable.state" />,
            children: <StateTag value={receivable?.state} />,
            span: 2
        }
    ];

    return (
        <>
            {cancellationModalVisible && (
                <ReceivableCancellationModal receivable={receivable!} onCancel={() => setCancellationModalVisible(false)} onSave={refresh} />
            )}
            <Descriptions
                title={
                    <Row>
                        <Col>
                            <h3 className={styles.title}>
                                <FormattedMessage id="receivable.title" />
                            </h3>
                        </Col>
                        <Col flex="auto"></Col>
                        <Col>
                            <Space size="middle">
                                <Link to={`/councils/${receivable?.councilId}/receivables`}>
                                    <Button icon={<ArrowLeftOutlined />}>{desktop && <FormattedMessage id="button.back" tagName="span" />}</Button>
                                </Link>
                                <Button icon={<UndoOutlined />} onClick={() => setCancellationModalVisible(true)}>
                                    <FormattedMessage id="receivable.cancellation.button" tagName="span" />
                                </Button>
                                <Button icon={<ReloadOutlined />} onClick={onRefresh} loading={loading === 'refreshing'} />
                            </Space>
                        </Col>
                    </Row>
                }
                layout="horizontal"
                items={items}
                size="middle"
                column={5}
                className={styles.summary}
                bordered
            />
        </>
    );
};
interface SummaryProps {
    receivable: Receivable | undefined;
    loading: 'initializing' | 'refreshing' | undefined;
    onRefresh: () => Promise<void>;
}

/**
 * Returns the path of the receivables page.
 * @param council the council
 * @param taxpayer the taxpayer
 * @returns  the path
 */
const PathComponent = (council: Council, councilTaxpayer?: CouncilTaxpayer, receivable?: Receivable): BreadcrumbItem[] => {
    let path: BreadcrumbItem[] = [];
    if (council.id && councilTaxpayer) {
        path = [
            { path: '/councils', name: <FormattedMessage id="councils.title" /> },
            { path: `/councils/${council.id}`, name: council.name },
            { path: `/councils/${council.id}/taxpayers`, name: <FormattedMessage id="taxpayers.title" /> },
            {
                path: `/councils/${council.id}/taxpayers/${councilTaxpayer.id}`,
                name: `${councilTaxpayer.taxpayer?.fullName}`
            },
            { path: `/councils/${council.id}/taxpayers/${councilTaxpayer.id}`, name: <FormattedMessage id="receivables.title" /> },
            {
                path: `/councils/${council.id}/taxpayers/${councilTaxpayer.id}/receivables/${receivable?.id}`,
                name: <ConstantLabel value={receivable?.tax} prefix="receivable.tax." />
            }
        ];
    } else {
        path = [
            { path: '/councils', name: <FormattedMessage id="councils.title" /> },
            { path: `/councils/${council.id}`, name: council.name },
            { path: `/councils/${council.id}/receivables`, name: <FormattedMessage id="receivables.title" /> },
            {
                path: `/councils/${council.id}/receivables/${receivable?.id}`,
                name: receivable && receivable.id && `${receivable?.councilTaxpayer?.taxpayer.fullName}`
            }
        ];
    }

    return path;
};
