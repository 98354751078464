import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Space, Tabs, TabsProps, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import userApi from '../../apis/UserApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import { User } from '../../models/Entities';
import alertService from '../../services/AlertService';
import rolesService from '../../services/RolesService';
import CustomContext from '../../contexts/CustomContext';

/***Returns the settings page.
 * @returns the settings page.
 */
const SettingsPage: React.FC = () => {
    /***HOOKS***/
    /***METHODS***/
    /***VISUAL***/

    const items: TabsProps['items'] = [
        {
            key: 'account',
            label: <FormattedMessage id="settings.account" />,
            children: <AccountComponent />,
            forceRender: true
        },
        {
            key: 'business',
            label: <FormattedMessage id="settings.business" />,
            children: <></>,
            forceRender: true,
            disabled: true
        },
        {
            key: 'paymentDetails',
            label: <FormattedMessage id="settings.paymentDetails" />,
            children: <></>,
            forceRender: true,
            disabled: true
        }
    ];
    return (
        <LayoutComponent
            title={<FormattedMessage id="settings.title" />}
            menu="settings"
            path={[{ path: '/settings', name: <FormattedMessage id="settings.title" /> }]}
        >
            <Tabs defaultActiveKey="account" items={items} />
        </LayoutComponent>
    );
};
export default SettingsPage;

/***Returns the accounts component.
 * @returns the accounts component.
 */

const AccountComponent: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const { auth } = useContext(CustomContext);
    const [loading, setLoading] = useState<'initializing' | 'loading'>();
    const [user, setUser] = useState<User>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('initializing');

                const user = await userApi.getCurrent();
                form.setFieldsValue(user);
                setUser(user);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [form, intl]);

    /*** METHODS ***/
    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedUser: User = Object.assign({}, user, values);
            updatedUser = await userApi.update(updatedUser);
            setUser(updatedUser);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** COMPONENTS */

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);

    return (
        <Form form={form} onFinish={save} colon={false} layout="vertical">
            <Row gutter={[28, 0]}>
                <Col lg={8}>
                    <Form.Item
                        label={<FormattedMessage id="account.firstName" />}
                        name="firstName"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input size="large" disabled={!isAdmin} />
                    </Form.Item>
                </Col>
                <Col lg={8}>
                    <Form.Item
                        label={<FormattedMessage id="account.lastName" />}
                        name="lastName"
                        rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                    >
                        <Input size="large" disabled={!isAdmin} />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={[28, 0]}>
                <Col lg={8}>
                    <Form.Item
                        label={<FormattedMessage id="account.email" />}
                        name="email"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                type: 'email',
                                message: <FormattedMessage id="status.email.invalid" />
                            }
                        ]}
                    >
                        <Input size="large" disabled={!!user?.id} />
                    </Form.Item>
                </Col>
            </Row>

            {isAdmin && (
                <Row gutter={[28, 0]} className="buttons">
                    <Col span={24}>
                        <Space>
                            <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                <FormattedMessage id="button.save" tagName="span" />
                            </Button>
                        </Space>
                    </Col>
                </Row>
            )}
        </Form>
    );
};
