import { Button, Col, Form, Modal, Radio, Row, Space, message } from 'antd';
import { List } from 'antd/lib';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import seizureIncomeApi from '../../../apis/SeizureIncomeApi';
import { SeizureIncome, SeizureIncomeDraft } from '../../../models/Entities';
import { seizureIncomeTypes } from '../../../models/Types';
import alertService from '../../../services/AlertService';
import styles from './SeizureIncomeRequestModal.module.scss';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';

const SeizureIncomeRequestModal: React.FC<Props> = (props) => {
    const { councilId } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'loading' | 'saving' | 'downloading'>();
    const [seizureIncomeDraft, setSeizureIncomeDraft] = useState<SeizureIncomeDraft>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                const seizureIncome: SeizureIncome = {
                    councilId,
                    income: 'SALARY'
                };
                form.setFieldsValue(seizureIncome);

                const seizureIncomeDraft = await seizureIncomeApi.createDraft(seizureIncome);
                setSeizureIncomeDraft(seizureIncomeDraft);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, form, intl]);

    /*** METHODS ***/

    const createDraft = async () => {
        try {
            setLoading('loading');

            const values = await form.validateFields();
            const seizureIncome: SeizureIncome = {
                councilId,
                income: values.income
            };

            const seizureIncomeDraft = await seizureIncomeApi.createDraft(seizureIncome);
            setSeizureIncomeDraft(seizureIncomeDraft);
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const save = async (values: any) => {
        try {
            setLoading('saving');

            const seizureIncome: SeizureIncome = {
                councilId,
                income: values.income
            };
            await seizureIncomeApi.create(seizureIncome);
            await props.onSave();

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error: any) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const downloadDraft = async () => {
        try {
            setLoading('downloading');

            const values = await form.validateFields();
            const seizureIncome: SeizureIncome = {
                councilId,
                income: values.income
            };
            const document = await seizureIncomeApi.downloadDraft(seizureIncome);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'seizureIncome.file.name' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = async () => {
        props.onCancel();
    };

    /*** VISUAL ***/

    const ctSeizureIncomeRequests = seizureIncomeDraft && seizureIncomeDraft.ctSeizureIncomeRequests ? seizureIncomeDraft.ctSeizureIncomeRequests : [];
    const seizureIncomeTypeOptions = seizureIncomeTypes.map((seizureIncomeType) => (
        <Radio key={seizureIncomeType} value={seizureIncomeType}>
            <ConstantLabel value={seizureIncomeType} prefix="seizureIncome.income." />
        </Radio>
    ));

    return (
        <Modal
            title={<FormattedMessage id="seizureIncomeRequestModal.title" />}
            open={true}
            onCancel={cancel}
            okText={<FormattedMessage id="button.save" tagName="span" />}
            okButtonProps={{ loading: loading === 'saving', size: 'large', htmlType: 'submit' }}
            cancelButtonProps={{ disabled: loading === 'saving', size: 'large' }}
            width={1000}
            destroyOnClose
            footer={[
                <Button key="cancel" size="large" onClick={cancel} disabled={loading === 'saving'}>
                    <FormattedMessage id="button.cancel" tagName="span" />
                </Button>,
                <Button key="preview" size="large" onClick={downloadDraft} loading={loading === 'downloading'} disabled={ctSeizureIncomeRequests.length === 0}>
                    <FormattedMessage id="button.preview" tagName="span" />
                </Button>,
                <Button
                    key="ok"
                    htmlType="submit"
                    size="large"
                    type="primary"
                    loading={loading === 'saving'}
                    disabled={ctSeizureIncomeRequests.length === 0 || loading === 'saving'}
                >
                    <FormattedMessage id="button.save" tagName="span" />
                </Button>
            ]}
            modalRender={(dom) => (
                <Form form={form} colon={false} layout="vertical" onFinish={save}>
                    {dom}
                </Form>
            )}
        >
            <Row gutter={[28, 0]} className={styles.container}>
                <Col span={24} className={styles.header}>
                    <FormattedMessage id="seizureIncomeRequestModal.desc" />
                </Col>
                <Col span={24}>
                    <Form.Item name="income" label={<FormattedMessage id="seizureIncome.income" />}>
                        <Radio.Group onChange={createDraft}>{seizureIncomeTypeOptions}</Radio.Group>
                    </Form.Item>
                </Col>

                <Col span={24}>
                    <List
                        itemLayout="horizontal"
                        className={styles.list}
                        size="small"
                        dataSource={ctSeizureIncomeRequests}
                        loading={loading === 'loading'}
                        renderItem={(ctsir) => (
                            <List.Item>
                                <List.Item.Meta
                                    title={ctsir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.fullName}
                                    description={
                                        <Space split="|">
                                            <FormattedMessage
                                                id="seizureIncomeRequestModal.taxpayer.id"
                                                values={{
                                                    id: ctsir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.id
                                                }}
                                            />
                                            <FormattedMessage
                                                id="seizureIncomeRequestModal.taxpayer.identifier"
                                                values={{
                                                    identifier: ctsir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.identifier
                                                }}
                                            />
                                        </Space>
                                    }
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    );
};
export default SeizureIncomeRequestModal;

interface Props {
    councilId: number;
    onSave: () => Promise<void>;
    onCancel: () => void;
}
