import { ArrowLeftOutlined, DownloadOutlined, FileTextOutlined, FormOutlined, CheckOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tag, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import FileSaver from 'file-saver';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import seizureIncomeApi from '../../apis/SeizureIncomeApi';
import ConstantLabel from '../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomContext from '../../contexts/CustomContext';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { Page } from '../../models/Elements';
import { CustomAuth, SeizureIncome } from '../../models/Entities';
import alertService from '../../services/AlertService';
import tableService from '../../services/TableService';
import styles from './SeizureIncomesPage.module.scss';
import rolesService from '../../services/RolesService';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import SeizureIncomeRequestModal from './SeizureIncomeRequestModal/SeizureIncomeRequestModal';
import SeizureIncomeUpdateModal from './SeizureIncomeUpdateModal/SeizureIncomeUpdateModal';

/**
 * Returns the seizure incomes page.
 * @returns the seizure incomes page.
 */

const SeizureIncomesPage: React.FC = () => {
    /***HOOKS */

    const intl = useIntl();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const { council } = useContext(CustomCouncilContext);
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const [filter, setFilter] = useState<Filter>({});
    const [seizureIncomePage, setSeizureIncomePage] = useState<Page<SeizureIncome>>();
    const [seizureIncomeRequestModalVisible, setSeizureIncomeRequestModalVisible] = useState<boolean>(false);
    const [seizureIncomeUpdateModalVisible, setSeizureIncomeUpdateModalVisible] = useState<boolean>(false);
    const [seizureIncome, setSeizureIncome] = useState<SeizureIncome>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('auditCreated');
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [loading, setLoading] = useState<'loading' | 'downloading'>();

    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const seizureIncomePage = await seizureIncomeApi.list(page, 100, sortField, sortOrder, council.id, filter.searchText);
                    setSeizureIncomePage(seizureIncomePage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterSeizureIncomes = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    const download = async (seizureIncome: SeizureIncome) => {
        try {
            setLoading('downloading');
            const document = await seizureIncomeApi.download(seizureIncome.id!);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'seizureIncome.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const downloadResponse = async (seizureIncome: SeizureIncome) => {
        try {
            if (seizureIncome.responseFile) {
                setLoading('downloading');
                const document = await seizureIncomeApi.downloadResponse(seizureIncome.id!);
                FileSaver.saveAs(document, seizureIncome.responseFile.name);
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const showSeizureIncomeRequestModal = async () => {
        setSeizureIncomeRequestModalVisible(true);
    };

    const showSeizureIncomeUpdateModal = async (seizureIncome: SeizureIncome) => {
        setSeizureIncome(seizureIncome);
        setSeizureIncomeUpdateModalVisible(true);
    };

    const cancel = () => {
        setSeizureIncomeUpdateModalVisible(false);
    };

    const save = async () => {
        try {
            setLoading('loading');
            setSeizureIncomeRequestModalVisible(false);
            setSeizureIncomeUpdateModalVisible(false);
            const seizureIncomePage = await seizureIncomeApi.list(page, 100, sortField, sortOrder, council.id, filter.searchText);
            setSeizureIncomePage(seizureIncomePage);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);
    const isCouncilUser = rolesService.hasAnyRole(auth, ['ROLE_COUNCIL_USER']);

    const items = seizureIncomePage ? seizureIncomePage.content : [];
    const columns: ColumnsType<SeizureIncome> = [
        {
            title: <FormattedMessage id="seizureIncome.id" />,
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 40,
            sorter: true,
            defaultSortOrder: 'descend',
            render: (value: string, seizureIncome: SeizureIncome) => value
        },
        {
            title: <FormattedMessage id="seizureIncome.income" />,
            dataIndex: 'income',
            key: 'income',
            align: 'center',
            width: 160,
            defaultSortOrder: 'descend',
            render: (value: string, seizureIncome: SeizureIncome) => (
                <>
                    {value === 'SALARY' && (
                        <Tag color="blue">
                            <ConstantLabel value={seizureIncome.income} prefix="seizureIncome.income." />
                        </Tag>
                    )}
                    {value === 'PENSION' && (
                        <Tag>
                            <ConstantLabel value={seizureIncome.income} prefix="seizureIncome.income." />
                        </Tag>
                    )}
                </>
            )
        },
        {
            title: <FormattedMessage id="seizureIncome.created" />,
            dataIndex: ['audit', 'created'],
            key: 'auditCreated',
            align: 'center',
            width: 160,
            render: (value: any) => <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
        },
        {
            title: <FormattedMessage id="seizureIncome.state" />,
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: 160,
            render: (value: any, seizureIncome: SeizureIncome) => (
                <>
                    {value === 'SENT' && (
                        <Tag color="green">
                            <ConstantLabel value={seizureIncome.state} prefix="seizureIncome.state." />
                        </Tag>
                    )}
                    {value === 'FAILED' && (
                        <Tag color="red">
                            <ConstantLabel value={seizureIncome.state} prefix="seizureIncome.state." />
                        </Tag>
                    )}
                </>
            )
        },
        {
            title: <FormattedMessage id="seizureIncome.sent" />,
            dataIndex: 'sent',
            key: 'sent',
            align: 'center',
            width: 180,
            render: (value: any, seizureIncome: SeizureIncome) =>
                value && <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
        },
        {
            title: <FormattedMessage id="seizureIncome.responseFile" />,
            dataIndex: 'responseFile',
            key: 'responseFile',
            align: 'center',
            width: 100,
            render: (value: File, seizureIncome: SeizureIncome) => value && <CheckOutlined />
        },
        {
            key: 'actions',
            width: 120,
            align: 'center',
            render: (seizureIncome: SeizureIncome) => (
                <Space>
                    <Tooltip title={<FormattedMessage id="seizureIncome.download.request" />}>
                        <Button icon={<FileTextOutlined />} className={styles.icon} onClick={() => download(seizureIncome)} type="link" />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="seizureIncome.download.response" />}>
                        <Button
                            icon={<DownloadOutlined />}
                            className={styles.icon}
                            onClick={() => downloadResponse(seizureIncome)}
                            type="link"
                            disabled={!seizureIncome.responseFile}
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="seizureIncome.update" />}>
                        <Button
                            icon={<FormOutlined />}
                            className={styles.icon}
                            onClick={() => showSeizureIncomeUpdateModal(seizureIncome)}
                            disabled={!isCouncilUser || !!seizureIncome.responseFile}
                            type="link"
                        />
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="users.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/seizure-incomes`, name: <FormattedMessage id="seizureIncomes.title" /> }
            ]}
            council={council}
        >
            <Form form={form} onFinish={filterSeizureIncomes} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'seizureIncomes.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6} className={styles.buttons}>
                        <Space>
                            <Link to={`/councils/${council?.id}`}>
                                <Button size="large" icon={<ArrowLeftOutlined />} />
                            </Link>
                            {isAdmin && (
                                <Tooltip title={<FormattedMessage id="seizureIncomes.new.tooltip" />}>
                                    <Button type="primary" size="large" icon={<PlusOutlined />} onClick={showSeizureIncomeRequestModal}>
                                        {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                    </Button>
                                </Tooltip>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(seizureIncomePage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
            {seizureIncomeRequestModalVisible && council.id && (
                <SeizureIncomeRequestModal councilId={council.id} onCancel={() => setSeizureIncomeRequestModalVisible(false)} onSave={save} />
            )}
            {seizureIncome && seizureIncome.id && seizureIncomeUpdateModalVisible && (
                <SeizureIncomeUpdateModal seizureIncomeId={seizureIncome.id} onSave={save} onCancel={cancel} />
            )}
        </LayoutComponent>
    );
};
export default SeizureIncomesPage;

interface Filter {
    searchText?: string | undefined;
}
