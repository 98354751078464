import { Page } from '../models/Elements';
import { NationalParameter } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class NationalParameterApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, searchText?: string): Promise<Page<NationalParameter>> => {
        const response = await adminApiAxios.get<Page<NationalParameter>>('/national-parameters', {
            params: { page, size, sortField, sortOrder, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((user) => this.loadDates(user));

        return response.data;
    };

    get = async (nationalParameterId: number): Promise<NationalParameter> => {
        const response = await adminApiAxios.get<NationalParameter>(`/national-parameters/${nationalParameterId}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (nationalParameter: NationalParameter): Promise<NationalParameter> => {
        const response = await adminApiAxios.post<NationalParameter>(`/national-parameters`, nationalParameter);
        this.loadDates(response.data);

        return response.data;
    };

    /**
     * Loads the dates of an national parameter.
     * @param nationalParameter - the national parameter
     */
    private loadDates(nationalParameter: NationalParameter) {
        nationalParameter.audit!.created = dateService.transformDate(nationalParameter.audit!.created)!;
        nationalParameter.audit!.updated = dateService.transformDate(nationalParameter.audit!.updated)!;
    }
}

const nationalParameterApi: NationalParameterApi = new NationalParameterApi();
export default nationalParameterApi;
