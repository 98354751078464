import { CheckOutlined, UndoOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, DescriptionsProps, Row, Space, Tooltip } from 'antd';
import { useContext, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import CustomContext from '../../../../contexts/CustomContext';
import { Receivable, ReceivableSeizure } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import rolesService from '../../../../services/RolesService';
import ReceivableCancellationModal from '../ReceivableCancellationModal/ReceivableCancellationModal';
import ReceivablePaymentModal from '../ReceivablePaymentModal/ReceivablePaymentModal';
import styles from './ReceivableSeizureComponent.module.scss';

const ReceivableSeizureComponent = (props: Props) => {
    const { receivable, seizure, readonly, onUpdate } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const { auth } = useContext(CustomContext);
    const [paymentModalVisible, setPaymentModalVisible] = useState<boolean>(false);
    const [cancellationModalVisible, setCancellationModalVisible] = useState<boolean>(false);

    /*** EFFECTS ***/

    /*** METHODS ***/

    const save = async () => {
        try {
            setCancellationModalVisible(false);
            await onUpdate();
        } catch (error) {
            alertService.displayError(error, intl);
        }
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);
    const items: DescriptionsProps['items'] = [
        {
            key: 'principal',
            label: <FormattedMessage id="receivable.amount.principal" />,
            children: seizure?.amount !== undefined && (
                <FormattedNumber
                    value={seizure.amount.principal!}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },
        {
            key: 'surcharge',
            label: (
                <>
                    <FormattedMessage id="amount.surcharge" /> ({seizure.penalty * 100}%)
                </>
            ),
            children: seizure.amount?.surcharge !== undefined && (
                <FormattedNumber
                    value={seizure.amount.surcharge}
                    minimumFractionDigits={2}
                    maximumFractionDigits={2}
                    style={'currency' as any}
                    currency="EUR"
                />
            )
        },
        {
            key: 'interest',
            label: <FormattedMessage id="receivable.amount.interest" />,
            children: seizure.amount?.interest !== undefined && (
                <FormattedNumber value={seizure.amount.interest} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            key: 'total',
            label: <FormattedMessage id="receivable.amount.total" />,
            children: seizure.amount?.total !== undefined && (
                <strong>
                    <FormattedNumber
                        value={seizure?.amount?.total}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        style={'currency' as any}
                        currency="EUR"
                    />
                </strong>
            )
        }
    ];

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Descriptions layout="horizontal" items={items} size="default" column={4} />
                </Col>
            </Row>

            <Row gutter={[28, 0]} className={styles.buttons}>
                <Col span={24}>
                    <Space>
                        {isAdmin && !readonly && (
                            <Tooltip title={<FormattedMessage id="receivable.paid.tooltip" />} mouseLeaveDelay={0}>
                                <Button type="primary" danger icon={<CheckOutlined />} onClick={() => setPaymentModalVisible(true)}>
                                    <FormattedMessage id="receivable.paid" tagName="span" />
                                </Button>
                            </Tooltip>
                        )}

                        {isAdmin && !readonly && (
                            <Button icon={<UndoOutlined />} onClick={() => setCancellationModalVisible(true)}>
                                <FormattedMessage id="receivable.cancellation.button" tagName="span" />
                            </Button>
                        )}
                    </Space>
                </Col>
            </Row>

            {paymentModalVisible && <ReceivablePaymentModal receivable={receivable} onCancel={() => setPaymentModalVisible(false)} onSave={save} />}
            {cancellationModalVisible && (
                <ReceivableCancellationModal receivable={receivable} onCancel={() => setCancellationModalVisible(false)} onSave={save} />
            )}
        </>
    );
};

export default ReceivableSeizureComponent;

interface Props {
    receivable: Receivable;
    seizure: ReceivableSeizure;
    onUpdate: () => Promise<void>;
    readonly?: boolean;
}
