import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, List, Modal, Row, message } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import paymentOrderApi from '../../apis/PaymentOrderApi';
import { PaymentOrder, PaymentOrderCreation, PaymentOrderDraft } from '../../models/Entities';
import alertService from '../../services/AlertService';
import styles from './SeizureIncomeOrderResendModal.module.scss';

const SeizureIncomeOrderResendModal: React.FC<Props> = (props) => {
    const { councilId, councilTaxpayerIds, clone } = props;

    //TODO

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading' | 'sending'>();
    const [paymentOrderDraft, setPaymentOrderDraft] = useState<PaymentOrderDraft>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const paymentOrderCreation: PaymentOrderCreation = {
                    councilId,
                    councilTaxpayerIds: councilTaxpayerIds || [],
                    clone
                };

                const paymentOrderDraft = await paymentOrderApi.createDraft(paymentOrderCreation);
                setPaymentOrderDraft(paymentOrderDraft);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [clone, councilId, councilTaxpayerIds, intl]);

    /*** METHODS ***/

    const send = async (paymentOrders: PaymentOrder[]) => {
        try {
            setLoading('sending');
            const updatedPaymentOrderCreation: PaymentOrderCreation = {
                councilId,
                councilTaxpayerIds: paymentOrders.map((po) => po.councilTaxpayer.id),
                clone
            };

            await paymentOrderApi.create(updatedPaymentOrderCreation);
            await props.onSave();
            message.success(intl.formatMessage({ id: 'status.sent' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = async () => {
        props.onCancel();
    };

    /*** VISUAL ***/

    const paymentOrders = paymentOrderDraft ? paymentOrderDraft.paymentOrders : [];

    return (
        <Modal
            title={<FormattedMessage id="paymentOrderModal.title" />}
            open={true}
            onCancel={cancel}
            onOk={() => send(paymentOrders)}
            okText={<FormattedMessage id="button.send" tagName="span" />}
            okButtonProps={{
                loading: loading === 'sending',
                size: 'large',
                disabled: !paymentOrderDraft || paymentOrderDraft.paymentOrders.length === 0
            }}
            cancelButtonProps={{ size: 'large' }}
            width={800}
        >
            <Row gutter={[0, 0]} className={styles.container}>
                {!clone && (
                    <Col span={24}>
                        <FormattedMessage id="paymentOrderModal.description" />
                    </Col>
                )}
                {clone && (
                    <Col span={24}>
                        <FormattedMessage id="paymentOrderModal.description.clone" />
                        <p className={styles.alert}>
                            <InfoCircleOutlined /> <FormattedMessage id="paymentOrderModal.description.clone.alert" />
                        </p>
                    </Col>
                )}
                <Col span={24}>
                    <List className={styles.list} size="small" itemLayout="horizontal" dataSource={paymentOrders} loading={loading === 'loading'} />
                </Col>
            </Row>
        </Modal>
    );
};
export default SeizureIncomeOrderResendModal;

interface Props {
    councilId: number;
    councilTaxpayerIds?: number[];
    clone: boolean;
    onSave: () => Promise<void>;
    onCancel: () => void;
}
