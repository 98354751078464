import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import councilCollectionAccountApi from '../../apis/CouncilCollectionAccountApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import StatusTag from '../../components/StatusTag/StatusTag';
import CustomContext from '../../contexts/CustomContext';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { Page } from '../../models/Elements';
import { CouncilCollectionAccount } from '../../models/Entities';
import { StatusType } from '../../models/Types';
import alertService from '../../services/AlertService';
import rolesService from '../../services/RolesService';
import tableService from '../../services/TableService';
import styles from './CouncilCollectionAccountsPage.module.scss';

/**
 * Returns the council accounts page.
 * @returns the council accounts page.
 */

const CouncilCollectionAccountsPage: React.FC = () => {
    /***HOOKS */

    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const { auth } = useContext(CustomContext);
    const { council } = useContext(CustomCouncilContext);
    const [filter, setFilter] = useState<Filter>({});
    const [councilCollectionAccountsPage, setCouncilCollectionAccountsPage] = useState<Page<CouncilCollectionAccount>>();
    const [page, setPage] = useState<number>(0);
    const [status, setStatus] = useState<StatusType | undefined>('ENABLED');
    const [sortField, setSortField] = useState<string>('iban');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [loading, setLoading] = useState<'loading'>();

    // list council accounts
    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const councilCollectionAccountsPage = await councilCollectionAccountApi.list(
                        page,
                        100,
                        sortField,
                        sortOrder,
                        council.id,
                        status,
                        filter.searchText
                    );
                    setCouncilCollectionAccountsPage(councilCollectionAccountsPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter, council, status]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterCouncilCollectionAccounts = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
        const includeDisabled = form.getFieldValue('includeDisabled');
        includeDisabled ? setStatus(undefined) : setStatus('ENABLED');
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);

    const items = councilCollectionAccountsPage ? councilCollectionAccountsPage.content : [];
    const columns: ColumnsType<CouncilCollectionAccount> = [
        {
            title: <FormattedMessage id="councilCollectionAccount.bank" />,
            dataIndex: ['bank', 'name'],
            key: 'bank',

            render: (value: string, councilCollectionAccount: CouncilCollectionAccount) => (
                <Link to={`/councils/${council.id}/collection-accounts/${councilCollectionAccount.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="councilCollectionAccount.iban" />,
            dataIndex: 'iban',
            key: 'iban',
            width: 300,
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string, councilCollectionAccount: CouncilCollectionAccount) => (
                <Link to={`/councils/${council.id}/collection-accounts/${councilCollectionAccount.id}`}>{value}</Link>
            )
        },

        {
            title: <FormattedMessage id="councilCollectionAccount.status" />,
            dataIndex: 'status',
            key: 'status',

            align: 'center',
            render: (value: StatusType, councilCollectionAccount: CouncilCollectionAccount) => (
                <Link to={`/councils/${council.id}/collection-accounts/${councilCollectionAccount.id}`}>
                    <StatusTag value={value} />
                </Link>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="councilCollectionAccounts.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/banks`, name: <FormattedMessage id="councilCollectionAccounts.title" /> }
            ]}
            council={council}
        >
            <Form form={form} onFinish={filterCouncilCollectionAccounts} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={12}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'councilCollectionAccounts.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="includeDisabled" valuePropName="checked">
                            <Checkbox onChange={form.submit} className={styles.checkbox}>
                                <FormattedMessage id="councilCollectionAccounts.include.DISABLED" />
                            </Checkbox>
                        </Form.Item>
                    </Col>
                    <Col span={8} className={styles.buttons}>
                        <Space>
                            <Link to={`/councils/${council?.id}`}>
                                <Button size="large" icon={<ArrowLeftOutlined />} />
                            </Link>
                            {isAdmin && (
                                <Link to={`/councils/${council?.id}/collection-accounts/new`}>
                                    <Tooltip title={<FormattedMessage id="councilCollectionAccounts.new.tooltip" />}>
                                        <Button type="primary" size="large" icon={<PlusOutlined />}>
                                            {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                        </Button>
                                    </Tooltip>
                                </Link>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(councilCollectionAccountsPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default CouncilCollectionAccountsPage;

interface Filter {
    searchText?: string | undefined;
}
