import { CloudDownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, List, Modal, Row, Tooltip, message } from 'antd';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import seizureIncomeOrderApi from '../../apis/SeizureIncomeOrderApi';
import { SeizureIncomeOrder, SeizureIncomeOrderCreation, SeizureIncomeOrderDraft } from '../../models/Entities';
import alertService from './../../services/AlertService';
import styles from './SeizureIncomeOrderModal.module.scss';

const SeizureIncomeOrderModal: React.FC<Props> = (props) => {
    const { councilId, councilTaxpayerIds, clone } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'loading' | 'sending'>();
    const [seizureIncomeOrderDraft, setSeizureIncomeDraft] = useState<SeizureIncomeOrderDraft>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const seizureIncomeOrderCreation: SeizureIncomeOrderCreation = {
                    councilId,
                    clone
                };

                const seizureIncomeOrderDraft = await seizureIncomeOrderApi.createDraft(seizureIncomeOrderCreation);
                setSeizureIncomeDraft(seizureIncomeOrderDraft);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [clone, councilId, councilTaxpayerIds, intl]);

    /*** METHODS ***/

    const send = async (seizureIncomeOrders: SeizureIncomeOrder[]) => {
        try {
            setLoading('sending');
            const updatedSeizureIncomeOrderCreation: SeizureIncomeOrderCreation = {
                councilId,
                clone
            };

            await seizureIncomeOrderApi.create(updatedSeizureIncomeOrderCreation);
            await props.onSave();
            message.success(intl.formatMessage({ id: 'status.sent' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = async () => {
        props.onCancel();
    };

    /*** VISUAL ***/

    const seizureIncomeOrders = seizureIncomeOrderDraft && seizureIncomeOrderDraft.seizureIncomeOrders ? seizureIncomeOrderDraft.seizureIncomeOrders : [];

    return (
        <Modal
            title={<FormattedMessage id="seizureIncomeOrderModal.title" />}
            open={true}
            onCancel={cancel}
            onOk={() => send(seizureIncomeOrders)}
            okText={<FormattedMessage id="button.send" tagName="span" />}
            okButtonProps={{
                loading: loading === 'sending',
                size: 'large',
                disabled: !seizureIncomeOrderDraft || seizureIncomeOrders.length === 0
            }}
            cancelButtonProps={{ size: 'large' }}
            width={800}
        >
            <Row gutter={[0, 0]} className={styles.container}>
                {!clone && (
                    <Col span={24}>
                        <FormattedMessage id="seizureIncomeOrderModal.description" />
                    </Col>
                )}
                {clone && (
                    <Col span={24}>
                        <FormattedMessage id="paymentOrderModal.description.clone" />
                        <p className={styles.alert}>
                            <InfoCircleOutlined /> <FormattedMessage id="paymentOrderModal.description.clone.alert" />
                        </p>
                    </Col>
                )}
                <Col span={24}>
                    <List
                        className={styles.list}
                        size="small"
                        itemLayout="horizontal"
                        dataSource={seizureIncomeOrders}
                        loading={loading === 'loading'}
                        renderItem={(seizureIncomeOrder) => <SeizureIncomeOrderComponent seizureIncomeOrder={seizureIncomeOrder} clone={clone} />}
                    />
                </Col>
            </Row>
        </Modal>
    );
};
export default SeizureIncomeOrderModal;

interface Props {
    councilId: number;
    councilTaxpayerIds?: number[];
    clone: boolean;
    onSave: () => Promise<void>;
    onCancel: () => void;
}

const SeizureIncomeOrderComponent: React.FC<SeizureIncomeOrderComponentProps> = (props) => {
    const { seizureIncomeOrder, clone } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'downloadingCompany'>();

    /*** METHODS ***/

    const downloadDraft = async (seizureIncomeOrder: SeizureIncomeOrder) => {
        try {
            setLoading('downloading');
            const document = await seizureIncomeOrderApi.downloadDraft(seizureIncomeOrder.councilTaxpayer!.id, clone);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'seizureIncomeOrder.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const downloadDraftCompany = async (seizureIncomeOrder: SeizureIncomeOrder) => {
        try {
            setLoading('downloadingCompany');
            const document = await seizureIncomeOrderApi.downloadDraftCompany(seizureIncomeOrder.councilTaxpayer!.id, clone);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'seizureIncomeOrderCompany.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const total: string = intl.formatNumber(seizureIncomeOrder.requestedAmount!, {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'EUR'
    });

    return (
        <List.Item
            actions={[
                <Tooltip title={<FormattedMessage id="seizureIncomeOrderModal.button.preview" />}>
                    <Button
                        icon={<CloudDownloadOutlined />}
                        danger
                        size="large"
                        onClick={() => downloadDraft(seizureIncomeOrder)}
                        loading={loading === 'downloading'}
                    />
                </Tooltip>,
                <Tooltip title={<FormattedMessage id="seizureIncomeOrderModal.button.preview.company" />}>
                    <Button
                        icon={<CloudDownloadOutlined />}
                        danger
                        size="large"
                        onClick={() => downloadDraftCompany(seizureIncomeOrder)}
                        loading={loading === 'downloadingCompany'}
                    />
                </Tooltip>
            ]}
        >
            <List.Item.Meta
                title={seizureIncomeOrder.councilTaxpayer!.taxpayer.fullName}
                description={
                    <strong>
                        <FormattedMessage
                            id="seizureIncomeOrderModal.total"
                            values={{
                                total: total
                            }}
                        />
                    </strong>
                }
            />
        </List.Item>
    );
};

interface SeizureIncomeOrderComponentProps {
    seizureIncomeOrder: SeizureIncomeOrder;
    clone: boolean;
}
