import { PlusOutlined, ReconciliationOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/es/table';
import { TablePaginationConfig } from 'antd/lib';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import councilTaxpayerApi from '../../apis/CouncilTaxpayerApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { BreadcrumbItem, Page } from '../../models/Elements';
import { Council, CouncilTaxpayer } from '../../models/Entities';
import alertService from '../../services/AlertService';
import tableService from '../../services/TableService';
import styles from './CouncilTaxpayersPage.module.scss';

/**
 * Returns the council taxpayers page.
 * @returns the council taxpayers page.
 */
const CouncilTaxpayersPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const { council } = useContext(CustomCouncilContext);
    const [filter, setFilter] = useState<Filter>({});
    const [councilTaxpayersPage, setCouncilTaxpayersPage] = useState<Page<CouncilTaxpayer>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [loading, setLoading] = useState<'loading'>();

    // list council taxpayers
    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const councilTaxpayersPage = await councilTaxpayerApi.list(
                        page,
                        tableService.pageSize,
                        sortField,
                        sortOrder,
                        council.id,
                        filter.searchText,
                        'ENABLED'
                    );
                    setCouncilTaxpayersPage(councilTaxpayersPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterUsers = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    /*** VISUAL ***/

    const items = councilTaxpayersPage ? councilTaxpayersPage.content : [];
    const columns: ColumnsType<CouncilTaxpayer> = [
        {
            title: <FormattedMessage id="councilTaxpayer.id.short" />,
            dataIndex: 'id',
            key: 'id',
            width: 100,
            fixed: 'left',
            align: 'center',
            sorter: true,
            defaultSortOrder: 'ascend'
        },
        {
            title: <FormattedMessage id="councilTaxpayer.identifier" />,
            dataIndex: ['taxpayer', 'identifier'],
            key: 'taxpayerIdentifier',
            width: 140,
            fixed: 'left',
            align: 'center'
        },
        {
            title: <FormattedMessage id="councilTaxpayer.name" />,
            dataIndex: ['taxpayer', 'fullName'],
            key: 'lastName',
            fixed: 'left',
            ellipsis: {
                showTitle: false
            }
        },
        {
            title: <FormattedMessage id="address.email" />,
            dataIndex: ['taxpayer', 'address', 'email'],
            key: 'email',
            width: 260
        },
        {
            title: <FormattedMessage id="address.street" />,
            dataIndex: ['taxpayer', 'address', 'street'],
            key: 'street',
            width: 340
        },
        {
            key: 'actions',
            width: 150,
            fixed: 'right',
            align: 'center',
            render: (record, councilTaxpayer: CouncilTaxpayer) => (
                <Space size="large">
                    <Link to={`/councils/${council.id}/taxpayers/${councilTaxpayer.id}`}>
                        <Tooltip title={<FormattedMessage id="councilTaxpayer.receivables.tooltip" />}>
                            <SolutionOutlined className={styles.icons} />
                        </Tooltip>
                    </Link>
                    <Link to={`/taxpayers/${councilTaxpayer.taxpayer.id}`}>
                        <Tooltip title={<FormattedMessage id="councilTaxpayer.tooltip" />}>
                            <UserOutlined className={styles.icons} />
                        </Tooltip>
                    </Link>
                    <Link to={`/taxpayers/${councilTaxpayer.taxpayer.id}/history`}>
                        <Tooltip title={<FormattedMessage id="councilTaxpayer.history.tooltip" />}>
                            <ReconciliationOutlined className={styles.icons} />
                        </Tooltip>
                    </Link>
                </Space>
            )
        }
    ];

    return (
        <LayoutComponent title={<FormattedMessage id="taxpayers.title" />} menu="councils" path={PathComponent(council)} council={council}>
            <Form form={form} onFinish={filterUsers} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={20}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'taxpayers.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} className={styles.buttons}>
                        {/* <Link to="/taxpayers/new"> */}
                        <Tooltip title={<FormattedMessage id="taxpayers.new.tooltip" />}>
                            <Button type="primary" size="large" icon={<PlusOutlined />} disabled>
                                {desktop && <FormattedMessage id="button.add" tagName="span" />}
                            </Button>
                        </Tooltip>
                        {/* </Link> */}
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(councilTaxpayersPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default CouncilTaxpayersPage;

/**
 * Returns the path of the council taxpayers page.
 * @param council the council
 * @returns  the path
 */
const PathComponent = (council: Council): BreadcrumbItem[] => {
    return [
        { path: '/councils', name: <FormattedMessage id="councils.title" /> },
        { path: `/councils/${council.id}`, name: council.name },
        { path: `/councils/${council.id}/taxpayers`, name: <FormattedMessage id="councilTaxpayers.title" /> }
    ];
};

interface Filter {
    searchText?: string;
}
