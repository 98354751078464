import { Council, CustomAuth, Receivable, ReceivableEvent } from '../models/Entities';
import { StateType } from '../models/Types';
import rolesService from './RolesService';

class ReceivableService {
    // receivable states
    apremioStates: StateType[] = [
        'REGISTERED',
        'INITIALIZED',
        'NOTIFICATION_SIGN',
        'NOTIFICATION_SIGNED',
        'NOTIFICATION_BUILD',
        'NOTIFICATION_SENT',
        'NOTIFICATION_RECEIVED',
        'NOTIFICATION_ABSENT',
        'NOTIFICATION_WRONG_ADDRESS',
        'NOTIFICATION_UNKNOWN',
        'PAYMENT_ORDER_ANNOUNCEMENT_SIGN',
        'PAYMENT_ORDER_ANNOUNCEMENT_SIGNED',
        'PAYMENT_ORDER_ANNOUNCEMENT_PUBLISHED'
    ];
    seizureStates: StateType[] = ['SEIZURE', 'SEIZURE_BANK', 'SEIZURE_INCOME'];
    finalStates: StateType[] = ['CANCELLED', 'PAID', 'OVERPAID'];

    blobToBase64 = (blob: Blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise((resolve) => {
            reader.onloadend = () => {
                const result = (reader.result as string).replace('data:application/octet-stream;base64,', '');
                resolve(result);
            };
        });
    };

    b64toBlob = (b64Data: string, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    };

    getEventKey = (event: ReceivableEvent | undefined, uuid?: string) => {
        return event ? `${event.state}-${event.id}-${uuid}` : undefined;
    };

    isApremio = (state: StateType | undefined): boolean => {
        return !!state && this.apremioStates.includes(state);
    };

    isSeizure = (state: StateType | undefined): boolean => {
        return !!state && this.seizureStates.includes(state);
    };

    isFinal = (state: StateType | undefined): boolean => {
        return !!state && this.finalStates.includes(state);
    };

    hasSameCouncil = (receivables: Receivable[]): boolean => {
        return receivables.every((r) => r.councilId === receivables[0].councilId);
    };

    isCreatePaymentLetterEnabled = (auth: CustomAuth | undefined, receivables: Receivable[]) => {
        let isEnabled: boolean = false;
        if (receivables.length > 0 && rolesService.hasAnyRole(auth, ['ROLE_ADMIN']) && this.hasSameCouncil(receivables)) {
            isEnabled = receivables.every((receivable) => receivable.state !== 'CANCELLED' && receivable.state !== 'PAID' && receivable.state !== 'OVERPAID');
        }

        return isEnabled;
    };

    isCreatePaymentEnabled = (auth: CustomAuth | undefined) => {
        return rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);
    };

    isPaymentOrderSignEnabled = (auth: CustomAuth | undefined, receivables: Receivable[]) => {
        let isEnabled: boolean = false;
        if (receivables.length > 0 && rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])) {
            return isEnabled;
        }
    };

    isPaymentOrderEnabled = (auth: CustomAuth | undefined, receivables: Receivable[]) => {
        let isEnabled: boolean = false;
        if (receivables.length > 0 && rolesService.hasAnyRole(auth, ['ROLE_ADMIN'])) {
            return isEnabled;
        }
    };

    isCreateNotificationSignEnabled = (auth: CustomAuth | undefined, receivables: Receivable[]) => {
        let isEnabled: boolean = false;
        if (
            receivables.length > 0 &&
            !rolesService.hasAnyRole(auth, ['ROLE_ADMIN']) &&
            rolesService.hasAnyRole(auth, ['ROLE_COUNCIL_USER']) &&
            this.hasSameCouncil(receivables)
        ) {
            isEnabled = receivables.every((receivable) => receivable.state === 'NOTIFICATION_BUILD');
        }

        return isEnabled;
    };

    isCreateNotificationSentEnabled = (auth: CustomAuth | undefined, receivables: Receivable[]) => {
        let isEnabled: boolean = false;
        if (receivables.length > 0 && rolesService.hasAnyRole(auth, ['ROLE_ADMIN']) && this.hasSameCouncil(receivables)) {
            isEnabled = receivables.every((receivable) => receivable.state === 'NOTIFICATION_SIGN');
        }

        return isEnabled;
    };

    isCreateSeizureBankInfoRequestApiEnabled = (auth: CustomAuth | undefined, receivables: Receivable[]) => {
        return rolesService.hasAnyRole(auth, ['ROLE_ADMIN']) && this.hasSameCouncil(receivables);
    };

    isCreateSeizureBankInfoResponseApiEnabled = (auth: CustomAuth | undefined, council: Council | undefined) => {
        let isEnabled: boolean = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']) && !!council;

        return isEnabled;
    };

    isCreateSeizureBankAmountRequestApiEnabled = (auth: CustomAuth | undefined, council: Council | undefined) => {
        let isEnabled: boolean = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']) && !!council;

        return isEnabled;
    };

    isCreateSeizureBankAmountResponseApiEnabled = (auth: CustomAuth | undefined, council: Council | undefined) => {
        let isEnabled: boolean = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']) && !!council;

        return isEnabled;
    };
}

const receivableService: ReceivableService = new ReceivableService();
export default receivableService;
