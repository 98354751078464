import { Page } from '../models/Elements';
import { SeizureBankAmountResponse, SeizureBankAmountResponseDraft } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class SeizureBankAmountResponseApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId: number,
        searchText?: string
    ): Promise<Page<SeizureBankAmountResponse>> => {
        const response = await adminApiAxios.get<Page<SeizureBankAmountResponse>>('/seizure-bank-amount-responses', {
            params: { page, size, sortField, sortOrder, councilId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (seizureBankAmountResponseId: number): Promise<SeizureBankAmountResponse> => {
        const response = await adminApiAxios.get<SeizureBankAmountResponse>(`/seizure-bank-amount-responses/${seizureBankAmountResponseId}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (seizureBankAmountResponse: SeizureBankAmountResponse, file: any): Promise<SeizureBankAmountResponse> => {
        const formData: FormData = this.buildFormData(seizureBankAmountResponse, file);
        const response = await adminApiAxios.post<SeizureBankAmountResponse>(`/seizure-bank-amount-responses`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    createDraft = async (seizureBankAmountResponse: SeizureBankAmountResponse, file: any): Promise<SeizureBankAmountResponseDraft> => {
        const formData: FormData = this.buildFormData(seizureBankAmountResponse, file);
        const response = await adminApiAxios.post<SeizureBankAmountResponseDraft>(`/seizure-bank-amount-responses/draft`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDraftDates(response.data);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/seizure-bank-amount-responses/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Builds a form data from a seizure bank amount response.
     * @param seizureBankAmountResponse the seizure bank amount response
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (seizureBankAmountResponse: SeizureBankAmountResponse, file?: File) => {
        const formData: FormData = new FormData();
        seizureBankAmountResponse.id && formData.append('id', seizureBankAmountResponse.id.toString());
        seizureBankAmountResponse.councilId && formData.append('councilId', seizureBankAmountResponse.councilId.toString());
        seizureBankAmountResponse.bankId && formData.append('bankId', seizureBankAmountResponse.bankId.toString());
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a seizure bank amount response.
     * @param seizureBankAmountResponse - the seizure bank amount response
     */
    private loadDates(seizureBankAmountResponse: SeizureBankAmountResponse) {
        seizureBankAmountResponse.audit!.created = dateService.transformDate(seizureBankAmountResponse.audit!.created)!;
        seizureBankAmountResponse.audit!.updated = dateService.transformDate(seizureBankAmountResponse.audit!.updated)!;
    }

    /**
     * Loads the dates of a seizure bank amount response draft.
     * @param seizureBankAmountResponseDraft - the seizure bank amount response draft
     */
    private loadDraftDates(seizureBankAmountResponseDraft: SeizureBankAmountResponseDraft) {
        const { seizureBankAmountResponse, ctSeizureBankAmountResponses } = seizureBankAmountResponseDraft;

        this.loadDates(seizureBankAmountResponse);
        ctSeizureBankAmountResponses.forEach((ctsbar) => {
            ctsbar.audit.created = dateService.transformDate(ctsbar.audit!.created)!;
            ctsbar.audit.updated = dateService.transformDate(ctsbar.audit!.updated)!;
        });
    }
}

const seizureBankAmountResponseApi: SeizureBankAmountResponseApi = new SeizureBankAmountResponseApi();
export default seizureBankAmountResponseApi;
