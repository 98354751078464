import { Col } from 'antd';
import React from 'react';
import PublicLayoutComponent from '../../../components/PublicLayoutComponent/PublicLayoutComponent';
import styles from './LegalNoticePage.module.scss';

/**
 * Returns the legal notice page.
 * @returns the legal notice page.
 */
const LegalNoticePage = (): React.ReactElement => {
    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <PublicLayoutComponent>
            <Col span={24} className={styles.content}>
                <h1>Legal Notice</h1>
                <div>
                    <p>
                        Walfen Technologies SLU, with CIF/NIF: B30923973 and registered office at C/ Ángel Bruna, 50, Esc. 1, 2ºa, 30203 – Cartagena (Murcia),
                        cannot assume any responsibility for the incorrect, inappropriate or illegal use of the information appearing on https://www.taxfen.com.
                    </p>
                    <p>Walfen Technologies SLU is a company registered in the Mercantile Registry of Murcia, volume 3357, folio 79, page MU-96942, entry 1ª.</p>
                    <p>
                        Within the limits established by law, Walfen Technologies SLU assumes no responsibility for the lack of completeness or accuracy of data
                        or information contained on its websites.
                    </p>
                    <p>
                        The contents and information do not bind Walfen Technologies SLU and do not imply opinions, advice or any kind of legal assessment, as
                        it is merely a service offered for information.
                    </p>
                    <p>
                        The websites of Walfen Technologies SLU may contain links to third parties, which are not under the control of Walfen Technologies SLU.
                        Therefore, Walfen Technologies SLU cannot assume any responsibility for the content that may appear on these pages.
                    </p>
                    <p>
                        The text, images, sounds, animations, software and other content placed on this website are the exclusive property of Walfen
                        Technologies SLU or its licensors. Their transmission, distribution, transfer, reproduction, storage, total or partial public
                        communication must have the express consent by Walfen Technologies SLU.
                    </p>
                    <p> Also, access to some of the services offered through the website requires the provision of some personal data. </p>
                    <p>
                        In compliance with the Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of
                        natural persons with regard to the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC
                        (General Data Protection Regulation – GDPR), we inform you that as a result of filling in these forms, your personal data will be stored
                        and processed by Walfen Technologies SLU for the purpose of offering and providing our services and informing you about any improvements
                        to the website.
                    </p>
                    <p>
                        We also inform you that you can exercise your rights to access, rectify, erase, object to, limit or transfer your personal data, free of
                        charge, via email to: info@walfen.com or by post to the address: C/ Ángel Bruna, 50, Esc. 1, 2ºa, 30203 – Cartagena (Murcia), providing
                        us with your identification.
                    </p>
                </div>
            </Col>
        </PublicLayoutComponent>
    );
};

export default LegalNoticePage;
