import { ConfigProvider, message } from 'antd';
import esES from 'antd/lib/locale/es_ES';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import objectSupport from 'dayjs/plugin/objectSupport';
import utc from 'dayjs/plugin/utc';
import 'pdfjs-dist/build/pdf.worker.entry'; // required by react-pdf
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './App.css';
import appThemeComponent from './components/AppThemeComponent/AppThemeComponent';
import HeadMetadata from './components/HeadMetadata';
import RoutesComponent from './components/RoutesComponent/RoutesComponent';
import configuration from './configs/Configuration';
import CustomContext from './contexts/CustomContext';
import messagesEs from './i18n/es.json';
import { CustomAuth } from './models/Entities';
import authService from './services/AuthService';
import { App as AntdApp } from 'antd';

// set up pdf js
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();

// set up DayJs
dayjs.extend(utc);
dayjs.extend(localizedFormat);
dayjs.extend(objectSupport);

// set up AWS authentication
Auth.configure(configuration.awsAuth);

/**
 * The app component.
 * @returns the app
 */
const App: React.FC = () => {
    /*** HOOKS ***/

    // create context
    const [auth, setAuth] = useState<CustomAuth>();
    const [initialized, setInitialized] = useState<boolean>(false);

    // initialize the app
    useEffect(() => {
        const init = async () => {
            try {
                // set up antd configuration
                message.config({ maxCount: 1 });
                // set up locale
                dayjs.locale('es');
                let auth = await authService.get();

                // store deep link
                storeDeepLink(auth);

                setAuth(auth);
                setInitialized(true);

                // initialize autofirma
                const AutoScript = window.AutoScript as any;
                AutoScript?.cargarAppAfirma();
            } catch (error: any) {
                console.log(error);
            }
        };
        init();
    }, []);

    /*** METHODS ***/

    const updateContextAuth = (auth?: CustomAuth): void => {
        setAuth(auth);
    };

    /*** VISUAL ***/

    if (initialized) {
        return (
            <ConfigProvider locale={esES} theme={appThemeComponent.getAppTheme()}>
                <AntdApp>
                    <CustomContext.Provider value={{ auth, setAuth: updateContextAuth }}>
                        <IntlProvider locale="es-ES" defaultLocale="es-ES" messages={messagesEs}>
                            <HeadMetadata />
                            <RoutesComponent />
                        </IntlProvider>
                    </CustomContext.Provider>
                </AntdApp>
            </ConfigProvider>
        );
    } else {
        return <></>;
    }
};

export default App;

/**
 * Stores the deep link if the user is not authenticated or not initialized.
 * @param auth the auth
 * @returns the current deep link
 */
const storeDeepLink = (auth: CustomAuth | undefined) => {
    if (!auth || !auth.initialized) {
        sessionStorage.setItem('deeplink', window.location.pathname + window.location.search);
    }
};
