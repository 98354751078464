import React from 'react';
import { FormattedNumber } from 'react-intl';

/**
 * This component displays a bytes in a user friendly format.
 * @param props - the props
 */
export const FileSizeComponent: React.FC<Props> = (props) => {
    const { value } = props;

    if (!value && value !== 0) {
        return <></>;
    } else if (value > 1024 * 1024 * 1024) {
        return (
            <>
                <FormattedNumber value={value / 1024 / 1024 / 1024} maximumFractionDigits={1} /> GB
            </>
        );
    } else if (value > 1024 * 1024) {
        return (
            <>
                <FormattedNumber value={value / 1024 / 1024} maximumFractionDigits={1} /> MB
            </>
        );
    } else if (value > 1024) {
        return (
            <>
                <FormattedNumber value={value / 1024} maximumFractionDigits={1} /> KB
            </>
        );
    } else {
        return (
            <>
                <FormattedNumber value={value} maximumFractionDigits={1} /> bytes
            </>
        );
    }
};
export default FileSizeComponent;

interface Props {
    value?: number;
}
