import { Page } from '../models/Elements';
import { CouncilTaxpayerSeizureBank, CouncilTaxpayerSeizureBankFull } from '../models/Entities';
import { CouncilTaxpayerSeizureBankPhaseType } from '../models/Types';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class CouncilTaxpayerSeizureBankApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilTaxpayerId: number,
        includeArchived?: boolean,
        phase?: CouncilTaxpayerSeizureBankPhaseType,
        searchText?: string
    ): Promise<Page<CouncilTaxpayerSeizureBank>> => {
        const response = await adminApiAxios.get<Page<CouncilTaxpayerSeizureBank>>('/council-taxpayer-seizure-banks', {
            params: { page, size, sortField, sortOrder, councilTaxpayerId, includeArchived, phase, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    getFull = async (id: number): Promise<CouncilTaxpayerSeizureBankFull> => {
        const response = await adminApiAxios.get<CouncilTaxpayerSeizureBankFull>(`/council-taxpayer-seizure-banks/${id}/full`);
        this.loadFullDates(response.data);

        return response.data;
    };

    /**
     * Loads the dates of the council taxpayer seizure bank info request.
     * @param councilTaxpayerSeizureBank
     */
    private loadDates(councilTaxpayerSeizureBank: CouncilTaxpayerSeizureBank) {
        councilTaxpayerSeizureBank.audit!.created = dateService.transformDate(councilTaxpayerSeizureBank.audit!.created)!;
        councilTaxpayerSeizureBank.audit!.updated = dateService.transformDate(councilTaxpayerSeizureBank.audit!.updated)!;
    }

    /**
     * Loads the dates of the council taxpayer seizure bank full info request.
     * @param councilTaxpayerSeizureBankFull
     */
    private loadFullDates(councilTaxpayerSeizureBankFull: CouncilTaxpayerSeizureBankFull) {
        const { councilTaxpayerSeizureBank, events } = councilTaxpayerSeizureBankFull;

        councilTaxpayerSeizureBank.audit!.created = dateService.transformDate(councilTaxpayerSeizureBank.audit!.created)!;
        councilTaxpayerSeizureBank.audit!.updated = dateService.transformDate(councilTaxpayerSeizureBank.audit!.updated)!;
        events.forEach((event) => {
            event.audit.created = dateService.transformDate(event.audit.created)!;
            event.audit.updated = dateService.transformDate(event.audit.updated)!;
            this.loadDates(event.councilTaxpayerSeizureBank);
        });
    }
}

const councilTaxpayerSeizureBankApi: CouncilTaxpayerSeizureBankApi = new CouncilTaxpayerSeizureBankApi();
export default councilTaxpayerSeizureBankApi;
