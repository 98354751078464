/**
 * The council taxpayer component with the vehicles information.
 * @param props the props
 * @returns the council taxpayer vehicles component
 */
const CouncilTaxpayerVehiclesComponent: React.FC = () => {
    /*** HOOKS ***/

    /*** VISUAL ***/

    return <p>Vehiculos</p>;
};
export default CouncilTaxpayerVehiclesComponent;
