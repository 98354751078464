import { Col, Divider, Row } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedDate, useIntl } from 'react-intl';
import councilTaxpayerSeizureIncomeApi from '../../../../apis/CouncilTaxpayerSeizureIncomeApi';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import {
    CouncilTaxpayerSeizureIncome,
    CouncilTaxpayerSeizureIncomeCancel,
    CouncilTaxpayerSeizureIncomeEvent,
    CouncilTaxpayerSeizureIncomeFull,
    CouncilTaxpayerSeizureIncomeOrderAbsent,
    CouncilTaxpayerSeizureIncomeOrderAnnouncementPublished,
    CouncilTaxpayerSeizureIncomeOrderAnnouncementSign,
    CouncilTaxpayerSeizureIncomeOrderAnnouncementSigned,
    CouncilTaxpayerSeizureIncomeOrderBuild,
    CouncilTaxpayerSeizureIncomeOrderReceived,
    CouncilTaxpayerSeizureIncomeOrderSent,
    CouncilTaxpayerSeizureIncomeOrderUnknown,
    CouncilTaxpayerSeizureIncomeOrderWrongAddress,
    CouncilTaxpayerSeizureIncomeRequest,
    CouncilTaxpayerSeizureIncomeResponse,
    CouncilTaxpayerSeizureIncomeSign,
    CouncilTaxpayerSeizureIncomeSigned
} from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import SeizureIncomeAnnouncementPublishedComponent from '../SeizureIncomeAnnouncementPublishedComponent/SeizureIncomeAnnouncementPublishedComponent';
import SeizureIncomeAnnouncementSignComponent from '../SeizureIncomeAnnouncementSignComponent/SeizureIncomeAnnouncementSignComponent';
import SeizureIncomeAnnouncementSignedComponent from '../SeizureIncomeAnnouncementSignedComponent/SeizureIncomeAnnouncementSignedComponent';
import SeizureIncomeCancelComponent from '../SeizureIncomeCancelComponent/SeizureIncomeCancelComponent';
import SeizureIncomeOrderAbsentComponent from '../SeizureIncomeOrderAbsentComponent/SeizureIncomeOrderAbsentComponent';
import SeizureIncomeOrderBuildComponent from '../SeizureIncomeOrderBuildComponent/SeizureIncomeOrderBuildComponent';
import SeizureIncomeOrderReceivedComponent from '../SeizureIncomeOrderReceivedComponent/SeizureIncomeOrderReceivedComponent';
import SeizureIncomeOrderSentComponent from '../SeizureIncomeOrderSentComponent/SeizureIncomeOrderSentComponent';
import SeizureIncomeOrderUnknownComponent from '../SeizureIncomeOrderUnknownComponent/SeizureIncomeOrderUnknownComponent';
import SeizureIncomeOrderWrongAddressComponent from '../SeizureIncomeOrderWrongAddressComponent/SeizureIncomeOrderWrongAddressComponent';
import SeizureIncomeInfoRequestComponent from '../SeizureIncomeRequestComponent/SeizureIncomeRequestComponent';
import SeizureIncomeResponseComponent from '../SeizureIncomeResponseComponent/SeizureIncomeResponseComponent';
import SeizureIncomeSignComponent from '../SeizureIncomeSignComponent/SeizureIncomeSignComponent';
import SeizureIncomeSignedComponent from '../SeizureIncomeSignedComponent/SeizureIncomeSignedComponent';

const CouncilTaxpayerSeizureIncomeComponent = (props: Props) => {
    const { councilTaxpayerSeizureIncome, onUpdate } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [councilTaxpayerSeizureIncomeFull, setCouncilTaxpayerSeizureIncomeFull] = useState<CouncilTaxpayerSeizureIncomeFull>();
    const [, setLoading] = useState<'loading'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const councilTaxpayerSeizureIncomeFull = await councilTaxpayerSeizureIncomeApi.getFull(councilTaxpayerSeizureIncome.id);
                setCouncilTaxpayerSeizureIncomeFull(councilTaxpayerSeizureIncomeFull);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, councilTaxpayerSeizureIncome]);

    /*** METHODS ***/

    const refresh = async (update?: boolean) => {
        try {
            setLoading('loading');
            const councilTaxpayerSeizureIncomeFull = await councilTaxpayerSeizureIncomeApi.getFull(councilTaxpayerSeizureIncome.id);
            setCouncilTaxpayerSeizureIncomeFull(councilTaxpayerSeizureIncomeFull);
            update && onUpdate();
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <>
            <Divider orientation="left" style={{ borderColor: '#94B4C6' }}>
                <ConstantLabel value={councilTaxpayerSeizureIncome.seizureIncome.income} prefix="seizureIncome.income." /> -{' '}
                <ConstantLabel value={councilTaxpayerSeizureIncome.phase} prefix="councilTaxpayerSeizureIncome." /> -{' '}
                <FormattedDate value={councilTaxpayerSeizureIncome.audit.updated! as any} day="2-digit" month="2-digit" year="numeric" />
            </Divider>
            <Row gutter={[16, 16]}>
                {councilTaxpayerSeizureIncomeFull &&
                    councilTaxpayerSeizureIncomeFull.events.map((event: CouncilTaxpayerSeizureIncomeEvent, index: number) => {
                        //const eventReadonly = index !== councilTaxpayerSeizureIncomeFull.events.length - 1;

                        switch (event.phase) {
                            case 'REQUEST':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeInfoRequestComponent request={event as CouncilTaxpayerSeizureIncomeRequest} onCancel={refresh} />
                                    </Col>
                                );
                            case 'RESPONSE':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeResponseComponent response={event as CouncilTaxpayerSeizureIncomeResponse} onCancel={refresh} />
                                    </Col>
                                );
                            case 'SIGN':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeSignComponent sign={event as CouncilTaxpayerSeizureIncomeSign} onCancel={refresh} />
                                    </Col>
                                );
                            case 'SIGNED':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeSignedComponent signed={event as CouncilTaxpayerSeizureIncomeSigned} onCancel={refresh} />
                                    </Col>
                                );
                            case 'NOTIFICATION_BUILD':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeOrderBuildComponent orderBuild={event as CouncilTaxpayerSeizureIncomeOrderBuild} onCancel={refresh} />
                                    </Col>
                                );
                            case 'NOTIFICATION_SENT':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeOrderSentComponent orderSent={event as CouncilTaxpayerSeizureIncomeOrderSent} onCancel={refresh} />
                                    </Col>
                                );
                            case 'NOTIFICATION_RECEIVED':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeOrderReceivedComponent
                                            orderReceived={event as CouncilTaxpayerSeizureIncomeOrderReceived}
                                            onCancel={refresh}
                                        />
                                    </Col>
                                );
                            case 'NOTIFICATION_ABSENT':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeOrderAbsentComponent orderAbsent={event as CouncilTaxpayerSeizureIncomeOrderAbsent} onCancel={refresh} />
                                    </Col>
                                );
                            case 'NOTIFICATION_UNKNOWN':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeOrderUnknownComponent
                                            orderUnknown={event as CouncilTaxpayerSeizureIncomeOrderUnknown}
                                            onCancel={refresh}
                                        />
                                    </Col>
                                );
                            case 'NOTIFICATION_WRONG_ADDRESS':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeOrderWrongAddressComponent
                                            orderWrongAddress={event as CouncilTaxpayerSeizureIncomeOrderWrongAddress}
                                            onCancel={refresh}
                                        />
                                    </Col>
                                );
                            case 'SEIZURE_ORDER_ANNOUNCEMENT_SIGN':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeAnnouncementSignComponent
                                            announcementSign={event as CouncilTaxpayerSeizureIncomeOrderAnnouncementSign}
                                            onCancel={refresh}
                                        />
                                    </Col>
                                );
                            case 'SEIZURE_ORDER_ANNOUNCEMENT_SIGNED':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeAnnouncementSignedComponent
                                            announcementSigned={event as CouncilTaxpayerSeizureIncomeOrderAnnouncementSigned}
                                            onCancel={refresh}
                                        />
                                    </Col>
                                );
                            case 'SEIZURE_ORDER_ANNOUNCEMENT_PUBLISHED':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeAnnouncementPublishedComponent
                                            announcementPublished={event as CouncilTaxpayerSeizureIncomeOrderAnnouncementPublished}
                                            onCancel={refresh}
                                        />
                                    </Col>
                                );
                            case 'CANCEL':
                                return (
                                    <Col key={`${event.councilTaxpayerSeizureIncome.phase}-${event.councilTaxpayerSeizureIncome.id}`} xs={24} md={4}>
                                        <SeizureIncomeCancelComponent cancel={event as CouncilTaxpayerSeizureIncomeCancel} />
                                    </Col>
                                );

                            default:
                                return <></>;
                        }
                    })}
            </Row>
        </>
    );
};
export default CouncilTaxpayerSeizureIncomeComponent;

interface Props {
    councilTaxpayerSeizureIncome: CouncilTaxpayerSeizureIncome;
    onUpdate: () => Promise<void>;
}
