import { Page } from '../models/Elements';
import { Announcement, AnnouncementCreation, AnnouncementDraft } from '../models/Entities';
import { AnnouncementSignatureType } from '../models/Types';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class AnnouncementApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, councilId?: number, searchText?: string): Promise<Page<Announcement>> => {
        const response = await adminApiAxios.get<Page<Announcement>>('/announcements', {
            params: { page, size, sortField, sortOrder, councilId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<Announcement> => {
        const response = await adminApiAxios.get<Announcement>(`/announcements/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (announcementCreation: AnnouncementCreation): Promise<Announcement> => {
        const response = await adminApiAxios.post<Announcement>('/announcements', announcementCreation);
        this.loadDates(response.data);

        return response.data;
    };

    createDraft = async (announcementCreation: AnnouncementCreation): Promise<AnnouncementDraft> => {
        const response = await adminApiAxios.post<AnnouncementDraft>('/announcements/draft', announcementCreation);
        this.loadDraftDates(response.data);

        return response.data;
    };

    download = async (id: number, signature: AnnouncementSignatureType): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/announcements/${id}/file`, {
            responseType: 'blob',
            params: { signature }
        });

        return response.data;
    };

    downloadXml = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/announcements/${id}/file/xml`, {
            responseType: 'blob'
        });

        return response.data;
    };

    downloadDraft = async (councilId: number): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(`/announcements/draft/file`, {}, { responseType: 'blob', params: { councilId } });

        return response.data;
    };

    downloadDraftXml = async (councilId: number): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(`/announcements/draft/file/xml`, {}, { responseType: 'blob', params: { councilId } });

        return response.data;
    };

    downloadBulletin = async (announcement: Announcement): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/announcements/${announcement.id}/bulletin`, {
            responseType: 'blob'
        });

        return response.data;
    };

    sign = async (announcement: Announcement, signature: AnnouncementSignatureType, signedFile: any): Promise<Announcement> => {
        const formData: FormData = this.buildFormData(announcement, signedFile);
        const response = await adminApiAxios.put<Announcement>(`/announcements/${announcement.id}/signs`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params: {
                signature
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    update = async (announcement: Announcement, bulletinFile: any): Promise<Announcement> => {
        const formData: FormData = this.buildFormData(announcement, undefined, bulletinFile);
        const response = await adminApiAxios.put<Announcement>(`/announcements/${announcement.id}/publications`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    /**
     * Loads the dates of an announcement.
     * @param announcement - the announcement
     */
    private loadDates(announcement: Announcement) {
        announcement.audit!.created = dateService.transformDate(announcement.audit!.created)!;
        announcement.audit!.updated = dateService.transformDate(announcement.audit!.updated)!;
        announcement.treasurerSigned = dateService.transformDate(announcement.treasurerSigned);
        announcement.bulletinPublished = dateService.transformDate(announcement.bulletinPublished);
        announcement.paymentOrderExpired = dateService.transformDate(announcement.paymentOrderExpired);
        announcement.seizureExpired = dateService.transformDate(announcement.seizureExpired);
        announcement.sent = dateService.transformDate(announcement.sent)!;
    }

    private buildFormData = (announcement: Announcement, signedFile?: any, bulletinFile?: any) => {
        const formData: FormData = new FormData();
        announcement.id && formData.append('id', announcement.id.toString());
        announcement.council.id && formData.append('council.id', announcement.council.id.toString());
        announcement.treasurer.id && formData.append('treasurer.id', announcement.treasurer.id.toString());
        announcement.treasurerSigned && formData.append('treasurerSigned', announcement.treasurerSigned.format('YYYY-MM-DDThh:mm:ss'));
        announcement.sent && formData.append('sent', announcement.sent.format('YYYY-MM-DDThh:mm:ss'));
        announcement.sentState && formData.append('sentState', announcement.sentState.toString());
        announcement.bulletinNumber && formData.append('bulletinNumber', announcement.bulletinNumber.toString());
        announcement.bulletinPublished && formData.append('bulletinPublished', announcement.bulletinPublished.format('YYYY-MM-DDThh:mm:ss'));
        announcement.paymentOrderExpired && formData.append('paymentOrderExpired', announcement.paymentOrderExpired.format('YYYY-MM-DDThh:mm:ss'));
        announcement.seizureExpired && formData.append('seizureExpired', announcement.seizureExpired.format('YYYY-MM-DDThh:mm:ss'));
        bulletinFile && formData.append('bulletinFile.name', bulletinFile.name);
        bulletinFile && formData.append('bulletinFile.file', bulletinFile);
        signedFile && formData.append('signedFile.name', signedFile.name);
        signedFile && formData.append('signedFile.file', signedFile);
        return formData;
    };

    /**
     * Loads the dates of the announcement  draft.
     * @param announcementDraft - the announcement draft
     */
    private loadDraftDates(announcementDraft: AnnouncementDraft) {
        const { announcement, receivablePaymentOrderAnnouncementSigns, receivables } = announcementDraft;
        this.loadDates(announcement);

        receivablePaymentOrderAnnouncementSigns.forEach((receivablePaymentOrderAnnouncementSign) => {
            receivablePaymentOrderAnnouncementSign.audit!.created = dateService.transformDate(receivablePaymentOrderAnnouncementSign.audit!.created)!;
            receivablePaymentOrderAnnouncementSign.audit!.updated = dateService.transformDate(receivablePaymentOrderAnnouncementSign.audit!.updated)!;
        });
        receivables.forEach((receivable) => {
            receivable.audit!.created = dateService.transformDate(receivable.audit!.created)!;
            receivable.audit!.updated = dateService.transformDate(receivable.audit!.updated)!;
        });
    }
}

const announcementApi: AnnouncementApi = new AnnouncementApi();
export default announcementApi;
