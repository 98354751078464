import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip } from 'antd';
import FileSaver from 'file-saver';
import { useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import councilTaxpayerSeizureIncomeCancelApi from '../../../../apis/CouncilTaxpayerSeizureIncomeCancelApi';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import { CouncilTaxpayerSeizureIncomeCancel } from '../../../../models/Entities';
import { SeizureIncomeStateType } from '../../../../models/Types';
import alertService from '../../../../services/AlertService';
import styles from './SeizureIncomeCancelComponent.module.scss';

const SeizureIncomeCancelComponent: React.FC<Props> = (props) => {
    const { cancel } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading'>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            const seizureIncomeOrderCancelId = cancel.id;
            const file = await councilTaxpayerSeizureIncomeCancelApi.download(seizureIncomeOrderCancelId!);
            FileSaver.saveAs(file, intl.formatMessage({ id: 'seizureIncome.cancellation.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const state = cancel && cancel.councilTaxpayerSeizureIncome.seizureIncome.state;

    return (
        <Card
            size="small"
            title={<ConstantLabel value={cancel.councilTaxpayerSeizureIncome.seizureIncome.income} prefix="councilTaxpayerSeizureIncome.income." />}
            extra={
                <span className={styles.date}>
                    <FormattedDate value={cancel.audit?.created as any} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
                </span>
            }
            actions={[
                <StateTag value={state} key="state" />,
                <Tooltip title={<FormattedMessage id="button.download" />}>
                    <Button type="text" size="small" icon={<CloudDownloadOutlined key="download" />} onClick={download} loading={loading === 'downloading'} />
                </Tooltip>
            ]}
            className={styles.card}
        >
            <p className={styles.title}>
                <FormattedMessage id="councilTaxpayerSeizureIncome.cancel.title" />
            </p>
        </Card>
    );
};
export default SeizureIncomeCancelComponent;
interface Props {
    cancel: CouncilTaxpayerSeizureIncomeCancel;
}

/**
 * Returns a state tag.
 * @returns the state tag.
 */
const StateTag: React.FC<StateTagProps> = (props) => {
    const { value } = props;

    if (value === 'SENT') {
        return (
            <span className={`${styles.state} ${styles.SENT}`}>
                <ConstantLabel value={value} prefix="councilTaxpayerSeizureIncome.sentState." />
            </span>
        );
    } else if (value === 'FAILED') {
        return (
            <span className={`${styles.state} ${styles.FAILED}`}>
                <ConstantLabel value={value} prefix="councilTaxpayerSeizureIncome.sentState." />
            </span>
        );
    } else {
        return (
            <span className={styles.state}>
                <ConstantLabel value="PENDING" prefix="councilTaxpayerSeizureIncome.sentState." />
            </span>
        );
    }
};
interface StateTagProps {
    value: SeizureIncomeStateType | undefined;
}
