import { ArrowLeftOutlined, CloudDownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tag, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { Dayjs } from 'dayjs';
import FileSaver from 'file-saver';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import seizureIncomeSignApi from '../../apis/SeizureIncomeSignApi';
import ConstantLabel from '../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { Page } from '../../models/Elements';
import { CustomAuth, SeizureIncomeSign } from '../../models/Entities';
import alertService from '../../services/AlertService';
import tableService from '../../services/TableService';
import styles from './SeizureIncomeSignsPage.module.scss';
import SeizureIncomeSignModal from './SeizureIncomeSignModal/SeizureIncomeSignModal';
import rolesService from '../../services/RolesService';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomContext from '../../contexts/CustomContext';

/**
 * Returns the seizure income signs page.
 * @returns the seizure income signs page.
 */

const SeizureIncomeSignsPage: React.FC = () => {
    /***HOOKS */

    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const { council } = useContext(CustomCouncilContext);
    const [filter, setFilter] = useState<Filter>({});
    const [seizureIncomeSignsPage, setSeizureIncomeSignsPage] = useState<Page<SeizureIncomeSign>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('auditCreated');
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [seizureIncomeSignModalVisible, setSeizureIncomeSignModalVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<'loading' | 'downloading'>();

    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const seizureIncomeSignsPage = await seizureIncomeSignApi.list(page, 100, sortField, sortOrder, council.id, filter.searchText);
                    setSeizureIncomeSignsPage(seizureIncomeSignsPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterSeizureIncomeSigns = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    const download = async (seizureIncomeSign: SeizureIncomeSign) => {
        try {
            setLoading('downloading');
            const document = await seizureIncomeSignApi.download(seizureIncomeSign.id!, 'LATEST');
            FileSaver.saveAs(document, intl.formatMessage({ id: 'seizureIncomeSign.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const refresh = async () => {
        try {
            setLoading('loading');

            const seizureIncomeSignsPage = await seizureIncomeSignApi.list(page, 100, sortField, sortOrder, council.id, filter.searchText);
            setSeizureIncomeSignModalVisible(false);
            setSeizureIncomeSignsPage(seizureIncomeSignsPage);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);
    const items = seizureIncomeSignsPage ? seizureIncomeSignsPage.content : [];

    const columns: ColumnsType<SeizureIncomeSign> = [
        {
            title: <FormattedMessage id="seizureIncomeSign.id" />,
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 50,
            sorter: true,
            defaultSortOrder: 'descend',
            render: (value: string, seizureIncomeSign: SeizureIncomeSign) => (
                <Link to={`/councils/${council?.id}/seizure-income-signs/${seizureIncomeSign.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="seizureIncomeSign.income" />,
            dataIndex: 'income',
            key: 'income',
            width: 250,
            sorter: true,
            render: (value: string, seizureIncomeSign: SeizureIncomeSign) => (
                <Link to={`/councils/${council?.id}/seizure-income-signs/${seizureIncomeSign.id}`}>
                    <ConstantLabel value={value} prefix="seizureIncomeSign.income." />
                </Link>
            )
        },
        {
            title: <FormattedMessage id="seizureIncomeSign.treasurer" />,
            dataIndex: ['treasurer', 'fullName'],
            key: 'treasurer',
            sorter: true,
            render: (value: string, seizureIncomeSign: SeizureIncomeSign) => (
                <Link to={`/councils/${council?.id}/seizure-income-signs/${seizureIncomeSign.id}`}>{value}</Link>
            )
        },
        {
            title: <FormattedMessage id="seizureIncomeSign.treasurerSigned" />,
            dataIndex: 'treasurerSigned',
            key: 'treasurerSigned',
            align: 'center',
            width: 250,
            render: (value: Dayjs, seizureIncomeSign: SeizureIncomeSign) => (
                <Link to={`/councils/${council?.id}/seizure-income-signs/${seizureIncomeSign.id}`}>
                    {value ? (
                        <Tooltip title={<FormattedDate value={value as any} dateStyle="medium" timeStyle="short" hour12={false} />}>
                            <Tag color="green">
                                <FormattedMessage id="paymentOrderSign.signed" />
                            </Tag>
                        </Tooltip>
                    ) : (
                        <Tag color="red">
                            <FormattedMessage id="paymentOrderSign.notSigned" />
                        </Tag>
                    )}
                </Link>
            )
        },
        {
            title: <FormattedMessage id="seizureIncomeSign.created" />,
            dataIndex: ['audit', 'created'],
            key: 'auditCreated',
            align: 'center',
            width: 180,
            render: (value: string, seizureIncomeSign: SeizureIncomeSign) =>
                value && (
                    <Link to={`/councils/${council?.id}/seizure-income-signs/${seizureIncomeSign.id}`}>
                        <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
                    </Link>
                )
        },
        {
            key: 'actions',
            width: 150,
            align: 'center',
            render: (seizureIncomeSign: SeizureIncomeSign) => (
                <Tooltip title={<FormattedMessage id="button.download" />}>
                    <CloudDownloadOutlined className={styles.icons} onClick={() => download(seizureIncomeSign)} />
                </Tooltip>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="users.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/seizure-income-signs`, name: <FormattedMessage id="seizureIncomeSigns.title" /> }
            ]}
            council={council}
        >
            <Form form={form} onFinish={filterSeizureIncomeSigns} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'seizureIncomeSigns.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6} className={styles.buttons}>
                        <Space>
                            <Link to={`/councils/${council?.id}`}>
                                <Button size="large" icon={<ArrowLeftOutlined />} />
                            </Link>
                            {isAdmin && (
                                <Tooltip title={<FormattedMessage id="seizureIncomeSigns.new.tooltip" />}>
                                    <Button type="primary" size="large" icon={<PlusOutlined />} onClick={() => setSeizureIncomeSignModalVisible(true)}>
                                        {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                    </Button>
                                </Tooltip>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(seizureIncomeSignsPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
            {seizureIncomeSignModalVisible && council.id && (
                <SeizureIncomeSignModal councilId={council.id} onCancel={() => setSeizureIncomeSignModalVisible(false)} onSave={refresh} />
            )}
        </LayoutComponent>
    );
};
export default SeizureIncomeSignsPage;

interface Filter {
    searchText?: string | undefined;
}
