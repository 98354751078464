import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Row, Space } from 'antd';
import FileSaver from 'file-saver';
import { useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import paymentOrderEventApi from '../../../../apis/PaymentOrderEventApi';
import { ReceivableNotificationReceived } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivableNotificationReceivedComponent.module.scss';
import { DescriptionsProps } from 'antd/lib';

const ReceivableNotificationReceivedComponent = (props: Props) => {
    const { notificationReceived } = props;
    const [loading, setLoading] = useState<'downloading'>();

    /*** HOOKS ***/

    const intl = useIntl();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            const document = await paymentOrderEventApi.download(notificationReceived.paymentOrderEvent.id);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'paymentOrderReceipt.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const items: DescriptionsProps['items'] = [
        {
            key: 'received',
            label: <FormattedMessage id="receivable.notificationReceived.received" />,
            children: notificationReceived.paymentOrderEvent.received && (
                <FormattedDate
                    value={notificationReceived.paymentOrderEvent.received as any}
                    day="2-digit"
                    month="2-digit"
                    year="numeric"
                    hour="2-digit"
                    minute="2-digit"
                />
            )
        }
    ];

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Descriptions layout="horizontal" items={items} size="default" column={4} />
                </Col>
            </Row>
            <Row gutter={[28, 0]} className={styles.buttons}>
                <Col span={24}>
                    <Space>
                        <Button icon={<CloudDownloadOutlined />} onClick={download} loading={loading === 'downloading'}>
                            <FormattedMessage id="button.download" tagName="span" />
                        </Button>
                    </Space>
                </Col>
            </Row>
        </>
    );
};

export default ReceivableNotificationReceivedComponent;

interface Props {
    notificationReceived: ReceivableNotificationReceived;
}
