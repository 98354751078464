import { FormattedDate, FormattedMessage } from 'react-intl';
import styles from './AuditComponent.module.scss';
import { Divider, Space } from 'antd';

const AuditComponent = (props: AuditProps) => {
    const { entity } = props;
    return (
        <div className={styles.audit}>
            <Space size={'small'} split={<Divider type="vertical" />}>
                <p className={styles.paragraph}>
                    {entity.audit?.creator ? (
                        <FormattedMessage
                            id="audit.createdOnBy"
                            values={{
                                date: <FormattedDate value={entity.audit?.created as any} dateStyle="medium" timeStyle="short" hour12={false} />,
                                user: `${entity.audit?.creator.firstName} ${entity.audit?.creator.lastName}`
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="audit.createdOn"
                            values={{
                                date: <FormattedDate value={entity.audit?.created as any} dateStyle="medium" timeStyle="short" hour12={false} />
                            }}
                        />
                    )}
                </p>
                <p className={styles.paragraph}>
                    {entity.audit?.updater ? (
                        <FormattedMessage
                            id="audit.updatedOnBy"
                            values={{
                                date: <FormattedDate value={entity.audit?.updated as any} dateStyle="medium" timeStyle="short" hour12={false} />,
                                user: `${entity.audit?.updater.firstName} ${entity.audit?.updater.lastName}`
                            }}
                        />
                    ) : (
                        <FormattedMessage
                            id="audit.updatedOn"
                            values={{
                                date: <FormattedDate value={entity.audit?.updated as any} dateStyle="medium" timeStyle="short" hour12={false} />
                            }}
                        />
                    )}
                </p>
            </Space>
        </div>
    );
};

export default AuditComponent;

interface AuditProps {
    entity: any;
}
