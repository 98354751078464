import { Tag } from 'antd';
import { StateType } from '../../models/Types';
import ConstantLabel from '../ConstantLabel/ConstantLabel';
import styles from './StateTag.module.scss';

/**
 * Returns a state tag.
 * @returns the state tag.
 */
const StateTag: React.FC<Props> = (props) => {
    const { value } = props;

    if (value === 'PAID') {
        return (
            <Tag color="green" className={styles.text}>
                <ConstantLabel value={value} prefix="receivable.state." />
            </Tag>
        );
    } else if (value === 'CANCELLED' || value === 'OVERPAID') {
        return (
            <Tag color="red" className={styles.text}>
                <ConstantLabel value={value} prefix="receivable.state." />
            </Tag>
        );
    } else if (value === 'INITIALIZED') {
        return (
            <Tag color="blue" className={styles.text}>
                <ConstantLabel value={value} prefix="receivable.state." />
            </Tag>
        );
    } else {
        return (
            <Tag color="orange" className={styles.text}>
                <ConstantLabel value={value} prefix="receivable.state." />
            </Tag>
        );
    }
};
export default StateTag;

interface Props {
    value: StateType | undefined;
}
