import dayjs, { Dayjs } from 'dayjs';

class DateService {
    /**
     * Changes date format to local
     * @param date the date
     * @returns the transformed date
     */

    transformDate = (date: Dayjs | undefined): Dayjs | undefined => {
        return date && dayjs.utc(date).local();
    };
}

const dateService: DateService = new DateService();
export default dateService;
