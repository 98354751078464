import { Col } from 'antd';
import React from 'react';
import PublicLayoutComponent from '../../../components/PublicLayoutComponent/PublicLayoutComponent';
import styles from './PrivacyPolicyPage.module.scss';

/**
 * Returns the privacy policy page.
 * @returns the privacy policy page.
 */
const PrivacyPolicyPage = (): React.ReactElement => {
    /*** HOOKS ***/

    /*** EFFECTS ***/

    /*** METHODS ***/

    /*** VISUAL ***/

    return (
        <PublicLayoutComponent>
            <Col span={24} className={styles.content}>
                <h1>Privacy Policy</h1>
                <div>
                    <h4>Personal Data Protection according to GDPR</h4>
                    <p>
                        Under the current legislation regarding personal data protection, Walfen Technologies SLU reports that personal data collected through
                        the forms available on the website: https://www.taxfen.com/, are included in computerized files for user specific services of Walfen
                        Technologies SLU.
                    </p>
                    <p>
                        The collection and processing of personal data are aimed at maintaining business relationship and performing such tasks as information,
                        training, counseling and other activities.
                    </p>
                    <p>
                        The data will only be transferred to those entities that are necessary for the sole purpose of complying with the purpose stated above.
                    </p>
                    <p>
                        Walfen Technologies SLU will adopt the necessary measures to ensure the security, integrity and confidentiality of data in accordance
                        with the provisions of the EU General Data Protection Regulation (GDPR).
                    </p>
                    <p>
                        User may at any time exercise their rights of access, opposition, rectification, limitation, transfer and cancellation stipulated in
                        Data Protection Regulation. User can exercise these rights free of charge, via email to: info@walfen.com or by post to the address: C/
                        Ángel Bruna, 50, Esc. 1, 2ºa, C.P. 30203 – Cartagena (Murcia).
                    </p>
                    <p>
                        User represents that all information provided by them are true and correct and undertake to keep them updated, communicating the changes
                        to Walfen Technologies SLU.
                    </p>
                    <h4>Purpose of the treatment of personal data</h4> <h5>For what purpose will we treat your personal data?</h5>
                    <p>
                        In Walfen Technologies SLU, we will treat your personal data collected through the Website: https://www.taxfen.com/ for the following
                        purposes:
                    </p>
                    <p>
                        1. In case of contracting the goods and services offered through: https://www.taxfen.com/, to maintain the contractual relationship, as
                        well as the management, administration, information, provision and improvement of the service.
                    </p>
                    <p> 2. To send the requested information through the forms provided at https://www.taxfen.com/. </p>
                    <p>
                        3. To send newsletters, as well as commercial communications of promotions and/or advertising of Walfen Technologies SLU, and of the
                        sector.
                    </p>
                    <p>
                        We remind you that you can object to sending you commercial communications by any means and at any time, by sending an email to the
                        address indicated above.
                    </p>
                    <p>
                        The completion of the fields of these registries is obligatory, making it impossible to meet the expressed purposes if these data are
                        not provided.
                    </p>
                    <h5>For how long are the collected personal data stored?</h5>
                    <p>
                        Personal data provided by you will be kept as long as the commercial relationship is maintained or you do not request its deletion and
                        during the period for which legal liabilities could arise for the services rendered.
                    </p>
                    <h4>Legal basis</h4> <p>Treatment for your data will be done on the following legal basis:</p>
                    <p>
                        1. Request for information and/or contracting the services of Walfen Technologies SLU, whose terms and conditions will be made available
                        to you in any case, prior to a possible contracting.
                    </p>
                    <p>
                        2. Free, specific, informed and unambiguous consent, as we inform you by making available this privacy policy, which after reading the
                        same, if you agree, you can accept by a statement or clear action affirmative, such as the marking of a box provided for this purpose.
                    </p>
                    <p>
                        In case you do not provide us with your information or you do it in an erroneous or incomplete way, we will not be able to meet your
                        request, since it is impossible to provide you with the information requested or to carry out the contracting of the services.
                    </p>
                    <h4>Recipients</h4> <p> Data will not be communicated to any third party outside Walfen Technologies SLU, except legal obligation. </p>
                    <p>
                        As treatment managers, we have hired following service providers, having committed to compliance with regulatory provisions, applicable
                        in terms for data protection, at time for hiring:
                    </p>
                    <h4>Data collected by service users</h4>
                    <p>
                        If user includes files that contain personal data on shared hosting servers, Walfen Technologies SLU is not responsible for the breach
                        by the user of Data Protection Regulation.
                    </p>
                    <h4>Data retention in accordance with LSSI</h4>
                    <p>
                        Walfen Technologies SLU reports that, as a hosting service provider and under the provisions contained on Law 34/2002, of July 11th, on
                        the Information Society Services and Electronic Commerce (LSSI), retained in a maximum period for 12 months essential information to
                        identify the source of data stored and the time when service started.
                    </p>
                    <p>
                        Retention of such data does not affect secret in communications and may only be used within the framework for a criminal investigation
                        or to safeguard public safety, making himself available to the judges and/or courts or the Ministry which thus requires.
                    </p>
                    <p> Data communication to State Forces will be under legal provisions on personal data protection. </p>
                    <h4>Intellectual Property Rights</h4>
                    <p>
                        Walfen Technologies SLU owns all copyrights, intellectual property, industrial, “know how” and any other rights related to the content
                        from the website https://www.taxfen.com/ and the services offered on it, as well as the programs necessary for its implementation and
                        related information.
                    </p>
                    <p>
                        No available any reproduction, publication and/or use for the contents strictly private, full or partial, at the website
                        https://www.taxfen.com/ without a prior written consent.
                    </p>
                    <h4>Software Intellectual Property Rights</h4>
                    <p> You should respect other programs made available by Walfen Technologies SLU, while being free and/or publicly available. </p>
                    <p> Walfen Technologies SLU has exploitation rights and intellectual property for software needed. </p>
                    <p>
                        User does not acquire any right or license by the contracted service, to the software necessary to provide service, or the technical
                        information service trace, except for rights and licenses necessary in the fulfillment at contracted services and only for the duration
                        thereof. In any action that exceeds the contract performance, user will need written permission from Walfen Technologies SLU, being
                        forbidden to user for access, modify, view the configuration, structure and server’s files property of Walfen Technologies SLU, assuming
                        the civil and criminal liability arising from any incident that might involve on servers and security systems as a direct result for a
                        negligence or malicious on his part.
                    </p>
                    <h4>Intellectual property content hosted</h4>
                    <p> Use contrary to intellectual property law services provided by Walfen Technologies SLU and in particular: </p>
                    <p>- Use that is contrary to Spanish laws or which infringes the personal rights.</p>
                    <p>
                        - Publication or transmission any content that, in Walfen Technologies SLU opinion, is violent, obscene, abusive, illegal, racist,
                        xenophobic or defamatory.
                    </p>
                    <p> - Cracks, software serial numbers or any other content that violates intellectual third parties property right. </p>
                    <p>
                        - Collection and/or use personal data from other users without their express consent or contrary to the EU General Data Protection
                        Regulation (GDPR) provisions.
                    </p>
                    <p>- Use a domain mail server and e-mail addresses for sending unsolicited bulk.</p>
                    <p>
                        User has full responsibility in the content from its website, the information transmitted and stored, hypertext links, third party
                        claims and legal actions in reference to intellectual property rights and minors protection.
                    </p>
                    <p>
                        User is responsible regarding laws and regulations in force and rules that have to do with running online service, electronic commerce,
                        copyright, maintain public order, and universal principles at Internet use.
                    </p>
                    <p>
                        User indemnifies to Walfen Technologies SLU for expenses that generate some cause whose responsibility was attributable to user,
                        including fees and legal expenses, even if a court decision is not final.
                    </p>
                    <h4>Information hosted protection</h4>
                    <p>
                        Walfen Technologies SLU backs content hosted on their servers, however not responsible for loss or accidental deletion data by users.
                        Similarly, replacement does not guarantee total data deleted by users, since such data could have been deleted and/or modified during
                        the period since the last backup.
                    </p>
                    <p>
                        Services offered, except specific backup services, do not include replacement for the contents stored in the backups made by Walfen
                        Technologies SLU when this loss is attributable to user; at this case, rate will be determined according to the complexity and volume
                        for the recovery, always previous user acceptance.
                    </p>
                    <p>
                        Replacement for deleted data is only included at cost in service when content loss is due to causes attributable to Walfen Technologies
                        SLU.
                    </p>
                    <h4>Commercial communications</h4>
                    <p>
                        Pursuant to LSSI. Walfen Technologies SLU will not send advertising or promotional communications by email or other means electronic
                        communication that have not been previously requested or expressly authorized by the recipient.
                    </p>
                    <p>
                        In cases where there are some prior contractual relationships, Walfen Technologies SLU is allowed to send commercial communications
                        relating to products or services which are similar to those initially made a contract with the customer.
                    </p>
                    <p>In any case, user can request that you do not get more commercial information through channels customer, upon proof of identity.</p>
                    <h4>Credits</h4>
                    <p>
                        Icons by{' '}
                        <a href="https://icons8.com" target="_blank" rel="noreferrer">
                            <strong>icons8.com</strong>
                        </a>{' '}
                        and
                        <a href="https://linktr.ee/vstudio.io" target="_blank" rel="noreferrer">
                            <strong>Seju</strong>
                        </a>
                    </p>
                </div>
            </Col>
        </PublicLayoutComponent>
    );
};

export default PrivacyPolicyPage;
