class StringService {
    normalize = (text?: string): string => {
        return text
            ? text
                  .normalize('NFD')
                  .replace(/\p{Diacritic}/gu, '')
                  .toLocaleLowerCase()
            : '';
    };

    filterOptions = (inputValue: string, option?: any): boolean => {
        let optionValue: string = option?.children;
        if (option && option.children && option.children.props && option.children.props.id) {
            optionValue = option.children.props.id;
        }
        const optionLabel = stringService.normalize(optionValue);
        const searchText = stringService.normalize(inputValue);

        return optionLabel.includes(searchText);
    };

    search = (value: string | undefined, searchText: string | undefined): boolean => {
        return !searchText || stringService.normalize(value).includes(stringService.normalize(searchText));
    };

    sort = (a: string | undefined, b: string | undefined): number => {
        const textA = a || '';
        const textB = b || '';
        return stringService.normalize(textA).localeCompare(stringService.normalize(textB));
    };

    capitalize = (text: string | undefined): string => {
        return text
            ? text
                  .toLowerCase()
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')
            : '';
    };
}

const stringService: StringService = new StringService();
export default stringService;
