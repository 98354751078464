import { CloudUploadOutlined, DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Col, InputNumber, List, Modal, Row, Upload, message } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { Form, UploadFile } from 'antd/lib';
import { useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import seizureBankPaymentResponseApi from '../../../apis/SeizureBankPaymentResponseApi';
import { CouncilTaxpayerSeizureBankAmountResponse, SeizureBankPaymentResponse, SeizureBankPaymentResponseDraft } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import ConstantLabel from '../../ConstantLabel/ConstantLabel';
import FileSizeComponent from '../../FileSizeComponent/FileSizeComponent';
import styles from './SeizureBankPaymentResponseModal.module.scss';

const SeizureBankPaymentResponseModal = (props: Props) => {
    const { councilId, amountResponse } = props;
    const maxFileSize = 5 * 1024 * 1024;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<'loading' | 'saving' | 'previewing'>();
    const [draft, setDraft] = useState<SeizureBankPaymentResponseDraft>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    // save
    const save = async () => {
        try {
            setLoading('saving');

            const values = await form.validateFields();
            const ctSeizureBankId = amountResponse.councilTaxpayerSeizureBank.id;
            let seizureBankPaymentResponse: SeizureBankPaymentResponse = Object.assign({}, { councilId, ctSeizureBankId }, values);
            const attachment: UploadFile | undefined = files.length > 0 ? files[0] : undefined;
            await seizureBankPaymentResponseApi.create(seizureBankPaymentResponse, attachment);
            await props.onSave();

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // preview matching in uploaded file
    const preview = async () => {
        try {
            setLoading('previewing');

            const values = await form.validateFields();
            const ctSeizureBankId = amountResponse.councilTaxpayerSeizureBank.id;
            let seizureBankPaymentResponse: SeizureBankPaymentResponse = Object.assign({}, { councilId, ctSeizureBankId }, values);
            const attachment: UploadFile | undefined = files.length > 0 ? files[0] : undefined;
            const draft = await seizureBankPaymentResponseApi.createDraft(seizureBankPaymentResponse, attachment);
            setDraft(draft);
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // cancel
    const cancel = async () => {
        props.onCancel();
    };

    // validate file
    const validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    // upload file
    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    //remove file
    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            file: files
        });
        setFiles(files);
    };

    /*** VISUAL ***/

    return (
        <Modal
            title={<FormattedMessage id="seizureBankPaymentResponseModal.title" />}
            open={true}
            onCancel={cancel}
            onOk={save}
            okText={<FormattedMessage id="seizureBankPaymentResponseModal.save" tagName="span" />}
            okButtonProps={{ loading: loading === 'saving', size: 'large' }}
            cancelButtonProps={{ disabled: loading === 'saving', size: 'large' }}
            width={1000}
        >
            <Form form={form} colon={false} layout="vertical">
                <Row gutter={28} className={styles.container}>
                    <Col span={12} lg={8}>
                        <Form.Item
                            label={<FormattedMessage id="seizureBankPaymentResponseModal.amount" />}
                            name="paidAmount"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <InputNumber size="large" controls={false} suffix="€" className="input-number" />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            name="file"
                            valuePropName="files"
                            label={
                                <>
                                    <span>
                                        <FormattedMessage id="seizureBankPaymentResponseModal.upload" />
                                    </span>
                                </>
                            }
                            rules={[
                                {
                                    validator: validateFile,
                                    required: true,
                                    message: <FormattedMessage id="status.mandatory" />
                                }
                            ]}
                            extra={
                                <>
                                    <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                                </>
                            }
                        >
                            <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                                <CloudUploadOutlined /> <FormattedMessage id="button.upload" />
                            </Upload.Dragger>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <List
                            className={styles.files}
                            itemLayout="horizontal"
                            dataSource={files}
                            locale={{ emptyText: <></> }}
                            renderItem={(file) => (
                                <List.Item actions={[<Button icon={<DeleteOutlined />} danger size="large" onClick={removeFile} />]}>
                                    <List.Item.Meta
                                        avatar={<PaperClipOutlined className={styles.icon} />}
                                        title={file.name}
                                        description={<FileSizeComponent value={file.size} />}
                                    />
                                    <Button size="large" onClick={preview} loading={loading === 'previewing'}>
                                        <FormattedMessage id="button.preview" />
                                    </Button>
                                </List.Item>
                            )}
                        />
                    </Col>
                    <Col span={24}>{draft && <SeizureBankPaymentResponseDraftComponent draft={draft} amountResponse={amountResponse} />}</Col>
                </Row>
            </Form>
        </Modal>
    );
};
export default SeizureBankPaymentResponseModal;

interface Props {
    councilId: number;
    amountResponse: CouncilTaxpayerSeizureBankAmountResponse;
    onSave: () => Promise<void>;
    onPay: () => Promise<void>;
    onCancel: () => void;
}

/**
 * Returns a seizure bank payment reponse draft.
 * @returns the seizure bank payment reponse draft.
 */
const SeizureBankPaymentResponseDraftComponent: React.FC<SeizureBankPaymentResponseDraftProps> = (props) => {
    const { draft, amountResponse } = props;
    const { receivablePayments, receivables } = draft;

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <th></th>
                    <th>
                        <FormattedMessage id="seizureBankPaymentResponseModal.preview.amount.principal" />
                    </th>
                    <th>
                        <FormattedMessage id="seizureBankPaymentResponseModal.preview.amount.surcharge" />
                    </th>
                    <th>
                        <FormattedMessage id="seizureBankPaymentResponseModal.preview.amount.interest" />
                    </th>
                    <th>
                        <FormattedMessage id="seizureBankPaymentResponseModal.preview.amount.costs" />
                    </th>
                    <th>
                        <FormattedMessage id="seizureBankPaymentResponseModal.preview.amount.total" />
                    </th>
                </tr>
            </thead>
            <tbody>
                {receivables.map((receivable) => {
                    const receivablePayment = receivablePayments.find((rp) => rp.receivableId === receivable.id)!;
                    return (
                        <>
                            <tr className={styles.subheader}>
                                <td colSpan={6}>
                                    {receivable.id} - <ConstantLabel value={receivable.tax} prefix="receivable.tax." />
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.left}>
                                    <FormattedMessage id="seizureBankPaymentResponseModal.preview.initialAmount" />
                                </td>
                                <td>
                                    <FormattedNumber
                                        value={receivablePayment.amount!.principal + receivable!.amount!.principal}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </td>
                                <td>
                                    <FormattedNumber
                                        value={receivablePayment.amount!.surcharge + receivable!.amount!.surcharge}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </td>
                                <td>
                                    <FormattedNumber
                                        value={receivablePayment.amount!.interest + receivable!.amount!.interest}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </td>
                                <td>
                                    <FormattedNumber
                                        value={amountResponse.councilTaxpayerSeizureBank.councilTaxpayer.costs}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </td>
                                <td>
                                    <strong>
                                        <FormattedNumber
                                            value={receivablePayment.amount!.total + receivable!.amount!.total}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.left}>
                                    <FormattedMessage id="seizureBankPaymentResponseModal.preview.paidAmount" />
                                </td>
                                <td>
                                    <FormattedNumber
                                        value={receivablePayment.amount!.principal}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </td>
                                <td>
                                    <FormattedNumber
                                        value={receivablePayment.amount!.surcharge}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </td>
                                <td>
                                    <FormattedNumber
                                        value={receivablePayment.amount!.interest}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </td>
                                <td>
                                    <FormattedNumber
                                        value={amountResponse.councilTaxpayerSeizureBank.councilTaxpayer.costs}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </td>
                                <td>
                                    <strong>
                                        <FormattedNumber
                                            value={receivablePayment.amount!.total}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    </strong>
                                </td>
                            </tr>
                            <tr>
                                <td className={styles.left}>
                                    <FormattedMessage id="seizureBankPaymentResponseModal.preview.remainingAmount" />
                                </td>
                                <td>
                                    <FormattedNumber
                                        value={receivable!.amount!.principal}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </td>
                                <td>
                                    <FormattedNumber
                                        value={receivable!.amount!.surcharge}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </td>
                                <td>
                                    <FormattedNumber
                                        value={receivable!.amount!.interest}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </td>
                                <td>
                                    <FormattedNumber
                                        value={amountResponse.councilTaxpayerSeizureBank.councilTaxpayer.costs}
                                        minimumFractionDigits={2}
                                        maximumFractionDigits={2}
                                        style={'currency' as any}
                                        currency="EUR"
                                    />
                                </td>
                                <td>
                                    <strong>
                                        <FormattedNumber
                                            value={receivable!.amount!.total}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    </strong>
                                </td>
                            </tr>
                        </>
                    );
                })}
            </tbody>
        </table>
    );
};
interface SeizureBankPaymentResponseDraftProps {
    draft: SeizureBankPaymentResponseDraft;
    amountResponse: CouncilTaxpayerSeizureBankAmountResponse;
}
