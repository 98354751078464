import { ArrowLeftOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { App, Button, Col, Form, Input, Row, Select, message } from 'antd';
import { Space } from 'antd/lib';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import bankApi from '../../../apis/BankApi';
import AddressComponent from '../../../components/AddressComponent/AddressComponent';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomContext from '../../../contexts/CustomContext';
import CustomCouncilContext from '../../../contexts/CustomCouncilContext';
import { Bank } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import rolesService from '../../../services/RolesService';
import stringService from '../../../services/StringService';

/**
 * Returns the council bank page.
 * @returns the council bank page.
 */
const CouncilBankPage: React.FC = () => {
    /***HOOKS***/
    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { council } = useContext(CustomCouncilContext);
    const { auth } = useContext(CustomContext);
    const [loading, setLoading] = useState<'initializing' | 'loading' | 'deleting'>();
    const [banks, setBanks] = useState<Bank[]>([]);
    const [councilBank, setCouncilBank] = useState<Bank>();
    const params = useParams<ParamsType>();
    const { modal } = App.useApp();

    /*** EFFECTS ***/
    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('initializing');
                    let councilBank: Bank;
                    if (params.id === 'new') {
                        councilBank = { councilId: council.id, status: 'ENABLED', online: false };
                    } else {
                        councilBank = await bankApi.get(+params.id!);
                    }
                    const banks = await bankApi.list(0, 1000, 'name', true, 'ENABLED', undefined, true);

                    form.setFieldsValue(councilBank);
                    setCouncilBank(councilBank);
                    setBanks(banks.content);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [council, form, intl, params.id]);

    /*** METHODS ***/
    const save = async (values: any) => {
        try {
            setLoading('loading');

            let updatedCouncilBank: Bank = Object.assign({}, councilBank, values);
            if (updatedCouncilBank.id) {
                updatedCouncilBank = await bankApi.update(updatedCouncilBank);
            } else {
                updatedCouncilBank = await bankApi.create(updatedCouncilBank);
            }
            setCouncilBank(updatedCouncilBank);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error) {
            alertService.displayError(error, intl, [{ status: 409, message: 'councilBank.duplicate' }]);
        } finally {
            setLoading(undefined);
        }
    };

    const remove = async () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'councilBank.deleteModal.title' }),
            okButtonProps: { loading: loading === 'deleting' },
            onOk: async () => {
                try {
                    if (councilBank && councilBank.councilId && councilBank.id) {
                        setLoading('deleting');
                        await bankApi.delete(councilBank.id);
                        message.success(intl.formatMessage({ id: 'status.deleted' }));
                        navigate(`/councils/${councilBank?.councilId}/banks`);
                    }
                } catch (error) {
                    alertService.displayError(error, intl);
                } finally {
                    setLoading(undefined);
                }
            }
        });
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);
    const bankOptions = banks.map((bank) => (
        <Select.Option key={bank.id} value={bank.id}>
            {bank.name}
        </Select.Option>
    ));

    return (
        <LayoutComponent
            title={<FormattedMessage id="councilBanks.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/banks`, name: <FormattedMessage id="councilBanks.title" /> },
                {
                    path: `/councils/${council?.id}/banks/${councilBank?.id}`,
                    name: councilBank && councilBank.id && `${councilBank?.name}`
                }
            ]}
            council={council}
        >
            <Form form={form} onFinish={save} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col xs={24} md={12}>
                        <Form.Item
                            label={<FormattedMessage id="bank.name" />}
                            name="name"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Form.Item label={<FormattedMessage id="councilBank.bank" />} name="parentId">
                            <Select
                                size="large"
                                allowClear
                                showSearch
                                filterOption={stringService.filterOptions}
                                disabled={(!!councilBank && !!councilBank.id) || !isAdmin}
                            >
                                {bankOptions}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={8}>
                        <Form.Item label={<FormattedMessage id="bank.parentEntity" />} name="parentEntity">
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item label={<FormattedMessage id="bank.euCode" />} name="euCode">
                            <Input size="large" maxLength={100} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={6}>
                        <Form.Item label={<FormattedMessage id="bank.lei" />} name="lei">
                            <Input size="large" maxLength={200} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={2}>
                        <Form.Item
                            label={<FormattedMessage id="bank.mode" />}
                            name="mode"
                            rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                        >
                            <Input size="large" maxLength={10} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={4}>
                        <Form.Item label={<FormattedMessage id="bank.supervisorCode" />} name="supervisorCode">
                            <Input size="large" maxLength={100} />
                        </Form.Item>
                    </Col>
                </Row>
                <AddressComponent field={'address'} hideHeader disabled={!isAdmin} />
                <Row gutter={[28, 0]} className="buttons">
                    <Col span={16}>
                        <Space>
                            {isAdmin && (
                                <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                                    <FormattedMessage id="button.save" tagName="span" />
                                </Button>
                            )}
                            {councilBank?.id !== undefined && isAdmin && (
                                <Button type="primary" danger size="large" onClick={remove} icon={<DeleteOutlined />}>
                                    {desktop && <FormattedMessage id="button.delete" tagName="span" />}
                                </Button>
                            )}
                            <Link to={`/councils/${councilBank?.councilId}/banks`}>
                                <Button size="large" icon={<ArrowLeftOutlined />}>
                                    {desktop && <FormattedMessage id="button.back" tagName="span" />}
                                </Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </LayoutComponent>
    );
};

export default CouncilBankPage;
type ParamsType = { id: string };
