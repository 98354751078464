import { Page } from '../models/Elements';
import { PaymentOrder, PaymentOrderCreation, PaymentOrderDraft } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class PaymentOrderApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId?: number,
        councilTaxpayerId?: number,
        searchText?: string
    ): Promise<Page<PaymentOrder>> => {
        const response = await adminApiAxios.get<Page<PaymentOrder>>('/payment-orders', {
            params: { page, size, sortField, sortOrder, councilId, councilTaxpayerId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<PaymentOrder> => {
        const response = await adminApiAxios.get<PaymentOrder>(`/payment-orders/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (paymentOrderCreation: PaymentOrderCreation): Promise<PaymentOrder[]> => {
        const response = await adminApiAxios.post<PaymentOrder[]>('/payment-orders', paymentOrderCreation);
        response.data.forEach((paymentOrder) => this.loadDates(paymentOrder));

        return response.data;
    };

    createDraft = async (paymentOrderCreation: PaymentOrderCreation): Promise<PaymentOrderDraft> => {
        const response = await adminApiAxios.post<PaymentOrderDraft>('/payment-orders/draft', paymentOrderCreation);
        this.loadDraftDates(response.data);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/payment-orders/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    downloadDraft = async (councilTaxpayerId: number, clone?: boolean): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(`/payment-orders/draft/file`, {}, { responseType: 'blob', params: { councilTaxpayerId, clone } });

        return response.data;
    };

    /**
     * Loads the dates of a paymentOrder.
     * @param paymentOrder - the payment order
     */
    private loadDates(paymentOrder: PaymentOrder) {
        paymentOrder.audit!.created = dateService.transformDate(paymentOrder.audit!.created)!;
        paymentOrder.audit!.updated = dateService.transformDate(paymentOrder.audit!.updated)!;
    }

    /**
     * Loads the dates of the payment order  draft.
     * @param paymentOrderDraft - the payment order  draft
     */
    private loadDraftDates(paymentOrderDraft: PaymentOrderDraft) {
        const { paymentOrders, receivableNotificationBuilds } = paymentOrderDraft;
        paymentOrders.forEach((paymentOrder) => {
            this.loadDates(paymentOrder);
        });

        receivableNotificationBuilds.forEach((receivableNotificationBuild) => {
            receivableNotificationBuild.audit!.created = dateService.transformDate(receivableNotificationBuild.audit!.created)!;
            receivableNotificationBuild.audit!.updated = dateService.transformDate(receivableNotificationBuild.audit!.updated)!;
        });
    }
}

const paymentOrderApi: PaymentOrderApi = new PaymentOrderApi();
export default paymentOrderApi;
