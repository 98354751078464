/**
 * The council taxpayer component with the properties information.
 * @param props the props
 * @returns the council taxpayer properties component
 */
const CouncilTaxpayerPropertiesComponent: React.FC = () => {
    /*** HOOKS ***/

    /*** VISUAL ***/

    return <p>Bienes inmuebles</p>;
};
export default CouncilTaxpayerPropertiesComponent;
