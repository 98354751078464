import { ColumnsType } from 'antd/es/table';
import { Table } from 'antd/lib';
import { Dayjs } from 'dayjs';
import { useEffect, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import billingApi from '../../../apis/BillingApi';
import { BillingPaymentsByCouncilCollectionAccount, BillingPaymentsCouncilCollectionAccount } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './BillingPaymentsByCouncilCollectionAccountComponent.module.scss';
import { Button, Tooltip } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import FileSaver from 'file-saver';

/**
 *
 * @param props the props
 * @returns the billing receivable payments component
 */
const BillingPaymentsByCouncilCollectionAccountComponent: React.FC<Props> = (props) => {
    const { councilId, startDate, endDate } = props;

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading' | 'downloading'>();
    const [paymentsByCouncilCollectionAccount, setPaymentsByCouncilCollectionAccount] = useState<BillingPaymentsByCouncilCollectionAccount>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const paymentsByCouncilCollectionAccount = await billingApi.getPaymentCollectionAccounts(councilId, startDate, endDate);
                setPaymentsByCouncilCollectionAccount(paymentsByCouncilCollectionAccount);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, endDate, intl, startDate]);

    /*** HOOKS ***/

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');

            const document = await billingApi.downloadPaymentCollectionAccounts(councilId, startDate, endDate);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'billingPaymentsCouncilCollectionAccount.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const columns: ColumnsType<BillingPaymentsCouncilCollectionAccount> = [
        {
            title: <FormattedMessage id="billingPaymentsCouncilCollectionAccount.councilCollectionAccountBankName" />,
            dataIndex: 'councilCollectionAccountBankName',
            key: 'councilCollectionAccountBankName',
            align: 'center',
            width: 20
        },
        {
            title: <FormattedMessage id="billingPaymentsCouncilCollectionAccount.councilCollectionAccountIban" />,
            dataIndex: 'councilCollectionAccountIban',
            key: 'councilCollectionAccountIban',
            align: 'center',
            width: 20
        },
        {
            title: <FormattedMessage id="billingPaymentsCouncilCollectionAccount.amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'amountPrincipal',
            align: 'center',
            width: 15,
            render: (value: number, billingPaymentsCouncilCollectionAccount: BillingPaymentsCouncilCollectionAccount) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsCouncilCollectionAccount.amount.surcharge" />,
            dataIndex: ['amount', 'surcharge'],
            key: 'amountSurcharge',
            align: 'center',
            width: 15,
            render: (value: number, billingPaymentsCouncilCollectionAccount: BillingPaymentsCouncilCollectionAccount) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsCouncilCollectionAccount.amount.interest" />,
            dataIndex: ['amount', 'interest'],
            key: 'amountInterest',
            align: 'center',
            width: 15,
            render: (value: number, billingPaymentsCouncilCollectionAccount: BillingPaymentsCouncilCollectionAccount) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },

        {
            title: <FormattedMessage id="billingPaymentsCouncilCollectionAccount.costs" />,
            dataIndex: 'costs',
            key: 'costs',
            align: 'center',
            width: 15,
            render: (value: number, billingPaymentsCouncilCollectionAccount: BillingPaymentsCouncilCollectionAccount) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsCouncilCollectionAccount.total" />,
            dataIndex: 'total',
            key: 'total',
            align: 'center',
            width: 15,
            render: (value: number, billingPaymentsCouncilCollectionAccount: BillingPaymentsCouncilCollectionAccount) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="billingPaymentsCouncilCollectionAccount.count" />,
            dataIndex: 'count',
            key: 'count',
            align: 'center',
            width: 15
        }
    ];

    const billingPaymentsCouncilCollectionAccounts = paymentsByCouncilCollectionAccount?.billingPaymentsCouncilCollectionAccounts
        ? paymentsByCouncilCollectionAccount?.billingPaymentsCouncilCollectionAccounts
        : [];
    return (
        <div className={styles.container}>
            <Tooltip title={<FormattedMessage id="button.download" />}>
                <Button size="large" icon={<FileExcelOutlined />} onClick={download} loading={loading === 'downloading'}>
                    <FormattedMessage id="button.download" tagName="span" />
                </Button>
            </Tooltip>
            <Table
                className={styles.table}
                dataSource={billingPaymentsCouncilCollectionAccounts}
                columns={columns}
                pagination={false}
                rowKey="id"
                showSorterTooltip={false}
                loading={loading === 'loading'}
                summary={(pageData) => {
                    return (
                        <>
                            <Table.Summary.Row className={styles.summary}>
                                <Table.Summary.Cell index={0} align="center">
                                    <FormattedMessage id="billingReceivablePayment.total" />
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} />
                                <Table.Summary.Cell index={2} align="center">
                                    {paymentsByCouncilCollectionAccount && paymentsByCouncilCollectionAccount.amount && (
                                        <FormattedNumber
                                            value={paymentsByCouncilCollectionAccount!.amount.principal}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3} align="center">
                                    {paymentsByCouncilCollectionAccount && paymentsByCouncilCollectionAccount.amount && (
                                        <FormattedNumber
                                            value={paymentsByCouncilCollectionAccount!.amount.surcharge}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={4} align="center">
                                    {paymentsByCouncilCollectionAccount && paymentsByCouncilCollectionAccount.amount && (
                                        <FormattedNumber
                                            value={paymentsByCouncilCollectionAccount!.amount.interest}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={5} align="center">
                                    {paymentsByCouncilCollectionAccount && paymentsByCouncilCollectionAccount.costs && (
                                        <FormattedNumber
                                            value={paymentsByCouncilCollectionAccount!.costs}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={6} align="center">
                                    {paymentsByCouncilCollectionAccount && paymentsByCouncilCollectionAccount.total && (
                                        <FormattedNumber
                                            value={paymentsByCouncilCollectionAccount!.total}
                                            minimumFractionDigits={2}
                                            maximumFractionDigits={2}
                                            style={'currency' as any}
                                            currency="EUR"
                                        />
                                    )}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={7} align="center">
                                    {paymentsByCouncilCollectionAccount && paymentsByCouncilCollectionAccount.count && (
                                        <FormattedNumber value={paymentsByCouncilCollectionAccount!.count} />
                                    )}
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    );
                }}
            />
        </div>
    );
};
export default BillingPaymentsByCouncilCollectionAccountComponent;

interface Props {
    councilId: number;
    startDate: Dayjs;
    endDate: Dayjs;
}
