import { CloudUploadOutlined, DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, List, Modal, Row, Space, Upload, message } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { UploadFile } from 'antd/lib';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './SeizureIncomeUpdateModal.module.scss';
import { SeizureIncome, SeizureIncomeResponseDraft } from '../../../models/Entities';
import seizureIncomeApi from '../../../apis/SeizureIncomeApi';
import alertService from '../../../services/AlertService';
import FileSizeComponent from '../../../components/FileSizeComponent/FileSizeComponent';

const SeizureIncomeUpdateModal: React.FC<Props> = (props) => {
    const { seizureIncomeId } = props;
    const maxFileSize = 5 * 1024 * 1024;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<'loading' | 'saving' | 'creatingDraft'>();
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [seizureIncome, setSeizureIncome] = useState<SeizureIncome>();
    const [responseDraft, setResponseDraft] = useState<SeizureIncomeResponseDraft>();

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const seizureIncome = await seizureIncomeApi.get(seizureIncomeId);
                setSeizureIncome(seizureIncome);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, seizureIncomeId, form]);

    /*** METHODS ***/

    const createDraft = async () => {
        try {
            setLoading('creatingDraft');

            await form.validateFields();
            if (seizureIncome) {
                const attachment: UploadFile | undefined = files.length > 0 && files[0] instanceof File ? files[0] : undefined;
                const responseDraft = await seizureIncomeApi.createDraftResponse(seizureIncome, attachment);
                setResponseDraft(responseDraft);
            }
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const save = async () => {
        try {
            if (seizureIncome && files.length > 0) {
                setLoading('saving');

                const attachment: UploadFile | undefined = files.length > 0 && files[0] instanceof File ? files[0] : undefined;
                await seizureIncomeApi.update(seizureIncome, attachment);
                props.onSave();

                message.success(intl.formatMessage({ id: 'status.saved' }));
            }
        } catch (error: any) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = async () => {
        props.onCancel();
    };

    const validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && value.responseFile && !isFileSizeValid(value.responseFile)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            bulletinFile: files
        });
        setFiles(files);
    };

    /*** VISUAL ***/

    return (
        <Modal
            title={<FormattedMessage id="seizureIncomeUpdateModal.title" />}
            open={true}
            width={1000}
            destroyOnClose
            onCancel={cancel}
            footer={[
                <Button key="cancel" size="large" onClick={cancel} disabled={loading === 'saving'}>
                    <FormattedMessage id="button.cancel" tagName="span" />
                </Button>,
                <Button key="preview" size="large" onClick={createDraft} loading={loading === 'creatingDraft'} disabled={!seizureIncome}>
                    <FormattedMessage id="button.preview" tagName="span" />
                </Button>,
                <Button key="ok" htmlType="submit" size="large" type="primary" loading={loading === 'saving'} disabled={!seizureIncome}>
                    <FormattedMessage id="button.save" tagName="span" />
                </Button>
            ]}
            modalRender={(dom) => (
                <Form form={form} colon={false} layout="vertical" onFinish={save}>
                    {dom}
                </Form>
            )}
        >
            <Row gutter={[28, 0]} className={styles.title}></Row>
            <Row gutter={[28, 0]}>
                <Col span={24}>
                    <Form.Item
                        name="responseFile"
                        valuePropName="files"
                        label={
                            <>
                                <span>
                                    <FormattedMessage id="seizureIncomeUpdateModal.upload" />
                                </span>
                            </>
                        }
                        rules={[
                            {
                                validator: validateFile
                            },
                            { required: true, message: <FormattedMessage id="status.mandatory" /> }
                        ]}
                        extra={
                            <>
                                <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                            </>
                        }
                    >
                        <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                            <CloudUploadOutlined /> <FormattedMessage id="button.upload" />
                        </Upload.Dragger>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <List
                        itemLayout="horizontal"
                        dataSource={files}
                        locale={{ emptyText: <></> }}
                        renderItem={(file) => (
                            <List.Item actions={[<Button icon={<DeleteOutlined />} danger size="large" onClick={removeFile} />]}>
                                <List.Item.Meta avatar={<PaperClipOutlined />} title={file.name} description={<FileSizeComponent value={file.size} />} />
                            </List.Item>
                        )}
                    />
                </Col>
                {responseDraft && (
                    <>
                        <Col span={24}>
                            <Divider children={<FormattedMessage id="seizureIncomeUpdateModal.draft" />} orientation="left" />
                        </Col>
                        <Col span={24}>
                            <List
                                itemLayout="horizontal"
                                dataSource={responseDraft?.ctSeizureIncomeResponses}
                                renderItem={(ctsir) => (
                                    <List.Item>
                                        <List.Item.Meta
                                            title={ctsir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.fullName}
                                            description={
                                                <Space split="|">
                                                    <FormattedMessage
                                                        id="seizureIncomeUpdateModal.draft.taxpayer.id"
                                                        values={{
                                                            id: ctsir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.id
                                                        }}
                                                    />
                                                    <FormattedMessage
                                                        id="seizureIncomeUpdateModal.draft.taxpayer.identifier"
                                                        values={{
                                                            identifier: ctsir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.identifier
                                                        }}
                                                    />
                                                    <span>{ctsir.companyName}</span>
                                                </Space>
                                            }
                                        />
                                    </List.Item>
                                )}
                            />
                        </Col>
                    </>
                )}
            </Row>
        </Modal>
    );
};
export default SeizureIncomeUpdateModal;

interface Props {
    seizureIncomeId: number;
    onSave: () => void;
    onCancel: () => void;
}
