import { TablePaginationConfig } from 'antd/lib/table';
import { Page } from '../models/Elements';

class TableService {
    readonly pageSize = 100;

    createPagination = (pageItems?: Page<any>, options?: { position?: TablePaginationPosition[]; pageSize?: number }): TablePaginationConfig => {
        const pageSize = options?.pageSize || this.pageSize;
        return {
            current: pageItems ? pageItems.number + 1 : 1,
            total: pageItems && pageItems.totalElements > 0 ? pageItems.totalElements : pageSize,
            pageSize: pageSize,
            showSizeChanger: false,
            size: 'default',
            hideOnSinglePage: false,
            position: options?.position || ['bottomRight']
        };
    };

    createMemoryPagination = (items: any[], position?: TablePaginationPosition[]): TablePaginationConfig => {
        return {
            total: items && items.length > 0 ? items.length : this.pageSize,
            pageSize: this.pageSize,
            showSizeChanger: false,
            size: 'default',
            hideOnSinglePage: false,
            position: position || ['bottomRight']
        };
    };
}

const tableService: TableService = new TableService();
export default tableService;

type TablePaginationPosition = 'topLeft' | 'topCenter' | 'topRight' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';
