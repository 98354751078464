import { CouncilTaxpayerSeizureIncomeCancel } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class CouncilTaxpayerSeizureIncomeCancelApi {
    create = async (ctSeizureBankCancel: CouncilTaxpayerSeizureIncomeCancel, file: any): Promise<CouncilTaxpayerSeizureIncomeCancel> => {
        const formData: FormData = this.buildFormData(ctSeizureBankCancel, file);
        const response = await adminApiAxios.post<CouncilTaxpayerSeizureIncomeCancel>('/council-taxpayer-seizure-income-cancels', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    downloadDraft = async (ctSeizureBankCancel: CouncilTaxpayerSeizureIncomeCancel): Promise<Blob> => {
        const formData: FormData = this.buildFormData(ctSeizureBankCancel, undefined);
        const response = await adminApiAxios.post<Blob>('/council-taxpayer-seizure-income-cancels/draft/file/company', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob'
        });

        return response.data;
    };

    downloadAttachment = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(`/council-taxpayer-seizure-income-cancels/{id}/file`, {}, { responseType: 'blob', params: { id } });

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/council-taxpayer-seizure-income-cancels/${id}/file/company`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Builds a form data.
     * @param councilTaxpayerSeizureIncome
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (councilTaxpayerSeizureIncomeCancel: CouncilTaxpayerSeizureIncomeCancel, file?: File) => {
        const formData: FormData = new FormData();
        councilTaxpayerSeizureIncomeCancel.id && formData.append('id', councilTaxpayerSeizureIncomeCancel.id.toString());
        councilTaxpayerSeizureIncomeCancel.councilTaxpayerSeizureIncome.id &&
            formData.append('councilTaxpayerSeizureIncome.id', councilTaxpayerSeizureIncomeCancel.councilTaxpayerSeizureIncome.id.toString());
        councilTaxpayerSeizureIncomeCancel.phase && formData.append('phase', councilTaxpayerSeizureIncomeCancel.phase.toString());
        councilTaxpayerSeizureIncomeCancel.remarks && formData.append('remarks', councilTaxpayerSeizureIncomeCancel.remarks.toString());
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of the council taxpayer seizure income cancel request
     * @param councilTaxpayerSeizureIncomeCancel - the council taxpayer seizure income cancel request
     */
    private loadDates(councilTaxpayerSeizureIncomeCancel: CouncilTaxpayerSeizureIncomeCancel) {
        councilTaxpayerSeizureIncomeCancel.audit.created = dateService.transformDate(councilTaxpayerSeizureIncomeCancel.audit.created)!;
        councilTaxpayerSeizureIncomeCancel.audit.updated = dateService.transformDate(councilTaxpayerSeizureIncomeCancel.audit.updated)!;
    }
}

const councilTaxpayerSeizureIncomeCancelApi: CouncilTaxpayerSeizureIncomeCancelApi = new CouncilTaxpayerSeizureIncomeCancelApi();
export default councilTaxpayerSeizureIncomeCancelApi;
