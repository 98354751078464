import { ArrowLeftOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import FileSaver from 'file-saver';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import paymentLetterApi from '../../apis/PaymentLetterApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { Page } from '../../models/Elements';
import { PaymentLetter } from '../../models/Entities';
import alertService from '../../services/AlertService';
import tableService from '../../services/TableService';
import styles from './PaymentLettersPage.module.scss';

/**
 * Returns the payment letters page.
 * @returns the payment letters page.
 */

const PaymentLettersPage: React.FC = () => {
    /***HOOKS */

    const intl = useIntl();
    const [form] = Form.useForm();

    const { council } = useContext(CustomCouncilContext);
    const [filter, setFilter] = useState<Filter>({});
    const [paymentLettersPage, setPaymentLettersPage] = useState<Page<PaymentLetter>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('auditCreated');
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [loading, setLoading] = useState<'loading' | 'downloading'>();

    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const paymentLettersPage = await paymentLetterApi.list(page, 50, sortField, sortOrder, council.id, undefined, filter.searchText);
                    setPaymentLettersPage(paymentLettersPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterPaymentLetters = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    const download = async (paymentLetter: PaymentLetter) => {
        try {
            setLoading('downloading');
            const document = await paymentLetterApi.download(paymentLetter.id);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'paymentLetter.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/
    const items = paymentLettersPage ? paymentLettersPage.content : [];
    const columns: ColumnsType<PaymentLetter> = [
        {
            title: <FormattedMessage id="paymentLetter.taxpayer.name" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'fullName'],
            key: 'fullName'
        },
        {
            title: <FormattedMessage id="paymentLetter.paymentBankDetails.reference" />,
            dataIndex: ['paymentBankDetails', 'reference'],
            key: 'reference',
            align: 'center',
            width: 160
        },
        {
            title: <FormattedMessage id="paymentLetter.paymentBankDetails.identifier" />,
            dataIndex: ['paymentBankDetails', 'identifier'],
            key: 'identifier',
            align: 'center',
            width: 100
        },
        {
            title: <FormattedMessage id="paymentLetter.taxpayer.street" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'address', 'street'],
            key: 'street',
            width: 200
        },
        {
            title: <FormattedMessage id="paymentLetter.taxpayer.city" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'address', 'city'],
            key: 'city',
            width: 160
        },
        {
            title: <FormattedMessage id="paymentLetter.taxpayer.identifier" />,
            dataIndex: ['councilTaxpayer', 'taxpayer', 'identifier'],
            key: 'identifier',
            align: 'center',
            width: 80
        },

        {
            title: <FormattedMessage id="paymentLetter.created" />,
            dataIndex: ['audit', 'created'],
            key: 'auditCreated',
            align: 'center',
            width: 140,
            render: (value: any, paymentLetter: PaymentLetter) => <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" />
        },
        {
            key: 'actions',
            width: 140,
            align: 'center',
            render: (paymentOrder: PaymentLetter) => (
                <Space size="small">
                    <Tooltip title={<FormattedMessage id="button.download" />}>
                        <Button icon={<CloudDownloadOutlined />} className={styles.icon} onClick={() => download(paymentOrder)} type="link" />
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="users.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/payment-letters`, name: <FormattedMessage id="paymentLetters.title" /> }
            ]}
            council={council}
        >
            <Form form={form} onFinish={filterPaymentLetters} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'paymentLetters.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6} className={styles.buttons}>
                        <Space>
                            <Link to={`/councils/${council?.id}`}>
                                <Button size="large" icon={<ArrowLeftOutlined />} />
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(paymentLettersPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default PaymentLettersPage;

interface Filter {
    searchText?: string | undefined;
}
