import { Button, Col, Form, Row, Space, Table, TablePaginationConfig, Tooltip } from 'antd';
import { CloudDownloadOutlined, FormOutlined, SendOutlined } from '@ant-design/icons';
import Search from 'antd/es/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import paymentOrderApi from '../../../../apis/PaymentOrderApi';
import ReceptionStateTag from '../../../../components/ReceptionStateTag/ReceptionStateTag';
import CustomCouncilContext from '../../../../contexts/CustomCouncilContext';
import CustomCouncilTaxpayerContext from '../../../../contexts/CustomCouncilTaxpayerContext';
import { PaymentOrder, Receivable, ReceivableFilter } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './CouncilTaxpayerPaymentOrdersComponent.module.scss';
import FileSaver from 'file-saver';
import PaymentOrderEventModal from '../../../../components/PaymentOrderEventModal/PaymentOrderEventModal';
import { ReceptionStateType } from '../../../../models/Types';
import PaymentOrderModal from '../../../../components/ReceivableActionsMenu/PaymentOrderModal/PaymentOrderModal';

/**
 * The council taxpayer payment orders component with the payment orders information.
 * @param props the props
 * @returns council taxpayer payment orders component
 */
const CouncilTaxpayerPaymentOrdersComponent: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm<ReceivableFilter>();
    const { council } = useContext(CustomCouncilContext);
    const { councilTaxpayer } = useContext(CustomCouncilTaxpayerContext);
    const [loading, setLoading] = useState<'loading' | 'saving' | 'downloading'>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('id');
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [filter, setFilter] = useState<Filter>({});
    const [paymentOrders, setPaymentOrders] = useState<PaymentOrder[]>();
    const [paymentOrder, setPaymentOrder] = useState<PaymentOrder>();
    const [paymentOrderEventModalVisible, setPaymentOrderEventModalVisible] = useState<boolean>(false);
    const [paymentOrderResendModalVisible, setPaymentOrderResendModalVisible] = useState<boolean>(false);

    /*** EFFECTS ***/

    //load payment orders

    useEffect(() => {
        const init = async () => {
            try {
                if (council && councilTaxpayer) {
                    setLoading('loading');
                    const paymentOrdersPage = await paymentOrderApi.list(
                        page,
                        1000000,
                        sortField,
                        sortOrder,
                        council.id,
                        councilTaxpayer.id,
                        filter.searchText
                    );
                    setPaymentOrders(paymentOrdersPage.content);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, sortField, sortOrder, filter, page, council.id, councilTaxpayer, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterPaymentOrders = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    const download = async (paymentOrder: PaymentOrder) => {
        try {
            setLoading('downloading');
            const document = await paymentOrderApi.download(paymentOrder.id);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'paymentOrder.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const save = async () => {
        try {
            setLoading('loading');
            setPaymentOrderEventModalVisible(false);
            const paymentOrdersPage = await paymentOrderApi.list(page, 1000000, sortField, sortOrder, council.id, councilTaxpayer!.id, filter.searchText);
            setPaymentOrders(paymentOrdersPage.content);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const showPaymentOrderEventModal = async (paymentOrder: PaymentOrder) => {
        setPaymentOrder(paymentOrder);
        setPaymentOrderEventModalVisible(true);
    };

    const showPaymentOrderResendModal = async (paymentOrder: PaymentOrder) => {
        setPaymentOrder(paymentOrder);
        setPaymentOrderResendModalVisible(true);
    };

    /*** VISUAL ***/

    const items = paymentOrders ? paymentOrders : [];
    const columns: ColumnsType<PaymentOrder> = [
        {
            title: <FormattedMessage id="paymentOrder.id" />,
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 50,
            sorter: true,
            defaultSortOrder: 'descend'
        },
        {
            title: <FormattedMessage id="paymentOrder.receivables" />,
            dataIndex: 'receivables',
            key: 'receivables',
            render: (receivables: Receivable[]) => receivables.map((r) => r.id).join(', ')
        },
        {
            title: <FormattedMessage id="paymentOrder.costs" />,
            dataIndex: ['councilTaxpayer', 'costs'],
            key: 'costs',
            align: 'right',
            width: 160,
            render: (value: any, paymentOrder: PaymentOrder) => (
                <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
            )
        },
        {
            title: <FormattedMessage id="paymentOrder.created" />,
            dataIndex: ['audit', 'created'],
            key: 'created',
            align: 'center',
            width: 200,
            render: (value: any, paymentOrder: PaymentOrder) => (
                <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
            )
        },
        {
            title: <FormattedMessage id="paymentOrder.receptionState" />,
            dataIndex: 'receptionState',
            key: 'receptionState',
            align: 'center',
            width: 200,
            render: (value: ReceptionStateType | undefined, paymentOrder: PaymentOrder) => <ReceptionStateTag value={value} />
        },
        {
            key: 'actions',
            width: 120,
            align: 'center',
            render: (paymentOrder: PaymentOrder) => (
                <Space size="small">
                    <Tooltip title={<FormattedMessage id="button.download" />}>
                        <Button icon={<CloudDownloadOutlined />} className={styles.icon} onClick={() => download(paymentOrder)} type="link" />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="button.update" />}>
                        <Button
                            icon={<FormOutlined />}
                            className={styles.icon}
                            onClick={() => showPaymentOrderEventModal(paymentOrder)}
                            disabled={!!paymentOrder.receptionState}
                            type="link"
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id="paymentOrders.resend" />}>
                        <Button
                            icon={<SendOutlined />}
                            className={styles.icon}
                            onClick={() => showPaymentOrderResendModal(paymentOrder)}
                            disabled={!['WRONG_ADDRESS', 'UNKNOWN'].includes(paymentOrder.receptionState)}
                            type="link"
                        />
                    </Tooltip>
                </Space>
            )
        }
    ];
    return (
        <>
            <Form form={form} onFinish={filterPaymentOrders} colon={false} layout="vertical" requiredMark={false} className={styles.form}>
                <Row gutter={[0, 0]}>
                    <Col span={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'paymentOrders.search'
                                })}
                                size="large"
                                allowClear
                                className={styles.search}
                                onSearch={form.submit}
                                disabled
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={false}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
            {paymentOrderEventModalVisible && paymentOrder && (
                <PaymentOrderEventModal paymentOrderId={paymentOrder.id} onCancel={() => setPaymentOrderEventModalVisible(false)} onSave={save} />
            )}
            {paymentOrderResendModalVisible && paymentOrder && (
                <PaymentOrderModal
                    councilId={council.id!}
                    councilTaxpayerId={paymentOrder.councilTaxpayer.id}
                    clone={true}
                    onCancel={() => setPaymentOrderResendModalVisible(false)}
                    onSave={save}
                />
            )}
        </>
    );
};
export default CouncilTaxpayerPaymentOrdersComponent;
interface Filter {
    searchText?: string | undefined;
}
