import { ThemeConfig } from 'antd';

class AppThemeComponent {
    getAppTheme = () => {
        const appTheme: ThemeConfig = {
            token: {
                colorPrimary: '#94B4C6',
                colorLink: '#000',
                colorLinkActive: '#000',
                colorLinkHover: '#000',
                fontFamily: 'Inter-Regular',
                colorError: '#f57e77'
            },
            components: {
                Button: {
                    colorPrimary: '#94B4C6'
                },
                Modal: {
                    colorPrimary: '#94B4C6'
                },
                Layout: {
                    bodyBg: '#EFF1F9'
                },
                Card: {
                    colorBgContainer: '#fff',
                    borderRadiusLG: 12,
                    borderRadiusSM: 12,
                    borderRadiusXS: 12
                },
                Menu: {
                    itemSelectedBg: '#94B4C6',
                    itemSelectedColor: '#fff',
                    itemBorderRadius: 12
                },
                Input: {
                    colorBgContainer: '#fff',
                    colorBorder: '#CFD3D4',
                    colorTextDisabled: 'rgba(0, 0, 0, 0.88)'
                },
                InputNumber: {
                    colorBgContainer: '#fff',
                    colorBorder: '#CFD3D4',
                    colorTextDisabled: 'rgba(0, 0, 0, 0.88)'
                },
                DatePicker: {
                    colorBgContainer: '#fff',
                    colorBorder: '#CFD3D4',
                    colorTextDisabled: 'rgba(0, 0, 0, 0.88)'
                },
                Select: {
                    colorBgContainer: '#fff',
                    colorBorder: '#CFD3D4',
                    colorTextDisabled: 'rgba(0, 0, 0, 0.88)'
                },
                Tabs: {
                    colorPrimary: 'rgba(43, 47, 50, 1)',
                    margin: 30
                },
                Descriptions: {
                    colorTextSecondary: 'rgba(43, 47, 50, 1)'
                }
            }
        };

        return appTheme;
    };
}

const appThemeComponent: AppThemeComponent = new AppThemeComponent();
export default appThemeComponent;
