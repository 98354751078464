import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip } from 'antd';
import FileSaver from 'file-saver';
import { useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import styles from './InfoResponseComponent.module.scss';
import seizureBankInfoResponseApi from '../../../../apis/SeizureBankInfoResponseApi';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import { CouncilTaxpayerSeizureBankInfoResponse } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';

const InfoResponseComponent: React.FC<Props> = (props) => {
    const { infoResponse } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading'>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            if (infoResponse.seizureBankInfoResponse) {
                const file = await seizureBankInfoResponseApi.download(infoResponse.seizureBankInfoResponse.id);
                FileSaver.saveAs(file, 'respuesta.txt');
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <>
            <Card
                size="small"
                title={<ConstantLabel value={infoResponse.phase} prefix="seizureBankAccount." />}
                extra={
                    <span className={styles.date}>
                        <FormattedDate
                            value={infoResponse.audit?.created as any}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                            hour="2-digit"
                            minute="2-digit"
                        />
                    </span>
                }
                actions={[
                    <StateTag bankAccounts={infoResponse.bankAccounts} key="state" />,
                    <Tooltip title={<FormattedMessage id="seizureBankAccount.infoResponse.download" />}>
                        <Button
                            key="download"
                            type="text"
                            size="small"
                            icon={<CloudDownloadOutlined key="download" />}
                            onClick={download}
                            loading={loading === 'downloading'}
                        />
                    </Tooltip>
                ]}
                className={styles.card}
            >
                <p>
                    <FormattedMessage id="seizureBankAccount.infoResponse" />
                </p>
                <ul>
                    {infoResponse.bankAccounts.map((bankAccount) => (
                        <li>{bankAccount}</li>
                    ))}
                </ul>
            </Card>
        </>
    );
};
export default InfoResponseComponent;
interface Props {
    infoResponse: CouncilTaxpayerSeizureBankInfoResponse;
}

/**
 * Returns a state tag.
 * @returns the state tag.
 */
const StateTag: React.FC<StateTagProps> = (props) => {
    const { bankAccounts } = props;

    if (bankAccounts && bankAccounts.length > 0) {
        return (
            <Tooltip
                title={
                    <FormattedMessage
                        id="seizureBankAccount.infoResponse.bankAccounts"
                        values={{
                            bankAccounts: bankAccounts.length
                        }}
                    />
                }
            >
                <span className={`${styles.state} ${styles.SENT}`}>{bankAccounts.length}</span>
            </Tooltip>
        );
    } else {
        return (
            <Tooltip title={<FormattedMessage id="seizureBankAccount.infoResponse.bankAccounts.empty" />}>
                <span className={`${styles.state} ${styles.FAILED}`}>0</span>
            </Tooltip>
        );
    }
};
interface StateTagProps {
    bankAccounts: string[] | undefined;
}
