import { Page } from '../models/Elements';
import { SeizureIncomeOrderEvent } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class SeizureIncomeOrderEventApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        paymentOrderId?: number,
        searchText?: string
    ): Promise<Page<SeizureIncomeOrderEvent>> => {
        const response = await adminApiAxios.get<Page<SeizureIncomeOrderEvent>>('/seizure-income-order-events', {
            params: { page, size, sortField, sortOrder, paymentOrderId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<SeizureIncomeOrderEvent> => {
        const response = await adminApiAxios.get<SeizureIncomeOrderEvent>(`/seizure-income-order-events/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (seizureIncomeOrderEvent: SeizureIncomeOrderEvent, file: any): Promise<SeizureIncomeOrderEvent> => {
        const formData: FormData = this.buildFormData(seizureIncomeOrderEvent, file);
        const response = await adminApiAxios.post<SeizureIncomeOrderEvent>('/seizure-income-order-events', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/seizure-income-order-events/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Builds a form data.
     * @param seizureIncomeOrderEvent, file
     * @returns the form data
     */
    private buildFormData = (seizureIncomeOrderEvent: SeizureIncomeOrderEvent, file?: File) => {
        const formData: FormData = new FormData();
        seizureIncomeOrderEvent.id && formData.append('id', seizureIncomeOrderEvent.id.toString());
        seizureIncomeOrderEvent.seizureIncomeOrderId && formData.append('seizureIncomeOrderId', seizureIncomeOrderEvent.seizureIncomeOrderId.toString());
        seizureIncomeOrderEvent.received && formData.append('received', seizureIncomeOrderEvent.received.format('YYYY-MM-DDThh:mm:ss'));
        seizureIncomeOrderEvent.remarks && formData.append('remarks', seizureIncomeOrderEvent.remarks.toString());
        seizureIncomeOrderEvent.state && formData.append('state', seizureIncomeOrderEvent.state.toString());
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a seizureIncomeOrderEvent.
     * @param seizureIncomeOrderEvent - the payment order event
     */
    private loadDates(seizureIncomeOrderEvent: SeizureIncomeOrderEvent) {
        seizureIncomeOrderEvent.audit!.created = dateService.transformDate(seizureIncomeOrderEvent.audit!.created)!;
        seizureIncomeOrderEvent.audit!.updated = dateService.transformDate(seizureIncomeOrderEvent.audit!.updated)!;
        seizureIncomeOrderEvent.received = dateService.transformDate(seizureIncomeOrderEvent.received)!;
    }
}

const seizureIncomeOrderEventApi: SeizureIncomeOrderEventApi = new SeizureIncomeOrderEventApi();
export default seizureIncomeOrderEventApi;
