import { Page } from '../models/Elements';
import { SeizureBankAmountRequest, SeizureBankAmountRequestCreation, SeizureBankAmountRequestDraft } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class SeizureBankAmountRequestApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId: number,
        searchText?: string
    ): Promise<Page<SeizureBankAmountRequest>> => {
        const response = await adminApiAxios.get<Page<SeizureBankAmountRequest>>('/seizure-bank-amount-requests', {
            params: { page, size, sortField, sortOrder, councilId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (seizureBankAmountRequestId: number): Promise<SeizureBankAmountRequest> => {
        const response = await adminApiAxios.get<SeizureBankAmountRequest>(`/seizure-bank-amount-requests/${seizureBankAmountRequestId}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (seizureBankAmountRequestCreation: SeizureBankAmountRequestCreation): Promise<SeizureBankAmountRequest[]> => {
        const response = await adminApiAxios.post<SeizureBankAmountRequest[]>(`/seizure-bank-amount-requests`, seizureBankAmountRequestCreation);
        response.data.forEach((seizureBankAmountRequest) => this.loadDates(seizureBankAmountRequest));

        return response.data;
    };

    createDraft = async (seizureBankAmountRequestCreation: SeizureBankAmountRequestCreation): Promise<SeizureBankAmountRequestDraft> => {
        const response = await adminApiAxios.post<SeizureBankAmountRequestDraft>(`/seizure-bank-amount-requests/draft`, seizureBankAmountRequestCreation);
        this.loadDraftDates(response.data);

        return response.data;
    };

    downloadDraft = async (councilId: number, bankId: number): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(
            `/seizure-bank-amount-requests/draft/file`,
            {},
            { responseType: 'blob', params: { councilId, bankId } }
        );

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/seizure-bank-amount-requests/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Loads the dates of a seizure bank amount request.
     * @param seizureBankAmountRequest - the seizure bank amount request
     */
    private loadDates(seizureBankAmountRequest: SeizureBankAmountRequest) {
        seizureBankAmountRequest.sent = dateService.transformDate(seizureBankAmountRequest.sent);
        seizureBankAmountRequest.audit!.created = dateService.transformDate(seizureBankAmountRequest.audit!.created)!;
        seizureBankAmountRequest.audit!.updated = dateService.transformDate(seizureBankAmountRequest.audit!.updated)!;
    }

    /**
     * Loads the dates of a seizure bank amount request draft.
     * @param seizureBankAmountRequestDraft - the seizure bank amount request draft
     */
    private loadDraftDates(seizureBankAmountRequestDraft: SeizureBankAmountRequestDraft) {
        const { seizureBankAmountRequests, ctSeizureBankAmountRequests } = seizureBankAmountRequestDraft;
        seizureBankAmountRequests.forEach(this.loadDates);
        ctSeizureBankAmountRequests.forEach((ctsbar) => {
            ctsbar.audit.created = dateService.transformDate(ctsbar.audit!.created)!;
            ctsbar.audit.updated = dateService.transformDate(ctsbar.audit!.updated)!;
        });
    }
}

const seizureBankAmountRequestApi: SeizureBankAmountRequestApi = new SeizureBankAmountRequestApi();
export default seizureBankAmountRequestApi;
