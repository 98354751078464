import { Page } from '../models/Elements';
import { SeizureIncome, SeizureIncomeDraft, SeizureIncomeResponseDraft } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class SeizureIncomeApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, councilId?: number, searchText?: string): Promise<Page<SeizureIncome>> => {
        const response = await adminApiAxios.get<Page<SeizureIncome>>('/seizure-incomes', {
            params: { page, size, sortField, sortOrder, councilId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<SeizureIncome> => {
        const response = await adminApiAxios.get<SeizureIncome>(`/seizure-incomes/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (seizureIncome: SeizureIncome): Promise<SeizureIncome> => {
        const response = await adminApiAxios.post<SeizureIncome>('/seizure-incomes', seizureIncome);
        this.loadDates(response.data);

        return response.data;
    };

    createDraft = async (seizureIncome: SeizureIncome): Promise<SeizureIncomeDraft> => {
        const response = await adminApiAxios.post<SeizureIncomeDraft>('/seizure-incomes/draft', seizureIncome);
        this.loadDraftDates(response.data);

        return response.data;
    };

    downloadDraft = async (seizureIncome: SeizureIncome): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(`/seizure-incomes/draft/file`, seizureIncome, { responseType: 'blob' });

        return response.data;
    };

    update = async (seizureIncome: SeizureIncome, file: any): Promise<SeizureIncome> => {
        const formData: FormData = this.buildFormData(seizureIncome, file);
        const response = await adminApiAxios.put<SeizureIncome>(`/seizure-incomes/${seizureIncome.id}/responses`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    createDraftResponse = async (seizureIncome: SeizureIncome, file: any): Promise<SeizureIncomeResponseDraft> => {
        const formData: FormData = this.buildFormData(seizureIncome, file);
        const response = await adminApiAxios.put<SeizureIncomeResponseDraft>(`/seizure-incomes/${seizureIncome.id}/responses/draft`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDraftResponseDates(response.data);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/seizure-incomes/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    downloadResponse = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/seizure-incomes/${id}/responses/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Builds a form data from a seizure income response.
     * @param seizureIncome the seizure income response
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (seizureIncome: SeizureIncome, file?: File) => {
        const formData: FormData = new FormData();
        seizureIncome.id && formData.append('id', seizureIncome.id.toString());
        seizureIncome.councilId && formData.append('councilId', seizureIncome.councilId.toString());
        seizureIncome.income && formData.append('income', seizureIncome.income.toString());
        seizureIncome.sent && formData.append('sent', seizureIncome.sent.format('YYYY-MM-DDThh:mm:ss'));
        seizureIncome.state && formData.append('state', seizureIncome.state.toString());
        file && formData.append('responseFile.name', file.name);
        file && formData.append('responseFile.file', file);

        return formData;
    };

    /**
     * Loads the dates of a seizureIncome.
     * @param seizureIncome - the seizure income
     */
    private loadDates(seizureIncome: SeizureIncome) {
        seizureIncome.sent && (seizureIncome.sent = dateService.transformDate(seizureIncome.sent));
        seizureIncome.audit!.created && (seizureIncome.audit!.created = dateService.transformDate(seizureIncome.audit!.created)!);
        seizureIncome.audit!.updated && (seizureIncome.audit!.updated = dateService.transformDate(seizureIncome.audit!.updated)!);
    }

    /**
     * Loads the dates of a seizureIncomeResponseDraft.
     * @param seizureIncome - the seizure income response draft
     */
    private loadDraftResponseDates(seizureIncomeResponseDraft: SeizureIncomeResponseDraft) {
        const { seizureIncome, ctSeizureIncomeResponses } = seizureIncomeResponseDraft;
        this.loadDates(seizureIncome);
        ctSeizureIncomeResponses &&
            ctSeizureIncomeResponses.forEach((csir) => {
                csir.councilTaxpayerSeizureIncome.audit!.created &&
                    (csir.councilTaxpayerSeizureIncome.audit!.created = dateService.transformDate(csir.councilTaxpayerSeizureIncome.audit.created)!);
                csir.councilTaxpayerSeizureIncome.audit!.updated &&
                    (csir.councilTaxpayerSeizureIncome.audit!.updated = dateService.transformDate(csir.councilTaxpayerSeizureIncome.audit.updated)!);
                csir.councilTaxpayerSeizureIncome.councilTaxpayer.audit.created &&
                    (csir.councilTaxpayerSeizureIncome.councilTaxpayer.audit.created = dateService.transformDate(
                        csir.councilTaxpayerSeizureIncome.councilTaxpayer.audit.created
                    )!);
                csir.councilTaxpayerSeizureIncome.councilTaxpayer.audit.updated &&
                    (csir.councilTaxpayerSeizureIncome.councilTaxpayer.audit.updated = dateService.transformDate(
                        csir.councilTaxpayerSeizureIncome.councilTaxpayer.audit.updated
                    )!);
                csir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit!.created &&
                    (csir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit!.created = dateService.transformDate(
                        csir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit?.created
                    )!);
                csir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit!.updated &&
                    (csir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit!.updated = dateService.transformDate(
                        csir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit?.updated
                    )!);
                csir.audit!.created && (csir.audit.created = dateService.transformDate(csir.audit!.created)!);
                csir.audit!.updated && (csir.audit!.updated = dateService.transformDate(csir.audit!.updated)!);
            });
    }

    /**
     * Loads the dates of a seizureIncomeDraft.
     * @param seizureIncome - the seizure income draft
     */
    private loadDraftDates(seizureIncomeDraft: SeizureIncomeDraft) {
        const { seizureIncome, ctSeizureIncomeRequests } = seizureIncomeDraft;
        this.loadDates(seizureIncome);
        ctSeizureIncomeRequests &&
            ctSeizureIncomeRequests.forEach((csir) => {
                csir.councilTaxpayerSeizureIncome.audit!.created &&
                    (csir.councilTaxpayerSeizureIncome.audit!.created = dateService.transformDate(csir.councilTaxpayerSeizureIncome.audit.created)!);
                csir.councilTaxpayerSeizureIncome.audit!.updated &&
                    (csir.councilTaxpayerSeizureIncome.audit!.updated = dateService.transformDate(csir.councilTaxpayerSeizureIncome.audit.updated)!);
                csir.councilTaxpayerSeizureIncome.councilTaxpayer.audit.created &&
                    (csir.councilTaxpayerSeizureIncome.councilTaxpayer.audit.created = dateService.transformDate(
                        csir.councilTaxpayerSeizureIncome.councilTaxpayer.audit.created
                    )!);
                csir.councilTaxpayerSeizureIncome.councilTaxpayer.audit.updated &&
                    (csir.councilTaxpayerSeizureIncome.councilTaxpayer.audit.updated = dateService.transformDate(
                        csir.councilTaxpayerSeizureIncome.councilTaxpayer.audit.updated
                    )!);
                csir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit!.created &&
                    (csir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit!.created = dateService.transformDate(
                        csir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit?.created
                    )!);
                csir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit!.updated &&
                    (csir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit!.updated = dateService.transformDate(
                        csir.councilTaxpayerSeizureIncome.councilTaxpayer.taxpayer.audit?.updated
                    )!);
                csir.audit!.created && (csir.audit.created = dateService.transformDate(csir.audit!.created)!);
                csir.audit!.updated && (csir.audit!.updated = dateService.transformDate(csir.audit!.updated)!);
            });
    }
}

const seizureIncomeApi: SeizureIncomeApi = new SeizureIncomeApi();
export default seizureIncomeApi;
