import { CloudUploadOutlined, DeleteOutlined, PaperClipOutlined } from '@ant-design/icons';
import { Button, Col, List, Modal, Row, Select, Upload, message } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { Form, UploadFile } from 'antd/lib';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import bankApi from '../../../apis/BankApi';
import seizureBankInfoResponseApi from '../../../apis/SeizureBankInfoResponseApi';
import { Bank, SeizureBankInfoResponse, SeizureBankInfoResponseDraft } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import stringService from '../../../services/StringService';
import FileSizeComponent from '../../FileSizeComponent/FileSizeComponent';
import styles from './SeizureBankInfoResponseModal.module.scss';

const SeizureBankInfoResponseModal = (props: Props) => {
    const { councilId } = props;
    const maxFileSize = 5 * 1024 * 1024;

    /*** HOOKS ***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [files, setFiles] = useState<UploadFile[]>([]);
    const [loading, setLoading] = useState<'loading' | 'saving' | 'previewing'>();
    const [banks, setBanks] = useState<Bank[]>([]);
    const [draft, setDraft] = useState<SeizureBankInfoResponseDraft>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                // list banks (council and parent online)
                const banksPage = await bankApi.list(0, 1000, 'name', true, 'ENABLED', undefined, undefined, undefined, undefined, councilId);
                setBanks(banksPage.content);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [councilId, intl]);

    /*** METHODS ***/

    // save
    const save = async () => {
        try {
            setLoading('saving');

            const values = await form.validateFields();
            let seizureBankInfoResponse: SeizureBankInfoResponse = Object.assign({}, { councilId }, values);
            const attachment: UploadFile | undefined = files.length > 0 ? files[0] : undefined;
            await seizureBankInfoResponseApi.create(seizureBankInfoResponse, attachment);
            await props.onSave();

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // preview
    const preview = async () => {
        try {
            setLoading('previewing');

            const values = await form.validateFields();
            let seizureBankInfoResponse: SeizureBankInfoResponse = Object.assign({}, { councilId }, values);
            const attachment: UploadFile | undefined = files.length > 0 ? files[0] : undefined;
            const draft = await seizureBankInfoResponseApi.createDraft(seizureBankInfoResponse, attachment);
            setDraft(draft);
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // cancel
    const cancel = async () => {
        props.onCancel();
    };

    // validate file
    const validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    // upload file
    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    //remove file
    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            file: files
        });
        setFiles(files);
    };

    /*** VISUAL ***/

    return (
        <Modal
            title={<FormattedMessage id="seizureBankInfoResponseModal.title" />}
            open={true}
            onCancel={cancel}
            onOk={save}
            okText={<FormattedMessage id="seizureBankInfoResponseModal.save" tagName="span" />}
            okButtonProps={{ loading: loading === 'saving', size: 'large' }}
            cancelButtonProps={{ disabled: loading === 'saving', size: 'large' }}
            width={1000}
        >
            <Form form={form} colon={false} layout="vertical">
                <Row gutter={28} className={styles.container}>
                    <Col span={24}>
                        <Row gutter={28}>
                            <Col span={24} lg={16}>
                                <Form.Item
                                    label={<FormattedMessage id="seizureBankInfoResponseModal.bank" />}
                                    name="bankId"
                                    rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]}
                                >
                                    <Select
                                        size="large"
                                        allowClear
                                        showSearch
                                        filterOption={stringService.filterOptions}
                                        className={styles.select}
                                        options={[
                                            {
                                                label: <FormattedMessage id="seizureBankInfoResponseModal.councilBanks" />,
                                                title: 'councilBanks',
                                                options: banks
                                                    .filter((bank) => bank.councilId)
                                                    .map((bank) => ({
                                                        label: bank.name,
                                                        value: bank.id
                                                    }))
                                            },
                                            {
                                                label: <FormattedMessage id="seizureBankAmountResponseModal.banks" />,
                                                title: 'banks',
                                                options: banks
                                                    .filter((bank) => !bank.councilId && bank.online === true)
                                                    .map((bank) => ({
                                                        label: bank.name,
                                                        value: bank.id
                                                    }))
                                            }
                                        ]}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={28}>
                            <Col span={24}>
                                <Form.Item
                                    name="file"
                                    valuePropName="files"
                                    label={
                                        <>
                                            <span>
                                                <FormattedMessage id="seizureBankInfoResponseModal.upload" />
                                            </span>
                                        </>
                                    }
                                    rules={[
                                        {
                                            validator: validateFile,
                                            required: true,
                                            message: <FormattedMessage id="status.mandatory" />
                                        }
                                    ]}
                                    extra={
                                        <>
                                            <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                                        </>
                                    }
                                >
                                    <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                                        <CloudUploadOutlined /> <FormattedMessage id="button.upload" />
                                    </Upload.Dragger>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <List
                                    className={styles.files}
                                    itemLayout="horizontal"
                                    dataSource={files}
                                    locale={{ emptyText: <></> }}
                                    renderItem={(file) => (
                                        <List.Item
                                            actions={[
                                                <Button icon={<DeleteOutlined />} danger size="large" onClick={removeFile} />,
                                                <Button size="large" onClick={preview} loading={loading === 'previewing'}>
                                                    <FormattedMessage id="button.preview" />
                                                </Button>
                                            ]}
                                        >
                                            <List.Item.Meta
                                                avatar={<PaperClipOutlined className={styles.icon} />}
                                                title={file.name}
                                                description={<FileSizeComponent value={file.size} />}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </Col>
                        </Row>
                        <Col>
                            {draft && draft.ctSeizureBankInfoResponses.length > 0 ? (
                                <List
                                    itemLayout="horizontal"
                                    dataSource={draft.ctSeizureBankInfoResponses}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                title={item.councilTaxpayerSeizureBank.councilTaxpayer.taxpayer.fullName}
                                                description={item.bankAccounts.join(', ')}
                                            />
                                        </List.Item>
                                    )}
                                />
                            ) : !draft ? (
                                <p></p>
                            ) : (
                                <p>
                                    <FormattedMessage id="seizureBankInfoResponseModal.preview.empty" />
                                </p>
                            )}
                        </Col>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};
export default SeizureBankInfoResponseModal;

interface Props {
    councilId: number;
    onSave: () => Promise<void>;
    onCancel: () => void;
}
