import { CloseOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip } from 'antd';
import FileSaver from 'file-saver';
import { useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import seizureIncomeOrderEventApi from '../../../../apis/SeizureIncomeOrderEventApi';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import SeizureIncomeCancelRequestModal from '../../../../components/ReceivableActionsMenu/SeizureIncomeCancelRequestModal/SeizureIncomeCancelRequestModal';
import { CouncilTaxpayerSeizureIncomeOrderUnknown } from '../../../../models/Entities';
import { SeizureIncomeStateType } from '../../../../models/Types';
import alertService from '../../../../services/AlertService';
import styles from './SeizureIncomeOrderUnknownComponent.module.scss';

const SeizureIncomeOrderUnknownComponent: React.FC<Props> = (props) => {
    const { orderUnknown } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading'>();
    const [seizureIncomeCancelRequestModalVisible, setSeizureIncomeCancelRequestModalVisible] = useState<boolean>(false);

    /*** EFFECTS ***/

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            const seizureIncomeOrderEventId = orderUnknown.seizureIncomeOrderEvent.id;
            const file = await seizureIncomeOrderEventApi.download(seizureIncomeOrderEventId!);
            FileSaver.saveAs(file, intl.formatMessage({ id: 'paymentOrderReceipt.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const onCancel = async () => {
        try {
            setLoading('downloading');
            setSeizureIncomeCancelRequestModalVisible(false);
            props.onCancel();
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const state = orderUnknown && orderUnknown.councilTaxpayerSeizureIncome.seizureIncome.state;

    return (
        <>
            <Card
                size="small"
                title={<ConstantLabel value={orderUnknown.councilTaxpayerSeizureIncome.seizureIncome.income} prefix="councilTaxpayerSeizureIncome.income." />}
                extra={
                    <span className={styles.date}>
                        <FormattedDate
                            value={orderUnknown.audit?.created as any}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                            hour="2-digit"
                            minute="2-digit"
                        />
                    </span>
                }
                actions={[
                    <StateTag value={state} key="state" />,
                    <Tooltip title={<FormattedMessage id="button.download" />}>
                        <Button
                            type="text"
                            size="small"
                            icon={<CloudDownloadOutlined key="download" />}
                            onClick={download}
                            loading={loading === 'downloading'}
                        />
                    </Tooltip>,
                    <Tooltip title={<FormattedMessage id="button.cancel" />}>
                        <Button
                            key="cancel"
                            type="primary"
                            danger
                            size="small"
                            icon={<CloseOutlined />}
                            onClick={() => setSeizureIncomeCancelRequestModalVisible(true)}
                            disabled={orderUnknown.councilTaxpayerSeizureIncome.phase !== 'NOTIFICATION_UNKNOWN'}
                        />
                    </Tooltip>
                ]}
                className={styles.card}
            >
                <p className={styles.title}>
                    <FormattedMessage id="councilTaxpayerSeizureIncome.orderUnknown.title" />
                </p>
            </Card>
            {seizureIncomeCancelRequestModalVisible && (
                <SeizureIncomeCancelRequestModal
                    event={orderUnknown}
                    onCancel={() => setSeizureIncomeCancelRequestModalVisible(false)}
                    onSave={() => onCancel()}
                />
            )}
        </>
    );
};
export default SeizureIncomeOrderUnknownComponent;
interface Props {
    orderUnknown: CouncilTaxpayerSeizureIncomeOrderUnknown;
    onCancel: () => void;
}

/**
 * Returns a state tag.
 * @returns the state tag.
 */
const StateTag: React.FC<StateTagProps> = (props) => {
    const { value } = props;

    if (value === 'SENT') {
        return (
            <span className={`${styles.state} ${styles.SENT}`}>
                <ConstantLabel value={value} prefix="councilTaxpayerSeizureIncome.sentState.response." />
            </span>
        );
    } else if (value === 'FAILED') {
        return (
            <span className={`${styles.state} ${styles.FAILED}`}>
                <ConstantLabel value={value} prefix="councilTaxpayerSeizureIncome.sentState." />
            </span>
        );
    } else {
        return (
            <span className={styles.state}>
                <ConstantLabel value="PENDING" prefix="councilTaxpayerSeizureIncome.sentState." />
            </span>
        );
    }
};
interface StateTagProps {
    value: SeizureIncomeStateType | undefined;
}
