import { Steps } from 'antd';
import { UploadFile } from 'antd/lib';
import { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { ReceivableRegistration, ReceivableRegistrationDocument } from '../../models/Entities';
import alertService from '../../services/AlertService';
import styles from './ReceivablesImportPage.module.scss';
import ResultComponent from './ResultComponent/ResultComponent';
import SummaryComponent from './SummaryComponent/SummaryComponent';
import UploadComponent from './UploadComponent/UploadComponent';

/**
 * Returns the receivables import page.
 * @returns the receivables import page.
 */
const ReceivablesImportPage: React.FC = () => {
    /*** HOOKS ***/

    const intl = useIntl();
    const { council } = useContext(CustomCouncilContext);
    const [step, setStep] = useState<number>(0);
    const [file, setFile] = useState<UploadFile>();
    const [receivableRegistrationDocument, setReceivableRegistrationDocument] = useState<ReceivableRegistrationDocument>();
    const [receivableRegistrations, setReceivableRegistrations] = useState<ReceivableRegistration[]>([]);

    /*** METHODS ***/

    const showSummary = async (file: UploadFile, receivableRegistrations: ReceivableRegistration[]) => {
        try {
            setFile(file);
            setReceivableRegistrations(receivableRegistrations);
            setStep(1);
        } catch (error) {
            alertService.displayError(error, intl);
        }
    };

    const showResult = async (receivableRegistrationDocument: ReceivableRegistrationDocument) => {
        try {
            setReceivableRegistrationDocument(receivableRegistrationDocument);
            setStep(2);
        } catch (error) {
            alertService.displayError(error, intl);
        }
    };

    const back = () => {
        setStep(step - 1);
    };

    /*** VISUAL ***/

    return (
        <LayoutComponent
            title={<FormattedMessage id="receivablesImport.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council.id}`, name: council?.name },
                { path: `/councils/${council.id}/receivables`, name: <FormattedMessage id="receivables.title" /> },
                { path: `/councils/${council.id}/receivables/import`, name: <FormattedMessage id="receivables.import.title" /> }
            ]}
            council={council}
        >
            <Steps current={step} className={styles.steps}>
                <Steps.Step title={<FormattedMessage id="receivables.import.steps.1" />} />
                <Steps.Step title={<FormattedMessage id="receivables.import.steps.2" />} />
                <Steps.Step title={<FormattedMessage id="receivables.import.steps.3" />} />
            </Steps>

            <div hidden={step !== 0}>
                <UploadComponent council={council} onLoad={showSummary} />
            </div>
            {step === 1 && (
                <SummaryComponent council={council} file={file} receivableRegistrations={receivableRegistrations} onImport={showResult} onBack={back} />
            )}
            {step === 2 && receivableRegistrationDocument && (
                <ResultComponent council={council} receivableRegistrationDocument={receivableRegistrationDocument} />
            )}
        </LayoutComponent>
    );
};
export default ReceivablesImportPage;
