import { CheckOutlined, CloseOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Card, Space, Tooltip } from 'antd';
import React, { useContext, useState } from 'react';
import { FormattedDate, FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import { CouncilTaxpayerSeizureBankAmountResponse } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './AmountResponseComponent.module.scss';
import seizureBankAmountResponseApi from '../../../../apis/SeizureBankAmountResponseApi';
import FileSaver from 'file-saver';
import SeizureBankCancelRequestModal from '../../../../components/ReceivableActionsMenu/SeizureBankCancelRequestModal/SeizureBankCancelRequestModal';
import CustomCouncilContext from '../../../../contexts/CustomCouncilContext';
import SeizureBankPaymentResponseModal from '../../../../components/ReceivableActionsMenu/SeizureBankPaymentResponseModal/SeizureBankPaymentResponseModal';

const AmountResponseComponent: React.FC<Props> = (props) => {
    const { amountResponse, readonly, onCancel, onPay } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading'>();
    const { council } = useContext(CustomCouncilContext);
    const [seizureBankCancelRequestModalVisible, setSeizureBankCancelRequestModalVisible] = useState<boolean>(false);
    const [seizureBankPaymentResponseModalVisible, setSeizureBankPaymentResponseModalVisible] = useState<boolean>(false);

    /*** EFFECTS ***/

    /*** METHODS ***/

    const cancel = async () => {
        try {
            setSeizureBankCancelRequestModalVisible(false);
            setSeizureBankPaymentResponseModalVisible(false);
            await onCancel();
        } catch (error) {
            alertService.displayError(error, intl);
        }
    };

    const pay = async () => {
        try {
            setSeizureBankCancelRequestModalVisible(false);
            setSeizureBankPaymentResponseModalVisible(false);
            await onPay();
        } catch (error) {
            alertService.displayError(error, intl);
        }
    };

    const download = async () => {
        try {
            setLoading('downloading');
            if (amountResponse.seizureBankAmountResponse && amountResponse.seizureBankAmountResponse.id) {
                const file = await seizureBankAmountResponseApi.download(amountResponse.seizureBankAmountResponse.id);
                FileSaver.saveAs(file, 'c63.dat');
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <>
            <Card
                size="small"
                title={<ConstantLabel value={amountResponse.phase} prefix="seizureBankAccount." />}
                extra={
                    <span className={styles.date}>
                        <FormattedDate
                            value={amountResponse.audit?.created as any}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                            hour="2-digit"
                            minute="2-digit"
                        />
                    </span>
                }
                actions={[
                    <StateTag amountResponse={amountResponse} key="state" />,
                    <Tooltip title={<FormattedMessage id="seizureBankAccount.amountResponse.download" />}>
                        <Button
                            key="download"
                            type="text"
                            size="small"
                            icon={<CloudDownloadOutlined key="download" />}
                            onClick={download}
                            loading={loading === 'downloading'}
                        />
                    </Tooltip>,
                    !readonly && (
                        <Space key="actions">
                            <Tooltip title={<FormattedMessage id="seizureBankAccount.amountResponse.cancel" />}>
                                <Button
                                    key="cancel"
                                    type="primary"
                                    danger
                                    size="small"
                                    icon={<CloseOutlined />}
                                    onClick={() => setSeizureBankCancelRequestModalVisible(true)}
                                    disabled={amountResponse.councilTaxpayerSeizureBank.phase !== 'PHASE_4'}
                                />
                            </Tooltip>
                            <Tooltip title={<FormattedMessage id="seizureBankAccount.amountResponse.paid" />}>
                                <Button
                                    key="paid"
                                    type="primary"
                                    size="small"
                                    icon={<CheckOutlined />}
                                    className={styles.paid}
                                    onClick={() => setSeizureBankPaymentResponseModalVisible(true)}
                                    disabled={amountResponse.councilTaxpayerSeizureBank.phase !== 'PHASE_4'}
                                />
                            </Tooltip>
                        </Space>
                    )
                ]}
                className={styles.card}
            >
                <p>
                    <FormattedMessage id="seizureBankAccount.amountResponse" />
                </p>
                <ul>
                    {amountResponse.bankAccounts.map((bankAccount) => (
                        <li>{bankAccount}</li>
                    ))}
                </ul>
                <p>
                    <FormattedMessage id="seizureBankAccount.amountResponse.amount" />
                    <FormattedNumber
                        value={amountResponse.seizedAmount}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        style={'currency' as any}
                        currency="EUR"
                    />
                </p>
            </Card>
            {seizureBankCancelRequestModalVisible && (
                <SeizureBankCancelRequestModal
                    councilId={council.id!}
                    amountResponse={amountResponse}
                    onCancel={() => setSeizureBankCancelRequestModalVisible(false)}
                    onSave={cancel}
                />
            )}

            {seizureBankPaymentResponseModalVisible && (
                <SeizureBankPaymentResponseModal
                    councilId={council.id!}
                    amountResponse={amountResponse}
                    onCancel={() => setSeizureBankPaymentResponseModalVisible(false)}
                    onPay={pay}
                    onSave={pay}
                />
            )}
        </>
    );
};
export default AmountResponseComponent;
interface Props {
    amountResponse: CouncilTaxpayerSeizureBankAmountResponse;
    readonly: boolean;
    onCancel: () => Promise<void>;
    onPay: () => Promise<void>;
}

/**
 * Returns a state tag.
 * @returns the state tag.
 */
const StateTag: React.FC<StateTagProps> = (props) => {
    const { amountResponse } = props;

    const intl = useIntl();

    if (amountResponse) {
        const requestedAmount: string = intl.formatNumber(amountResponse.requestedAmount, {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'EUR'
        });
        const seizedAmount: string = intl.formatNumber(amountResponse.seizedAmount, {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'EUR'
        });

        if (amountResponse.requestedAmount === amountResponse.seizedAmount) {
            return (
                <Tooltip title={<FormattedMessage id="seizureBankAccount.amountResponse.status" values={{ requestedAmount, seizedAmount }} />}>
                    <span className={`${styles.state} ${styles.FULL}`}>{seizedAmount}</span>
                </Tooltip>
            );
        } else if (amountResponse.seizedAmount === 0) {
            return (
                <Tooltip title={<FormattedMessage id="seizureBankAccount.amountResponse.status" values={{ requestedAmount, seizedAmount }} />}>
                    <span className={`${styles.state} ${styles.PARTIAL}`}>{seizedAmount}</span>
                </Tooltip>
            );
        } else {
            return (
                <Tooltip title={<FormattedMessage id="seizureBankAccount.amountResponse.status" values={{ requestedAmount, seizedAmount }} />}>
                    <span className={`${styles.state} ${styles.NONE}`}>{seizedAmount}</span>
                </Tooltip>
            );
        }
    } else {
        return <></>;
    }
};
interface StateTagProps {
    amountResponse: CouncilTaxpayerSeizureBankAmountResponse | undefined;
}
