import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Card, Tooltip } from 'antd';
import FileSaver from 'file-saver';
import React, { useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import styles from './PaymentResponseComponent.module.scss';
import alertService from '../../../../services/AlertService';
import { CouncilTaxpayerSeizureBankPaymentResponse } from '../../../../models/Entities';
import ConstantLabel from '../../../../components/ConstantLabel/ConstantLabel';
import seizureBankPaymentResponseApi from '../../../../apis/SeizureBankPaymentResponseApi';

const PaymentResponseComponent: React.FC<Props> = (props) => {
    const { paymentResponse } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading'>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            if (paymentResponse.seizureBankPaymentResponse) {
                const file = await seizureBankPaymentResponseApi.download(paymentResponse.seizureBankPaymentResponse.id!);
                FileSaver.saveAs(file, paymentResponse.seizureBankPaymentResponse.file?.name);
            }
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <>
            <Card
                size="small"
                title={<ConstantLabel value={paymentResponse.phase} prefix="seizureBankAccount." />}
                extra={
                    <span className={styles.date}>
                        <FormattedDate
                            value={paymentResponse.audit?.created as any}
                            day="2-digit"
                            month="2-digit"
                            year="numeric"
                            hour="2-digit"
                            minute="2-digit"
                        />
                    </span>
                }
                actions={[
                    <StateTag paymentResponse={paymentResponse} key="state" />,
                    <Tooltip title={<FormattedMessage id="seizureBankAccount.paymentResponse.download" />}>
                        <Button
                            key="download"
                            type="text"
                            size="small"
                            icon={<CloudDownloadOutlined key="download" />}
                            onClick={download}
                            loading={loading === 'downloading'}
                        />
                    </Tooltip>
                ]}
                className={styles.card}
            >
                <p>
                    <FormattedMessage id="seizureBankAccount.paymentResponse" />
                </p>
            </Card>
        </>
    );
};
export default PaymentResponseComponent;
interface Props {
    paymentResponse: CouncilTaxpayerSeizureBankPaymentResponse;
}

/**
 * Returns a state tag.
 * @returns the state tag.
 */
/**
 * Returns a state tag.
 * @returns the state tag.
 */
const StateTag: React.FC<StateTagProps> = (props) => {
    const { paymentResponse } = props;

    const intl = useIntl();

    if (paymentResponse && paymentResponse.paidAmount) {
        const paidAmount: string = intl.formatNumber(paymentResponse.paidAmount, {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'EUR'
        });

        return (
            <Tooltip title={<FormattedMessage id="seizureBankAccount.paymentResponse.status" values={{ paidAmount }} />}>
                <span className={`${styles.state} ${styles.FULL}`}>{paidAmount}</span>
            </Tooltip>
        );
    } else {
        return <></>;
    }
};
interface StateTagProps {
    paymentResponse: CouncilTaxpayerSeizureBankPaymentResponse | undefined;
}
