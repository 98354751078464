import { ArrowLeftOutlined, FileTextOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tag, Tooltip } from 'antd';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import FileSaver from 'file-saver';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import seizureBankInfoRequestApi from '../../apis/SeizureBankInfoRequestApi';
import ConstantLabel from '../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { Page } from '../../models/Elements';
import { CustomAuth, SeizureBankInfoRequest } from '../../models/Entities';
import alertService from '../../services/AlertService';
import rolesService from '../../services/RolesService';
import tableService from '../../services/TableService';
import styles from './SeizureBankInfoRequestPage.module.scss';
import CustomContext from '../../contexts/CustomContext';
import SeizureBankInfoRequestModal from '../../components/ReceivableActionsMenu/SeizureBankInfoRequestModal/SeizureBankInfoRequestModal';

/**
 * Returns the seizure bank info request page.
 * @returns the seizure bank info request  page.
 */

const SeizureBankInfoRequestPage: React.FC = () => {
    /***HOOKS */

    const intl = useIntl();
    const context = useContext(CustomContext);
    const auth = context.auth as CustomAuth;
    const { council } = useContext(CustomCouncilContext);
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const [filter, setFilter] = useState<Filter>({});
    const [seizureBankInfoRequestsPage, setSeizureBankInfoRequestsPage] = useState<Page<SeizureBankInfoRequest>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('auditCreated');
    const [sortOrder, setSortOrder] = useState<boolean>(false);
    const [loading, setLoading] = useState<'loading' | 'downloading'>();
    const [seizureBankInfoRequestModalVisible, setSeizureBankInfoRequestModalVisible] = useState<boolean>(false);

    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const seizureBankInfoRequestsPage = await seizureBankInfoRequestApi.list(page, 100, sortField, sortOrder, council.id, filter.searchText);
                    setSeizureBankInfoRequestsPage(seizureBankInfoRequestsPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterSeizureBanks = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    const download = async (seizureBankInfoRequest: SeizureBankInfoRequest) => {
        try {
            setLoading('downloading');
            const document = await seizureBankInfoRequestApi.download(seizureBankInfoRequest.id!);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'seizureBankInfoRequest.request.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const save = async () => {
        try {
            setSeizureBankInfoRequestModalVisible(false);
            const seizureBankInfoRequestsPage = await seizureBankInfoRequestApi.list(page, 100, sortField, sortOrder, council.id, filter.searchText);
            setSeizureBankInfoRequestsPage(seizureBankInfoRequestsPage);
        } catch (error) {
            alertService.displayError(error, intl);
        }
    };

    /*** VISUAL ***/
    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);
    const items = seizureBankInfoRequestsPage ? seizureBankInfoRequestsPage.content : [];
    const columns: ColumnsType<SeizureBankInfoRequest> = [
        {
            title: <FormattedMessage id="seizureBankInfoRequest.created" />,
            dataIndex: ['audit', 'created'],
            key: 'created',
            align: 'center',
            width: 180,
            render: (value: any, seizureBankInfoRequest: SeizureBankInfoRequest) =>
                value && <FormattedDate value={value} day="2-digit" month="2-digit" year="numeric" hour="2-digit" minute="2-digit" />
        },

        {
            title: <FormattedMessage id="seizureBankInfoRequest.state" />,
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            width: 160,
            render: (value: any, seizureBankInfoRequest: SeizureBankInfoRequest) => (
                <>
                    {value === 'SENT' && (
                        <Tag color="green">
                            <ConstantLabel value={seizureBankInfoRequest.state} prefix="seizureIncome.state." />
                        </Tag>
                    )}
                    {value === 'FAILED' && (
                        <Tag color="red">
                            <ConstantLabel value={seizureBankInfoRequest.state} prefix="seizureIncome.state." />
                        </Tag>
                    )}
                </>
            )
        },

        {
            title: <FormattedMessage id="seizureBankInfoRequest.bank.name" />,
            dataIndex: ['bank', 'name'],
            key: 'bankName',
            align: 'center',
            width: 100
        },
        {
            key: 'actions',
            width: 120,
            align: 'center',
            render: (seizureBankInfoRequest: SeizureBankInfoRequest) => (
                <Space>
                    <Tooltip title={<FormattedMessage id="seizureBankInfoRequest.download.request" />}>
                        <Button icon={<FileTextOutlined />} className={styles.icon} onClick={() => download(seizureBankInfoRequest)} type="link" />
                    </Tooltip>
                </Space>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="users.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/seizure-incomes`, name: <FormattedMessage id="seizureBankInfoRequest.title" /> }
            ]}
            council={council}
        >
            <Form form={form} onFinish={filterSeizureBanks} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={24} className={styles.buttons}>
                        <Space>
                            <Link to={`/councils/${council?.id}`}>
                                <Button size="large" icon={<ArrowLeftOutlined />} />
                            </Link>
                            {isAdmin && (
                                <Tooltip title={<FormattedMessage id="seizureBankInfoRequest.new.tooltip" />}>
                                    <Button type="primary" size="large" icon={<PlusOutlined />} onClick={() => setSeizureBankInfoRequestModalVisible(true)}>
                                        {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                    </Button>
                                </Tooltip>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(seizureBankInfoRequestsPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
            {seizureBankInfoRequestModalVisible && council && council.id && (
                <SeizureBankInfoRequestModal councilId={council.id} onCancel={() => setSeizureBankInfoRequestModalVisible(false)} onSave={save} />
            )}
        </LayoutComponent>
    );
};
export default SeizureBankInfoRequestPage;

interface Filter {
    searchText?: string | undefined;
}
