import { PlusOutlined, ReconciliationOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import styles from './TaxpayersPage.module.scss';
import taxpayerApi from '../../apis/TaxpayerApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import ProvinceComponent from '../../components/ProvinceComponent/ProvinceComponent';
import { Page } from '../../models/Elements';
import { Taxpayer } from '../../models/Entities';
import alertService from '../../services/AlertService';
import tableService from '../../services/TableService';

/**
 * Returns the  taxpayers page.
 * @returns the taxpayers page.
 */

const TaxpayersPage: React.FC = () => {
    /*** HOOKS ***/
    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const [filter, setFilter] = useState<Filter>({});
    const [taxpayersPage, setTaxpayersPage] = useState<Page<Taxpayer>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('identifier');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [loading, setLoading] = useState<'loading'>();

    // list taxpayers
    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                const taxpayersPage = await taxpayerApi.list(page, tableService.pageSize, sortField, sortOrder, filter.searchText);
                setTaxpayersPage(taxpayersPage);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter]);

    /*** METHODS ***/
    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterUsers = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    /*** VISUAL ***/

    const items = taxpayersPage ? taxpayersPage.content : [];
    const columns: ColumnsType<Taxpayer> = [
        {
            title: <FormattedMessage id="councilTaxpayer.id" />,
            dataIndex: 'id',
            key: 'id',
            width: 120,
            fixed: 'left',
            sorter: false,
            defaultSortOrder: 'ascend'
        },
        {
            title: <FormattedMessage id="taxpayer.identifier" />,
            dataIndex: 'identifier',
            key: 'identifier',
            width: 120,
            fixed: 'left',
            sorter: false,
            defaultSortOrder: 'ascend'
        },
        {
            title: <FormattedMessage id="taxpayer.fullName" />,
            dataIndex: 'fullName',
            key: 'lastName',
            width: 260,
            fixed: 'left',
            sorter: false,
            defaultSortOrder: 'ascend',
            ellipsis: {
                showTitle: false
            },
            render: (value: string, taxpayer: Taxpayer) => <Tooltip title={taxpayer.fullName}>{taxpayer.fullName}</Tooltip>
        },
        {
            title: <FormattedMessage id="address.email" />,
            dataIndex: ['address', 'email'],
            key: 'email',
            width: 220,
            sorter: false
        },
        {
            title: <FormattedMessage id="address.street" />,
            dataIndex: ['address', 'street'],
            key: 'street',
            width: 240,
            sorter: false
        },
        {
            title: <FormattedMessage id="address.city" />,
            dataIndex: ['address', 'city'],
            key: 'city',
            width: 120,
            sorter: false
        },
        {
            title: <FormattedMessage id="address.province" />,
            dataIndex: ['address', 'province'],
            key: 'province',
            width: 120,
            sorter: false,
            render: (value: string) => <ProvinceComponent provinceId={value} />
        },
        {
            key: 'actions',
            width: 150,
            fixed: 'right',
            align: 'center',
            render: (record, taxpayer: Taxpayer) => (
                <Space size="large">
                    <Link to={`/taxpayers/${taxpayer.id}`}>
                        <Tooltip title={<FormattedMessage id="taxpayers.tooltip" />}>
                            <UserOutlined className={styles.icons} />
                        </Tooltip>
                    </Link>
                    <Link to={`/taxpayers/${taxpayer.id}/history`}>
                        <Tooltip title={<FormattedMessage id="taxpayers.history.tooltip" />}>
                            <ReconciliationOutlined className={styles.icons} />
                        </Tooltip>
                    </Link>
                </Space>
            )
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="taxpayers.title" />}
            menu="taxpayers"
            path={[{ path: '/taxpayers', name: <FormattedMessage id="taxpayers.title" /> }]}
        >
            <Form form={form} onFinish={filterUsers} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={20}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'taxpayers.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4} className={styles.buttons}>
                        {/* <Link to="/taxpayers/new"> */}
                        <Tooltip title={<FormattedMessage id="taxpayers.new.tooltip" />}>
                            <Button type="primary" size="large" icon={<PlusOutlined />} disabled>
                                {desktop && <FormattedMessage id="button.add" tagName="span" />}
                            </Button>
                        </Tooltip>
                        {/* </Link> */}
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(taxpayersPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default TaxpayersPage;

interface Filter {
    searchText?: string;
}
