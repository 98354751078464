import Icon, {
    BankOutlined,
    CloseOutlined,
    HomeOutlined,
    LogoutOutlined,
    MenuOutlined,
    PercentageOutlined,
    UserOutlined,
    SolutionOutlined
} from '@ant-design/icons';
import { App, Avatar, Breadcrumb, Button, Col, Divider, Drawer, Image, Layout, Menu, Row, Space } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { Footer } from 'antd/lib/layout/layout';
import React, { PropsWithChildren, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import CustomContext from '../../contexts/CustomContext';
import { BreadcrumbItem } from '../../models/Elements';
import { MenuType } from '../../models/Types';
import { ReactComponent as dashboardOneIcon } from '../../resources/images/dashboard1-icon.svg';
import taxfenIcon from '../../resources/images/taxfen-icon.svg';
import taxfenTitleIcon from '../../resources/images/taxfen-title-icon.svg';
import { ReactComponent as usersIcon } from '../../resources/images/users-icon.svg';
import authService from '../../services/AuthService';
import rolesService from '../../services/RolesService';
import './LayoutComponent.css';
import styles from './LayoutComponent.module.scss';
import useResponsiveLayout from './UseResponsiveLayout/UseResponsiveLayout';
import { Council } from '../../models/Entities';

const { Header, Sider, Content } = Layout;

const LayoutComponent: React.FC<Props> = (props) => {
    const { menu, path, council, children } = props;
    const intl = useIntl();
    const navigate = useNavigate();
    const [collapsed, setCollapsed] = useState(localStorage.getItem('collapsed') !== null && localStorage.getItem('collapsed') === 'true');
    const [desktop] = useResponsiveLayout();
    const { modal } = App.useApp();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const signOut = () => {
        modal.confirm({
            title: intl.formatMessage({ id: 'layout.header.menu.signOut.title' }),
            icon: <LogoutOutlined />,
            onOk: async () => {
                await authService.signOut();
                navigate('/signin');
            }
        });
    };

    /**
     * Collapses or expands the sidebar menu.
     * @param collapsed if the sidebar shuld be collapsed
     */
    const collapse = (collapsed: boolean) => {
        desktop ? localStorage.setItem('collapsed', collapsed.toString()) : localStorage.removeItem('collapsed');
        setCollapsed(collapsed);
    };

    /*** COMPONENTS ***/

    if (desktop) {
        return (
            <DesktopLayoutComponent menu={menu} children={children} collapse={collapse} collapsed={collapsed} path={path} council={council} signOut={signOut} />
        );
    } else {
        return <MobileLayoutComponent menu={menu} children={children} collapse={collapse} collapsed={collapsed} signOut={signOut} />;
    }
};
export default LayoutComponent;

interface Props extends PropsWithChildren {
    title: React.ReactNode | string;
    menu: MenuType;
    path?: BreadcrumbItem[];
    council?: Council;
}

const DesktopLayoutComponent = (props: DesktopProps): React.ReactElement => {
    const { collapsed, menu, children, collapse, signOut, path, council } = props;
    const navigate = useNavigate();

    const siderStyles = collapsed ? `${styles.sider} ${styles.collapsed}` : styles.sider;

    return (
        <Layout className={styles.layout} hasSider>
            <Sider collapsible collapsed={collapsed} onCollapse={(value) => collapse(value)} className={siderStyles} theme="light">
                <div className={styles.title}>
                    <Link to="/">
                        <Image src={taxfenIcon} preview={false} className={styles.logo} />
                        {!collapsed && <Image src={taxfenTitleIcon} preview={false} className={styles.logoTitle} />}
                    </Link>
                </div>
                <MenuComponent menu={menu} desktop={true} council={council} />
            </Sider>
            <Layout>
                <Header className={styles.header}>
                    <Breadcrumb className={styles.breadcrumb}>
                        <Breadcrumb.Item key="dashboard">
                            <Link to="/">
                                <HomeOutlined />
                            </Link>
                        </Breadcrumb.Item>
                        {path?.map((p) => (
                            <Breadcrumb.Item key={p.path}>
                                <Link to={p.path}>{p.name} </Link>
                            </Breadcrumb.Item>
                        ))}
                    </Breadcrumb>
                    <Menu
                        items={[
                            {
                                icon: <Avatar icon={<UserOutlined />} className={styles.avatar} />,
                                key: 'menu',
                                children: [
                                    {
                                        key: 'user',
                                        label: <FormattedMessage id="layout.header.menu.user" />,
                                        icon: <UserOutlined />,
                                        onClick: () => navigate('/settings')
                                    },
                                    {
                                        key: 'signout',
                                        label: <FormattedMessage id="layout.header.menu.signOut" />,
                                        icon: <LogoutOutlined />,
                                        onClick: signOut
                                    }
                                ]
                            }
                        ]}
                        mode="horizontal"
                    />
                </Header>
                <Content className={styles.content}>
                    <div className={styles.main}>{children}</div>
                </Content>
                <Footer className={styles.footer}>
                    <Row>
                        <Col className={styles.links}>
                            <Space className={styles.links} size="large">
                                <Link to="/legal-notice" className={styles.link}>
                                    <FormattedMessage id="common.legalNotice" />
                                </Link>
                                <Link to="/privacy-policy" className={styles.link}>
                                    <FormattedMessage id="common.privacyPolicy" />
                                </Link>
                            </Space>
                        </Col>
                        <Col className={styles.copyright}>
                            <a href="https://walfen.com" target="_blank" rel="noreferrer">
                                ©2024 Walfen Technologies S.L.U.
                            </a>
                        </Col>
                    </Row>
                </Footer>
            </Layout>
        </Layout>
    );
};

interface DesktopProps {
    collapsed: boolean;
    menu: MenuType;
    children: React.ReactNode;
    collapse: (collapse: boolean) => void;
    signOut: () => void;
    path?: BreadcrumbItem[];
    council?: Council;
}

const MobileLayoutComponent = (props: MobileProps): React.ReactElement => {
    const { collapsed, menu, children, collapse, signOut } = props;

    return (
        <Layout className={styles.mobileLayout}>
            <Header className={styles.header}>
                <div className={styles.headerLeft}>
                    <Link to="/">
                        <Image src={taxfenIcon} preview={false} className={styles.logo} />
                        <Image src={taxfenTitleIcon} preview={false} className={styles.logoTitle} />
                    </Link>
                </div>
                <div className={styles.headerRight}>
                    <Button type="link" onClick={() => collapse(true)} icon={<MenuOutlined />} />
                </div>
            </Header>
            <Drawer
                title={
                    <div className={styles.topDrawer}>
                        <div className={styles.topLeft}>
                            <Image src={taxfenIcon} preview={false} className={styles.logo} />
                            <Image src={taxfenTitleIcon} preview={false} className={styles.logoTitle} />
                        </div>
                        <Button type="link" className={styles.drawerClose} onClick={() => collapse(false)} icon={<CloseOutlined />} />
                    </div>
                }
                placement="right"
                closable={false}
                open={collapsed}
                width="100%"
            >
                <MenuComponent menu={menu} desktop={false} signOut={signOut} />
            </Drawer>
            <Content className={styles.content}>
                <div className={styles.main}>{children}</div>
            </Content>
            <Footer className={styles.footer}>
                <div>
                    <Space className={styles.links} size="large">
                        <Link to="/legal-notice" className={styles.link}>
                            <FormattedMessage id="common.legalNotice" />
                        </Link>
                        <Link to="/privacy-policy" className={styles.link}>
                            <FormattedMessage id="common.privacyPolicy" />
                        </Link>
                    </Space>
                </div>
                <div className={styles.copyright}>
                    <a href="https://walfen.com" target="_blank" rel="noreferrer">
                        ©2024 Walfen Technologies S.L.U.
                    </a>
                </div>
            </Footer>
        </Layout>
    );
};

interface MobileProps {
    collapsed: boolean;
    menu: MenuType;
    children: React.ReactNode;
    collapse: (collapse: boolean) => void;
    signOut: () => void;
}

const MenuComponent: React.FC<MenuProps> = (props) => {
    const { menu, desktop, signOut, council } = props;
    const context = useContext(CustomContext);
    const isCouncil: boolean = !rolesService.hasAnyRole(context.auth, ['ROLE_ADMIN']);

    const menuItems: MenuItemType[] = [
        {
            key: 'dashboard',
            icon: <Icon component={dashboardOneIcon} className="icon" />,
            label: (
                <Link to="/">
                    <FormattedMessage id="layout.menu.dashboard" />
                </Link>
            ),
            disabled: true
        },
        {
            key: 'councils',
            icon: <HomeOutlined />,
            label: (
                <Link to="/councils">
                    <FormattedMessage id="layout.menu.councils" />
                </Link>
            ),
            disabled: !!council
        },
        {
            key: 'council',
            icon: <HomeOutlined />,
            label: <Link to={`/councils/${council?.id}`}>{council?.name}</Link>,
            disabled: !council
        },
        {
            key: 'council-taxpayers',
            icon: <UserOutlined />,
            label: (
                <Link to={`/councils/${council?.id}/taxpayers`}>
                    <FormattedMessage id="layout.menu.councilTaxpayers" />
                </Link>
            ),
            disabled: !council || isCouncil
        },
        {
            key: 'receivables',
            icon: <SolutionOutlined />,
            label: (
                <Link to={`/councils/${council?.id}/receivables`}>
                    <FormattedMessage id="layout.menu.receivables" />
                </Link>
            ),
            disabled: !council || isCouncil
        },
        {
            key: 'taxpayers',
            icon: <UserOutlined />,
            label: (
                <Link to="/taxpayers">
                    <FormattedMessage id="layout.menu.taxpayers" />
                </Link>
            ),
            disabled: !!council || isCouncil
        },
        {
            key: 'banks',
            icon: <BankOutlined />,
            label: (
                <Link to="/banks">
                    <FormattedMessage id="layout.menu.banks" />
                </Link>
            ),
            disabled: !!council || isCouncil
        },
        {
            key: 'nationalParameters',
            icon: <PercentageOutlined className={styles.icon} />,
            label: (
                <Link to="/national-parameters">
                    <FormattedMessage id="layout.menu.nationalParameters" />
                </Link>
            ),
            disabled: !!council || isCouncil
        },
        {
            key: 'users',
            icon: <Icon component={usersIcon} className="icon" />,
            label: (
                <Link to="/users">
                    <FormattedMessage id="layout.menu.users" />
                </Link>
            ),
            disabled: !!council || isCouncil
        },
        {
            key: 'divider',
            label: <Divider />,
            disabled: desktop
        },
        {
            key: 'settings',
            icon: <UserOutlined />,
            label: (
                <Link to="/settings">
                    <FormattedMessage id="layout.header.menu.user" />
                </Link>
            ),
            disabled: desktop
        },
        {
            key: 'signOut',
            icon: <LogoutOutlined />,
            label: <FormattedMessage id="layout.header.menu.signOut" />,
            disabled: desktop,
            onClick: signOut
        }
    ];
    const availableMenuItems = menuItems.filter((i) => !i.disabled);

    return <Menu mode="inline" selectedKeys={[menu]} items={availableMenuItems} className={styles.menu} />;
};

interface MenuProps extends PropsWithChildren {
    menu: MenuType;
    desktop?: boolean;
    signOut?: () => void;
    council?: Council;
}
