import { ArrowLeftOutlined, SaveOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { UploadFile } from 'antd/lib';
import { PropsWithChildren, useState } from 'react';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import receivableRegistrationDocumentApi from '../../../apis/ReceivableRegistrationDocumentApi';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import { Council, ReceivableRegistration, ReceivableRegistrationDocument, ReceivableRegistrationError } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './SummaryComponent.module.scss';
import ProvinceComponent from '../../../components/ProvinceComponent/ProvinceComponent';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import { ReceivableRegistrationFieldType } from '../../../models/Types';

/**
 * Returns the summary component.
 * @returns the summary component.
 */
const SummaryComponent: React.FC<Props> = (props) => {
    const { onImport, onBack, receivableRegistrations, council, file } = props;

    /***HOOKS***/

    const [desktop] = useResponsiveLayout();
    const intl = useIntl();
    const [loading, setLoading] = useState<'loading'>();

    /***METHODS***/

    const save = async () => {
        try {
            setLoading('loading');
            let receivableRegistrationDocument: ReceivableRegistrationDocument = {
                councilId: council.id
            };
            receivableRegistrationDocument = await receivableRegistrationDocumentApi.create(receivableRegistrationDocument, file);

            onImport(receivableRegistrationDocument);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const errors = receivableRegistrations.map((r) => r.errors.length).reduce((partialSum, a) => partialSum + a, 0);

    /***VISUAL***/

    const columns: ColumnsType<ReceivableRegistration> = [
        {
            dataIndex: 'errors',
            key: 'errors',
            width: 50,
            fixed: 'left',
            align: 'center',
            render: (errors: ReceivableRegistrationError[]) => {
                if (errors && errors.length > 0) {
                    return (
                        <Tooltip title={<FormattedMessage id="receivables.import.steps.2.status.error" />}>
                            <WarningOutlined className={`${styles.status} ${styles.error}`} />
                        </Tooltip>
                    );
                } else {
                    return <CheckCircleOutlined className={`${styles.status} ${styles.success}`} />;
                }
            }
        },
        {
            title: <FormattedMessage id="taxpayer.identifier" />,
            dataIndex: 'identifier',
            key: 'identifier',
            width: 150,
            fixed: 'left',
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="IDENTIFIER">
                    {value}
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="taxpayer.firstName" />,
            dataIndex: 'firstName',
            key: 'firstName',
            width: 220,
            fixed: 'left',
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="FIRST_NAME">
                    {value}
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="taxpayer.lastName" />,
            dataIndex: 'lastName',
            key: 'lastName',
            width: 150,
            fixed: 'left',
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="LAST_NAME">
                    {value}
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="taxpayer.secondLastName" />,
            dataIndex: 'secondLastName',
            key: 'secondLastName',
            width: 150,
            fixed: 'left',
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="SECOND_LAST_NAME">
                    {value}
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="address.tax" />,
            dataIndex: ['address', 'street'],
            key: 'street',
            width: 220,
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="ADDRESS_STREET">
                    {value}
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="address.postcode" />,
            dataIndex: ['address', 'postcode'],
            key: 'postcode',
            width: 150,
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="ADDRESS_POSTCODE">
                    {value}
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="address.city" />,
            dataIndex: ['address', 'city'],
            key: 'city',
            width: 220,
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="ADDRESS_CITY">
                    {value}
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="address.province" />,
            dataIndex: ['address', 'province'],
            key: 'province',
            width: 220,
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="ADDRESS_PROVINCE">
                    <ProvinceComponent provinceId={value} />
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="address.phone" />,
            dataIndex: ['address', 'phone'],
            key: 'phone',
            width: 220,
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="ADDRESS_PHONE">
                    {value}
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="address.email" />,
            dataIndex: ['address', 'email'],
            key: 'email',
            width: 250,
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="ADDRESS_EMAIL">
                    {value}
                </FormattedCell>
            )
        },

        {
            title: <FormattedMessage id="receivable.subtax" />,
            dataIndex: 'subtax',
            key: 'subtax',
            align: 'center',
            width: 240,
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="SUBTAX">
                    <ConstantLabel value={value} prefix="receivable.subtax." />
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="receivable.taxObject" />,
            dataIndex: 'taxObject',
            key: 'taxObject',
            align: 'center',
            width: 200,
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="TAX_OBJECT">
                    {value}
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="receivable.fiscalYear" />,
            dataIndex: 'fiscalYear',
            key: 'fiscalYear',
            align: 'center',
            width: 100,
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="FISCAL_YEAR">
                    {value}
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="receivable.fiscalPeriod" />,
            dataIndex: 'fiscalPeriod',
            key: 'fiscalPeriod',
            align: 'center',
            width: 100,
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="FISCAL_PERIOD">
                    <ConstantLabel value={value} prefix="receivable.fiscalPeriod." />
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="receivable.amount.principal" />,
            dataIndex: ['amount', 'principal'],
            key: 'principal',
            align: 'right',
            width: 100,
            render: (value: number, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="AMOUNT_PRINCIPAL">
                    <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="receivable.amount.surcharge" />,
            dataIndex: ['amount', 'surcharge'],
            key: 'surcharge',
            align: 'right',
            width: 100,
            render: (value: number, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="AMOUNT_SURCHARGE">
                    <FormattedNumber value={value} minimumFractionDigits={2} maximumFractionDigits={2} style={'currency' as any} currency="EUR" />
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="receivable.expired" />,
            dataIndex: 'expired',
            key: 'expired',
            align: 'right',
            width: 200,
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="EXPIRED">
                    {value}
                </FormattedCell>
            )
        },
        {
            title: <FormattedMessage id="receivable.reference" />,
            dataIndex: 'reference',
            key: 'reference',
            align: 'right',
            width: 150,
            render: (value: string, receivableRegistration: ReceivableRegistration) => (
                <FormattedCell receivableRegistration={receivableRegistration} field="REFERENCE">
                    {value}
                </FormattedCell>
            )
        }
    ];

    return (
        <>
            <Table
                dataSource={receivableRegistrations}
                columns={columns}
                rowKey="id"
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                pagination={false}
                className="table"
                scroll={{ x: 2400 }}
            />
            <Row gutter={[28, 0]} className="buttons">
                <Col span={24}>
                    <Space>
                        {errors > 0 && (
                            <Tooltip title={<FormattedMessage id="receivables.import.tooltip.error.title" />}>
                                <Button type="primary" size="large" icon={<SaveOutlined />} onClick={save} disabled>
                                    <FormattedMessage id="button.import" tagName="span" />
                                </Button>
                            </Tooltip>
                        )}
                        {errors === 0 && (
                            <Button type="primary" size="large" loading={loading === 'loading'} icon={<SaveOutlined />} onClick={save}>
                                <FormattedMessage id="button.import" tagName="span" />
                            </Button>
                        )}
                        <Button size="large" icon={<ArrowLeftOutlined />} onClick={onBack}>
                            {desktop && <FormattedMessage id="button.back" tagName="span" />}
                        </Button>
                    </Space>
                </Col>
            </Row>
        </>
    );
};
export default SummaryComponent;

interface Props {
    council: Council;
    receivableRegistrations: ReceivableRegistration[];
    file: UploadFile | undefined;
    onImport: (receivableRegistrationDocument: ReceivableRegistrationDocument) => void;
    onBack: () => void;
}

/**
 * Returns the styling for a cell with import error.
 * @returns the styling for a cell with import error.
 */
const FormattedCell: React.FC<FormattedCellProps> = (props) => {
    const { children, receivableRegistration, field } = props;
    //find errors for a specific field, the result is the array of ReceivableRegistrationErrors
    const errors = receivableRegistration.errors.filter((e) => e.field === field);

    if (errors.length > 0) {
        return (
            <span className={styles.errorCell}>
                <FormattedCellTooltip errors={errors} />
            </span>
        );
    } else {
        return <>{children}</>;
    }
};
interface FormattedCellProps extends PropsWithChildren {
    receivableRegistration: ReceivableRegistration;
    field: ReceivableRegistrationFieldType;
}

/**
 * Returns the formatted text in the tooltip.
 * @returns the formatted text in the tooltip.
 */
const FormattedCellTooltip: React.FC<FormattedCellTooltipProps> = (props) => {
    const { errors } = props;

    const value = errors[0].value;
    const titles = (
        <>
            {errors.map((error) => (
                <>
                    <FormattedMessage id={`receivables.import.error.${error.error}`} values={{ parameter1: error.parameter1 }} />
                    <br />
                </>
            ))}
        </>
    );

    return <Tooltip title={titles}>{value || '---'}</Tooltip>;
};
interface FormattedCellTooltipProps {
    errors: ReceivableRegistrationError[];
}
