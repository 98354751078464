import { Page } from '../models/Elements';
import { Council } from '../models/Entities';
import { StatusType } from '../models/Types';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class CouncilApi {
    list = async (page: number, size: number, sortField: string, sortOrder: boolean, status?: StatusType, searchText?: string): Promise<Page<Council>> => {
        const response = await adminApiAxios.get<Page<Council>>('/councils', {
            params: { page, size, sortField, sortOrder, status, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((user) => this.loadDates(user));

        return response.data;
    };

    get = async (councilId: number): Promise<Council> => {
        const response = await adminApiAxios.get<Council>(`/councils/${councilId}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (council: Council): Promise<Council> => {
        const response = await adminApiAxios.post<Council>(`/councils`, council);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (council: Council): Promise<Council> => {
        const response = await adminApiAxios.put<Council>(`/councils/${council.id}`, council);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (councilId: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/councils/${councilId}`);

        return response.data;
    };

    /**
     * Loads the dates of an council.
     * @param council - the council
     */
    private loadDates(council: Council) {
        council.audit!.created = dateService.transformDate(council.audit!.created)!;
        council.audit!.updated = dateService.transformDate(council.audit!.updated)!;
    }
}

const councilApi: CouncilApi = new CouncilApi();
export default councilApi;
