import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Space, message } from 'antd';
import FileSaver from 'file-saver';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import seizureBankCancelRequestApi from '../../../apis/SeizureBankCancelRequestApi';
import {
    CouncilTaxpayerSeizureBankAmountResponse,
    CouncilTaxpayerSeizureBankCancelRequest,
    SeizureBankCancelRequest,
    SeizureBankCancelRequestDraft
} from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './SeizureBankCancelRequestModal.module.scss';

const SeizureBankCancelRequestModal = (props: Props) => {
    const { councilId, amountResponse } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [draft, setDraft] = useState<SeizureBankCancelRequestDraft>();
    const [loading, setLoading] = useState<'loading' | 'saving'>();

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                if (amountResponse && amountResponse.seizureBankAmountResponse) {
                    setLoading('loading');
                    const councilTaxpayerId = amountResponse.councilTaxpayerSeizureBank.councilTaxpayer.id;
                    const councilTaxpayerSeizureBankId = amountResponse.councilTaxpayerSeizureBank.id;
                    // get draft of seizure bank account cancel request file (c63)
                    const draft = await seizureBankCancelRequestApi.createDraft(councilTaxpayerId, councilTaxpayerSeizureBankId!);
                    setDraft(draft);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [amountResponse, councilId, intl]);

    /*** METHODS ***/

    const save = async () => {
        try {
            if (amountResponse && amountResponse.seizureBankAmountResponse) {
                setLoading('saving');
                const councilTaxpayerId = amountResponse.councilTaxpayerSeizureBank.councilTaxpayer.id;
                const councilTaxpayerSeizureBankId = amountResponse.councilTaxpayerSeizureBank.id;
                await seizureBankCancelRequestApi.create(councilTaxpayerId, councilTaxpayerSeizureBankId!);
                await props.onSave();
                message.success(intl.formatMessage({ id: 'status.saved' }));
            }
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // cancel
    const cancel = async () => {
        props.onCancel();
    };

    /*** VISUAL ***/

    const ctSeizureBankCancelRequests = draft ? draft.ctSeizureBankCancelRequests : [];

    return (
        <Modal
            title={<FormattedMessage id="seizureBankCancelRequestModal.title" />}
            open={true}
            onCancel={cancel}
            onOk={save}
            okText={<FormattedMessage id="seizureBankCancelRequestModal.send" tagName="span" />}
            okButtonProps={{ loading: loading === 'saving', size: 'large' }}
            cancelButtonProps={{ disabled: loading === 'saving', size: 'large' }}
            width={1000}
        >
            <Row gutter={[0, 0]} className={styles.container}>
                <Col span={24}>
                    <FormattedMessage id="seizureBankCancelRequestModal.banks" />
                    <CouncilTaxpayerSeizureCancelBanks amountResponse={amountResponse} ctSeizureBankCancelRequests={ctSeizureBankCancelRequests} />
                </Col>
            </Row>
        </Modal>
    );
};
export default SeizureBankCancelRequestModal;

interface Props {
    councilId: number;
    amountResponse: CouncilTaxpayerSeizureBankAmountResponse | undefined;
    onSave: () => Promise<void>;
    onCancel: () => void;
}

const CouncilTaxpayerSeizureCancelBanks: React.FC<CouncilTaxpayerSeizureCancelBanksProps> = (props) => {
    const { ctSeizureBankCancelRequests, amountResponse } = props;

    /*** HOOKS ***/
    const [loading, setLoading] = useState<string>();
    const intl = useIntl();

    /*** METHODS ***/

    const download = async (seizureBankCancelRequest: SeizureBankCancelRequest) => {
        try {
            setLoading(`downloading-${seizureBankCancelRequest.bank.id}`);
            const councilTaxpayerSeizureBankId = amountResponse!.councilTaxpayerSeizureBank.id;
            const document = await seizureBankCancelRequestApi.downloadDraft(councilTaxpayerSeizureBankId);
            FileSaver.saveAs(document, 'c63.dat');
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    // group council taxpayer seizure banks by council bank id or bank id
    const ctSeizureBankCancelRequestsByBanks = _.groupBy(ctSeizureBankCancelRequests, (ctscb) => ctscb.councilTaxpayerSeizureBank.bank.id);

    if (Object.keys(ctSeizureBankCancelRequestsByBanks).length > 0) {
        return (
            <ul>
                {Object.keys(ctSeizureBankCancelRequestsByBanks).map((bankId) => {
                    const ctscbs = ctSeizureBankCancelRequestsByBanks[bankId];
                    const bankName = ctscbs[0].councilTaxpayerSeizureBank.bank.name;

                    return (
                        <li className={styles.bank}>
                            <Space>
                                <h4>{bankName}</h4>
                                <Button
                                    icon={<CloudDownloadOutlined />}
                                    size="small"
                                    onClick={() => download(ctscbs[0].seizureBankCancelRequest)}
                                    loading={loading === `downloading-${bankId}`}
                                ></Button>
                            </Space>
                        </li>
                    );
                })}
            </ul>
        );
    } else {
        return (
            <p>
                <ul>
                    <li>
                        <strong>
                            <FormattedMessage id="seizureBankCancelRequestModal.councilTaxpayerSeizureBanks.empty" />
                        </strong>
                    </li>
                </ul>
            </p>
        );
    }
};
interface CouncilTaxpayerSeizureCancelBanksProps {
    ctSeizureBankCancelRequests: CouncilTaxpayerSeizureBankCancelRequest[];
    amountResponse: CouncilTaxpayerSeizureBankAmountResponse | undefined;
}
