import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router';
import taxpayerApi from '../../apis/TaxpayerApi';
import CustomTaxpayerContext from '../../contexts/CustomTaxpayerContext';
import { Taxpayer } from '../../models/Entities';

/**
 * This component represents a taxpayer wrapper.
 * @param props - the props
 */
const TaxpayerWrapper: React.FC = () => {
    const params = useParams<ParamsType>();
    const [taxpayer, setTaxpayer] = useState<Taxpayer>({});

    // initialize the app
    useEffect(() => {
        const init = async () => {
            if (params.taxpayerId && params.taxpayerId !== 'new') {
                const taxpayer = await taxpayerApi.get(+params.taxpayerId);
                setTaxpayer(taxpayer);
            }
        };
        init();
    }, [params.taxpayerId]);

    return (
        <CustomTaxpayerContext.Provider value={{ taxpayer, setTaxpayer }}>
            <Outlet />
        </CustomTaxpayerContext.Provider>
    );
};
export default TaxpayerWrapper;
type ParamsType = { taxpayerId: string };
