import { Page } from '../models/Elements';
import { PaymentLetter, PaymentLetterCreation, PaymentLetterDraft } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class PaymentLetterApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId?: number,
        councilTaxpayerId?: number,
        searchText?: string
    ): Promise<Page<PaymentLetter>> => {
        const response = await adminApiAxios.get<Page<PaymentLetter>>('/payment-letters', {
            params: { page, size, sortField, sortOrder, councilId, councilTaxpayerId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<PaymentLetter> => {
        const response = await adminApiAxios.get<PaymentLetter>(`/payment-letters/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (paymentLetterCreation: PaymentLetterCreation): Promise<PaymentLetter> => {
        const response = await adminApiAxios.post<PaymentLetter>('/payment-letters', paymentLetterCreation);
        this.loadDates(response.data);

        return response.data;
    };

    createDraft = async (paymentLetterCreation: PaymentLetterCreation): Promise<PaymentLetterDraft> => {
        const response = await adminApiAxios.post<PaymentLetterDraft>('/payment-letters/draft', paymentLetterCreation);
        this.loadDraftDates(response.data);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/payment-letters/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    downloadDraft = async (paymentLetterCreation: PaymentLetterCreation): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(`/payment-letters/draft/file`, paymentLetterCreation, { responseType: 'blob' });

        return response.data;
    };

    /**
     * Loads the dates of a paymentLetter.
     * @param paymentLetter - the payment letter
     */
    private loadDates(paymentLetter: PaymentLetter) {
        paymentLetter.sent = dateService.transformDate(paymentLetter.sent);
        paymentLetter.audit!.created = dateService.transformDate(paymentLetter.audit!.created)!;
        paymentLetter.audit!.updated = dateService.transformDate(paymentLetter.audit!.updated)!;
    }

    /**
     * Loads the dates of the payment letter  draft.
     * @param paymentLetterDraft - the payment letter  draft
     */
    private loadDraftDates(paymentLetterDraft: PaymentLetterDraft) {
        const { paymentLetter, receivablePaymentLetters } = paymentLetterDraft;
        this.loadDates(paymentLetter);

        receivablePaymentLetters.forEach((receivablePaymentLetter) => {
            receivablePaymentLetter.audit!.created = dateService.transformDate(receivablePaymentLetter.audit!.created)!;
            receivablePaymentLetter.audit!.updated = dateService.transformDate(receivablePaymentLetter.audit!.updated)!;
        });
    }
}

const paymentLetterApi: PaymentLetterApi = new PaymentLetterApi();
export default paymentLetterApi;
