import { Page } from '../models/Elements';
import { CouncilTaxpayer } from '../models/Entities';
import { StatusType } from '../models/Types';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class CouncilTaxpayerApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId: number,
        searchText?: string,
        status?: StatusType
    ): Promise<Page<CouncilTaxpayer>> => {
        const response = await adminApiAxios.get<Page<CouncilTaxpayer>>(`/council-taxpayers`, {
            params: { page, size, sortField, sortOrder, councilId, searchText, status }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((councilTaxpayer) => this.loadDates(councilTaxpayer));

        return response.data;
    };

    get = async (id: number): Promise<CouncilTaxpayer> => {
        const response = await adminApiAxios.get<CouncilTaxpayer>(`/council-taxpayers/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    /**
     * Loads the dates of a council taxpayers.
     * @param councilTaxpayer - the  council taxpayer
     */
    private loadDates(councilTaxpayer: CouncilTaxpayer) {
        councilTaxpayer.taxpayer.audit!.created = dateService.transformDate(councilTaxpayer.taxpayer.audit!.created)!;
        councilTaxpayer.taxpayer.audit!.updated = dateService.transformDate(councilTaxpayer.taxpayer.audit!.updated)!;
    }
}

const councilTaxpayerApi: CouncilTaxpayerApi = new CouncilTaxpayerApi();
export default councilTaxpayerApi;
