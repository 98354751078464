import { ReceivableCancellation } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class ReceivableCancellationApi {
    create = async (receivableCancellation: ReceivableCancellation, file: any): Promise<ReceivableCancellation> => {
        const formData: FormData = this.buildFormData(receivableCancellation, file);
        const response = await adminApiAxios.post<ReceivableCancellation>(`/receivable-cancellations`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);
        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/receivable-cancellations/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Builds a form data from a receivable cancellation document.
     * @param receivableCancellationDocument the receivable cancellation document
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (receivableCancellation: ReceivableCancellation, file?: File) => {
        const formData: FormData = new FormData();
        receivableCancellation.id && formData.append('id', receivableCancellation.id.toString());
        receivableCancellation.receivableId && formData.append('receivableId', receivableCancellation.receivableId.toString());
        receivableCancellation.remarks && formData.append('remarks', receivableCancellation.remarks.toString());
        receivableCancellation.canceled && formData.append('canceled', receivableCancellation.canceled.format('YYYY-MM-DDThh:mm:ss'));
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a receivableCancellation.
     * @param receivableCancellation - the receivable cancellation
     */
    private loadDates(receivableCancellation: ReceivableCancellation) {
        receivableCancellation.audit!.created = dateService.transformDate(receivableCancellation.audit!.created)!;
        receivableCancellation.audit!.updated = dateService.transformDate(receivableCancellation.audit!.updated)!;
    }
}

const receivableCancellationApi: ReceivableCancellationApi = new ReceivableCancellationApi();
export default receivableCancellationApi;
