import Icon, { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Col, Form, Image, Input, message, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import PublicLayoutComponent from '../../../components/PublicLayoutComponent/PublicLayoutComponent';
import CustomContext from '../../../contexts/CustomContext';
import { ReactComponent as emailIcon } from '../../../resources/images/email-icon.svg';
import { ReactComponent as lockIcon } from '../../../resources/images/lock-icon.svg';
import taxfenIcon from '../../../resources/images/taxfen-icon.svg';
import authService from '../../../services/AuthService';
import InfoComponent from '../InfoComponent/InfoComponent';
import styles from './SignInPage.module.scss';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';

/**
 * Returns the sign in page.
 * @returns the sign in page.
 */
const SignInPage: React.FC = () => {
    /***HOOKS */
    const [awsCognitoAuth, setAwsCognitoAuth] = useState();
    const [desktop] = useResponsiveLayout();

    return (
        <PublicLayoutComponent>
            <Col xs={0} lg={12}>
                <InfoComponent />
            </Col>
            <Col md={24} lg={12} className={`${styles.form} ${desktop ? styles.desktop : styles.mobile}`}>
                {awsCognitoAuth && <CompleteNewPasswordComponent awsCognitoAuth={awsCognitoAuth} />}
                {!awsCognitoAuth && <SignInComponent setAwsCognitoAuth={setAwsCognitoAuth} />}
            </Col>
        </PublicLayoutComponent>
    );
};
export default SignInPage;

const SignInComponent: React.FC<SignInProps> = (props) => {
    const { setAwsCognitoAuth } = props;

    /*** HOOKS ***/
    const context = useContext(CustomContext);
    const [form] = Form.useForm();
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<'loading'>();

    /*** METHODS ***/

    const signIn = async (values: any) => {
        try {
            setLoading('loading');
            const auth = await authService.signIn(values.username, values.password);

            if ('challengeName' in auth) {
                setAwsCognitoAuth(auth);
            } else {
                context.setAuth(auth);
                navigate('/');
            }
        } catch (error: any) {
            if (error.message && error.message === 'Incorrect username or password.') {
                message.error(intl.formatMessage({ id: 'signIn.status.incorrect' }), 6);
            } else if (error.message && error.message === 'Temporary password has expired and must be reset by an administrator.') {
                message.error(intl.formatMessage({ id: 'signIn.status.expired' }), 6);
            } else if (error.message && error.message === 'User does not exist.') {
                message.error(intl.formatMessage({ id: 'signIn.status.user.inexistent' }), 6);
            } else if (error.message) {
                message.error(error.message, 6);
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
            }
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <Form form={form} onFinish={signIn} colon={false} layout="vertical">
            <Row>
                <Col span={24} className={styles.panel}>
                    <Image src={taxfenIcon} preview={false} />
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panel}>
                    <div className={styles.title}>
                        <FormattedMessage id="signIn.welcome" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panel}>
                    <div className={styles.loginToAcc}>
                        <FormattedMessage id="signIn.login" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="username" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]} required={false}>
                        <Input
                            maxLength={100}
                            size="large"
                            placeholder={intl.formatMessage({ id: 'signIn.email' })}
                            prefix={<Icon component={emailIcon} className={styles.icon} />}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item name="password" rules={[{ required: true, message: <FormattedMessage id="status.mandatory" /> }]} required={false}>
                        <Input.Password
                            type="password"
                            size="large"
                            placeholder={intl.formatMessage({ id: 'signIn.password' })}
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            prefix={<Icon component={lockIcon} className={styles.icon} />}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.recoverPassword}>
                    <Link to="/forgot-password">
                        <Button type="link" className={styles.recoverButton}>
                            <FormattedMessage id="signIn.recoverPassword" />
                        </Button>
                    </Link>
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panel}>
                    <span className={styles.haveAccountText}>
                        <FormattedMessage id="signIn.haveAnAccount" />
                    </span>
                    <a href="/signup" className={styles.signUp}>
                        <FormattedMessage id="signIn.signup" />
                    </a>
                </Col>
            </Row>
            <Row>
                <Col span={14} className={styles.button}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} block className={styles.signinButton}>
                            <FormattedMessage id="signIn.submit" tagName="span" />
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
interface SignInProps {
    setAwsCognitoAuth: (awsCognitoAuth: any) => void;
}

const CompleteNewPasswordComponent: React.FC<CompleteNewPasswordProps> = (props) => {
    const { awsCognitoAuth } = props;

    /*** HOOKS ***/
    const context = useContext(CustomContext);
    const [form] = Form.useForm();
    const intl = useIntl();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<'loading'>();

    /*** METHODS ***/

    const completeNewPassword = async (values: any) => {
        try {
            setLoading('loading');
            const auth = await authService.completeNewPassword(awsCognitoAuth, values.password);
            context.setAuth(auth);
            navigate('/');
        } catch (error: any) {
            if (error.message) {
                message.error(error.message, 6);
            } else {
                message.error(intl.formatMessage({ id: 'status.internalError' }), 6);
            }
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <Form form={form} onFinish={completeNewPassword} colon={false} layout="vertical">
            <Row>
                <Col span={24} className={styles.panel}>
                    <Image src={taxfenIcon} preview={false} />
                </Col>
            </Row>
            <Row>
                <Col span={24} className={styles.panel}>
                    <div className={styles.title}>
                        <FormattedMessage id="signIn.title.completeNewPassword" />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item
                        name="password"
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                pattern: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-_.,;]).{8,}$/,
                                message: <FormattedMessage id="status.password" />
                            }
                        ]}
                        required={false}
                    >
                        <Input.Password
                            type="password"
                            size="large"
                            maxLength={35}
                            placeholder={intl.formatMessage({ id: 'signIn.password' })}
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            prefix={<Icon component={lockIcon} className={styles.icon} />}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={8} className={styles.button}>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} block className={styles.signinButton}>
                            <FormattedMessage id="signIn.submit.completeNewPassword" tagName="span" />
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
interface CompleteNewPasswordProps {
    awsCognitoAuth: any | undefined;
}
