import { Page } from '../models/Elements';
import { SeizureBankInfoRequest, SeizureBankInfoRequestCreation, SeizureBankInfoRequestDraft } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class SeizureBankInfoRequestApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId?: number,
        searchText?: string
    ): Promise<Page<SeizureBankInfoRequest>> => {
        const response = await adminApiAxios.get<Page<SeizureBankInfoRequest>>('/seizure-bank-info-requests', {
            params: { page, size, sortField, sortOrder, councilId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<SeizureBankInfoRequest> => {
        const response = await adminApiAxios.get<SeizureBankInfoRequest>(`/seizure-bank-info-requests/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (seizureBankInfoRequestCreation: SeizureBankInfoRequestCreation): Promise<SeizureBankInfoRequest[]> => {
        const response = await adminApiAxios.post<SeizureBankInfoRequest[]>(`/seizure-bank-info-requests`, seizureBankInfoRequestCreation);
        response.data.forEach((seizureBankInfoRequest) => this.loadDates(seizureBankInfoRequest));

        return response.data;
    };

    createDraft = async (seizureBankInfoRequestCreation: SeizureBankInfoRequestCreation): Promise<SeizureBankInfoRequestDraft> => {
        const response = await adminApiAxios.post<SeizureBankInfoRequestDraft>(`/seizure-bank-info-requests/draft`, seizureBankInfoRequestCreation);
        this.loadDraftDates(response.data);

        return response.data;
    };

    downloadDraft = async (councilId: number, bankId: number): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(`/seizure-bank-info-requests/draft/file`, {}, { responseType: 'blob', params: { councilId, bankId } });

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/seizure-bank-info-requests/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Loads the dates of the seizure bank info request.
     * @param seizureBankInfoRequest - the seizure bank info request
     */
    private loadDates(seizureBankInfoRequest: SeizureBankInfoRequest) {
        seizureBankInfoRequest.audit!.created = dateService.transformDate(seizureBankInfoRequest.audit!.created)!;
        seizureBankInfoRequest.audit!.updated = dateService.transformDate(seizureBankInfoRequest.audit!.updated)!;
    }

    /**
     * Loads the dates of the seizure bank info request draft.
     * @param seizureBankInfoRequestDraft - the seizure bank info request draft
     */
    private loadDraftDates(seizureBankInfoRequestDraft: SeizureBankInfoRequestDraft) {
        const { seizureBankInfoRequests, ctSeizureBankInfoRequests } = seizureBankInfoRequestDraft;
        seizureBankInfoRequests.forEach((seizureBankInfoRequest) => {
            this.loadDates(seizureBankInfoRequest);
        });

        ctSeizureBankInfoRequests.forEach((ctSeizureBankInfoRequest) => {
            ctSeizureBankInfoRequest.audit!.created = dateService.transformDate(ctSeizureBankInfoRequest.audit!.created)!;
            ctSeizureBankInfoRequest.audit!.updated = dateService.transformDate(ctSeizureBankInfoRequest.audit!.updated)!;
        });
    }
}

const seizureBankInfoRequestApi: SeizureBankInfoRequestApi = new SeizureBankInfoRequestApi();
export default seizureBankInfoRequestApi;
