import { Page } from '../models/Elements';
import { PaymentOrderUnofficial, PaymentOrderUnofficialCreation, PaymentOrderUnofficialDraft } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class PaymentOrderUnofficialApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId?: number,
        councilTaxpayerId?: number,
        searchText?: string
    ): Promise<Page<PaymentOrderUnofficial>> => {
        const response = await adminApiAxios.get<Page<PaymentOrderUnofficial>>('/payment-order-unofficials', {
            params: { page, size, sortField, sortOrder, councilId, councilTaxpayerId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<PaymentOrderUnofficial> => {
        const response = await adminApiAxios.get<PaymentOrderUnofficial>(`/payment-order-unofficials/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (paymentOrderCreation: PaymentOrderUnofficialCreation): Promise<PaymentOrderUnofficial[]> => {
        const response = await adminApiAxios.post<PaymentOrderUnofficial[]>('/payment-order-unofficials', paymentOrderCreation);
        response.data.forEach((paymentOrder) => this.loadDates(paymentOrder));

        return response.data;
    };

    createDraft = async (paymentOrderUnofficialCreation: PaymentOrderUnofficialCreation): Promise<PaymentOrderUnofficialDraft> => {
        const response = await adminApiAxios.post<PaymentOrderUnofficialDraft>('/payment-order-unofficials/draft', paymentOrderUnofficialCreation);
        this.loadDraftDates(response.data);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/payment-order-unofficials/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    downloadDraft = async (councilTaxpayerId: number, clone?: boolean): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(
            `/payment-order-unofficials/draft/file`,
            {},
            { responseType: 'blob', params: { councilTaxpayerId, clone } }
        );

        return response.data;
    };

    /**
     * Loads the dates of a paymentOrderUnofficial.
     * @param paymentOrderUnofficial - the payment order
     */
    private loadDates(paymentOrderUnofficial: PaymentOrderUnofficial) {
        paymentOrderUnofficial.audit!.created = dateService.transformDate(paymentOrderUnofficial.audit!.created)!;
        paymentOrderUnofficial.audit!.updated = dateService.transformDate(paymentOrderUnofficial.audit!.updated)!;
    }

    /**
     * Loads the dates of the payment order  draft.
     * @param paymentOrderUnofficialDraft - the payment order  unofficial draft
     */
    private loadDraftDates(paymentOrderUnofficialDraft: PaymentOrderUnofficialDraft) {
        const { paymentOrderUnofficials, receivableNotificationUnofficials } = paymentOrderUnofficialDraft;
        paymentOrderUnofficials.forEach((paymentOrderUnofficial) => {
            this.loadDates(paymentOrderUnofficial);
        });

        receivableNotificationUnofficials.forEach((receivableNotificationUnofficial) => {
            receivableNotificationUnofficial.audit!.created = dateService.transformDate(receivableNotificationUnofficial.audit!.created)!;
            receivableNotificationUnofficial.audit!.updated = dateService.transformDate(receivableNotificationUnofficial.audit!.updated)!;
        });
    }
}

const paymentOrderUnofficialApi: PaymentOrderUnofficialApi = new PaymentOrderUnofficialApi();
export default paymentOrderUnofficialApi;
