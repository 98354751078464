import { Page } from '../models/Elements';
import { ReceivableRegistration, ReceivableRegistrationDocument } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class ReceivableRegistrationDocumentApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId: number,
        searchText?: string
    ): Promise<Page<ReceivableRegistrationDocument>> => {
        const response = await adminApiAxios.get<Page<ReceivableRegistrationDocument>>(`/receivable-registration-documents`, {
            params: { page, size, sortField, sortOrder, councilId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<ReceivableRegistrationDocument> => {
        const response = await adminApiAxios.get<ReceivableRegistrationDocument>(`/receivable-registration-documents/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (receivableRegistrationDocument: ReceivableRegistrationDocument, file: any): Promise<ReceivableRegistrationDocument> => {
        const formData: FormData = this.buildFormData(receivableRegistrationDocument, file);
        const response = await adminApiAxios.post<ReceivableRegistrationDocument>(`/receivable-registration-documents`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    getResults = async (receivableRegistrationDocument: ReceivableRegistrationDocument, file: any): Promise<ReceivableRegistration[]> => {
        const formData: FormData = this.buildFormData(receivableRegistrationDocument, file);
        const response = await adminApiAxios.post<ReceivableRegistration[]>(`/receivable-registration-documents/results`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        response.data.forEach((item) => this.loadReceivableRegistrationDates(item));

        return response.data;
    };

    /**
     * Builds a form data from a receivable registration document.
     * @param receivableRegistrationDocument the receivable registration document
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (receivableRegistrationDocument: ReceivableRegistrationDocument, file?: File) => {
        const formData: FormData = new FormData();
        receivableRegistrationDocument.id && formData.append('id', receivableRegistrationDocument.id.toString());
        receivableRegistrationDocument.councilId && formData.append('councilId', receivableRegistrationDocument.councilId.toString());
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of a receivable registration document.
     * @param receivableRegistrationDocument - the receivable registration document
     */
    private loadDates(receivableRegistrationDocument: ReceivableRegistrationDocument) {
        receivableRegistrationDocument.audit!.created = dateService.transformDate(receivableRegistrationDocument.audit!.created)!;
        receivableRegistrationDocument.audit!.updated = dateService.transformDate(receivableRegistrationDocument.audit!.updated)!;
    }

    /**
     * Loads the dates of a receivable registration.
     * @param receivableRegistration - the receivable registration
     */
    private loadReceivableRegistrationDates(receivableRegistration: ReceivableRegistration) {
        receivableRegistration.audit!.created = dateService.transformDate(receivableRegistration.audit!.created)!;
        receivableRegistration.audit!.updated = dateService.transformDate(receivableRegistration.audit!.updated)!;
    }
}

const receivableRegistrationDocumentApi: ReceivableRegistrationDocumentApi = new ReceivableRegistrationDocumentApi();
export default receivableRegistrationDocumentApi;
