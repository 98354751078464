import { Page } from '../models/Elements';
import { PaymentOrderSign, PaymentOrderSignCreation, PaymentOrderSignDraft } from '../models/Entities';
import { PaymentOrderSignSignatureType, TaxType } from '../models/Types';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class PaymentOrderSignApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId?: number,
        searchText?: string
    ): Promise<Page<PaymentOrderSign>> => {
        const response = await adminApiAxios.get<Page<PaymentOrderSign>>('/payment-order-signs', {
            params: { page, size, sortField, sortOrder, councilId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<PaymentOrderSign> => {
        const response = await adminApiAxios.get<PaymentOrderSign>(`/payment-order-signs/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (paymentOrderSignCreation: PaymentOrderSignCreation): Promise<PaymentOrderSign[]> => {
        const response = await adminApiAxios.post<PaymentOrderSign[]>('/payment-order-signs', paymentOrderSignCreation);
        response.data.forEach((paymentOrderSign) => this.loadDates(paymentOrderSign));

        return response.data;
    };

    createDraft = async (paymentOrderSignCreation: PaymentOrderSignCreation): Promise<PaymentOrderSignDraft> => {
        const response = await adminApiAxios.post<PaymentOrderSignDraft>('/payment-order-signs/draft', paymentOrderSignCreation);
        this.loadDraftDates(response.data);

        return response.data;
    };

    downloadDraft = async (councilId: number, tax: TaxType, fiscalYear: number): Promise<Blob> => {
        const response = await adminApiAxios.post<Blob>(
            `/payment-order-signs/draft/file`,
            {},
            { responseType: 'blob', params: { councilId, tax, fiscalYear } }
        );

        return response.data;
    };

    download = async (id: number, signature: PaymentOrderSignSignatureType): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/payment-order-signs/${id}/file`, {
            responseType: 'blob',
            params: { signature }
        });

        return response.data;
    };

    sign = async (paymentOrderSign: PaymentOrderSign, signature: PaymentOrderSignSignatureType, signedFile: any): Promise<PaymentOrderSign> => {
        const formData: FormData = this.buildFormData(paymentOrderSign, signedFile);
        const response = await adminApiAxios.put<PaymentOrderSign>(`/payment-order-signs/${paymentOrderSign.id}/signs`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            params: {
                signature
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    /**
     * Builds a form data from a payment order sign.
     * @param paymentOrderSign the payment order sign
     * @returns the form data
     */
    private buildFormData = (paymentOrderSign: PaymentOrderSign, signedFile?: any) => {
        const formData: FormData = new FormData();
        paymentOrderSign.id && formData.append('id', paymentOrderSign.id.toString());
        signedFile && formData.append('signedFile.name', signedFile.name);
        signedFile && formData.append('signedFile.file', signedFile);
        return formData;
    };

    /**
     * Loads the dates of a paymentOrderSign.
     * @param paymentOrderSign - the payment order sign
     */
    private loadDates(paymentOrderSign: PaymentOrderSign) {
        paymentOrderSign.audit!.created = dateService.transformDate(paymentOrderSign.audit!.created)!;
        paymentOrderSign.audit!.updated = dateService.transformDate(paymentOrderSign.audit!.updated)!;
        paymentOrderSign.controllerSigned = dateService.transformDate(paymentOrderSign.controllerSigned)!;
        paymentOrderSign.treasurerSigned = dateService.transformDate(paymentOrderSign.treasurerSigned)!;
        paymentOrderSign.sent = dateService.transformDate(paymentOrderSign.sent)!;
    }

    /**
     * Loads the dates of the payment order sign draft.
     * @param paymentOrderSignDraft - the payment order sign draft
     */
    private loadDraftDates(paymentOrderSignDraft: PaymentOrderSignDraft) {
        const { paymentOrderSigns, receivableNotificationSigns } = paymentOrderSignDraft;
        paymentOrderSigns.forEach((paymentOrderSign) => {
            this.loadDates(paymentOrderSign);
        });

        receivableNotificationSigns.forEach((receivableNotificationSign) => {
            receivableNotificationSign.audit!.created = dateService.transformDate(receivableNotificationSign.audit!.created)!;
            receivableNotificationSign.audit!.updated = dateService.transformDate(receivableNotificationSign.audit!.updated)!;
        });
    }
}

const paymentOrderSignApi: PaymentOrderSignApi = new PaymentOrderSignApi();
export default paymentOrderSignApi;
