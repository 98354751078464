import { ArrowLeftOutlined, CloudUploadOutlined, DeleteOutlined, PaperClipOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, List, Row, Space, Upload } from 'antd';
import { RuleObject } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';
import { UploadFile } from 'antd/lib';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import receivableRegistrationDocumentApi from '../../../apis/ReceivableRegistrationDocumentApi';
import FileSizeComponent from '../../../components/FileSizeComponent/FileSizeComponent';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import { Council, ReceivableRegistration, ReceivableRegistrationDocument } from '../../../models/Entities';
import alertService from '../../../services/AlertService';
import styles from './UploadComponent.module.scss';

/**
 * Returns the upload component.
 * @returns the upload component.
 */
const UploadComponent: React.FC<Props> = (props) => {
    const maxFileSize = 5 * 1024 * 1024;
    const { council, onLoad } = props;

    /***HOOKS***/

    const intl = useIntl();
    const [form] = Form.useForm();
    const [desktop] = useResponsiveLayout();
    const [loading, setLoading] = useState<'loading'>();
    const [files, setFiles] = useState<UploadFile[]>([]);

    /***METHODS***/

    const loadDocument = async (values: any) => {
        try {
            setLoading('loading');
            const receivableRegistrationDocument: ReceivableRegistrationDocument = {
                councilId: council.id
            };
            const results = await receivableRegistrationDocumentApi.getResults(receivableRegistrationDocument, files[0]);
            onLoad(files[0], results);
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const uploadFile = (file: UploadFile) => {
        const files: UploadFile[] = [];
        if (!isFileSizeValid(file)) {
            setFiles(files);
        } else {
            files.push(file);
            setFiles(files);
        }

        return false;
    };

    const removeFile = () => {
        const files: UploadFile[] = [];
        form.setFieldsValue({
            file: files
        });
        setFiles(files);
    };

    const validateFile = (rule: RuleObject, value: StoreValue, callback: (error?: string) => void): Promise<void> | void => {
        if (value && value.file && !isFileSizeValid(value.file)) {
            callback(intl.formatMessage({ id: 'status.file.size' }));
        }
        callback();
    };

    const isFileSizeValid = (file: UploadFile) => !file.size || file.size <= maxFileSize;

    /***VISUAL***/

    return (
        <Form form={form} onFinish={loadDocument} colon={false} layout="vertical">
            <Row gutter={[28, 0]}>
                <Col span={12}>
                    <Form.Item
                        name="file"
                        valuePropName="files"
                        label={
                            <>
                                <span>
                                    <FormattedMessage id="receivables.import.steps.1.upload" />
                                </span>
                                <a
                                    href={`${process.env.PUBLIC_URL}/receivable-registration-document.xlsx`}
                                    download={intl.formatMessage({
                                        id: 'receivables.import.steps.1.upload.file'
                                    })}
                                    className={styles.link}
                                >
                                    <FormattedMessage id="receivables.import.steps.1.upload.link" />
                                </a>
                            </>
                        }
                        rules={[
                            { required: true, message: <FormattedMessage id="status.mandatory" /> },
                            {
                                validator: validateFile
                            }
                        ]}
                        extra={
                            <>
                                <FormattedMessage id="attachment.size" /> <FileSizeComponent value={maxFileSize} />
                            </>
                        }
                    >
                        <Upload.Dragger beforeUpload={uploadFile} fileList={files} showUploadList={false}>
                            <CloudUploadOutlined /> <FormattedMessage id="receivables.import.steps.1.upload.button" />
                        </Upload.Dragger>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <List
                        className={styles.files}
                        itemLayout="horizontal"
                        dataSource={files}
                        locale={{ emptyText: <></> }}
                        renderItem={(file) => (
                            <List.Item actions={[<Button icon={<DeleteOutlined />} danger size="large" onClick={removeFile} />]}>
                                <List.Item.Meta
                                    avatar={<PaperClipOutlined className={styles.icon} />}
                                    title={file.name}
                                    description={<FileSizeComponent value={file.size} />}
                                />
                            </List.Item>
                        )}
                    />
                </Col>
            </Row>
            <Row gutter={[28, 0]} className="buttons">
                <Col span={24}>
                    <Space>
                        <Button type="primary" htmlType="submit" size="large" loading={loading === 'loading'} icon={<SaveOutlined />}>
                            <FormattedMessage id="button.load" tagName="span" />
                        </Button>
                        <Link to={`/councils/${council.id}/receivables`}>
                            <Button size="large" icon={<ArrowLeftOutlined />}>
                                {desktop && <FormattedMessage id="button.back" tagName="span" />}
                            </Button>
                        </Link>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};
export default UploadComponent;

interface Props {
    council: Council;
    onLoad: (file: UploadFile, results: ReceivableRegistration[]) => Promise<void>;
}
