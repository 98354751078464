import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import FileSaver from 'file-saver';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import announcementApi from '../../../../apis/AnnouncementApi';
import { ReceivablePaymentOrderAnnouncementSign } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivablePaymentOrderAnnouncementSignComponent.module.scss';

const ReceivablePaymentOrderAnnouncementSignComponent = (props: Props) => {
    const { paymentOrderAnnouncementSign } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading' | 'downloadingXml'>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            const document = await announcementApi.download(paymentOrderAnnouncementSign.announcementId, 'LATEST');
            FileSaver.saveAs(document, intl.formatMessage({ id: 'announcement.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const downloadXml = async () => {
        try {
            setLoading('downloadingXml');
            const document = await announcementApi.downloadXml(paymentOrderAnnouncementSign.announcementId);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'announcement.xml.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <Row gutter={[28, 0]} className={styles.buttons}>
            <Col span={24}>
                <Space>
                    <Button icon={<CloudDownloadOutlined />} onClick={download} loading={loading === 'downloading'}>
                        <FormattedMessage id="button.download" tagName="span" />
                    </Button>
                    <Button icon={<CloudDownloadOutlined />} onClick={downloadXml} loading={loading === 'downloading'}>
                        <FormattedMessage id="announcement.button.download.xml" tagName="span" />
                    </Button>
                </Space>
            </Col>
        </Row>
    );
};

export default ReceivablePaymentOrderAnnouncementSignComponent;

interface Props {
    paymentOrderAnnouncementSign: ReceivablePaymentOrderAnnouncementSign;
}
