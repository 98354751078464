import { CloudDownloadOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, CheckboxProps, Col, List, Modal, Row, Space, message } from 'antd';
import FileSaver from 'file-saver';
import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import paymentOrderUnofficialApi from '../../apis/PaymentOrderUnofficialApi';
import { PaymentOrderUnofficial, PaymentOrderUnofficialCreation, PaymentOrderUnofficialDraft, ReceivableNotificationUnofficial } from '../../models/Entities';
import styles from './PaymentOrderUnofficialModal.module.scss';
import alertService from '../../services/AlertService';

const PaymentOrderUnofficialModal: React.FC<Props> = (props) => {
    const { councilId, councilTaxpayerId, clone } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'loading' | 'sending'>();
    const [paymentOrderUnofficialDraft, setPaymentOrderUnofficialDraft] = useState<PaymentOrderUnofficialDraft>();
    const [, setPaymentOrderUnofficialCreation] = useState<PaymentOrderUnofficialCreation>();
    const [councilTaxpayerIds, setCouncilTaxpayerIds] = useState<number[]>([]);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');
                let councilTaxpayerIds = councilTaxpayerId ? [councilTaxpayerId] : [];
                const paymentOrderUnofficialCreation: PaymentOrderUnofficialCreation = {
                    councilId,
                    councilTaxpayerIds,
                    clone
                };
                setPaymentOrderUnofficialCreation(paymentOrderUnofficialCreation);
                //create draft
                const paymentOrderUnofficialDraft = await paymentOrderUnofficialApi.createDraft(paymentOrderUnofficialCreation);
                setPaymentOrderUnofficialDraft(paymentOrderUnofficialDraft);

                //select all council taxpayer ids
                councilTaxpayerIds = paymentOrderUnofficialDraft.paymentOrderUnofficials.map((po) => po.councilTaxpayer.id);
                setCouncilTaxpayerIds(councilTaxpayerIds);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [clone, councilId, councilTaxpayerId, intl]);

    /*** METHODS ***/

    const send = async () => {
        try {
            setLoading('sending');
            const updatedPaymentOrderUnofficialCreation: PaymentOrderUnofficialCreation = {
                councilId,
                councilTaxpayerIds,
                clone
            };

            await paymentOrderUnofficialApi.create(updatedPaymentOrderUnofficialCreation);
            await props.onSave();
            message.success(intl.formatMessage({ id: 'status.sent' }));
        } catch (error: any) {
            !error.errorFields && alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const cancel = async () => {
        props.onCancel();
    };

    const filterReceivableNotificationUnofficials = (paymentOrderUnofficial: PaymentOrderUnofficial) => {
        return paymentOrderUnofficialDraft!.receivableNotificationUnofficials.filter(
            (rnu) => rnu.paymentOrderUnofficial.councilTaxpayer.taxpayer.id === paymentOrderUnofficial.councilTaxpayer.taxpayer.id
        );
    };

    const updateCouncilTaxpayerIds = (councilTaxpayerId: number) => {
        if (councilTaxpayerIds.includes(councilTaxpayerId)) {
            setCouncilTaxpayerIds(councilTaxpayerIds.filter((e) => e !== councilTaxpayerId));
        } else {
            setCouncilTaxpayerIds([...councilTaxpayerIds, councilTaxpayerId]);
        }
    };

    /*** VISUAL ***/

    const paymentOrderUnofficials = paymentOrderUnofficialDraft ? paymentOrderUnofficialDraft.paymentOrderUnofficials : [];

    return (
        <Modal
            title={<FormattedMessage id="paymentOrderUnofficialModal.title" />}
            open={true}
            onCancel={cancel}
            onOk={() => send()}
            okText={<FormattedMessage id="button.send" tagName="span" />}
            okButtonProps={{
                loading: loading === 'sending',
                size: 'large',
                disabled: !paymentOrderUnofficialDraft || paymentOrderUnofficialDraft.paymentOrderUnofficials.length === 0
            }}
            cancelButtonProps={{ size: 'large' }}
            width={1000}
        >
            <Row gutter={[0, 0]} className={styles.container}>
                {!clone && (
                    <Col span={24}>
                        <FormattedMessage id="paymentOrderUnofficialModal.description" />
                    </Col>
                )}
                {clone && (
                    <Col span={24}>
                        <FormattedMessage id="paymentOrderUnofficialModal.description.clone" />
                        <p className={styles.alert}>
                            <InfoCircleOutlined /> <FormattedMessage id="paymentOrderUnofficialModal.description.clone.alert" />
                        </p>
                    </Col>
                )}
                <Col span={24}>
                    <List
                        className={styles.list}
                        size="small"
                        itemLayout="horizontal"
                        dataSource={paymentOrderUnofficials}
                        loading={loading === 'loading'}
                        renderItem={(paymentOrderUnofficial) => (
                            <PaymentOrderUnofficialComponent
                                paymentOrderUnofficial={paymentOrderUnofficial}
                                onSelected={updateCouncilTaxpayerIds}
                                receivableNotificationUnofficials={filterReceivableNotificationUnofficials(paymentOrderUnofficial)}
                                clone={clone}
                            />
                        )}
                    />
                </Col>
            </Row>
        </Modal>
    );
};
export default PaymentOrderUnofficialModal;

interface Props {
    councilId: number;
    councilTaxpayerId?: number;
    clone: boolean;
    onSave: () => Promise<void>;
    onCancel: () => void;
}

const PaymentOrderUnofficialComponent: React.FC<PaymentOrderUnofficialComponentProps> = (props) => {
    const { paymentOrderUnofficial, receivableNotificationUnofficials, clone } = props;

    /*** HOOKS ***/

    const intl = useIntl();
    const [loading, setLoading] = useState<'downloading'>();

    /*** METHODS ***/

    const download = async (paymentOrderUnofficial: PaymentOrderUnofficial) => {
        try {
            setLoading('downloading');
            const document = await paymentOrderUnofficialApi.downloadDraft(paymentOrderUnofficial.councilTaxpayer.id, clone);
            FileSaver.saveAs(document, intl.formatMessage({ id: 'paymentOrderUnofficial.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const selectCouncilTaxpayerId: CheckboxProps['onChange'] = (e) => {
        props.onSelected(e.target.value);
    };

    /*** VISUAL ***/

    const totalPrincipal: string = intl.formatNumber(
        receivableNotificationUnofficials.map((rnu) => rnu.amount!.principal).reduce((partialSum, a) => partialSum + a, 0),
        {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'EUR'
        }
    );
    const totalSurcharge: string = intl.formatNumber(
        receivableNotificationUnofficials.map((rnu) => rnu.amount!.surcharge).reduce((partialSum, a) => partialSum + a, 0),
        {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'EUR'
        }
    );
    const totalCosts: string = intl.formatNumber(paymentOrderUnofficial.costs, {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'EUR'
    });

    const totalTotal: string = intl.formatNumber(
        receivableNotificationUnofficials.map((rnu) => rnu.amount!.total).reduce((partialSum, a) => partialSum + a, paymentOrderUnofficial.costs),
        {
            maximumFractionDigits: 2,
            style: 'currency',
            currency: 'EUR'
        }
    );

    return (
        <List.Item
            actions={[
                <Button
                    icon={<CloudDownloadOutlined />}
                    danger
                    size="large"
                    onClick={() => download(paymentOrderUnofficial)}
                    loading={loading === `downloading`}
                />
            ]}
        >
            <List.Item.Meta
                title={
                    <span>
                        <Checkbox value={paymentOrderUnofficial.councilTaxpayer.id} onChange={selectCouncilTaxpayerId} defaultChecked>
                            {paymentOrderUnofficial.councilTaxpayer.taxpayer.fullName}
                        </Checkbox>
                    </span>
                }
                description={
                    <Space split="|">
                        <FormattedMessage id="paymentOrderUnofficialModal.receivables" values={{ receivables: receivableNotificationUnofficials.length }} />
                        <FormattedMessage
                            id="paymentOrderUnofficialModal.total.principal"
                            values={{
                                totalPrincipal: totalPrincipal
                            }}
                        />
                        <FormattedMessage
                            id="paymentOrderUnofficialModal.total.surcharge"
                            values={{
                                totalSurcharge: totalSurcharge
                            }}
                        />
                        <FormattedMessage
                            id="paymentOrderUnofficialModal.total.costs"
                            values={{
                                totalCosts: totalCosts
                            }}
                        />
                        <strong>
                            <FormattedMessage
                                id="paymentOrderUnofficialModal.total.total"
                                values={{
                                    total: totalTotal
                                }}
                            />
                        </strong>
                    </Space>
                }
            />
        </List.Item>
    );
};

interface PaymentOrderUnofficialComponentProps {
    paymentOrderUnofficial: PaymentOrderUnofficial;
    onSelected: (councilTaxpayerId: number) => void;
    receivableNotificationUnofficials: ReceivableNotificationUnofficial[];
    clone: boolean;
}
