import { Page } from '../models/Elements';
import { SeizureBankInfoResponse, SeizureBankInfoResponseDraft } from '../models/Entities';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';

class SeizureBankInfoResponseApi {
    list = async (
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        councilId?: number,
        searchText?: string
    ): Promise<Page<SeizureBankInfoResponse>> => {
        const response = await adminApiAxios.get<Page<SeizureBankInfoResponse>>('/seizure-bank-info-responses', {
            params: { page, size, sortField, sortOrder, councilId, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((item) => this.loadDates(item));

        return response.data;
    };

    get = async (id: number): Promise<SeizureBankInfoResponse> => {
        const response = await adminApiAxios.get<SeizureBankInfoResponse>(`/seizure-bank-info-responses/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (seizureBankInfoResponse: SeizureBankInfoResponse, file: any): Promise<SeizureBankInfoResponse> => {
        const formData: FormData = this.buildFormData(seizureBankInfoResponse, file);
        const response = await adminApiAxios.post<SeizureBankInfoResponse>(`/seizure-bank-info-responses`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDates(response.data);

        return response.data;
    };

    createDraft = async (seizureBankInfoResponse: SeizureBankInfoResponse, file: any): Promise<SeizureBankInfoResponseDraft> => {
        const formData: FormData = this.buildFormData(seizureBankInfoResponse, file);
        const response = await adminApiAxios.post<SeizureBankInfoResponseDraft>(`/seizure-bank-info-responses/draft`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        this.loadDraftDates(response.data);

        return response.data;
    };

    download = async (id: number): Promise<Blob> => {
        const response = await adminApiAxios.get<Blob>(`/seizure-bank-info-responses/${id}/file`, {
            responseType: 'blob'
        });

        return response.data;
    };

    /**
     * Builds a form data from a seizure bank account info response.
     * @param seizureBankInfoResponse the seizure bank account info response
     * @param file the file
     * @returns the form data
     */
    private buildFormData = (seizureBankInfoResponse: SeizureBankInfoResponse, file?: File) => {
        const formData: FormData = new FormData();
        seizureBankInfoResponse.id && formData.append('id', seizureBankInfoResponse.id.toString());
        seizureBankInfoResponse.councilId && formData.append('councilId', seizureBankInfoResponse.councilId.toString());
        seizureBankInfoResponse.bankId && formData.append('bankId', seizureBankInfoResponse.bankId.toString());
        file && formData.append('file.name', file.name);
        file && formData.append('file.file', file);

        return formData;
    };

    /**
     * Loads the dates of the seizure bank account info response.
     * @param seizureBankInfoResponse - the seizure bank info response
     */
    private loadDates(seizureBankInfoResponse: SeizureBankInfoResponse) {
        seizureBankInfoResponse.audit!.created = dateService.transformDate(seizureBankInfoResponse.audit!.created)!;
        seizureBankInfoResponse.audit!.updated = dateService.transformDate(seizureBankInfoResponse.audit!.updated)!;
    }

    /**
     * Loads the dates of the seizure bank info response draft.
     * @param seizureBankInfoResponseDraft - the seizure bank info response draft
     */
    private loadDraftDates(seizureBankInfoResponseDraft: SeizureBankInfoResponseDraft) {
        const { seizureBankInfoResponse, ctSeizureBankInfoResponses } = seizureBankInfoResponseDraft;
        this.loadDates(seizureBankInfoResponse);
        ctSeizureBankInfoResponses.forEach((ctSeizureBankInfoResponse) => {
            ctSeizureBankInfoResponse.audit.created = dateService.transformDate(ctSeizureBankInfoResponse.audit!.created)!;
            ctSeizureBankInfoResponse.audit.updated = dateService.transformDate(ctSeizureBankInfoResponse.audit!.updated)!;
        });
    }
}

const seizureBankInfoResponseApi: SeizureBankInfoResponseApi = new SeizureBankInfoResponseApi();
export default seizureBankInfoResponseApi;
