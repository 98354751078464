import { Page } from '../models/Elements';
import dateService from '../services/DateService';
import { adminApiAxios } from './CustomAxios';
import { User } from '../models/Entities';
import { StatusType } from '../models/Types';

class CouncilUserApi {
    list = async (
        councilId: number,
        page: number,
        size: number,
        sortField: string,
        sortOrder: boolean,
        status: StatusType,
        searchText?: string
    ): Promise<Page<User>> => {
        const response = await adminApiAxios.get<Page<User>>(`/councils/${councilId}/users`, {
            params: { councilId, page, size, sortField, sortOrder, status, searchText }
        });
        response.data.sort.field = sortField;
        response.data.sort.order = sortOrder;
        response.data.content.forEach((user) => this.loadDates(user));

        return response.data;
    };

    get = async (councilId: number, id: number): Promise<User> => {
        const response = await adminApiAxios.get<User>(`/councils/${councilId}/users/${id}`);
        this.loadDates(response.data);

        return response.data;
    };

    create = async (user: User): Promise<User> => {
        const response = await adminApiAxios.post<User>(`/councils/${user.councilId}/users`, user);
        this.loadDates(response.data);

        return response.data;
    };

    update = async (user: User): Promise<User> => {
        const response = await adminApiAxios.put<User>(`/councils/${user.councilId}/users/${user.id}`, user);
        this.loadDates(response.data);

        return response.data;
    };

    delete = async (councilId: number, id: number): Promise<void> => {
        const response = await adminApiAxios.delete<void>(`/councils/${councilId}/users/${id}`);

        return response.data;
    };

    /**
     * Loads the dates of a council user.
     * @param user - the council user
     */
    private loadDates(user: User) {
        user.audit!.created = dateService.transformDate(user.audit!.created)!;
        user.audit!.updated = dateService.transformDate(user.audit!.updated)!;
    }
}

const councilUserApi: CouncilUserApi = new CouncilUserApi();
export default councilUserApi;
