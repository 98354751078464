import { CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space } from 'antd';
import FileSaver from 'file-saver';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import announcementApi from '../../../../apis/AnnouncementApi';
import { ReceivablePaymentOrderAnnouncementPublished } from '../../../../models/Entities';
import alertService from '../../../../services/AlertService';
import styles from './ReceivablePaymentOrderAnnouncementPublishedComponent.module.scss';

const ReceivablePaymentOrderAnnouncementPublishedComponent = (props: Props) => {
    const { paymentOrderAnnouncementPublished } = props;

    /*** HOOKS ***/

    const intl = useIntl();

    const [loading, setLoading] = useState<'downloading'>();

    /*** EFFECTS ***/

    /*** METHODS ***/

    const download = async () => {
        try {
            setLoading('downloading');
            const document = await announcementApi.download(paymentOrderAnnouncementPublished.announcementId, 'LATEST');
            FileSaver.saveAs(document, intl.formatMessage({ id: 'announcement.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    return (
        <Row gutter={[28, 0]} className={styles.buttons}>
            <Col span={24}>
                <Space>
                    <Button icon={<CloudDownloadOutlined />} onClick={download} loading={loading === 'downloading'}>
                        <FormattedMessage id="button.download" tagName="span" />
                    </Button>
                </Space>
            </Col>
        </Row>
    );
};

export default ReceivablePaymentOrderAnnouncementPublishedComponent;

interface Props {
    paymentOrderAnnouncementPublished: ReceivablePaymentOrderAnnouncementPublished;
}
