import { ArrowLeftOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, message } from 'antd';
import { Space } from 'antd/lib';
import FileSaver from 'file-saver';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Document, Page } from 'react-pdf';
import { DocumentCallback } from 'react-pdf/dist/cjs/shared/types';
import { Link, useParams } from 'react-router-dom';
import seizureIncomeSignApi from '../../../apis/SeizureIncomeSignApi';
import ConstantLabel from '../../../components/ConstantLabel/ConstantLabel';
import LayoutComponent from '../../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomContext from '../../../contexts/CustomContext';
import CustomCouncilContext from '../../../contexts/CustomCouncilContext';
import { SeizureIncomeSign } from '../../../models/Entities';
import { SeizureIncomeSignSignatureType, seizureIncomeTypes } from '../../../models/Types';
import alertService from '../../../services/AlertService';
import styles from './SeizureIncomeSignPage.module.scss';

/**
 * Returns the seizure order sign page.
 * @returns the seizure order sign page.
 */
const SeizureIncomeSignPage: React.FC = () => {
    /***HOOKS***/
    const intl = useIntl();
    const params = useParams<ParamsType>();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const { auth } = useContext(CustomContext);
    const { council } = useContext(CustomCouncilContext);
    const [loading, setLoading] = useState<'loading' | 'signing' | 'downloading'>();
    const [seizureIncomeSign, setSeizureIncomeSign] = useState<SeizureIncomeSign>();
    const [document, setDocument] = useState<Blob>();
    const [pages, setPages] = useState<number>(0);

    /*** EFFECTS ***/

    useEffect(() => {
        const init = async () => {
            try {
                setLoading('loading');

                const seizureIncomeSign = await seizureIncomeSignApi.get(+params.id!);
                setSeizureIncomeSign(seizureIncomeSign);
                form.setFieldsValue(seizureIncomeSign);

                const document: Blob = await seizureIncomeSignApi.download(seizureIncomeSign.id!, 'LATEST');
                setDocument(document);
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [council, form, intl, params.id]);

    /*** METHODS ***/

    const sign = async (signature: SeizureIncomeSignSignatureType) => {
        try {
            setLoading('signing');
            if (seizureIncomeSign && document) {
                // const AutoScript = window.AutoScript as any;
                // const documentBase64 = await receivableService.blobToBase64(document);
                // const formattedDocumentBase64: string = (documentBase64 as string).replace('data:text/xml;base64,', '');
                // const parameters: string[] = [
                //     `signaturePositionOnPageLowerLeftX = ${signature === 'CONTROLLER' ? '20' : '300'}`,
                //     'signaturePositionOnPageLowerLeftY = 10',
                //     `signaturePositionOnPageUpperRightX = ${signature === 'CONTROLLER' ? '320' : '600'}`,
                //     'signaturePositionOnPageUpperRightY = 50',
                //     'signaturePages = 1',
                //     'layer2FontSize = 8'
                // ];
                // const formattedParameters: string = parameters.join('\n');

                // AutoScript.sign(
                //     formattedDocumentBase64,
                //     'SHA512withRSA',
                //     'PAdES',
                //     formattedParameters,
                //     (signatureB64: string, certificateB64: string, extraData: any) => {
                //         const signedDocument = receivableService.b64toBlob(signatureB64, 'application/pdf');
                //         save(paymentOrderSign, signature, signedDocument);
                //         // const blobUrl = URL.createObjectURL(signedDocument);
                //         // (window as any).location = blobUrl;

                //         //
                //     },
                //     (type: string, error: string) => {
                //         message.error(error);
                //     }
                // );

                save(seizureIncomeSign, signature, document);
            }
        } catch (error: any) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const save = async (seizureIncomeSign: SeizureIncomeSign, signature: SeizureIncomeSignSignatureType, signedDocument: Blob) => {
        try {
            const updatedSeizureIncomeSign = await seizureIncomeSignApi.sign(seizureIncomeSign, signature, signedDocument);
            setSeizureIncomeSign(updatedSeizureIncomeSign);
            form.setFieldsValue(updatedSeizureIncomeSign);

            // refresh document
            const document: Blob = await seizureIncomeSignApi.download(updatedSeizureIncomeSign.id!, 'LATEST');
            setDocument(document);

            message.success(intl.formatMessage({ id: 'status.saved' }));
        } catch (error: any) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    const initDocument = (document: DocumentCallback) => {
        setPages(document.numPages);
    };

    const download = async (seizureIncomeSign: SeizureIncomeSign) => {
        try {
            setLoading('downloading');
            const document = await seizureIncomeSignApi.download(seizureIncomeSign.id!, 'LATEST');
            FileSaver.saveAs(document, intl.formatMessage({ id: 'seizureIncomeSign.file.name' }));
        } catch (error) {
            alertService.displayError(error, intl);
        } finally {
            setLoading(undefined);
        }
    };

    /*** VISUAL ***/

    const isTreasurerAllowedToSign = seizureIncomeSign && !seizureIncomeSign.treasurerSigned && seizureIncomeSign.treasurer.id === auth?.id;

    const incomeOptions = seizureIncomeTypes.map((sit) => (
        <Select.Option key={sit} value={sit}>
            <ConstantLabel value={sit} prefix="seizureIncomeSign.income." />
        </Select.Option>
    ));

    return (
        <LayoutComponent
            title={<FormattedMessage id="users.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/seizure-income-signs`, name: <FormattedMessage id="seizureIncomeSigns.title" /> },
                {
                    path: `/councils/${council?.id}/seizure-income-signs/${seizureIncomeSign?.id}`,
                    name: seizureIncomeSign?.id
                }
            ]}
            council={council}
        >
            <Form form={form} onFinish={sign} colon={false} layout="vertical">
                <Row gutter={[28, 0]}>
                    <Col span={12} lg={18}>
                        <Form.Item label={<FormattedMessage id="seizureIncomeSign.income" />} name="income">
                            <Select disabled>{incomeOptions}</Select>
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={6}>
                        <Form.Item label={<FormattedMessage id="seizureIncomeSign.treasurer" />} name={['treasurer', 'fullName']}>
                            <Input size="middle" maxLength={100} disabled />
                        </Form.Item>
                    </Col>
                    <Col span={12} lg={4}>
                        <Form.Item label={<FormattedMessage id="seizureIncomeSign.treasurerSigned" />} name="treasurerSigned">
                            <DatePicker format="lll" disabled className={styles.date} placeholder="" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[28, 0]}>
                    <Col span={24}>
                        <div className={styles.document}>
                            {document && (
                                <Document file={document} onLoadSuccess={initDocument}>
                                    {Array.from(new Array(pages), (el, index) => (
                                        <Page key={`page${index + 1}`} pageNumber={index + 1} width={935} />
                                    ))}
                                </Document>
                            )}
                        </div>
                    </Col>
                </Row>
                <Row gutter={[28, 0]} className="buttons">
                    <Col span={16}>
                        <Space>
                            <Button
                                type="primary"
                                onClick={() => sign('TREASURER')}
                                size="large"
                                loading={loading === 'loading' || loading === 'signing'}
                                icon={<CheckOutlined />}
                                disabled={!isTreasurerAllowedToSign}
                            >
                                <FormattedMessage id="seizureIncomeSign.sign.treasurer" tagName="span" />
                            </Button>
                            <Button type="primary" onClick={() => download(seizureIncomeSign!)} size="large" loading={loading === 'downloading'}>
                                <FormattedMessage id="button.download" tagName="span" />
                            </Button>
                            <Link to={`/councils/${council?.id}/seizure-income-signs`}>
                                <Button size="large" icon={<ArrowLeftOutlined />}>
                                    {desktop && <FormattedMessage id="button.back" tagName="span" />}
                                </Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>
            </Form>
        </LayoutComponent>
    );
};

export default SeizureIncomeSignPage;
type ParamsType = { id: string };
