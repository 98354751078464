import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Row, Space, Table, Tooltip } from 'antd';
import Search from 'antd/es/input/Search';
import { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import bankApi from '../../apis/BankApi';
import LayoutComponent from '../../components/LayoutComponent/LayoutComponent';
import useResponsiveLayout from '../../components/LayoutComponent/UseResponsiveLayout/UseResponsiveLayout';
import CustomCouncilContext from '../../contexts/CustomCouncilContext';
import { Page } from '../../models/Elements';
import { Bank } from '../../models/Entities';
import alertService from '../../services/AlertService';
import tableService from '../../services/TableService';
import styles from './CouncilBanksPage.module.scss';
import rolesService from '../../services/RolesService';
import CustomContext from '../../contexts/CustomContext';

/**
 * Returns the council banks page.
 * @returns the council banks page.
 */

const CouncilBanksPage: React.FC = () => {
    /***HOOKS */

    const intl = useIntl();
    const [desktop] = useResponsiveLayout();
    const [form] = Form.useForm();
    const { auth } = useContext(CustomContext);
    const { council } = useContext(CustomCouncilContext);
    const [filter, setFilter] = useState<Filter>({});
    const [councilBanksPage, setCouncilBanksPage] = useState<Page<Bank>>();
    const [page, setPage] = useState<number>(0);
    const [sortField, setSortField] = useState<string>('name');
    const [sortOrder, setSortOrder] = useState<boolean>(true);
    const [loading, setLoading] = useState<'loading'>();

    // list council banks
    useEffect(() => {
        const init = async () => {
            try {
                if (council && council.id) {
                    setLoading('loading');
                    const councilBanksPage = await bankApi.list(
                        page,
                        100,
                        sortField,
                        sortOrder,
                        'ENABLED',
                        filter.searchText,
                        undefined,
                        undefined,
                        council.id
                    );
                    setCouncilBanksPage(councilBanksPage);
                }
            } catch (error) {
                alertService.displayError(error, intl);
            } finally {
                setLoading(undefined);
            }
        };
        init();
    }, [intl, page, sortField, sortOrder, filter, council]);

    /*** METHODS ***/

    const handleTableChange = async (pagination: TablePaginationConfig, filters: any, sorter: any) => {
        setPage(pagination.current ? pagination.current - 1 : 0);
        setSortField(sorter.field);
        setSortOrder(sorter.order === 'ascend');
    };

    const filterCouncilBanks = async (values: any) => {
        const filter: Filter = {
            searchText: values.searchText
        };
        setFilter(filter);
    };

    /*** VISUAL ***/

    const isAdmin = rolesService.hasAnyRole(auth, ['ROLE_ADMIN']);

    const items = councilBanksPage ? councilBanksPage.content : [];
    const columns: ColumnsType<Bank> = [
        {
            title: <FormattedMessage id="bank.name" />,
            dataIndex: 'name',
            key: 'name',
            sorter: true,
            defaultSortOrder: 'ascend',
            render: (value: string, councilBank: Bank) => <Link to={`/councils/${council?.id}/banks/${councilBank.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="address.street" />,
            dataIndex: ['address', 'street'],
            key: 'street',
            width: 350,
            render: (value: string, councilBank: Bank) => <Link to={`/councils/${council?.id}/banks/${councilBank.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="address.email" />,
            dataIndex: ['address', 'email'],
            key: 'email',
            width: 250,
            render: (value: string, councilBank: Bank) => <Link to={`/councils/${council?.id}/banks/${councilBank.id}`}>{value}</Link>
        },
        {
            title: <FormattedMessage id="address.phone" />,
            dataIndex: ['address', 'phone'],
            key: 'phone',
            width: 200,
            render: (value: string, councilBank: Bank) => <Link to={`/councils/${council?.id}/banks/${councilBank.id}`}>{value}</Link>
        }
    ];

    return (
        <LayoutComponent
            title={<FormattedMessage id="councilBanks.title" />}
            menu="councils"
            path={[
                { path: '/councils', name: <FormattedMessage id="councils.title" /> },
                { path: `/councils/${council?.id}`, name: council?.name },
                { path: `/councils/${council?.id}/banks`, name: <FormattedMessage id="councilBanks.title" /> }
            ]}
            council={council}
        >
            <Form form={form} onFinish={filterCouncilBanks} colon={false} layout="vertical" requiredMark={false}>
                <Row gutter={[48, 16]}>
                    <Col span={18}>
                        <Form.Item name="searchText">
                            <Search
                                placeholder={intl.formatMessage({
                                    id: 'councilBanks.search'
                                })}
                                size="large"
                                allowClear
                                onSearch={form.submit}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={6} className={styles.buttons}>
                        <Space>
                            <Link to={`/councils/${council?.id}`}>
                                <Button size="large" icon={<ArrowLeftOutlined />} />
                            </Link>
                            {isAdmin && (
                                <Link to={`/councils/${council?.id}/banks/new`}>
                                    <Tooltip title={<FormattedMessage id="councilBanks.new.tooltip" />}>
                                        <Button type="primary" size="large" icon={<PlusOutlined />}>
                                            {desktop && <FormattedMessage id="button.add" tagName="span" />}
                                        </Button>
                                    </Tooltip>
                                </Link>
                            )}
                        </Space>
                    </Col>
                </Row>
            </Form>
            <Table
                dataSource={items}
                columns={columns}
                pagination={tableService.createPagination(councilBanksPage)}
                rowKey="id"
                onChange={handleTableChange}
                sortDirections={['ascend', 'descend']}
                showSorterTooltip={false}
                loading={loading === 'loading'}
                className="table"
            />
        </LayoutComponent>
    );
};
export default CouncilBanksPage;

interface Filter {
    searchText?: string | undefined;
}
